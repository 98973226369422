import {Component, OnInit} from '@angular/core';
import {AgGridDataManagerListComponent} from "../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {Model} from "../../../../models/model";
import {SimpleDataManagerFormComponent} from "../../../code-template/simple-data-manager/simple-data-manager-form/simple-data-manager-form.component";
import {SimpleDataManagerPrintComponent} from "../../../code-template/simple-data-manager/simple-data-manager-print/simple-data-manager-print.component";
import {RootServices} from "../../../../services/root.services";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {CommonService} from "../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {HttpClient} from "@angular/common/http";
import {CodeTemplateStateMap} from "../../../code-template/code-template.state.map";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import {agMakeStateColDef} from "../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {ZaloOaTemplateFormComponent} from "../zalo-oa-template-form/zalo-oa-template-form.component";
import {agMakeButtonsColDef} from "../../../../lib/custom-element/ag-list/column-define/buttons.define";
import {IRowNode} from "ag-grid-community";
import {ZaloOaOfficialAccountModel} from "../../../../models/zalo-oa.model";
import {ShowcaseDialogComponent} from "../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component";
import {DialogFormComponent, DialogFormControl} from "../../../../lib/component/dialog/dialog-form/dialog-form.component";

@Component({
  selector: 'ngx-zalo-oa-template-list',
  templateUrl: './zalo-oa-template-list.component.html',
  styleUrls: ['./zalo-oa-template-list.component.scss']
})
export class ZaloOaTemplateListComponent extends AgGridDataManagerListComponent<Model, SimpleDataManagerFormComponent> implements OnInit {

  feature = {
    Module: {id: 'ZaloOa', text: 'Zalo OA'},
    Feature: {id: 'Template', text: 'Mẫu ZNS'}
  };

  componentName: string = 'ZaloOaTemplateListComponent';
  apiPath = '/zalo-oa/templates';
  idKey = ['Code'];
  formDialog = ZaloOaTemplateFormComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<ZaloOaTemplateListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      const processingMap = CodeTemplateStateMap.simpleDataManagerStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 250,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 300,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Template ID',
          field: 'TemplateId',
          width: 200,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Zalo OA App',
          field: 'ZaloOa',
          width: 200,
        },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'sendZns',
              label: 'Gửi ZNS',
              status: 'danger',
              outline: true,
              ghost: false,
              icon: 'paper-plane-outline',
              action: async (params: { node: Partial<IRowNode>, data: any }, brnConfig, btn) => {
                const template = await this.apiService.getPromise(this.apiPath + this.makeId(params?.node?.data), {includeDetails: true}).then(rs => rs[0]);
                const parameterDefs = template.Details;
                const parameterControls: DialogFormControl[] = [];
                for (const parameterDef of parameterDefs) {
                  parameterControls.push({
                    name: parameterDef.Name,
                    label: parameterDef.Description,
                    placeholder: '<' + parameterDef.Name + '>',
                  });
                }
                this.cms.openDialog(DialogFormComponent, {
                  context: {
                    title: 'Test gửi tin nhắn ZNS',
                    width: '720px',
                    controls: [
                      {
                        name: 'ReceiverPhone',
                        label: 'Số điện thoại',
                        placeholder: 'Số điện thoại...',
                        focus: true,
                      },
                      ...parameterControls,
                    ],
                    actions: [
                      {
                        label: 'Trở về',
                        status: 'basic',
                        outline: true,
                        action: async () => true,
                      },
                      {
                        label: 'Gửi',
                        icon: 'paper-plane-outline',
                        status: 'danger',
                        action: async (form, dialog) => {
                          const parameters = {};
                          for (const parameterDef of parameterDefs) {
                            parameters[parameterDef.Name] = form.get(parameterDef.Name).value;
                          }
                          return this.apiService.postPromise('/zalo-oa/messages', {}, [
                            {
                              Type: 'ZNS',
                              ZaloOa: template.ZaloOa,
                              TemplateId: template.TemplateId,
                              ReceiverPhone: form.get('ReceiverPhone').value,
                              Parameters: parameters,
                            }
                          ]).then(rs => {
                            this.cms.showToast('Đã gửi tin nhắn ZNS tới số ' + form.get('ReceiverPhone').value + ' thành công!', 'Test gửi tin nhắn ZNS thành công', {status: 'success'});
                            return true;
                          });
                        },
                      },
                    ],
                  }
                });
                return true;
              }
            },
          ]),
          headerName: 'Action',
          width: 200,
          resizable: true,
          pinned: 'right'
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
