import {Component, OnInit} from '@angular/core';
import {AgGridDataManagerListComponent} from "../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {Model} from "../../../../models/model";
import {SimpleDataManagerFormComponent} from "../../../code-template/simple-data-manager/simple-data-manager-form/simple-data-manager-form.component";
import {SimpleDataManagerPrintComponent} from "../../../code-template/simple-data-manager/simple-data-manager-print/simple-data-manager-print.component";
import {RootServices} from "../../../../services/root.services";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {CommonService} from "../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {HttpClient} from "@angular/common/http";
import {CodeTemplateStateMap} from "../../../code-template/code-template.state.map";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import {agMakeStateColDef} from "../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {ZaloOfficialAccountFormComponent} from "../zalo-official-account-form/zalo-official-account-form.component";
import {ZaloOaStateMap} from "../../zalo-oa.state.map";
import {agMakeCheckboxColDef} from "../../../../lib/custom-element/ag-list/column-define/checkbox.define";
import {agMakeButtonsColDef} from "../../../../lib/custom-element/ag-list/column-define/buttons.define";
import {IRowNode} from "ag-grid-community";
import {DialogFormAction, DialogFormComponent} from "../../../../lib/component/dialog/dialog-form/dialog-form.component";
import {FormGroup} from "@angular/forms";
import {ZaloOaOfficialAccountModel} from "../../../../models/zalo-oa.model";
import {ShowcaseDialogComponent} from "../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component";

@Component({
  selector: 'ngx-zalo-official-account-list',
  templateUrl: './zalo-official-account-list.component.html',
  styleUrls: ['./zalo-official-account-list.component.scss']
})
export class ZaloOfficialAccountListComponent extends AgGridDataManagerListComponent<Model, SimpleDataManagerFormComponent> implements OnInit {

  feature = {
    Module: {id: 'ZaloOa', text: 'Zalo OA'},
    Feature: {id: 'OfficialAccount', text: 'Tài khoản Official Account'},
  };

  componentName: string = 'ZaloOfficialAccountListComponent';
  apiPath = '/zalo-oa/official-accounts';
  idKey = ['Code'];
  formDialog = ZaloOfficialAccountFormComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<ZaloOfficialAccountListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      const processingMap = ZaloOaStateMap.zaloOfficialAccountStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 320,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 320,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Zalo OA',
          field: 'OaId',
          width: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'App Id',
          field: 'AppId',
          width: 150,
        },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'generateWebhookToken',
              label: 'Tạo token',
              status: 'success',
              outline: true,
              ghost: false,
              icon: 'lock-outline',
              action: async (params: { node: Partial<IRowNode>, data: any }, brnConfig, btn) => {
                this.apiService.getPromise<ZaloOaOfficialAccountModel[]>('/zalo-oa/official-accounts', {'generateWebhookToken': true, id: [params.data.Code]}).then(token => {
                  this.cms.openDialog(ShowcaseDialogComponent, {
                    context: {
                      title: this.cms.translateText('ZaloOa.Webhook.token'),
                      content: token[0].WebhookToken,
                      actions: [
                        {
                          label: this.cms.translateText('Common.close'),
                          status: 'basic',
                          outline: true,
                        },
                        {
                          label: this.cms.translateText('Common.copy'),
                          status: 'success',
                          action: () => {
                            this.cms.copyTextToClipboard(token[0].WebhookToken);
                          }
                        },
                      ],
                    },
                  });
                });
                return true;
              }
            },
          ]),
          headerName: 'Action',
          width: 200,
          resizable: true,
          pinned: 'right'
        },
        {
          ...agMakeCheckboxColDef(this, this.cms, (params, checked) => {
            console.log(checked);
          }),
          headerName: 'Default',
          field: 'IsDefault',
          width: 150,
          headerComponentParams: {enabledCheckbox: false}
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'IsEnabled',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
