import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {RootServices} from '../../../../services/root.services';
import {Router} from '@angular/router';
import {CommonService} from '../../../../services/common.service';
import {NbButtonComponent, NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {AdminProductService} from '../../../admin-product/admin-product.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {agMakeSelectionColDef} from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import {agMakeImageColDef} from '../../../../lib/custom-element/ag-list/column-define/image.define';
import {AgTextCellRenderer} from '../../../../lib/custom-element/ag-list/cell/text.component';
import {AgSelect2Filter} from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import {AgDateCellRenderer} from '../../../../lib/custom-element/ag-list/cell/date.component';
import {ColDef, IGetRowsParams} from '@ag-grid-community/core';
import {ProductCategoryModel, ProductGroupModel, ProductModel, ProductUnitModel} from '../../../../models/product.model';
import {AgGridDataManagerListComponent} from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import {FormGroup} from '@angular/forms';
import {agMakeNumberColDef} from '../../../../lib/custom-element/ag-list/column-define/number.define';
import {agMakeButtonsColDef} from '../../../../lib/custom-element/ag-list/column-define/buttons.define';
import {DialogFormAction, DialogFormComponent} from '../../../../lib/component/dialog/dialog-form/dialog-form.component';
import {CommercePosOrderModel} from "../../../../models/commerce-pos.model";
import {CommercePosOrderFormComponent} from "../../../commerce-pos/commerce-pos-order/commerce-pos-order-form/commerce-pos-order-form.component";
import {IRowNode} from "ag-grid-community";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ContactModel} from "../../../../models/contact.model";
import {PurchaseOrderVoucherModel} from "../../../../models/purchase.model";
import {WarehouseGoodsContainerModel} from "../../../../models/warehouse.model";
import {IdTextModel} from "../../../../models/common.model";
import {WarehouseGoodsFindOrderTempPrintComponent} from "../warehouse-goods-find-order-temp-print/warehouse-goods-find-order-temp-print.component";
import {WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent} from "../../goods-receipt-note/warehouse-goods-access-number-print/warehouse-goods-access-number-print.component";
import {AssignNewContainerFormComponent} from '../assign-new-containers-form/assign-new-containers-form.component';
import {AgDynamicListComponent} from "../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import {agMakeTagsColDef} from "../../../../lib/custom-element/ag-list/column-define/tags.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import {agMakeDatetimeColDef} from "../../../../lib/custom-element/ag-list/column-define/datetime.define";
import {filter, take, takeUntil} from "rxjs/operators";
import {WarehouseGoodsContainerBarcodePrintComponent} from "../../goods-container/goods-container-barcode-print/warehouse-goods-container-barcode-print.component";

@Component({
  selector: 'ngx-warehouse-goods-list',
  templateUrl: './warehouse-goods-list.component.html',
  styleUrls: ['./warehouse-goods-list.component.scss'],
  providers: [DecimalPipe]
})
export class WarehouseGoodsListComponent extends AgGridDataManagerListComponent<CommercePosOrderModel, CommercePosOrderFormComponent> implements OnInit {

  feature = {
    Module: {id: 'Warehouse', text: 'Kho bãi'},
    Feature: {id: 'Goods', text: 'Hàng hóa'},
  };

  componentName: string = 'WarehouseGoodsListComponent';
// formPath = '/commerce-pos/commerce-pos-order/form';
  apiPath = '/warehouse/goods';
  idKey: string[] = ['Code', 'Unit', 'Container'];
// formDialog = CommercePosOrderFormComponent;
// printDialog = CommercePosOrderPrintComponent;

// AG-Grid config
  public rowHeight: number = 100;
// @Input() gridHeight = '100%';
// @Input() suppressRowClickSelection = false;

  // Use for load settings menu for context

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<WarehouseGoodsListComponent>,
    public datePipe: DatePipe,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  categoryList: ProductCategoryModel[] = [];
  groupList: ProductGroupModel[] = [];
  unitList: ProductUnitModel[] = [];
  containerList: WarehouseGoodsContainerModel[] = [];
  shelfList: IdTextModel[];

  async loadCache() {
    this.loading = true;
    const toast = this.cms.showToast('Vui lòng chờ trong giây lát', 'Đang tải dữ liệu cache', {status: 'warning', duration: 0});
    // this.containerList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', {includePath: true, includeIdText: true, limit: 'nolimit'})).map(container => ({...container, text: `${container.FindOrder} - ${container.Path}`})) as any;
    // this.shelfList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', {includePath: true, limit: 'nolimit', eq_Type: 'SHELF'})).map(container => ({id: container.Code, text: `${container.Name}`})) as any;
    this.containerList = await this.adminProductService.containerList$.pipe(takeUntil(this.destroy$), filter(f => Array.isArray(f)), take(1)).toPromise();
    this.shelfList = this.containerList.filter(f => this.cms.getObjectId(f.Type) == 'SHELF');
    await this.adminProductService.unitList$.pipe(takeUntil(this.destroy$), filter(f => f != null), take(1)).toPromise();
    toast.close();
    this.loading = false;
  }

  async init() {
    return super.init().then(async state => {
      this.actionButtonList = this.actionButtonList.filter(f => ['add', 'edit', 'delete', 'preview'].indexOf(f.name) < 0);
      await this.cms.waitForLanguageLoaded();

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'STT',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 150,
          minWidth: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
          // initialSort: 'desc',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Sku',
          field: 'Sku',
          // pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          // pinned: 'left',
          width: 350,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Vị trí',
          field: 'Container',
          // pinned: 'left',
          width: 300,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', {onlyIdText: true}, {
                placeholder: 'Chọn vị trí...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = '[' + item['FindOrder'] + ']' + item['text'] + ' (' + item['id'] + ')';
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kho',
          field: 'Warehouse',
          // pinned: 'left',
          width: 200,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/warehouses', {onlyIdText: true}, {
                placeholder: 'Chọn kho...', limit: 10, prepareResultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kệ',
          field: 'ContainerShelf',
          // pinned: 'left',
          width: 200,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', {onlyIdText: true, eq_Type: 'SHELF'}, {
                placeholder: 'Chọn kệ...', limit: 10, prepareResultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 200,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 200,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Tồn kho',
          field: 'Inventory',
          pinned: 'right',
          width: 120,
          cellRendererParams: {
            format: '1.0-2',
          }
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ĐVT',
          field: 'Unit',
          pinned: 'right',
          width: 100,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              placeholder: 'Chọn ĐVT...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              withThumbnail: false,
              keyMap: {
                id: 'id',
                text: 'text',
              },
              multiple: true,
              logic: 'OR',
              data: this.adminProductService.unitList$.value,
            }
          },
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'assignNewContainer',
              label: 'Tạo vị trí',
              status: 'warning',
              outline: false,
              ghost: true,
              icon: 'archive-outline',
              action: async (params: any, buttonConfig?: any, btn?: NbButtonComponent) => {
                let editedItem = params?.node?.data;
                editedItem.WarehouseUnit = editedItem.Unit;
                this.cms.openDialog(AssignNewContainerFormComponent, {
                  context: {
                    inputMode: 'dialog',
                    inputGoodsList: [editedItem],
                    onDialogSave: (newData: ProductModel[]) => {
                      this.refresh();
                    },
                    onDialogClose: () => {
                    },
                  },
                  closeOnEsc: false,
                  closeOnBackdropClick: false,
                });
                return true;
              }
            },
            {
              name: 'printFindOrderTem',
              // label: 'In tem',
              status: 'info',
              outline: false,
              ghost: true,
              icon: 'printer-outline',
              action: async (params: any, buttonConfig: ProductModel) => {
                if (this.cms.getObjectId(params.data.Container)) {
                  this.cms.openDialog(WarehouseGoodsFindOrderTempPrintComponent, {
                    context: {
                      priceTable: 'default',
                      id: [this.makeId(params.data)],
                    }
                  });
                } else {
                  this.cms.toastService.show('Hàng hóa chứa được cài đặt vị trí', 'In tem nhận thức', {status: 'warning'});
                }
                return true;
              }
            },
            {
              name: 'showDetails',
              // label: '',
              status: 'danger',
              outline: false,
              ghost: true,
              icon: 'external-link-outline',
              // disabled: (data: any) => !data.IsManageByAccessNumber,
              action: async (nodeParams: any, buttonConfig: ProductModel) => {
                if (!nodeParams.data.Container) {
                  this.cms.showToast('Bạn chỉ có thể in tem nhận thức cho các hàng hóa đã cài đặt vị trí.', 'Hàng hóa chưa được cài đặt vị trí !', {status: 'warning'});
                  return;
                }
                if (!nodeParams.data.IsManageByAccessNumber) {

                  this.cms.openDialog(AgDynamicListComponent, {
                    context: {
                      title: 'Lịch sử nhập hàng',
                      width: '90%',
                      height: '95vh',
                      apiPath: '/accounting/reports',
                      idKey: ['Voucher', 'WriteNo'],
                      getRowNodeId: (item) => {
                        return item.Voucher + '-' + item.WriteNo;
                      },
                      // rowMultiSelectWithClick: true,
                      // suppressRowClickSelection: false,
                      prepareApiParams: (exParams, getRowParams) => {
                        exParams['eq_VoucherType'] = 'PURCHASE';
                        exParams['eq_Accounts'] = [632, 151, 152, 153, 154, 155, 156, 157, 158];
                        exParams['reportDetailByAccountAndObject'] = true;
                        exParams['groupBy'] = 'Voucher,WriteNo';
                        exParams['eq_Product'] = `[${nodeParams.node.data.Code}]`;
                        return exParams;
                      },
                      onDialogChoose: (chooseItems) => {

                      },
                      columnDefs: [
                        {
                          ...agMakeSelectionColDef(this.cms),
                          headerName: 'STT',
                          // width: 52,
                          field: 'Id',
                          valueGetter: 'node.data.Voucher',
                        },
                        {
                          headerName: 'Ngày nhập',
                          field: 'VoucherDate',
                          width: 180,
                          filter: 'agDateColumnFilter',
                          filterParams: {
                            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
                          },
                          cellRenderer: AgDateCellRenderer,
                          // initialSort: 'desc'
                        },
                        {
                          headerName: 'Voucher',
                          field: 'Voucher',
                          width: 200,
                          filter: 'agTextColumnFilter',
                          headerComponentParams: {enableMenu: true, menuIcon: 'fa-external-link-alt'},
                          filterParams: {
                            filterOptions: ['contains'],
                            textMatcher: ({value, filterText}) => {
                              const literalMatch = this.cms.smartFilter(value, filterText);
                              return literalMatch;
                            },
                            trimInput: true,
                            debounceMs: 1000,
                          },
                          cellRenderer: AgTextCellRenderer,
                          pinned: 'left',
                        },
                        {
                          headerName: this.cms.textTransform(this.cms.translate.instant('Common.supplier'), 'head-title'),
                          field: 'Object',
                          pinned: 'left',
                          width: 250,
                          cellRenderer: AgTextCellRenderer,
                          valueGetter: 'node.data.ObjectName',
                          filter: AgSelect2Filter,
                          filterParams: {
                            select2Option: {
                              ...this.cms.makeSelect2AjaxOption('/contact/contacts', {includeIdText: true, includeGroups: true, sort_SearchRank: 'desc'}, {
                                placeholder: 'Chọn liên hệ...', limit: 10, prepareResultItem: (item) => {
                                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                                  return item;
                                }
                              }),
                              multiple: true,
                              logic: 'OR',
                              allowClear: true,
                            }
                          },
                        },
                        {
                          headerName: 'Tiêu đề',
                          field: 'VoucherDescription',
                          width: 400,
                          filter: 'agTextColumnFilter',
                          headerComponentParams: {enableMenu: true, menuIcon: 'fa-external-link-alt'},
                          filterParams: {
                            filterOptions: ['contains'],
                            textMatcher: ({value, filterText}) => {
                              const literalMatch = this.cms.smartFilter(value, filterText);
                              return literalMatch;
                            },
                            trimInput: true,
                            debounceMs: 1000,
                          }
                        },
                        {
                          headerName: 'Tên sản phẩm',
                          field: 'Product',
                          width: 400,
                          valueGetter: 'node.data.Description',
                          filter: 'agTextColumnFilter',
                          headerComponentParams: {enableMenu: true, menuIcon: 'fa-external-link-alt'},
                          filterParams: {
                            filterOptions: ['contains'],
                            textMatcher: ({value, filterText}) => {
                              const literalMatch = this.cms.smartFilter(value, filterText);
                              return literalMatch;
                            },
                            trimInput: true,
                            debounceMs: 1000,
                          }
                        },
                        {
                          ...agMakeNumberColDef(this.cms),
                          headerName: 'Số lượng',
                          field: 'Quantity',
                          pinned: 'right',
                          width: 120,
                        },
                        {
                          headerName: 'ĐVT',
                          field: 'ProductUnit',
                          width: 100,
                          pinned: 'right',
                          // cellRenderer: AgTextCellRenderer,
                          valueFormatter: 'node.data.ProductUnitLabel',
                          filter: AgSelect2Filter,
                          filterParams: {
                            select2Option: {
                              placeholder: 'Chọn...',
                              allowClear: true,
                              width: '100%',
                              dropdownAutoWidth: true,
                              minimumInputLength: 0,
                              withThumbnail: false,
                              keyMap: {
                                id: 'id',
                                text: 'text',
                              },
                              data: this.adminProductService.unitList$.value,
                              multiple: true,
                              logic: 'OR',
                            }
                          },
                        },
                      ],
                      onInit: (component) => {
                        component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh'].indexOf(f.name) > -1);
                      }
                    }
                  });

                  return true;
                }
                this.cms.openDialog(AgDynamicListComponent, {
                  context: {
                    title: 'Số truy xuất',
                    width: '90%',
                    height: '95vh',
                    apiPath: '/warehouse/goods-receipt-note-detail-access-numbers',
                    idKey: ['Code'],
                    // rowMultiSelectWithClick: true,
                    suppressRowClickSelection: false,
                    // rowModelType: 'clientSide',
                    // rowData: data.AccessNumbers?.map(accessNumber => ({
                    //   ...data,
                    //   AccessNumber: accessNumber,
                    // })),
                    getRowNodeId: (item: any) => {
                      return item.AccessNumber;
                    },
                    prepareApiParams: (params, getRowParams) => {
                      // const sites = formGroup.get('Sites').value;
                      params['id'] = nodeParams.data.AccessNumbers && nodeParams.data.AccessNumbers.length > 0 ? nodeParams.data.AccessNumbers : '-1';
                      params['includeVoucherInfo'] = true;
                      return params;
                    },
                    onDialogChoose: (chooseItems) => {
                      console.log(chooseItems);
                      if (chooseItems && chooseItems.length > 0) {
                        // this.loading = true;
                        this.cms.openDialog(WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent, {
                          context: {
                            id: chooseItems.map(m => this.cms.getObjectId(m['AccessNumber']))
                          }
                        });
                      }

                    },
                    columnDefs: [
                      {
                        ...agMakeSelectionColDef(this.cms),
                        headerName: 'STT',
                        // width: 52,
                        field: 'Id',
                        valueGetter: 'node.data.Id',
                        // cellRenderer: 'loadingCellRenderer',
                        // sortable: true,
                        // pinned: 'left',
                      },
                      {
                        headerName: this.cms.textTransform(this.cms.translate.instant('Warehouse.dateOfReceipted'), 'head-title'),
                        field: 'DateOfReceipted',
                        width: 180,
                        pinned: 'left',
                        filter: 'agDateColumnFilter',
                        filterParams: {
                          inRangeFloatingFilterDateFormat: 'DD/MM/YY',
                        },
                        cellRenderer: AgDateCellRenderer,
                      },
                      {
                        ...agMakeTagsColDef(this.cms, (tag) => {
                          this.cms.previewVoucher(tag.type, tag.id);
                        }),
                        valueGetter: params => {
                          return params.node?.data && [{id: params.node.data.Voucher, text: params.node.data.Voucher}] || [];
                        },
                        headerName: 'Chứng từ liên quan',
                        field: 'Voucher',
                        width: 330,
                      },
                      {
                        headerName: 'Nhà cung cấp',
                        field: 'Object',
                        pinned: 'left',
                        width: 400,
                        cellRenderer: AgTextCellRenderer,
                        filter: AgSelect2Filter,
                        valueGetter: 'node.data.ObjectName',
                        filterParams: {
                          select2Option: {
                            ...this.cms.makeSelect2AjaxOption('/contact/contacts', {includeIdText: true, includeGroups: true, sort_SearchRank: 'desc'}, {
                              placeholder: 'Chọn liên hệ...', limit: 10, prepareResultItem: (item) => {
                                item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                                return item;
                              }
                            }),
                            multiple: true,
                            logic: 'OR',
                            allowClear: true,
                          }
                        },
                      },
                      {
                        headerName: 'Tiêu đề',
                        field: 'Title',
                        width: 500,
                        filter: 'agTextColumnFilter',
                        autoHeight: true,
                      },
                      {
                        headerName: 'Số truy xuất',
                        field: 'AccessNumber',
                        width: 300,
                        pinned: 'right',
                        filter: 'agTextColumnFilter',
                        autoHeight: true,
                      },
                      {
                        ...agMakeButtonsColDef(this.cms, [
                          {
                            name: 'print',
                            // label: 'In',
                            status: 'success',
                            outline: false,
                            icon: 'printer-outline',
                            action: async (params: any, data: ProductModel) => {
                              let editedItem = params.data;
                              this.cms.openDialog(WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent, {
                                context: {
                                  id: params.data.AccessNumber
                                }
                              });
                              return true;
                            }
                          },
                        ]),
                        headerName: 'Action'
                      }
                    ],
                    onInit: (component) => {

                    }
                  }
                });
              }
            },
          ]),
          headerName: 'Lệnh',
          resizable: true,
          width: 190,
          maxWidth: null,
        }
      ] as ColDef[]);

      this.actionButtonList.unshift({
        name: 'printFindOrderTem',
        status: 'primary',
        label: 'In tem nhận thức',
        title: 'In tem nhận thức',
        icon: 'grid-outline',
        size: 'medium',
        hidden: () => this.isChoosedMode,
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          // const editedItems = this.selectedItems;
          this.cms.openDialog(WarehouseGoodsFindOrderTempPrintComponent, {
            context: {
              priceTable: 'default',
              id: this.selectedItems.map(item => this.makeId(item)),
            }
          });
        }
      });

      this.actionButtonList.unshift({
        name: 'printFindOrderTem',
        status: 'info',
        label: 'In tem bán hàng',
        title: 'In tem mã vạc vị trí quét bán hàng trên POS',
        icon: 'grid-outline',
        size: 'medium',
        hidden: () => this.isChoosedMode,
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          // const editedItems = this.selectedItems;
          // this.cms.openDialog(WarehouseGoodsFindOrderTempPrintComponent, {
          //   context: {
          //     priceTable: 'default',
          //     id: this.selectedItems.map(item => this.makeId(item)),
          //   }
          // });

          const productNotManageByAccessNumberList = this.selectedItems.map(m => ({
            id: m.Code + '/' + m.Sku + ': ' + m.Code + '-' + this.cms.getObjectId(m.Unit),
            text: m.Name + ' (' + this.cms.getObjectText(m.Unit) + ')',
            // ...m,
            Product: m.Code,
            // Unit: m.Product.Units.find(u => this.cms.getObjectId(u) == this.cms.getObjectId(m.Unit)),
          }));
          this.cms.openDialog(WarehouseGoodsContainerBarcodePrintComponent, {
            context: {
              // voucher: item.Code,
              // priceTable: 'default',
              productList: productNotManageByAccessNumberList,
              id: this.selectedItems.map(m => `${m.Code}-${this.cms.getObjectId(m.Unit)}-${this.cms.getObjectId(m['Container'])}`),
              // id: productNotManageByAccessNumberList.map(m => `${this.cms.getObjectId(m.Product)}-${this.cms.getObjectId(m.Unit)}-${this.cms.getObjectId(m['Container'])}`),
            }
          });
        }
      });

      this.actionButtonList.unshift({
        name: 'reprintAccessNumbers',
        status: 'success',
        label: 'In lại số truy xuất',
        title: 'Thêm vào sanh sách in lại',
        icon: 'pricetags-outline',
        size: 'medium',
        hidden: () => this.isChoosedMode,
        click: () => {
          this.cms.openDialog(DialogFormComponent, {
            context: {
              width: '500px',
              title: 'Thêm số truy xuất vào danh sách in lại',
              controls: [
                {
                  name: 'AccessNumbers',
                  label: 'Số truy xuất',
                  initValue: '',
                  placeholder: 'Mỗi dòng 1 số truy xuất',
                  type: 'textarea',
                },
              ],
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: async () => true,
                },
                {
                  label: 'In',
                  icon: 'printer-outline',
                  status: 'success',
                  action: async (form: FormGroup) => {

                    let accessNumbersText: string = form.get('AccessNumbers').value;
                    accessNumbersText = accessNumbersText.trim();
                    let accessNumbers = accessNumbersText.split('\n');

                    accessNumbers = accessNumbers.filter(f => {
                      f = f.trim();
                      return f && !/[^0-9]/.test(f) && /^127/.test(f);
                    });

                    console.log(accessNumbers);

                    this.cms.openDialog(WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent, {
                      context: {
                        id: accessNumbers,
                      }
                    });
                    return false; // do not close dialog after action
                  },
                },
              ],
            },
          });
        }
      });

      this.actionButtonList.unshift({
        name: 'assignNewContainer',
        status: 'warning',
        label: 'Tạo vị trí',
        title: 'Tạo vị trí mới cho các dòng đã chọn',
        icon: 'archive-outline',
        size: 'medium',
        hidden: () => this.isChoosedMode,
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          const editedItems = this.selectedItems;
          this.cms.openDialog(AssignNewContainerFormComponent, {
            context: {
              inputMode: 'dialog',
              inputGoodsList: editedItems.map(m => (
                {
                  ...m,
                  WarehouseUnit: m.Unit,
                }
              )),
              onDialogSave: (newData: ProductModel[]) => {
                this.refresh();
              },
              onDialogClose: () => {
              },
            },
            closeOnEsc: false,
            closeOnBackdropClick: false,
          });
        }
      });

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

// @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
//   return 123;
// }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeCategories'] = true;
    params['includeFeaturePicture'] = true;
    params['includeUnit'] = true;
    params['includeContainer'] = true;
    params['includeInventory'] = true;
    params['includeLastInventoryAdjust'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: CommercePosOrderModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(CommercePosOrderFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: CommercePosOrderModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

// async getFormData(ids: string[]) {
//   return this.apiService.getPromise<CommercePosOrderModel[]>('/sales/commerce-pos-orders', { id: ids, includeContact: true, includeDetails: true, useBaseTimezone: true });
// }

  onGridReady(params) {
    super.onGridReady(params);
  }

  async pushGoodsToPurchaseOrderQueue(supplier: ContactModel, product: ProductModel, unit: ProductUnitModel, quantity: number, description?: string, price?: number) {
    let queuePo = await this.apiService.getPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', {eq_State: 'INQUEUE', eq_Object: supplier.id, sort_Id: 'desc', includeDetails: true}).then(rs => rs[0]);
    if (!queuePo) {
      const contact = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + supplier.id).then(rs => rs[0]);
      queuePo = await this.apiService.postPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', {eq_State: 'INQUEUE', Object: supplier.id, sort_Id: 'desc'}, [{
        Object: this.cms.getObjectId(supplier),
        ObjectName: supplier.text,
        ObjectPhone: contact.Phone,
        ObjectEmail: contact.Email,
        ObjectAddress: contact.Address,
        ObjectIdentified: contact.IdNumber,
        State: 'INQUEUE',
        Title: `Đặt hàng ${supplier.text} ${new Date().toLocaleString()}`,
      }]).then(rs => rs[0]);

      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, {changeState: 'INQUEUE'}, [{Code: queuePo.Code}]).then(rs => rs[0]);

    }
    if (queuePo) {
      if (!queuePo.Details) {
        queuePo.Details = [];
      }

      if (queuePo.Details.findIndex(f => this.cms.getObjectId(f.Product) == this.cms.getObjectId(product.Goods) && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit)) > -1) {
        this.cms.showError('Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code);
        return Promise.reject({errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code]});
      }

      const productInfo = await this.apiService.getPromise<ProductModel[]>('/admin-product/products/' + this.cms.getObjectId(product.Goods), {includeUnitConversions: true}).then(rs => rs[0]);
      const business = [];
      if (productInfo && productInfo.UnitConversions) {
        // this.cms.showError('Không tìm thấy thông tin sản phẩm ');
        // return Promise.reject({ errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code] });
        const unitConversion = productInfo.UnitConversions.find(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit));
        if (unitConversion) {
          if (unitConversion.IsAutoAdjustInventory) {
            business.push({
              id: 'PURCHASEWAREHOUSE',
              text: 'Nhập kho & mua hàng (công nợ)',
            });
          } else {
            business.push({
              id: 'PURCHASESKIPWAREHOUSE',
              text: 'Mua hàng không qua kho (công nợ)',
            });
          }
        }
      }

      queuePo.Details.push({
        Type: 'PRODUCT',
        Business: business,
        Image: [
          ...(product.Thumbnail ? [product.Thumbnail] : []),
          ...(product.FeaturePicture ? [product.FeaturePicture] : []),
          ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
        ],
        Product: product.Goods,
        Unit: unit,
        Quantity: quantity || 0,
        Price: price,
        Description: product.Name + (description ? (' - ' + description) : ''),
      });
      queuePo.DateOfOrder = new Date().toISOString();
      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, {}, [queuePo]).then(rs => rs[0]);

      this.cms.showToast('Đã thêm hàng hóa vào phiếu đặt mua hàng ' + queuePo.Code, 'Thành công');

    } else {
      this.cms.showError('Không thể đặt hàng cho ' + product.Name + ' (' + product.UnitLabel + ')');
    }
    return queuePo;
  }
}
