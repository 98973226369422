import {Component, OnInit} from '@angular/core';
import {DataManagerFormComponent} from "../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../models/model";
import {RootServices} from "../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../services/common.service";
import {AdminProductService} from "../../../admin-product/admin-product.service";
import {HttpErrorResponse} from "@angular/common/http";
import {IoWorkflowHttpRequestNodeFormComponent} from "../nodes/http-request/io-workflow-http-request-node-form/io-workflow-http-request-node-form.component";
import {IoWorkflowNodeModel} from "../../io.model";
import {IoWorkflowWebhookNodeFormComponent} from "../nodes/webhook/io-workflow-webhook-node-form/io-workflow-webhook-node-form.component";

@Component({
  selector: 'ngx-io-workflow-form',
  templateUrl: './io-workflow-form.component.html',
  styleUrls: ['./io-workflow-form.component.scss'],
})
export class IoWorkflowFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'IoWorkflowFormComponent';
  idKey = 'SystemUuid';
  apiPath = '/io/workflows';
  baseFormUrl = '/io/workflow/form';

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<IoWorkflowFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }


  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    return super.init();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeNodes'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (itemFormData?.Nodes) {
        const nodes = this.getNodes(newForm);

        nodes.clear();
        for (const nodeData of itemFormData.Nodes) {
          const newNodeFormGroup = this.makeNewNodeFormGroup(newForm, nodeData);
          nodes.push(newNodeFormGroup);
          this.onAddNodeFormGroup(newForm, newNodeFormGroup, nodes.length - 1);
        }
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      SystemUuid: {value: null, disabled: true},
      Name: [null, Validators.required],
      Description: [],
      Nodes: this.formBuilder.array([]),
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  /** Node Form */
  makeNewNodeFormGroup(parentFormGroup: FormGroup, data?: Model): FormGroup {
    let newForm = null;
    switch (this.cms.getObjectId(data.Type)) {
      case 'WEBHOOK':
        newForm = this.formBuilder.group<any>({
          SystemUuid: [],
          Type: [],
          Name: [],

          Path: [],
          HttpMethod: [],
          AuthType: [],
          Credential: [],
          Respond: [],

          NextNodes: [],
        });
        break;
      case 'HTTPREQUEST':
        newForm = this.formBuilder.group<any>({
          SystemUuid: [],
          Type: [],
          Name: [],

          TargetUrl: [],
          Path: [],
          HttpMethod: [],
          AuthType: [],
          Credential: [],
          Respond: [],
          Params: [],
          Headers: [],
          Body: [],
          BodyPreview: [],

          NextNodes: [],
        });
        break;
      default:
        newForm = this.formBuilder.group<any>({
          SystemUuid: [],
          Type: ['UNDEFINED'],
          Name: [],

          NextNodes: [],
        });
        break;
    }

    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  getNodes(parentFormGroup: FormGroup) {
    return parentFormGroup.get('Nodes') as FormArray;
  }

  addNodeFormGroup(parentFormGroup: FormGroup) {

    this.cms.showDialog('Thêm node', 'Bạn muốn node gì ?', [
      {
        label: 'Trở về',
        status: 'basic',
        outline: true,
        action: () => {
          return true;
        },
      },
      {
        label: 'Webhook',
        status: 'info',
        outline: true,
        action: () => {
          const newChildFormGroup = this.makeNewNodeFormGroup(parentFormGroup, {Type: 'WEBHOOK'});
          const detailsFormArray = this.getNodes(parentFormGroup);
          detailsFormArray.push(newChildFormGroup);
          this.onAddNodeFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
          return true;
        },
      },
      {
        label: 'Http request',
        status: 'info',
        outline: true,
        action: () => {
          const newChildFormGroup = this.makeNewNodeFormGroup(parentFormGroup, {Type: 'HTTPREQUEST'});
          const detailsFormArray = this.getNodes(parentFormGroup);
          detailsFormArray.push(newChildFormGroup);
          this.onAddNodeFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
          return true;
        },
      },
    ]);


    return false;
  }

  removeNodeGroup(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    this.getNodes(parentFormGroup).removeAt(index);
    this.onRemoveNodeFormGroup(parentFormGroup, detail);
    return false;
  }

  onAddNodeFormGroup(parentFormGroup: FormGroup, newChildFormGroup: FormGroup, index: number) {
    this.updateInitialFormPropertiesCache(newChildFormGroup);
  }

  onRemoveNodeFormGroup(parentFormGroup: FormGroup, detailFormGroup: FormGroup) {
  }

  /** End Node Form */

  onNodeChange(nodeData: IoWorkflowNodeModel, node: FormGroup) {
    node.patchValue(nodeData);
  }

  async save(): Promise<Model[]> {
    return super.save().then(rs => {
      this.adminProductService.updateGroupList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  }
}
