import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class CollaboratorStateMap {

  static orderStateMap: { [key: string]: ProcessMap } = {
    '': {
      ...stateMap.NEW,
      nextState: 'PROCESSING',
      nextStates: [
        stateMap.PROCESSING,
      ],
    },
    NOTJUSTAPPROVED: {
      ...stateMap.NEW,
      id: 'NOTJUSTAPPROVED',
      state: 'NOTJUSTAPPROVED',
      nextState: 'PROCESSING',
      nextStates: [
        stateMap.PROCESSING,
      ],
    },
    PROCESSING: {
      ...stateMap.PROCESSING,
      nextState: 'APPROVED',
      nextStates: [
        {...stateMap.APPROVED, confirmLabel: 'Chốt đơn', confirmText: 'Bạn có muốn chốt đơn', status: 'success'},
        stateMap.UNRECORDED,
      ],
    },
    APPROVED: {
      ...stateMap.APPROVED,
      label: 'Chốt đơn',
      nextState: 'COMPLETED',
      nextStates: [
        // AppModule.depploymentState,
        // AppModule.transportState,
        stateMap.COMPLETED,
        stateMap.UNRECORDED,
      ],
    },
    DEPLOYED: {
      ...stateMap.DEPLOYED,
      nextState: 'COMPLETED',
      nextStates: [
        stateMap.COMPLETED,
        stateMap.UNRECORDED,
      ],
    },
    COMPLETED: {
      ...stateMap.COMPLETED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    RETURN: {
      ...stateMap.RETURN,
      nextState: 'COMPLETED',
      nextStates: [
        // { ...AppModule.completeState, status: 'success' },
        stateMap.COMPLETED,
        stateMap.UNRECORDED,
      ],
    },
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'PROCESSING',
      nextStates: [
        stateMap.PROCESSING,
      ],
    },
  };

  static freezeState: ProcessMap = {
    state: 'FREEZE',
    label: 'Lạnh (Đạt chuẩn IQL - Cool lead)',
    confirmLabel: 'Chuyển sang Lạnh',
    status: 'info',
    outline: true,
    confirmTitle: 'Chuyển sang Lạnh',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Lạnh',
    responseTitle: 'Chuyển sang trạng thái Lạnh',
    responseText: 'Đã chuyển sang trạng thái Lạnh',
  };
  static coolState: ProcessMap = {
    state: 'COOL',
    label: 'Mát',
    confirmLabel: 'Chuyển sang Mát',
    status: 'primary',
    outline: true,
    confirmTitle: 'Chuyển sang Mát',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Mát',
    responseTitle: 'Chuyển sang trạng thái Mát',
    responseText: 'Đã chuyển sang trạng thái Mát',
  };
  static warmState: ProcessMap = {
    state: 'WARM',
    label: 'Ấm (Đạt chuẩn MQL - Warm lead)',
    confirmLabel: 'Chuyển sang Ấm',
    status: 'warning',
    outline: true,
    confirmTitle: 'Chuyển sang Ấm',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Ấm',
    responseTitle: 'Chuyển sang trạng thái Ấm',
    responseText: 'Đã chuyển sang trạng thái Ấm',
  };
  static hotState: ProcessMap = {
    state: 'HOT',
    label: 'Nóng (Đạt chuẩn SQL - Hot lead)',
    confirmLabel: 'Chuyển sang Nóng',
    status: 'danger',
    outline: true,
    confirmTitle: 'Chuyển sang Nóng',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Nóng',
    responseTitle: 'Chuyển sang trạng thái Nóng',
    responseText: 'Đã chuyển sang trạng thái Nóng',
    nextState: 'CONVERTED',
  };
  static convertedState: ProcessMap = {
    state: 'CONVERTED',
    label: 'Đã chuyển đổi',
    confirmLabel: 'Chuyển đổi',
    status: 'success',
    outline: true,
    confirmTitle: 'Chuyển đổi',
    confirmText: 'Bạn có muốn chuyển đổi',
    responseTitle: 'Chuyển đổi',
    responseText: 'Đã chuyển đổi',
    nextState: 'FREEZE',
  };

  static unrecordedState: ProcessMap = {
    state: 'UNRECORDED',
    status: 'warning',
    label: 'Common.unrecorded',
    confirmLabel: 'Common.unrecord',
    confirmTitle: 'Common.unrecorded',
    confirmText: 'Common.unrecordedConfirm',
    responseTitle: 'Common.unrecorded',
    responseText: 'Common.unrecordedResponse',
  };
  static notJustApprovedState: ProcessMap = {
    state: 'NOTJUSTAPPROVED',
    label: 'Common.notJustApproved',
    confirmLabel: 'Common.unApprove',
    status: 'info',
    outline: true,
    confirmTitle: 'Common.unApprove',
    confirmText: 'Common.unApproveConfirmText',
    responseTitle: 'Common.unApprove',
    responseText: 'Common.unApproveSuccessText',
  };

  static opportunityStateMap: { [key: string]: ProcessMap } = {
    '': {
      ...stateMap.NEW,
      nextState: 'FREEZE',
      nextStates: [
        stateMap.FREEZE,
      ],
    },
    NOTJUSTAPPROVED: {
      ...CollaboratorStateMap.notJustApprovedState,
      nextState: 'FREEZE',
      nextStates: [
        stateMap.FREEZE,
      ],
    },
    NEW: {
      ...stateMap.NEW,
      nextState: 'FREEZE',
      nextStates: [
        stateMap.FREEZE,
      ],
    },
    FREEZE: {
      ...CollaboratorStateMap.freezeState,
      nextState: 'COOL',
      nextStates: [
        stateMap.COOL,
        stateMap.UNRECORDED,
      ],
    },
    COOL: {
      ...CollaboratorStateMap.coolState,
      nextState: 'WARM',
      nextStates: [
        stateMap.WARM,
        stateMap.UNRECORDED,
      ],
    },
    WARM: {
      ...CollaboratorStateMap.warmState,
      nextState: 'HOT',
      nextStates: [
        stateMap.HOT,
        stateMap.UNRECORDED,
      ],
    },
    HOT: {
      ...CollaboratorStateMap.hotState,
      nextState: 'CONVERTED',
      nextStates: [
        stateMap.CONVERTED,
        stateMap.UNRECORDED,
      ],
    },
    CONVERTED: {
      ...CollaboratorStateMap.convertedState,
      nextState: 'FREEZE',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    UNRECORDED: {
      ...CollaboratorStateMap.unrecordedState,
      nextState: 'FREEZE',
      nextStates: [
        stateMap.FREEZE,
      ],
    },
  };

  static publisherCommissionStateMap = {
    CONFIRMATIONREQUESTED: {
      ...stateMap.CONFIRMATIONREQUESTED,
      nextState: 'CONFIRMED',
      nextStates: [
        stateMap.CONFIRMED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    CONFIRMED: {
      ...stateMap.CONFIRMED,
      nextState: 'COMMITED',
      nextStates: [
        stateMap.COMMITED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    COMMITED: {
      ...stateMap.COMMITED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED
      ],
    } as ProcessMap,
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'CONFIRMATIONREQUESTED',
      nextStates: [
        stateMap.CONFIRMATIONREQUESTED,
      ],
    } as ProcessMap,
    NOTJUSTAPPROVED: {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'CONFIRMATIONREQUESTED',
      nextStates: [
        stateMap.CONFIRMATIONREQUESTED,
      ],
    } as ProcessMap,
    '': {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'CONFIRMATIONREQUESTED',
      nextStates: [
        stateMap.CONFIRMATIONREQUESTED,
      ],
    } as ProcessMap,
  };

  static RUNNINGREQUESTED = {
    state: 'RUNNINGREQUESTED',
    id: 'RUNNINGREQUESTED',
    label: 'Yêu cầu chạy báo cáo',
    text: 'Yêu cầu chạy báo cáo',
    status: 'info',
    color: 'lightblue',
    outline: true,
    confirmLabel: 'Yêu cầu chạy báo cáo',
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn yêu cầu chạy báo cáo ?',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã yêu cầu chạy báo cáo',
    nextState: 'UNRECORDED',
  };

  static publisherCommissionListStateMap = {
    RUNNINGREQUESTED: {
      ...CollaboratorStateMap.RUNNINGREQUESTED,
      nextStates: [
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    RUNNING: {
      ...stateMap.RUNNING,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    SUCCESS: {
      ...stateMap.SUCCESS,
      nextState: 'CONFIRMATIONREQUESTED',
      nextStates: [
        stateMap.CONFIRMATIONREQUESTED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    FAILED: {
      ...stateMap.FAILED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    CONFIRMATIONREQUESTED: {
      ...stateMap.CONFIRMATIONREQUESTED,
      nextState: 'COMMITED',
      nextStates: [
        stateMap.COMMITED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    COMMITED: {
      ...stateMap.COMMITED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED
      ],
    } as ProcessMap,
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'UNRECORDED',
      nextStates: [
        CollaboratorStateMap.RUNNINGREQUESTED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    NOTJUSTAPPROVED: {
      ...stateMap.NEW,
      state: 'NOTJUSTAPPROVED',
      id: 'NOTJUSTAPPROVED',
      nextState: 'UNRECORDED',
      nextStates: [
        CollaboratorStateMap.RUNNINGREQUESTED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    '': {
      ...stateMap.NEW,
      state: 'NOTJUSTAPPROVED',
      id: 'NOTJUSTAPPROVED',
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
        CollaboratorStateMap.RUNNINGREQUESTED,
      ],
    } as ProcessMap,
  };

  static strategyStateMap = {
    APPROVED: {
      ...stateMap.APPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.RUNNING,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    RUNNING: {
      ...stateMap.RUNNING,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.COMPLETED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    COMPLETED: {
      ...stateMap.COMPLETED,
      nextState: 'COMPLETED',
      nextStates: [],
    } as ProcessMap,
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'APPROVED',
      nextStates: [
        stateMap.APPROVED,
      ],
    } as ProcessMap,
    NOTJUSTAPPROVED: {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    '': {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
  };
}
