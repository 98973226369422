import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {ApiService} from "../../../../services/api.service";
import {FileModel} from "../../../../models/file.model";
import {CommonService} from "../../../../services/common.service";

@Component({
  selector: 'ngx-media-capture',
  templateUrl: './media-capture.component.html',
  styleUrls: ['./media-capture.component.scss']
})
export class MediaCaptureComponent implements AfterViewInit {

  @Input() maxFileCount: number;
  @Input() onUploaded: (files: FileModel[]) => void;
  @Output() uploaded = new EventEmitter<FileModel[]>();
  // Webcam capture
  @ViewChild("video")
  public video: ElementRef;

  @ViewChild("canvas")
  public canvas: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;

  isDeviceSetup = false;
  isProcessing = false;

  progressList: { loaded: number, total: number, percent: number, label: string, status: string }[] = [];

  constructor(
    // private elementRef: ElementRef,
    // private renderer: Renderer2,
    public cms: CommonService,
    public apiService: ApiService,
    public ref: NbDialogRef<MediaCaptureComponent>,
  ) {

  }

  ngAfterViewInit(): void {
    this.setupDevices().then(() => {
      this.isDeviceSetup = true;
    });
  }

  onCaptureBtnClick() {
    this.capture();
    return false;
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();

          this.error = null;
        } else {
          this.error = "You have no output video device";
          console.error(this.error);
        }
      } catch (e) {
        console.error(e);
        this.error = e;
      }
    }
  }

  capture() {
    if (this.maxFileCount && this.captures.length >= this.maxFileCount) {
      this.cms.showError('Vượt quá số file cho phép tải lên !', {duration: 5000});
      return;
    }
    const videoWidth = this.video.nativeElement.videoWidth;
    this.canvas.nativeElement.width = videoWidth;
    this.canvas.nativeElement.height = videoWidth * this.video.nativeElement.clientHeight / this.video.nativeElement.clientWidth;

    this.drawImageToCanvas(this.video.nativeElement, videoWidth, videoWidth * this.video.nativeElement.clientHeight / this.video.nativeElement.clientWidth);
    this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    // this.isCaptured = true;
  }

  // removeCurrent() {
  //   this.isCaptured = false;
  // }

  // setPhoto(idx: number) {
  //   this.isCaptured = true;
  //   const image = new Image();
  //   image.src = this.captures[idx];
  //   this.drawImageToCanvas(image);
  // }

  drawImageToCanvas(image: any, width: number, height: number) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, width, height);
  }

  // End webcam capture

  onUploadBtnClick() {
    const allPromise: Promise<FileModel>[] = [];
    this.progressList = [];
    for (const capture of this.captures) {

      // ChatGPT code
      const dataParts = capture.split(",");
      const byteString = atob(dataParts[1]);
      const mimeString = dataParts[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], {type: mimeString});

      // Tạo FormData và gửi lên server
      const formData = new FormData();
      formData.append("file", blob, 'media-capture-' + Date.now() + '.png');
      // End ChatGPT code
      const progress = {loaded: 0, total: 0, percent: 0, label: '', status: 'danger'};
      this.progressList.push(progress);
      allPromise.push(this.apiService.uploadFileData(formData, (event) => {
        // progress(event);
        console.log(event);
        progress.loaded = event['loaded'];
        progress.total = event['total'];
        if (event['type'] == 1) {
          progress.percent = ((event['loaded'] / event['total'] * 100) - 20).toFixed(0) as any;
          if (event['loaded'] > 0 && event['loaded'] == event['total']) {
            progress.label = 'đang xử lý';
            progress.status = 'warning';
          } else {
            progress.label = 'đang tải';
            progress.status = 'danger';
          }
        }
        if (event['type'] == 4) {
          progress.percent = 100;
          progress.label = 'hoàn tất';
          progress.status = 'success';
        }
      }));

    }
    this.isProcessing = true;
    Promise.all(allPromise).then(files => {
      this.onUploaded && this.onUploaded(files);
      this.uploaded && this.uploaded.emit(files);
      this.isProcessing = false;
      this.close();
    }).catch(error => {
      this.cms.showError(error);
      this.isProcessing = false;
    });
    return false;
  }

  getCompileProgressPercent() {
    let percent = 0;
    for (const progress of this.progressList) {
      percent += progress.percent;
    }
    return percent / this.progressList.length;
  }

  close() {
    this.ref.close();
    return false;
  }
}
