<div class="thumbnail input-full-width size-medium status-basic shape-rectangle scrollable-container" ngFileDrop (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }" [ngStyle]="style" fullWidth nbInput>
  <div class="rowx images">
    <!-- <div class="image-wrap" ngClass="x-col-md-{{config.colSize || 6}}" *ngFor="let image of value; let i=index"> -->
    <div *ngFor="let image of value; let i=index" class="image" [ngStyle]="buildThumbnailStyle(image)" (click)="onFileClick(image, i)">
      <div class="progress-wrap">
        <nb-progress-bar *ngIf="image.uploading" size="tiny" [value]="image.progress.percentage" status="danger">{{ image.progress.percentage }}%</nb-progress-bar>
      </div>
      <nb-icon [pack]="'eva'" [icon]="'close-circle'" [status]="'danger'" (click)="remove(i)"></nb-icon>
    </div>
    <!-- </div> -->

    <div class="upload-btn-wrap zcol-md-{{config.colSize || 6}}">
      <button [disabled]="isProcessing" class="upload-btn" nbButton size="tiny" (click)="chooseFile()">upload</button>
      <br>
      <button [disabled]="isProcessing" class="upload-btn" nbButton size="tiny" (click)="uploadByLinks()">upload by links</button>
      <br>
      <button [disabled]="isProcessing" class="upload-btn" nbButton size="tiny" (click)="onCaptureBtnClick()">{{ 'Common.capture' | translate | headtitlecase }}</button>
    </div>
  </div>
</div>
<input #uploadButton nbIn style="display: none;" type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>