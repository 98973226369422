import {Component, OnInit} from '@angular/core';
import {DataManagerFormComponent} from "../../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../../models/model";
import {RootServices} from "../../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../../services/common.service";
import {AdminProductService} from "../../../../admin-product/admin-product.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductModel} from "../../../../../models/product.model";
import {Select2Option} from "../../../../../lib/custom-element/select2/select2.component";
import {GoodsModel, WarehouseGoodsContainerModel} from "../../../../../models/warehouse.model";
import {CurrencyMaskConfig} from "ng2-currency-mask";
import {data} from "jquery";

@Component({
  selector: 'ngx-b2b-new-purchase-product-form',
  templateUrl: './b2b-new-purchase-product-form.component.html',
  styleUrls: ['./b2b-new-purchase-product-form.component.scss']
})
export class B2bNewPurchaseProductFormComponent extends DataManagerFormComponent<ProductModel> implements OnInit {

  componentName: string = 'B2bNewPurchaseProductFormComponent';
  idKey = ['Code'];
  apiPath = '/admin-product/products';

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<B2bNewPurchaseProductFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  currencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  moneyMask: any = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: this.currencyFormat.thousands,
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: this.currencyFormat.decimal,
  };

  select2OptionForUnit = {
    placeholder: 'Chọn ĐVT...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  select2OptionForContainer = {
    placeholder: 'Vị trí (nằm trong kệ)...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', {filter_Path: settings.data['term'] ? settings.data['term'] : '', includeIdText: true, ne_Type: 'DRAWERS', limit: 20}).then(rs => {
          success(rs.map(m => {
            m.text = m.Path;
            return m;
          }));
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data,
        };
      },
    },
  };

  select2OptionForGroups = {
    placeholder: 'Chọn nhóm...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    multiple: true,
    // tags: true,
  };

  select2OptionForCategories: Select2Option = {
    placeholder: 'Chọn danh mục...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    multiple: true,
    // tags: true,
  };

  getRequestId(callback: (id?: string[]) => void) {
    callback(this.inputId);
  }


  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    return super.init();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const newForm = this.formBuilder.group<any>({
      Code: [],
      Type: ['PRODUCT'],
      Pictures: [null, Validators.required],
      Name: [null, Validators.required],
      WarehouseUnit: [null, Validators.required],
      Container: [null, Validators.required],
      Categories: [],
      // Groups: [],
      Price: [],
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  getRawFormData(): { array: ProductModel[] } {
    const rawData = super.getRawFormData();
    for (const formItem of rawData.array) {
      if (!formItem.UnitConversions) {
        formItem.UnitConversions = [];
      }
      if (formItem.UnitConversions.findIndex(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(formItem.WarehouseUnit)) < 0) {
        formItem.UnitConversions.push({
          Unit: formItem.WarehouseUnit,
          UnitLabel: this.cms.getObjectText(formItem.WarehouseUnit),
          IsDefaultPurchase: true,
          IsDefaultSales: true,
          IsAutoAdjustInventory: true,
        });
      }
      formItem.FeaturePicture = formItem.Pictures[0] || null;
      formItem.Groups = [{id: 'B2BPOPNEWPRODUCT', 'text': 'Sản phẩm mới B2BPOP'}];
    }
    return rawData;
  }

  async createAndAssign(product, unit, container, warehouse) {
    let choosingContainer: string = this.cms.getObjectId(container);
    let rs = null;
    if (choosingContainer) {
      rs = await this.apiService.postPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', {}, [{
        Parent: choosingContainer as any,
        Warehouse: warehouse,
        Type: 'DRAWERS',
      }]).then(async rs => {
        const goods = await this.apiService.putPromise<GoodsModel[]>('/warehouse/goods', {id: this.cms.getObjectId(product), updateContainerDescription: true, assignContainers: rs[0].Code}, [
          {
            Code: this.cms.getObjectId(product),
            WarehouseUnit: this.cms.getObjectId(unit)
          }
        ]);
        rs[0].Path = goods[0].Containers[0]['Path'];
        return rs;
      });
      return rs;
    }
    return true;
  }

  onAfterCreateSubmit(newFormData: ProductModel[]) {
    super.onAfterCreateSubmit(newFormData);
  }

  executePost(params: any, data: ProductModel[], success: (rs: ProductModel[]) => void, error: (e: any) => void) {
    super.executePost(params, data, async (rs) => {
      const fromData = this.array.controls[0].value;
      await this.createAndAssign(rs[0].Code, rs[0].WarehouseUnit, fromData.Container, fromData.Container.Warehouse).then(() => {
        rs[0].Price = fromData.Price;
      });
      success(rs);
    }, error);
  }

  async save(): Promise<ProductModel[]> {
    const fromData = this.array.controls[0].value;
    if (!fromData.Container || !fromData.Container.Warehouse) {
      return Promise.reject('Bạn chưa chọn kệ hoặc kệ chưa xác định kho chứa');
    }
    return super.save().then(rs => {
      // this.adminProductService.updateGroupList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  }
}
