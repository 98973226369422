import {AfterViewInit, Directive, EventEmitter, Output} from "@angular/core";

@Directive({
  selector: '[elementReady]'
})
export class ElementReadyDirective implements AfterViewInit {
  @Output() elementReady = new EventEmitter<void>();

  ngAfterViewInit() {
    this.elementReady.emit();
  }
}