import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "../../services/auth-guard.service";
import {NgModule} from "@angular/core";
import {ECommerceDashboardComponent} from "./e-commerce/e-commerce-dashboard.component";

export const dashboardRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'e-commerce',
  },
  {
    path: 'e-commerce',
    canActivate: [AuthGuardService],
    component: ECommerceDashboardComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {
}
