<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [ngStyle]="{width: width, height: height}">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Webhook' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Module" label="{{'Module' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Module" [select2Option]="select2OptionForModule" [data]="moduleList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Event" label="{{'Sự kiện' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Event" [select2Option]="select2OptionForEvent" [data]="formItem.get('Module').value?.events"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Method" label="{{'Phương thức' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <nb-select fullWidth formControlName="Method" placeholder="Phương thức GET,POST,PUT,DELETE">
                    <nb-option value="GET">GET</nb-option>
                    <nb-option value="POST">POST</nb-option>
                    <nb-option value="PUT">PUT</nb-option>
                    <nb-option value="DELETE">DELETE</nb-option>
                  </nb-select>
                </ngx-form-group>
              </div>
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Target" label="{{'URL Đích' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Target" placeholder="{{'URL Đích' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AuthType" label="{{'Chứng thực' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <nb-select fullWidth formControlName="AuthType" placeholder="Chứng thực">
                    <nb-option value="NONE">Không chứng thực</nb-option>
                    <nb-option value="BASIC" disabled>Basic: Cơ bản</nb-option>
                    <nb-option value="HEADER" disabled>Header</nb-option>
                    <nb-option value="JWT">JWT</nb-option>
                  </nb-select>
                </ngx-form-group>
              </div>
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="AuthData" label="{{'Dữ liệu chứng thực' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth class="form-group-input-control" formControlName="AuthData" placeholder="{{'/^.*(?=.{12,}+)(?=.*[0-9]+)(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[\\*&!@%\\^#\\$]+).*$/' | translate | headtitlecase}}">
                  <nb-icon pack="eva" icon="sync-outline" class="form-group-icon-control link" status="success" nbTooltip="Phát sinh chuỗi bí mật ngẫu nhiên" (click)="generateSecretString(formItem)"></nb-icon>
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>{{ 'Common.addOne' | translate | headtitlecase }}</button>
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{ 'Common.saveAndClose' | translate | headtitlecase }}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>{{ 'Common.save' | translate | headtitlecase }}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
