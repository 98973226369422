<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('ZaloOa.Template.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ZaloOa" label="ZaloOa.Oa.name" [array]="array" [index]="i" [allowCopy]="false">
                  <ngx-select2 formControlName="ZaloOa" [select2Option]="select2OptionForZaloOaId"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="TemplateId" label="ZaloOa.Template.id" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="TemplateId" placeholder="{{'ZaloOa.Template.id' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <textarea nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div>
            </div>


            <hr>
            <label class="label">Details</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <!-- <div class="row"> -->
              <!-- <div class="col-sm-12"> -->
              <div class="row fit-content-column">
                <div class="col-sm-3 label column">{{ 'Common.name' | translate | headtitlecase }}</div>
                <div class="col-sm-3 label column">{{ 'Common.dataType' | translate | headtitlecase }}</div>
                <div class="col-sm-3 label column">{{ 'Định dạng' | translate | headtitlecase }}</div>
                <div class="col-sm-3 label column">{{ 'Mô tả' | translate | headtitlecase }}</div>
              </div>
              <!-- </div> -->
              <!-- </div> -->
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton status="success" (click)="addDetailFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Details" class="form-details">
              <div class="form-detail-item" *ngFor="let detail of getDetails(i).controls; let ir=index" [formGroup]="detail">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ir + 1 }}">
                      <input type="hidden" formControlName="Id">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-3">
                          <ngx-form-group [formGroup]="detail" name="Name" label="Common.name" [hideLabel]="true" [array]="getDetails(i)" [index]="ir">
                            <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-3">
                          <ngx-form-group [formGroup]="detail" name="TypeOfData" label="Common.dataType" [hideLabel]="true" [array]="getDetails(i)" [index]="ir">
                            <ngx-select2 formControlName="TypeOfData" [select2Option]="select2OptionForAccountingBusiness" [data]="[{id: 'STRING', text: 'STRING'}, {id: 'NUMBER', text: 'NUMBER'}, {id: 'DATE', text: 'DATE'}, {id: 'TIME', text: 'TIME'}, {id: 'DATETIME', text: 'DATE_TIME'}]"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-3">
                          <ngx-form-group [formGroup]="detail" name="Format" label="Định dạng" [hideLabel]="true" [array]="getDetails(i)" [index]="ir">
                            <input type="text" nbInput fullWidth formControlName="Format" placeholder="{{'Định dạng' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-3">
                          <ngx-form-group [formGroup]="detail" name="Description" [hideLabel]="true" label="Common.description" [array]="getDetails(i)" [index]="ir">
                            <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <!-- <label class="label">CM</label> -->
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeDetail(i, ir)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>