import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NbButtonComponent, NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {agMakeCommandColDef} from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import {agMakeSelectionColDef} from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import {agMakeTextColDef} from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import {AgGridDataManagerListComponent} from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import {ApiService} from '../../../../../services/api.service';
import {CommonService} from '../../../../../services/common.service';
import {RootServices} from '../../../../../services/root.services';
import {ColDef, IGetRowsParams, RowModelType} from '@ag-grid-community/core';
import {SystemApiDocFormComponent} from '../system-api-doc-form/system-api-doc-form.component';
import {Model} from '../../../../../models/model';
import {SystemApiDocPrintComponent} from '../system-api-doc-print/system-api-doc-print.component';
import {agMakeStateColDef} from '../../../../../lib/custom-element/ag-list/column-define/state.define';
import {agMakeButtonsColDef} from "../../../../../lib/custom-element/ag-list/column-define/buttons.define";
import {AgTextFilter} from "../../../../../lib/custom-element/ag-list/filter/ag-text-filter";
import {AgSelect2Filter} from "../../../../../lib/custom-element/ag-list/filter/select2.component.filter";

@Component({
  selector: 'ngx-system-api-doc-list',
  templateUrl: './system-api-doc-list.component.html',
  styleUrls: ['./system-api-doc-list.component.scss']
})
export class SystemApiDocListComponent extends AgGridDataManagerListComponent<Model, SystemApiDocFormComponent> implements OnInit {

  componentName: string = 'SystemApiDocListComponent';
  apiPath = '/system/full-api-docs';
  // formPath = '/code-template/simple-data-manager/form';
  idKey = ['Id'];
  formDialog = SystemApiDocFormComponent;
  printDialog = SystemApiDocPrintComponent;
  @Input() rowModelType: RowModelType = 'clientSide';

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<SystemApiDocListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  moduleList: Model[];

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      this.rowData = await this.apiService.getPromise<Model[]>(this.apiPath, {});
      this.moduleList = await this.apiService.getPromise('/system/hook/webhooks/module-events', {});

      this.actionButtonList.unshift({
        name: 'HOOKS',
        label: 'Hook',
        status: 'primary',
        icon: 'cast-outline',
        title: 'Hook cho các docs đã chọn',
        size: 'medium',
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          this.apiService.putPromise<Model[]>(this.apiPath, {hook: true}, this.selectedItems.map(m => ({Id: m.Id}))).then(rs => {
            this.cms.showToast('Đã hook api docs', 'Hook thành công', {status: 'success'});
          }).catch(err => {
            console.error(err);
          });
        },
      });
      this.actionButtonList.unshift({
        name: 'HOOKALL',
        label: 'Hook all',
        status: 'danger',
        icon: 'cast-outline',
        title: 'Hook cho các docs đã lọc',
        size: 'medium',
        click: () => {
          const items = [];
          this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
            items.push(rowNode.data);
          });
          this.apiService.putPromise<Model[]>(this.apiPath, {hook: true}, items.map(m => ({Id: m.Id}))).then(rs => {
            this.cms.showToast('Đã hook api docs đã lọc', 'Hook thành công', {status: 'success'});
          }).catch(err => {
            console.error(err);
          });
        },
      });


      // const processingMap = CodeTemplateStateMap.simpleDataManagerStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Module',
          field: 'Module',
          width: 250,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.select2OptionForTemplate,
              data: this.moduleList
            }
          },
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Endpoint',
          field: 'Endpoint',
          pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 800,
        },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'hook',
              icon: 'cast-outline',
              title: 'Hook',
              status: 'primary',
              label: 'Hook',
              outline: false,
              action: async (params: any, buttonConfig: any, btn: NbButtonComponent) => {
                this.apiService.putPromise<Model[]>(this.apiPath, {hook: true}, [params?.node?.data]).then(rs => {
                  this.cms.showToast('Đã hook api docs', 'Hook thành công', {status: 'success'});
                }).catch(err => {
                  console.error(err);
                });
                return true;
              }
            }
          ]),
          headerName: 'Hook',
          field: 'Hook',
          width: 120,
          pinned: 'right',
        },
        // {
        //   ...agMakeStateColDef(this.cms, processingMap, (data) => {
        //     this.preview([data]);
        //   }),
        //   headerName: 'Trạng thái',
        //   field: 'State',
        //   width: 155,
        // },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
