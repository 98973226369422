import {Component, OnInit} from '@angular/core';
import {MinierpBaseFormComponent} from '../../minierp-base-form.component';
import {MiniErpModel} from '../../../../models/minierp.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NbToastrService, NbDialogService, NbDialogRef, NbGlobalPhysicalPosition, NbToastRef} from '@nebular/theme';
import {CommonService} from '../../../../services/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MinierpService} from '../../minierp-service.service';
import {RootServices} from '../../../../services/root.services';
import {ContactModel} from "../../../../models/contact.model";
import {DataManagerFormComponent} from "../../../../lib/data-manager/data-manager-form.component";
import {CustomIcon, FormGroupComponent} from "../../../../lib/custom-element/form/form-group/form-group.component";
import {ContactFormComponent} from "../../../contact/contact/contact-form/contact-form.component";
import {PbxModel} from "../../../../models/pbx.model";
import {WhHostingModel} from "../../../../models/wh-hosting.model";
import {CollaboratorOpportunityModel} from "../../../../models/collaborator.model";
import {takeUntil} from "rxjs/operators";
import {PbxUserModel} from "../../../../models/pbx-user.model";
import {PbxDomainModel} from "../../../../models/pbx-domain.model";
import {PbxExtensionModel} from "../../../../models/pbx-extension.model";
import {PbxDialplanModel} from "../../../../models/pbx-dialplan.model";
import {WhWebsiteModel} from "../../../../models/wh-website.model";
import {WhCronJobModel} from "../../../../models/wh-cron-job.model";
import {WhDatabaseUserModel} from "../../../../models/wh-database-user.model";
import {WhDatabaseModel} from "../../../../models/wh-database.model";
import {WhFtpModel} from "../../../../models/wh-ftp.model";
import {PbxDeploymentModel} from "../../../../models/pbx-deployment.model";
import {MiniErpDeploymentModel} from "../../../../models/minierp-deployment.model";
import {PbxCustomerModel} from "../../../../models/pbx-customer.model";
import {ShowcaseDialogComponent} from "../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component";
import {Executable} from "../../../ivoip/customers/customer-form/customer-form.component";
import {PbxGatewayModel} from '../../../../models/pbx-gateway.model';
import {PbxPstnNumberModel} from '../../../../models/pbx-pstn-number.model';

@Component({
  selector: 'ngx-minierp-form',
  templateUrl: './minierp-form.component.html',
  styleUrls: ['./minierp-form.component.scss'],
})
export class MinierpFormComponent extends DataManagerFormComponent<MiniErpModel> implements OnInit {

  componentName: string = 'MinierpFormComponent';
  idKey = 'Code';
  apiPath = '/mini-erp/minierps';
  baseFormUrl = '/minierp/minierps/form';

  gatewaylist: { id: string, text: string }[];
  pbxList: PbxModel[] = [];
  progressBarValue = 10;
  processBarlabel = 'Tiến trình';
  hostingList: { Code: string, Name: string }[] = [];
  longToastRef: NbToastRef = null;

  select2OptionForPbxList = {
    placeholder: 'Chọn tổng đài...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Description',
    },
  };
  hostingListConfig = {
    placeholder: 'Chọn web hosting...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Host',
    },
  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public minierpService: MinierpService,
    public ref: NbDialogRef<MinierpFormComponent>,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms, ref);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    this.pbxList = await this.apiService.getPromise<PbxModel[]>('/ivoip/pbxs', {
      select: 'Code,Description,ApiUrl',
      limit: 'nolimit',
    }).then(list => this.convertOptionList(list, 'Code', 'Description'));
    this.hostingList = await this.apiService.getPromise<WhHostingModel[]>('/web-hosting/hostings', {}).then(hostings => this.convertOptionList(hostings, 'Code', 'Host'));
    let checked = false;
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => {
      if (this.form.valid) {
        if (!checked) {
          this.progressBarValue = 30;
          this.processBarlabel = 'Khai báo thông tin';
          checked = true;
        }
      }
    });
    return super.init().then(rs => {

      // this.apiService.get<PbxModel[]>('/ivoip/pbxs', {select: 'Code,Description,ApiUrl', limit: 9999}, list => {
      //   this.pbxList = this.convertOptionList(list, 'Code', 'Description');
      //
      //   this.apiService.get<WhHostingModel[]>('/web-hosting/hostings', {}, hostings => {
      //     this.hostingList = this.convertOptionList(hostings, 'Code', 'Host');
      //
      //     let checked = false;
      //     this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => {
      //       if (this.form.valid) {
      //         if (!checked) {
      //           this.progressBarValue = 30;
      //           this.processBarlabel = 'Khai báo thông tin';
      //           checked = true;
      //         }
      //       }
      //     });
      //   });
      // });

      this.actionButtonList.unshift({
        name: 'deplpoy',
        label: 'Triển khai',
        title: 'Triển khai BM Core',
        status: 'danger',
        icon: 'flash-outline',
        size: 'medium',
        disabled: () => this.isProcessing,
        click: (event, option, context, controlEle) => {
          this.deployPbxAndMiniErp(option.form.value, () => {
            this.onProcessed();
          }, e => {
            this.onProcessed();
            this.cms.openDialog(ShowcaseDialogComponent, {
              context: {
                title: 'Lỗi triển khai Tổng Đài Điện Toán',
                content: e && e.error && e.error.logs ? e.error.logs.join('\n') : JSON.stringify(e),
                actions: [
                  {
                    label: 'Đóng',
                    icon: 'back',
                    status: 'info',
                    action: () => {
                    },
                  },
                ],
              },
            });
          });
          return true;
        }
      });
      return rs;
    });
  }

  async formLoad(formData: MiniErpModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: MiniErpModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.cms.getObjectId(itemFormData.Pbx)) {
        this.apiService.get<PbxGatewayModel[]>('/ivoip/gateways', {pbx: this.cms.getObjectId(itemFormData.Pbx)}, gateways => {
          this.gatewaylist = gateways.map(g => {
            return {id: g.gateway_uuid, text: g.gateway};
          });
        });
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  get progressBarStatus() {
    if (this.progressBarValue <= 25) {
      return 'danger';
    } else if (this.progressBarValue <= 50) {
      return 'warning';
    } else if (this.progressBarValue <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: MiniErpModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['includeUsers'] = true;
    super.executeGet(params, success, error);
  }

  makeNewFormGroup(data?: MiniErpModel): FormGroup {
    const newForm = this.formBuilder.group<any>({
      Code: [],

      // Summary info
      Name: [],
      Description: [],

      // Connection info
      // ApiUrl: {disabled: true, value: null},
      // ApiVersion: {disabled: true, value: null},
      // Version: {disabled: true, value: null},
      // License: {disabled: true, value: null},
      // ApiUser: {disabled: true, value: null},
      // ApiPassword: {disabled: true, value: '******'},
      Enabled: [true],
      AutoUpdate: [false],

      // Contact
      Customer: [null, Validators.required],
      CustomerName: [null, Validators.required],
      CustomerPhone: [],
      CustomerEmail: [null, Validators.required],
      CustomerAddress: [],
      CustomerTaxCode: [],
      Note: [],

      // Hosting info
      Hosting: [null, Validators.required],
      DomainName: [null, Validators.required],

      // PBX Info
      Pbx: [],
      PbxGateway: [],
      PbxDomainName: [],
      PbxPstnNumber: [],
      PbxExtensions: [],
      PbxPrivateNumber: [],
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: MiniErpModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  // goback(): false {
  //   this.router.navigate(['/minierp/minierps/list']);
  //   return false;
  // }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onAfterCreateSubmit(newFormData: MiniErpModel[]) {
    super.onAfterCreateSubmit(newFormData);
    // this.minierpService.reloadCache();
  }

  onAfterUpdateSubmit(newFormData: MiniErpModel[]) {
    super.onAfterUpdateSubmit(newFormData);
    // this.minierpService.reloadCache();
  }

  onCustomerChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('CustomerName').setValue(selectedData.Name);
          formGroup.get('CustomerPhone').setValue(selectedData.Phone);
          formGroup.get('CustomerEmail').setValue(selectedData.Email);
          formGroup.get('CustomerAddress').setValue(selectedData.FullAddress);
          formGroup.get('CustomerTaxCode').setValue(selectedData.TaxCode);
        }
      }
    }
  }

  objectControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: {
      parentForm: FormGroup
    }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Customer').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoading: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: ContactModel = {...newData[0], id: newData[0].Code, text: newData[0].Name};
            formGroup.get('Customer').patchValue(newContact);
            formGroup.get('CustomerName').setValue(newContact.Name);
            formGroup.get('CustomerPhone').setValue(newContact.Phone);
            formGroup.get('CustomerEmail').setValue(newContact.Email);
            formGroup.get('CustomerAddress').setValue(newContact.FullAddress);
            formGroup.get('CustomerTaxCode').setValue(newContact.TaxCode);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  onPbxChange(event: { id: string, text: string }, formGroup: FormGroup, index: number) {
    if (event && event.id) {
      this.apiService.get<PbxGatewayModel[]>('/ivoip/gateways', {pbx: event.id}, gateways => {

        this.gatewaylist = gateways.map(item => {
          return {id: item.gateway_uuid, text: item.gateway};
        });

      });
    } else {
      this.gatewaylist = [];
    }
  }

  // Event

  // Deployment function
  async deployPbxPbxUser(pbx: string, domainId: string, givenName: string, familyName: string, organization: string, email: string, groups: string[], username: string) {
    return new Promise<PbxUserModel>((resolve, reject) => {
      this.apiService.get<PbxUserModel[]>('/ivoip/users', {
        username: username,
        domainId: domainId + '@' + pbx,
        silent: true
      }, oldPbxUsers => {
        let pbxUser: PbxUserModel = null;
        let method = 'POST';
        if (oldPbxUsers.length > 0) {
          pbxUser = oldPbxUsers[0];
          method = 'PUT';
        } else {
          pbxUser = new PbxUserModel();
        }

        pbxUser.username = username;
        pbxUser.user_email = email;
        pbxUser.contact_name_given = givenName;
        pbxUser.contact_name_family = familyName;
        pbxUser.contact_organization = organization;
        pbxUser.domain_uuid = domainId;
        pbxUser.groups = groups;
        pbxUser.user_enabled = true;

        this.apiService.postPut<PbxUserModel[]>(method, '/ivoip/users', {
          domainId: domainId + '@' + pbx,
          autoGeneratePassword: true,
          autoGenerateApiKey: true,
          silent: true
        }, [pbxUser], newPbxUsers => {
          if (newPbxUsers && newPbxUsers.length > 0) {
            resolve(newPbxUsers[0]);
          } else {
            reject('Lỗi tạo thông tin kết nối api cho tổng đài');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async deployPbxDomain(pbx: string, domainName: string, description: string, silent: boolean) {
    await this.updatePbxDomainCache(pbx);
    return new Promise<PbxDomainModel>((resolve, reject) => {
      const domain = new PbxDomainModel();

      this.apiService.get<PbxDomainModel[]>('/ivoip/domains', {DomainName: domainName, silent: true}, domains => {

        const tmpDomain = domains.find(f => f.DomainId);
        if (tmpDomain) {
          resolve(tmpDomain);
        } else {
          // Create domain and assign user to owner domain
          domain.Pbx = pbx;
          domain.DomainName = domainName;
          domain.Description = description;

          this.apiService.post<PbxDomainModel[]>('/ivoip/domains', {silent: silent}, [domain], newDomains => {
            const newDomain = newDomains[0];
            if (newDomain) {
              resolve(newDomain);
            } else {
              reject('Hệ thống không thể khởi tạo tổng đài');
            }

          }, e => reject(e));
        }
      });
    });
  }

  async updatePbxDomainCache(pbx: string) {
    return new Promise<PbxModel[] | HttpErrorResponse>((resolve, reject) => {
      /** Sync pbx domains and get current pbx doamins */
      this.apiService.get<PbxModel[]>('/ivoip/pbxs', {id: pbx}, pbxs => {
        this.apiService.put<PbxModel[]>('/ivoip/pbxs', {cachePbxDomain: true, silent: true}, pbxs, respPbxs => {
        }, null, respPbxs => {
          resolve(respPbxs);
        });
      }, e => reject(e));
    });
  }

  async deployPbxPbxPstnNumber(pbx: string, domainId: string, domainName: string, pstnNumberStr: string, transferToExt: string) {
    return new Promise<PbxPstnNumberModel>((resolve, reject) => {
      this.apiService.get<PbxPstnNumberModel[]>('/ivoip/pstn-numbers', {
        domainId: domainId + '@' + pbx,
        destination_accountcode: pstnNumberStr,
        silent: true
      }, oldPbxPstnNumbers => {
        if (oldPbxPstnNumbers.length > 0) {
          resolve(oldPbxPstnNumbers[0]);
        } else {
          const pstnNumber = new PbxPstnNumberModel();
          pstnNumber.destination_accountcode = pstnNumberStr;
          pstnNumber.destination_number = '(\\d{1,3}' + pstnNumberStr.replace(/^0/, '') + ')';
          pstnNumber.domain_uuid = domainId;
          pstnNumber.destination_type = 'inbound';
          pstnNumber.destination_description = 'Goi vao';
          pstnNumber.destination_record = true;
          pstnNumber.destination_enabled = true;
          pstnNumber.dialplan_details = [
            {
              dialplan_detail_data: 'transfer:' + transferToExt + ' XML ' + domainName,
            },
          ];

          this.apiService.post<PbxPstnNumberModel[]>('/ivoip/pstn-numbers', {
            domainId: domainId + '@' + pbx,
            silent: true
          }, [pstnNumber], newPbxPstnNumbers => {
            const newPbxPstnNumber = newPbxPstnNumbers[0];

            if (newPbxPstnNumber) {
              resolve(newPbxPstnNumber);
            } else {
              reject('Lỗi khai báo số đấu nối');
            }
          }, e => reject(e));
        }
      }, e => reject(e));
    });
  }

  async deployPbxPbxExtensions(pbx: string, domainId: string, domainName: string, extensionsStr: string) {
    return new Promise<PbxExtensionModel[]>((resolve, reject) => {
      // Config pstn number
      let exts: string[] = [];
      let minExtension: string;
      let maxExtension: string;
      let firstExtension: string;
      if (/\,/.test(extensionsStr)) {
        exts = extensionsStr.split(',');
        firstExtension = exts[0];
      } else {
        const tmpList = extensionsStr.split('-');
        if (tmpList.length > 1) {
          minExtension = tmpList[0];
          maxExtension = tmpList[1];
          firstExtension = minExtension;

          for (let i = +minExtension; i <= +maxExtension; i++) {
            exts.push('' + i);
          }
        } else if (tmpList[0]) {
          exts.push('' + tmpList[0]);
        }
      }

      const extensions: PbxExtensionModel[] = [];
      exts.forEach(ext => {
        extensions.push({
          extension: ext,
          call_timeout: 30,
          enabled: true,
          password: '',
          domain_uuid: domainId,
          user_record: 'all',
          description: ext + '@' + domainName.split('.')[0],
        });
      });

      this.apiService.get<PbxExtensionModel[]>('/ivoip/extensions', {
        domainId: domainId + '@' + pbx,
        silent: true
      }, oldPbxExtensions => {
        if (oldPbxExtensions.length > 0) {
          resolve(oldPbxExtensions);
        } else {
          if (extensions.length > 0) {
            this.apiService.post<PbxExtensionModel[]>('/ivoip/extensions', {
              domainId: domainId + '@' + pbx,
              silent: true
            }, extensions, newPbxExtensions => {
              if (newPbxExtensions && newPbxExtensions.length > 0) {
                resolve(newPbxExtensions);
              } else {
                reject('Lỗi khai báo danh sách số mở rộng');
              }
            }, e => reject(e));
          } else {
            resolve([]);
          }
        }
      }, e => reject(e));
    });
  }

  async deployPbxOutboundRule(pbx: string, domainId: string, domainName, pstnNumberStr: string, gateway: string) {
    return new Promise<PbxDialplanModel>((resolve, reject) => {

      // Create outbound route
      const dialplan = new PbxDialplanModel();
      dialplan.dialplan_type = 'outbound';
      dialplan.dialplan_gateway = gateway;
      dialplan.dialplan_name = 'Goi ra ' + pstnNumberStr;
      dialplan.dialplan_number = pstnNumberStr;
      dialplan.dialplan_regex = '\\d{7,12}';
      dialplan.dialplan_context = domainName;
      dialplan.domain_uuid = domainId;
      dialplan.dialplan_description = 'Goi ra ' + pstnNumberStr;
      dialplan.dialplan_order = 100;
      dialplan.dialplan_enabled = true;
      this.apiService.get<PbxDialplanModel[]>('/ivoip/dialplans', {
        dialplan_number: pstnNumberStr,
        domainId: domainId + '@' + pbx,
        silent: true
      }, oldDialplans => {

        let method = 'POST';
        if (oldDialplans && oldDialplans.length > 0) {
          method = 'PUT';
          dialplan.dialplan_uuid = oldDialplans[0].dialplan_uuid;
        }

        this.apiService.postPut<PbxDialplanModel[]>(method, '/ivoip/dialplans', {
          domainId: domainId + '@' + pbx,
          silent: true
        }, [dialplan], newDialplans => {
          if (newDialplans && newDialplans.length > 0) {
            resolve(dialplan);
          } else {
            reject('Lỗi thêm cấu hình gọi ra');
          }

        }, e => reject(e));
      });

    });
  }

  async deployMiniErpWebiste(hosting: string, domainName: string) {
    return new Promise<WhWebsiteModel>((resolve, reject) => {

      this.apiService.get<WhWebsiteModel[]>('/web-hosting/websites', {
        hosting: hosting,
        domain: domainName,
        silent: true
      }, oldWebsites => {

        let website = oldWebsites[0];
        let menthod = 'POST';
        if (website) {
          menthod = 'PUT';
        } else {
          website = new WhWebsiteModel();
        }

        website.hosting = hosting;
        website.domain = domainName;

        this.apiService.postPut<WhWebsiteModel[]>(menthod, '/web-hosting/websites', {hosting: hosting}, [website], newWebsites => {
          const newWebsite = newWebsites[0];
          if (newWebsite) {
            resolve(newWebsite);
          } else {
            reject('Lỗi khởi tạo website');
          }
        }, e => reject(e));
      }, e => reject(e));

    });
  }

  async checkAllowDeploy(miniErpDeploymentCode: string) {
    return this.apiService.getPromise<{ data: boolean }>('/mini-erp/deployments', {
      id: miniErpDeploymentCode,
      checkAllowDeploy: true,
      silent: true
    }).then(rs => rs.data);
  }

  async deployMiniErpCronJob(hosting: string, domainId: string, command: string, run_min: string, run_hour: string, run_mday: string, run_month: string, run_wday: string): Promise<WhCronJobModel> {

    return this.apiService.getPromise<WhCronJobModel[]>('/web-hosting/cron-jobs', {
      hosting: hosting,
      parent_domain_id: domainId,
      silent: true
    }).then(oldCronJobs => {

      let cronJob = oldCronJobs[0];
      let menthod = 'POST';
      if (cronJob) {
        menthod = 'PUT';
      } else {
        cronJob = new WhCronJobModel();
      }

      cronJob.parent_domain_id = domainId;
      cronJob.command = command;
      cronJob.run_min = run_min;
      cronJob.run_hour = run_hour;
      cronJob.run_mday = run_mday;
      cronJob.run_month = run_month;
      cronJob.run_wday = run_wday;
      cronJob.type = 'url';
      cronJob.log = 'n';
      cronJob.active = 'y';

      return this.apiService.putPromise<WhCronJobModel[]>('/web-hosting/cron-jobs', {hosting: hosting}, [cronJob]).then(newCronJobs => {
        const newCronJob = newCronJobs[0];
        if (newCronJob) {
          return newCronJob;
        } else {
          return Promise.reject('no cron was created');
        }
      });
    });
  }

  async deployMiniErpDatabaseUser(hosting: string, clientId: string, username: string) {
    return new Promise<WhDatabaseUserModel>((resolve, reject) => {

      const dbUsername = 'c' + clientId + username;
      this.apiService.get<WhDatabaseUserModel[]>('/web-hosting/database-users', {
        hosting: hosting,
        database_user: dbUsername,
        silent: true
      }, oldDbUsers => {
        let dbUser = oldDbUsers[0];
        let method = 'POST';
        if (dbUser) {
          method = 'PUT';
        } else {
          dbUser = new WhDatabaseUserModel();
        }

        dbUser.database_user = dbUsername;
        this.apiService.postPut<WhDatabaseUserModel[]>(method, '/web-hosting/database-users', {
          hosting: hosting,
          autoGeneratePassword: true,
          silent: true
        }, [dbUser], newDbUsers => {
          const newDbUser = newDbUsers[0];
          if (newDbUser) {
            resolve(newDbUser);
          } else {
            reject('Lỗi tại tài khoản database cho website');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async deployMiniErpDatabase(hosting: string, websiteId: string, clientId: string, dbUserId: string, dbName: string) {
    return new Promise<WhDatabaseModel>((resolve, reject) => {
      const database = new WhDatabaseModel();
      database.parent_domain_id = websiteId;
      database.database_user_id = dbUserId;
      database.database_name = 'c' + clientId + dbName;

      this.apiService.get<WhDatabaseModel[]>('/web-hosting/databases', {
        hosting: hosting,
        database_name: database.database_name,
        silent: true
      }, oldDatabases => {
        const oldDatabase = oldDatabases[0];
        if (oldDatabase) {
          resolve(oldDatabase);
        } else {
          this.apiService.post<WhDatabaseModel[]>('/web-hosting/databases', {
            hosting: hosting,
            silent: true
          }, [database], newDatabases => {
            const newDatabase = newDatabases[0];
            if (newDatabase) {
              resolve(newDatabase);
            } else {
              reject('Lỗi tạo database cho website');
            }
          }, e => reject(e));
        }
      }, e => reject(e));
    });

  }

  async deployMiniErpFtp(hosting: string, clientName: string, websiteId: string, username: string) {
    return new Promise<WhFtpModel>((resolve, reject) => {

      const ftpUser = clientName + username;
      this.apiService.get<WhFtpModel[]>('/web-hosting/ftps', {
        hosting: hosting,
        username: ftpUser,
        silent: true
      }, oldFtps => {

        let ftp = oldFtps[0];
        let method = 'POST';
        if (ftp) {
          method = 'PUT';
        } else {
          ftp = new WhFtpModel();
        }

        ftp.parent_domain_id = websiteId;
        ftp.username = username;
        this.apiService.postPut<WhFtpModel[]>(method, '/web-hosting/ftps', {
          hosting: hosting,
          autoGeneratePassword: true,
          silent: true
        }, [ftp], newFtps => {

          const newFtp = newFtps[0];
          if (newFtp) {
            resolve(newFtp);
          } else {
            reject('Lỗi tạo tài khoản FTP cho webiste');
          }
        }, e => reject(e));
        // }
      }, e => reject(e));
    });
  }

  async destroyMiniErpFtp(hosting: string, clientName: string, websiteId: string, username: string) {
    // return new Promise<WhFtpModel>((resolve, reject) => {

    const ftpUser = clientName + username;
    return this.apiService.getPromise<WhFtpModel[]>('/web-hosting/ftps', {
      hosting: hosting,
      username: ftpUser,
      silent: true
    }).then(oldFtps => {
      let ftp = oldFtps[0];
      let method = 'POST';
      if (ftp) {
        method = 'PUT';
      } else {
        ftp = new WhFtpModel();
      }

      ftp.parent_domain_id = websiteId;
      // ftp.username = username;
      return this.apiService.deletePromise('/web-hosting/ftps/' + ftp.ftp_user_id, {
        hosting: hosting,
        silent: true,
      });
    });
    // });
  }

  async deployPbx(miniErp: string, silent: boolean) {
    return new Promise<PbxDeploymentModel>((resovle, reject) => {

      this.apiService.get<PbxDeploymentModel[]>('/ivoip/deployments', {
        miniErp: miniErp,
        silent: true
      }, ivoipDeployments => {
        if (ivoipDeployments && ivoipDeployments.length > 0) {
          const ivoipDeployment = ivoipDeployments[0];

          this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
            deploy: true,
            silent: silent
          }, [ivoipDeployment], newPbxDeployments => {
            resovle(newPbxDeployments[0]);
          }, e => reject(e));

        } else {
          reject('Pbx deploy ment was not declare');
        }
      });

    });
  }

  async deployMiniErpCore(miniErp: string, silent: boolean) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {

      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        miniErp: miniErp,
        silent: true
      }, miniErpDeployments => {
        if (miniErpDeployments.length > 0) {

          const miniErpDeployment = miniErpDeployments[0];
          this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
            deploy: true,
            silent: true
          }, [miniErpDeployment], results => {
            resolve(results[0]);
          }, e => {
            reject(e);
          });

        } else {
          reject('Thông tin triển khai ProBox One không tồn tại');
        }
      }, e => reject(e));
    });
  }

  async checkFtpReady(miniErpDeploymentCode: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.get<boolean>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        checkFtpReady: true,
        silent: true
      }, status => {
        resolve(status);
      }, e => reject(e));
    });
  }

  async uploadMiniErpInstaller(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          uploadMiniErpInstaller: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          if (respMiniErpDeployments && respMiniErpDeployments.length > 0) {

            this.toastService.show('success', 'Đã tải bộ cài ProBox One lên hosting', {
              status: 'success',
              hasIcon: true,
              position: NbGlobalPhysicalPosition.TOP_RIGHT,
            });

            resolve(respMiniErpDeployments[0]);
          } else {
            reject('Lỗi upload bộ cài ProBox One');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async checkDomainReady(miniErpDeploymentCode: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.get<boolean>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        checkDomainReady: true,
        silent: true
      }, status => {
        resolve(status);
      }, e => reject(e));
    });
  }

  async extractMiniErpInstaller(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          extractMiniErpInstaller: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          if (respMiniErpDeployments && respMiniErpDeployments.length > 0) {
            resolve(respMiniErpDeployments[0]);
          } else {
            reject('Lỗi giải nến bộ cài ProBox One');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async configMiniErp(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          configMiniErp: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          if (respMiniErpDeployments && respMiniErpDeployments.length > 0) {
            resolve(respMiniErpDeployments[0]);
          } else {
            reject('Lỗi cấu hình ProBox One');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async configUserForMiniErp(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          configUserForMiniErp: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          if (respMiniErpDeployments && respMiniErpDeployments.length > 0) {
            resolve(respMiniErpDeployments[0]);
          } else {
            reject('Lỗi khởi tạo tài khoản admin cho ProBox One');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async configPbxMiniErp(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          configPbxMiniErp: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          if (respMiniErpDeployments && respMiniErpDeployments.length > 0) {
            resolve(respMiniErpDeployments[0]);
          } else {
            reject('Lỗi cấu hình tổng đài PBX cho ProBox One');
          }
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async cleanMiniErpInstaller(miniErpDeploymentCode: string) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
        id: miniErpDeploymentCode,
        silent: true
      }, miniErpDeployments => {
        this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', {
          id: miniErpDeploymentCode,
          cleanMiniErpInstaller: true,
          silent: true
        }, miniErpDeployments, respMiniErpDeployments => {
          resolve(respMiniErpDeployments[0]);
        }, e => reject(e));
      }, e => reject(e));
    });
  }

  async updateMiniErpDeployment(miniErpDeployment: MiniErpDeploymentModel, params?: { [key: string]: any }) {
    return new Promise<MiniErpDeploymentModel>((resolve, reject) => {
      if (!params) params = {};
      params['id'] = miniErpDeployment.Code;
      params['silent'] = true;
      this.apiService.put<MiniErpDeploymentModel[]>('/mini-erp/deployments', params, [miniErpDeployment], resp => resolve(resp[0]), e => reject(e));
    });
  }

  async deployPbxAndMiniErp(formData: PbxCustomerModel, onAfterDeploy: () => void, error: (error: any) => void) {

    /** Prepare info */
    // const newFormData = newFormDatas[0];
    // const formData: {
    //     Code: string,
    //     Pbx: string,
    //     DomainName: string,
    //     Name: string,
    //     Phone: string,
    //     Email: string,
    //     PbxPstnNumber: string,
    //     PbxExtensions: string,
    //     Hosting: string;
    //     PbxGateway: string,
    //   } = newFormData;

    console.log('formData', formData);

    const hosting: WhHostingModel = this.hostingList.filter(w => w.Code === this.cms.getObjectId(formData.Hosting))[0];
    const pbx = this.pbxList.filter(p => p.Code === this.cms.getObjectId(formData.Pbx))[0];
    // const domainParse = formData.DomainName.split('.');
    // const deployName = domainParse[0] + (domainParse.length > 1 ? domainParse[1] : '');
    // const deployName = formData.PbxDomainName.replace(/\.+/g, '').slice(0, 13);
    const deployName = parseInt((performance.now() * 1000) + '') + '';

    // let miniErpDeployment = await new Promise<MiniErpDeploymentModel>((resolve, reject) => {
    //   this.apiService.get<MiniErpDeploymentModel[]>('/mini-erp/deployments', {customer: this.cms.getObjectId(formData['Customer'])}, resp => resolve(resp[0]), e => reject(e));
    // });
    let miniErpDeployment = await this.apiService.getPromise<MiniErpDeploymentModel[]>('/mini-erp/deployments', {miniErp: this.cms.getObjectId(formData['Code'])}).then(rs => rs[0]);

    let tryCount = 0;
    let newPbxDomain: PbxDomainModel;
    let newPbxUser: PbxUserModel;
    let newPbxPbxExtensions: PbxExtensionModel[];
    let newPbxPbxPstnNumber: PbxPstnNumberModel;
    let newPbxOutboundRule: PbxDialplanModel;
    let newWesite: WhWebsiteModel;
    let newCronJob: WhCronJobModel;
    let newWebsiteDbUser: WhDatabaseUserModel;
    let newWesiteDb: WhDatabaseModel;
    let newWebsiteFtp: WhFtpModel;
    // let newMiniErpDeployment: MiniErpDeploymentModel;


    const executeScript: Executable[] = [
      {
        message: 'Kiểm tra',
        maxTry: 1,
        delayTry: 15000,
        execute: async () => {
          const result = await this.checkAllowDeploy(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Tạo trang quản lý',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWesite = await this.deployMiniErpWebiste(hosting.Code, formData.DomainName);
          return true;
        },
      },
      {
        message: 'Tạo cron job',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newCronJob = await this.deployMiniErpCronJob(hosting.Code, newWesite.domain_id, `https://${newWesite.domain}/robot.php`, '*', '*', '*', '*', '*');
          return true;
        },
      },
      {
        message: 'Tạo webiste database user',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteDbUser = await this.deployMiniErpDatabaseUser(hosting.Code, hosting.ClientId, deployName);
          miniErpDeployment.DbHost = 'localhost';
          miniErpDeployment.DbUser = newWebsiteDbUser.database_user;
          miniErpDeployment.DbPassword = newWebsiteDbUser.database_password;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo website database',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWesiteDb = await this.deployMiniErpDatabase(hosting.Code, newWesite.domain_id, hosting.ClientId, newWebsiteDbUser.database_user_id, deployName);
          miniErpDeployment.DbName = newWesiteDb.database_name;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo tài khoản FTP',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteFtp = await this.deployMiniErpFtp(hosting.Code, hosting.ClientName, newWesite.domain_id, deployName);
          miniErpDeployment.FtpUser = newWebsiteFtp.username;
          miniErpDeployment.FtpPassword = newWebsiteFtp.password;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      // Deploy PBX
      {
        message: 'Khởi tạo tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newPbxDomain = await this.deployPbxDomain(pbx.Code, formData.PbxDomainName, formData.Name, true);
          return true;
        },
      },
      {
        message: 'Tạo thông tin kết nối api cho tổng đài',
        maxTry: 5,
        delayTry: 15000,
        execute: async () => {
          newPbxUser = await this.deployPbxPbxUser(pbx.Code, newPbxDomain.DomainId, 'Administrator', 'administrator', formData.Name, formData.CustomerEmail, ['admin'], 'administrator');
          miniErpDeployment.PbxApiKey = newPbxUser.api_key;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo danh sách số mở rộng cho tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newPbxPbxExtensions = await this.deployPbxPbxExtensions(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, formData.PbxExtensions);
          return true;
        },
      },
      {
        message: 'Khai báo số đấu nối cho tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          if (formData.PbxPstnNumber) {
            newPbxPbxPstnNumber = await this.deployPbxPbxPstnNumber(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, formData.PbxPstnNumber, newPbxPbxExtensions[0].extension);
          }
          return true;
        },
      },
      // Deploy minierp
      {
        message: 'Cài đặt quy tắt gọi ra',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          if (formData.PbxGateway) {
            newPbxOutboundRule = await this.deployPbxOutboundRule(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, newPbxPbxPstnNumber.destination_accountcode, formData.PbxGateway);
          }
          return true;
        },
      },
      // {
      //   message: 'Triển khai ProBox One',
      //   maxTry: 3,
      //   delayTry: 15000,
      //   execute: async () => {
      //     newMiniErpDeployment = await this.deployMiniErpCore(formData.Code, true);
      //     return true;
      //   },
      // },
      // Deploy ProBox One
      {
        message: 'Kiểm tra kết nối FTP',
        maxTry: 30,
        delayTry: 10000,
        execute: async () => {
          await this.checkFtpReady(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Tải lên website bộ cài ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          await this.uploadMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Kiểm tra website online',
        maxTry: 30,
        delayTry: 10000,
        execute: async () => {
          await this.checkDomainReady(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Giải nén bộ cài ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.extractMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Cấu hình ProBox One',
        maxTry: 10,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.configMiniErp(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Cấu hình người dùng cho ProBox One',
        maxTry: 10,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.configUserForMiniErp(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Dọn dep file cài đặt ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.cleanMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Xóa FTP Account',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteFtp = await this.destroyMiniErpFtp(hosting.Code, hosting.ClientName, newWesite.domain_id, deployName);
          miniErpDeployment.FtpUser = '';
          miniErpDeployment.FtpPassword = '';
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        skipSuccess: true,
        message: 'Đã triển khai xong ProBox One cho ' + formData.Name,
        title: formData.PbxDomainName,
        status: 'success',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          this.cms.openDialog(ShowcaseDialogComponent, {
            context: {
              title: 'Triển khai ProBox One',
              content: 'Đã triển khai thành công ProBox One cho khách ' + formData.Name,
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: () => {
                  },
                },
                {
                  label: 'Truy cập',
                  icon: 'goto',
                  status: 'success',
                  action: () => {
                    window.open(`https://${formData.PbxDomainName}`);
                    this.close();
                  },
                },
              ],
            },
          });
          return true;
        },
      },

    ];

    const deployHostingScript: Executable[] = [
      {
        message: 'Kiểm tra',
        maxTry: 1,
        delayTry: 15000,
        execute: async () => {
          const result = await this.checkAllowDeploy(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Tạo trang quản lý',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWesite = await this.deployMiniErpWebiste(hosting.Code, formData.DomainName);
          return true;
        },
      },
      {
        message: 'Tạo tài khoản FTP',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteFtp = await this.deployMiniErpFtp(hosting.Code, hosting.ClientName, newWesite.domain_id, deployName);
          miniErpDeployment.FtpUser = newWebsiteFtp.username;
          miniErpDeployment.FtpPassword = newWebsiteFtp.password;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo cron job',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newCronJob = await this.deployMiniErpCronJob(hosting.Code, newWesite.domain_id, `https://${newWesite.domain}/robot.php`, '*', '*', '*', '*', '*');
          return true;
        },
      },
      {
        message: 'Tạo webiste database user',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteDbUser = await this.deployMiniErpDatabaseUser(hosting.Code, hosting.ClientId, deployName);
          miniErpDeployment.DbHost = 'localhost';
          miniErpDeployment.DbUser = newWebsiteDbUser.database_user;
          miniErpDeployment.DbPassword = newWebsiteDbUser.database_password;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo website database',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWesiteDb = await this.deployMiniErpDatabase(hosting.Code, newWesite.domain_id, hosting.ClientId, newWebsiteDbUser.database_user_id, deployName);
          miniErpDeployment.DbName = newWesiteDb.database_name;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      // Deploy ProBox One
      {
        message: 'Kiểm tra kết nối FTP',
        maxTry: 50,
        delayTry: 60000,
        execute: async () => {
          await this.checkFtpReady(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Tải lên website bộ cài ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          await this.uploadMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Kiểm tra website online',
        maxTry: 30,
        delayTry: 30000,
        execute: async () => {
          await this.checkDomainReady(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Giải nén bộ cài ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.extractMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Cấu hình ProBox One',
        maxTry: 10,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.configMiniErp(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Cấu hình người dùng cho ProBox One',
        maxTry: 10,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.configUserForMiniErp(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Dọn dep file cài đặt ProBox One',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.cleanMiniErpInstaller(miniErpDeployment.Code);
          return true;
        },
      },
      {
        message: 'Xóa FTP Account',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newWebsiteFtp = await this.destroyMiniErpFtp(hosting.Code, hosting.ClientName, newWesite.domain_id, deployName);
          miniErpDeployment.FtpUser = '';
          miniErpDeployment.FtpPassword = '';
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
    ];

    const deployHostingCompletedScript: Executable[] = [
      {
        skipSuccess: true,
        message: 'Đã triển khai xong Hosting cho ' + formData.CustomerName,
        title: formData.DomainName,
        status: 'success',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          this.cms.showToast('Đã triển khai thành hosting cho khách ' + formData.CustomerName, 'Đã triển khai thành hosting', {status: 'success'});
          return true;
        },
      },
    ];

    const deployPbxScript: Executable[] = [
      // Deploy PBX
      {
        message: 'Khởi tạo tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newPbxDomain = await this.deployPbxDomain(pbx.Code, formData.PbxDomainName, formData.Name, true);
          return true;
        },
      },
      {
        message: 'Tạo thông tin kết nối api cho tổng đài',
        maxTry: 5,
        delayTry: 15000,
        execute: async () => {
          newPbxUser = await this.deployPbxPbxUser(pbx.Code, newPbxDomain.DomainId, 'Administrator', 'administrator', formData.Name, formData.CustomerEmail, ['admin'], 'administrator');
          miniErpDeployment.PbxApiKey = newPbxUser.api_key;
          miniErpDeployment = await this.updateMiniErpDeployment(miniErpDeployment);
          return true;
        },
      },
      {
        message: 'Tạo danh sách số mở rộng cho tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          newPbxPbxExtensions = await this.deployPbxPbxExtensions(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, formData.PbxExtensions);
          return true;
        },
      },
      {
        message: 'Khai báo số đấu nối cho tổng đài',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          if (formData.PbxPstnNumber) {
            newPbxPbxPstnNumber = await this.deployPbxPbxPstnNumber(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, formData.PbxPstnNumber, newPbxPbxExtensions[0].extension);
          }
          return true;
        },
      },
      // Deploy minierp
      {
        message: 'Cài đặt quy tắt gọi ra',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          if (formData.PbxGateway) {
            newPbxOutboundRule = await this.deployPbxOutboundRule(pbx.Code, newPbxDomain.DomainId, newPbxDomain.DomainName, newPbxPbxPstnNumber.destination_accountcode, formData.PbxGateway);
          }
          return true;
        },
      },
      {
        message: 'Cấu hình PBX cho ProBox One',
        maxTry: 10,
        delayTry: 15000,
        execute: async () => {
          miniErpDeployment = await this.configPbxMiniErp(miniErpDeployment.Code);
          return true;
        },
      },
    ];
    const deployPbxCompletedScript: Executable[] = [
      {
        skipSuccess: true,
        message: 'Đã triển khai xong PBX cho ' + formData.CustomerName,
        title: formData.PbxDomainName,
        status: 'success',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          this.cms.showToast('Đã triển khai xong PBX cho khách ' + formData.CustomerName, 'Triển khai tổng đài PBX', {status: 'success'});
          return true;
        },
      },
    ];
    const finalCompletedScript: Executable[] = [
      {
        skipSuccess: true,
        message: 'Đã triển khai xong Core BM cho ' + formData.CustomerName,
        title: formData.DomainName,
        status: 'success',
        maxTry: 3,
        delayTry: 15000,
        execute: async () => {
          this.cms.openDialog(ShowcaseDialogComponent, {
            context: {
              title: 'Triển khai Core BM',
              content: 'Đã triển khai thành công Core BM cho khách ' + formData.CustomerName,
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: () => {
                  },
                },
                {
                  label: 'Truy cập',
                  icon: 'goto',
                  status: 'success',
                  action: () => {
                    window.open(`https://${formData.DomainName}`);
                    this.close();
                  },
                },
              ],
            },
          });
          return true;
        },
      },
    ];

    /** Execute deployment */
    let execute: Executable;
    // setTimeout(() => this.onProcessing(), 1001);
    const numOfStep = executeScript.length;
    let processedStep = 0;

    let compileScripts: Executable[] = [];
    try {
      await this.checkAllowDeploy(miniErpDeployment.Code);

      compileScripts = [
        ...deployHostingScript,
        ...deployHostingCompletedScript,
        ...finalCompletedScript,
      ];

      if (pbx?.Code && formData.PbxGateway && formData.PbxDomainName && formData.PbxPstnNumber && formData.PbxExtensions) {
        compileScripts = [
          ...deployHostingScript,
          ...deployHostingCompletedScript,
          ...deployPbxScript,
          ...deployPbxCompletedScript,
          ...finalCompletedScript,
        ];
      }

    } catch (e) {
      // Check PBX deployed
      if (await new Promise<boolean>(async (resolve, reject) => {
        const pbx = await this.apiService.getPromise<PbxModel[]>('/ivoip/pbxs', {id: this.cms.getObjectId(formData.Pbx)}).then(rs => rs[0]);
        if (pbx) {
          const domain = await this.apiService.getPromise<PbxModel[]>('/ivoip/domains', {
            DomainName: formData.PbxDomainName,
          }).then(rs => rs[0]);
          if (domain) {
            this.cms.showDialog('Triển khai tổng đài PBX', 'PBX Domain đã tồn tại, bạn có muốm triển khai lại không ?', [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: () => {
                  resolve(false);
                },
              },
              {
                label: 'Triển khai PBX',
                status: 'primary',
                action: () => {
                  resolve(true);
                },
              },
            ], () => resolve(false));
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      })) {
        compileScripts = [
          ...deployPbxScript,
          ...deployPbxCompletedScript,
        ];
      }

    }


    if (compileScripts.length === 0) {
      this.cms.showToast('Core BM đã được triển khai trước đó rồi, mọi cố gắng triển khai lại có thể ghi đè dữ liệu của Core BM !!!', 'Core BM đã đuợc triển khai !', {status: 'warning', duration: 0});
    }


    console.log(compileScripts);
    // this.onProcessed();
    // return;

    while (execute = compileScripts.shift()) {
      processedStep++;
      this.onProcessing();
      this.progressBarValue = processedStep / numOfStep * 100;
      this.processBarlabel = execute.message;
      tryCount = 0;
      while (true) {
        // this.onProcessing();
        tryCount++;
        try {
          if (this.longToastRef) this.longToastRef.close();
          this.longToastRef = this.toastService.show(execute.title ? execute.title : 'Đang thực thi...', execute.message + (tryCount > 1 ? (` lần ${tryCount}/${execute.maxTry}`) : ''), {
            status: execute.status ? execute.status : 'primary',
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            duration: execute.durarion ? execute.durarion : 0
          });
          // newPbxDomain = await this.deployPbxDomain(pbx.Code, formData.PbxDomainName, formData.Name, tryCount < 5);
          if (execute.execute) {
            await execute.execute().catch(e => {
              if (execute.onException) {
                return execute.onException(e);
              }
              return Promise.reject(e);
            });
            if (!execute.skipSuccess) {
              this.toastService.show(execute.title ? execute.title : 'Thành công', execute.message, {
                status: 'success',
                hasIcon: true,
                position: NbGlobalPhysicalPosition.TOP_RIGHT,
                duration: 7000
              });
            }
          }
          break;
        } catch (e) {
          console.error(e);
          this.onProcessed();
          if (tryCount >= execute.maxTry) {
            error(e);
            // this.onProcessed();
            this.toastService.show('STOP: ' + execute.message + (tryCount > 1 ? (` lần ${tryCount}/${execute.maxTry}`) : ''), 'Tiến trình đã dừng do lỗi bị lặp lại quá nhiều lần, Hãy kiểm tra lại thông tin và nhấn nút triển khai lại lần nữa', {
              status: 'warning',
              hasIcon: true,
              position: NbGlobalPhysicalPosition.TOP_RIGHT,
              duration: 0
            });
            return;
          } else {
            // Notification auto close
            this.toastService.show('Thông báo', e && e.error && e.error.logs ? e.error.logs.join('\n') : e, {
              status: 'warning',
              hasIcon: true,
              position: NbGlobalPhysicalPosition.TOP_RIGHT,
              duration: 7000
            });
          }
        }

        // Close previous notification and open new
        if (this.longToastRef) this.longToastRef.close();
        this.longToastRef = this.toastService.show('Thử lại trong ' + (execute.delayTry / 1000) + ' giây nữa...', 'Lỗi ' + execute.message, {
          status: 'danger',
          hasIcon: true,
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
          duration: 0
        });
        await new Promise(resolve => setTimeout(() => resolve(true), execute.delayTry));
      }

    }

    this.onProcessed();
    onAfterDeploy();
  }

}
