import { RouterModule, Routes } from "@angular/router";
import {AuthGuardService, ChangeLayoutAlertGuardService} from "../../services/auth-guard.service";
import { CommercePosDashboardComponent } from "./commerce-pos-dashboard/commerce-pos-dashboard.component";
import { CommercePosOrderListComponent } from "./commerce-pos-order/commerce-pos-order-list/commerce-pos-order-list.component";
import { CommercePosReturnListComponent } from "./commerce-pos-return/commerce-pos-return-list/commerce-pos-return-list.component";
import { CommercePosGuiComponent } from "./gui/commerce-pos-gui/commerce-pos-gui.component";
import { NgModule } from "@angular/core";

export const commercePosRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: CommercePosDashboardComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'gui',
    canActivate: [AuthGuardService, ChangeLayoutAlertGuardService],
    component: CommercePosGuiComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'order/list',
    canActivate: [AuthGuardService],
    component: CommercePosOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'return/list',
    canActivate: [AuthGuardService],
    component: CommercePosReturnListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(commercePosRoutes)],
    exports: [RouterModule],
})
export class CommercePosRoutingModule {
}
