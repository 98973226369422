import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class CodeTemplateStateMap {

    static SENT: ProcessMap = {
        state: 'SENT',
        label: 'Đã gửi',
        id: 'SENT',
        text: 'Đã gửi',
        confirmLabel: 'Gửi đơn hàng',
        status: 'info',
        color: 'ligthblue',
        outline: true,
        confirmTitle: 'Gửi đơn hàng',
        confirmText: 'Bạn có muốn gửi đơn hàng này tới bên bán ?',
        responseTitle: 'Đã gửi đơn hàng',
        responseText: 'Đã gửi đơn hàng thành công',
    };
    static RECEIVED: ProcessMap = {
        state: 'RECEIVED',
        label: 'Đã nhận',
        id: 'RECEIVED',
        text: 'Đã nhận',
        confirmLabel: 'Tải đơn hàng',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmTitle: 'Tải đơn hàng',
        confirmText: 'Bạn có muốn tải đơn hàng này tới bên mua ?',
        responseTitle: 'Đã tải đơn hàng',
        responseText: 'Đã tải đơn hàng thành công',
    };
    static PURCHASECOMPLETED: ProcessMap = {
        state: 'PURCHASECOMPLETED',
        label: 'Đã hoàn tất mua hàng',
        id: 'PURCHASECOMPLETED',
        text: 'Đã hoàn tất mua hàng',
        confirmLabel: 'Hoàn tất mua hàng',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất mua hàng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất',
    };
    static SALESCOMPLETED: ProcessMap = {
        state: 'SALESCOMPLETED',
        label: 'Đã hoàn tất bán hàng',
        id: 'SALESCOMPLETED',
        text: 'Đã hoàn tất bán hàng',
        confirmLabel: 'Hoàn tất bán hàng',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất bán hàng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất',
    };

    static simpleDataManagerStateMap: { [key: string]: ProcessMap } = {
        ACTIVE: {
            state: 'ACTIVE',
            label: 'Common.active',
            status: 'success',
            outline: true,
            nextState: 'INACTIVE',
            nextStateLabel: 'Common.changeToInactive',
            confirmText: 'Common.inactiveConfirm',
            responseTitle: 'Common.inactivated',
            responseText: 'Common.inactiveSuccess',
        },
        INACTIVE: {
            state: 'INACTIVE',
            label: 'Common.inactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        NOTJUSTACTIVE: {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        "": {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
    };


}
