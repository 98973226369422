import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService, ChangeLayoutAlertGuardService} from "../../services/auth-guard.service";
import {B2bProductMapListComponent} from "./product/product-map/product-map-list/product-map-list.component";
import {B2bPublicProductListComponent} from "./product/public/public-product-list/public-product-list.component";
import {B2bCenterPageListComponent} from "./page/b2b-center-page-list/b2b-center-page-list.component";
import {B2bOrderListComponent} from "./order/b2b-order-list/b2b-order-list.component";
import {B2bPurchaseOrderListComponent} from "./purchase/order/b2b-purchase-order-list/b2b-purchase-order-list.component";
import {B2bSalesOrderListComponent} from "./sales/order/b2b-sales-order-list/b2b-sales-order-list.component";
import {B2bBasicSalesCommissionConfigListComponent} from "./sales/commission/basic-config/list/basic-commission-config-list.component";
import {B2bAdvanceSalesCommissionConfigListComponent} from "./sales/commission/advance-config/list/advance-commission-config-list.component";
import {B2bSalesCustomerListComponent} from "./sales/partner/list/sales-partner-list.component";
import {B2bAddonSalesCommissionConfigListComponent} from "./sales/commission/addon-config/list/addon-commission-config-list.component";
import {B2bSupplierListComponent} from "./purchase/supplier/list/supplier-list.component";
import {NgModule} from "@angular/core";
import {B2bPurchasePopGuiComponent} from "./purchase/pop/purchase-pop-gui/b2b-purchase-pop-gui.component";
import {B2bPurchaseQueueComponent} from "./purchase/queue/b2b-purchase-queue.component";

export const b2bCenterRoutes: Routes = [
  {
    path: 'page/list',
    canActivate: [AuthGuardService],
    component: B2bCenterPageListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product-map/list',
    canActivate: [AuthGuardService],
    component: B2bProductMapListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/public/list',
    canActivate: [AuthGuardService],
    component: B2bPublicProductListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'order/list',
    canActivate: [AuthGuardService],
    component: B2bOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'purchase',
    children: [
      {
        path: 'order',
        children: [
          {
            path: 'list',
            canActivate: [AuthGuardService],
            component: B2bPurchaseOrderListComponent,
            data: {
              reuse: true,
            },
          }
        ]
      },
      {
        path: 'supplier',
        children: [
          {
            path: 'list',
            canActivate: [AuthGuardService],
            component: B2bSupplierListComponent,
            data: {
              reuse: true,
            },
          },
        ]
      },
      {
        path: 'pop',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'gui',
          },
          {
            path: 'gui',
            canActivate: [AuthGuardService, ChangeLayoutAlertGuardService],
            component: B2bPurchasePopGuiComponent,
            data: {
              reuse: true,
            },
          }
        ]
      },
      {
        path: 'queue',
        canActivate: [AuthGuardService],
        component: B2bPurchaseQueueComponent,
        data: {
          reuse: true,
        }
      },
    ]
  },
  {
    path: 'sales/order/list',
    canActivate: [AuthGuardService],
    component: B2bSalesOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'sales/commission/basic/list',
    canActivate: [AuthGuardService],
    component: B2bBasicSalesCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'sales/commission/advance/list',
    canActivate: [AuthGuardService],
    component: B2bAdvanceSalesCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'sales/commission/add-on/list',
    canActivate: [AuthGuardService],
    component: B2bAddonSalesCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'sales/partner/list',
    canActivate: [AuthGuardService],
    component: B2bSalesCustomerListComponent,
    data: {
      reuse: true,
    },
  },
];


@NgModule({
  imports: [RouterModule.forChild(b2bCenterRoutes)],
  exports: [RouterModule],
})
export class B2bCenterRoutingModule {
}
