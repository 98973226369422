<nb-card [nbSpinner]="isProcessing">
  <nb-card-header>
    <div class="title-wrap" style="display: flex;">
      <div style="flex: 1">Chụp hình</div>
      <div>
        <button nbButton hero size="medium" status="primary" (click)="onCaptureBtnClick()" [disabled]="this.error">
          <nb-icon pack="eva" icon="radio-button-off"></nb-icon>
          {{ 'Common.capture' | translate | headtitlecase }}
        </button>
        <button nbButton hero size="medium" status="success" (click)="onUploadBtnClick()" [disabled]="captures.length == 0">
          <nb-icon pack="eva" icon="cloud-upload"></nb-icon>
          {{ 'Common.upload' | translate | headtitlecase }}
        </button>
        <button nbButton hero size="medium" status="danger" (click)="close()">
          <nb-icon pack="eva" icon="close"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="video-container">
      <video #video id="video" style="width: 100%" autoplay></video>
      <canvas #canvas id="canvas" class="hide"></canvas>
      <div class="capture-preview-wrap" style="display: flex; flex-direction: row">
        <div class="capture-preview" *ngFor="let capture of captures; let i = index" [ngStyle]="{backgroundImage: 'url('+capture+')'}">
          <nb-icon pack="eva" icon="close-circle" status="danger" (click)="captures.splice(i,1)"></nb-icon>
          <nb-progress-bar *ngIf="progressList[i]" [value]="progressList[i].percent" [status]="progressList[i].status" size="small">{{ progressList[i].percent + '%' }} {{ progressList[i].label }}</nb-progress-bar>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>