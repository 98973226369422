import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../../../services/api.service';
import {RootServices} from '../../../../../../services/root.services';
import {Router} from '@angular/router';
import {CommonService} from '../../../../../../services/common.service';
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {AdminProductService} from '../../../../../admin-product/admin-product.service';
import {BaseComponent} from '../../../../../../lib/base-component';
import {ProductCategoryModel, ProductModel} from '../../../../../../models/product.model';
import {filter, take, takeUntil} from 'rxjs/operators';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {IModel, Model} from "../../../../../../models/model";

declare const $: any;

@Component({
  selector: 'ngx-b2b-purchase-pop-choose-product',
  templateUrl: './b2b-purchase-pop-choose-product.component.html',
  styleUrls: ['./b2b-purchase-pop-choose-product.component.scss'],
})
export class B2bPurchasePopChooseProductComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  componentName: string = 'B2bPurchasePopChooseProductComponent';
  @Input() reuseDialog = true;
  @Input() searchKey: string;
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() object: IModel;
  @Input() onChooseProduct: (chooseItems: any) => void;


  @ViewChild('searchListViewport', {static: true}) searchListViewport: CdkVirtualScrollViewport;
  @ViewChild('Search', {static: true}) searchEleRef: ElementRef;

  categoryList: ProductCategoryModel[] = [];
  productList: ProductModel[] = [];
  parentCategory: ProductCategoryModel = null;
  searchResultActiveIndex = 0;
  isHideProductList = false;

  cstate?: { title: string, categories: ProductCategoryModel[], products: ProductModel[], searchResults: ProductModel[] };
  stateStack: { title: string, categories: ProductCategoryModel[], products: ProductModel[], searchResults: ProductModel[] }[] = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<B2bPurchasePopChooseProductComponent>,
    public adminProductService: AdminProductService,
    public themeService: NbThemeService,
  ) {
    super(rsv, cms, router, apiService, ref);


  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  async init() {
    const status = await super.init();
    // keep alway focus
    $(this.searchEleRef.nativeElement).blur(() => {
      this.searchEleRef.nativeElement.focus();
    });

    // await this.adminProductService.downloadPriceTableComplete$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise();
    this.adminProductService.categoryTree$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise().then(categoryTree => {
      // this.categoryList = categoryTree;
      let initProductList: ProductModel[];
      const objectId = this.cms.getObjectId(this.object);
      if (objectId) {
        initProductList = this.adminProductService.productSearchIndexsGroupByIdUnitAndContainer.filter(f => (f.RelativeObjectIds || []).indexOf(objectId) > -1);
      } else {
        initProductList = this.adminProductService.productSearchIndexsGroupByIdUnitAndContainer;
      }
      this.cstate = {
        title: 'Chọn theo danh mục',
        categories: categoryTree,
        products: initProductList,
        searchResults: [],
      };
      // this.stateState.push(this.cstate);

      this.cstate.categories.map(cate => {
        // if (!cate.FeaturePicture) {
        const relativeCategories = [
          this.cms.getObjectId(cate),
          ...this.getAllChildenCategories(cate),
        ];
        const productList = this.cstate.products.filter(f => this.cms.getObjectId(f.BaseUnit) == this.cms.getObjectId(f.Unit) && f.FeaturePicture && (f.Categories || []).some(s => relativeCategories.some(rs => rs == this.cms.getObjectId(s)))).slice(0, 12);
        cate.FeatureProducts = productList;
        // }
        return cate;
      });
      this.cstate.categories = this.cstate.categories.filter(f => f.FeatureProducts && f.FeatureProducts.length > 0);
    });

    this.actionButtonList = this.actionButtonList.filter(f => f.name !== 'refresh');
    this.actionButtonList.unshift(
      {
        name: 'back',
        status: 'primary',
        // label: 'Refresh',
        icon: 'arrow-back-outline',
        title: 'Back',
        size: 'medium',
        disabled: () => this.stateStack.length == 0,
        hidden: () => !this.ref || Object.keys(this.ref).length === 0 ? true : false,
        click: () => {
          this.backToParentCategory();
          return false;
        },
      }
    );
    this.searchEleRef.nativeElement.value = this.searchKey;
    return status;
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  getAllChildenCategories(cate: ProductCategoryModel) {
    let childrenCategories = [];
    for (const i in cate.Children) {
      // childrenCategories.push(this.cms.getObjectId(cate.Children[i]));
      childrenCategories = [
        ...childrenCategories,
        this.cms.getObjectId(cate.Children[i]),
        ...this.getAllChildenCategories(cate.Children[i]),
      ];
    }
    return childrenCategories;
  }

  chooseCategory(cate: ProductCategoryModel) {
    this.isHideProductList = true;
    // const parentCategory = this.parentCategory;
    // const parentCategoryList = this.categoryList;
    // this.parentCategory = cate;
    // this.categoryList = cate.Children;
    // this.categoryList['__parentCategory'] = parentCategory;
    // this.categoryList['__parentCategoryList'] = parentCategoryList;
    // this.categoryList['__parentProductList'] = this.productList;

    // Load relative products
    const relativeCategories = [
      this.cms.getObjectId(cate),
      ...this.getAllChildenCategories(cate),
    ];
    // relativeCategories.push(this.cms.getObjectId(cate));
    // for (const i in cate.Children) {
    //   relativeCategories.push(this.cms.getObjectId(cate.Children[i]));
    // }

    const productList = this.cstate.products.filter(f => (f.Categories || []).some(s => relativeCategories.some(rs => rs == this.cms.getObjectId(s))));
    // console.log(this.productList);
    this.stateStack.push(this.cstate);
    this.cstate = {
      title: cate.Name,
      categories: cate.Children,
      products: productList,
      searchResults: [],
    };

    this.cstate.categories.map(cate => {
      if (!cate.FeaturePicture) {
        const relativeCategories = [
          this.cms.getObjectId(cate),
          ...this.getAllChildenCategories(cate),
        ];
        const productList = this.cstate.products.filter(f => this.cms.getObjectId(f.BaseUnit) == this.cms.getObjectId(f.Unit) && f.FeaturePicture && (f.Categories || []).some(s => relativeCategories.some(rs => rs == this.cms.getObjectId(s)))).slice(0, 12);
        cate.FeatureProducts = productList;
      }
      return cate;
    });
    this.cstate.categories = this.cstate.categories.filter(f => f.FeatureProducts && f.FeatureProducts.length > 0);

    this.searchFromCategory(this.searchEleRef.nativeElement.value);
    setTimeout(() => {
      this.isHideProductList = false;
    }, 100);
  }

  backToParentCategory() {
    // if (this.categoryList['__parentProductList']) {
    //   this.parentCategory = this.categoryList['__parentCategory'];
    //   this.productList = this.categoryList['__parentProductList'] || [];
    //   this.categoryList = this.categoryList['__parentCategoryList'];
    // } else {
    //   this.parentCategory = null;
    // }
    if (this.stateStack.length > 0) {
      this.isHideProductList = true;
      this.cstate = this.stateStack.pop();
      setTimeout(() => {
        this.isHideProductList = false;
      }, 100);
    }
  }

  async onSearchInputKeydown(event: any) {

    // const inputValue: string = event.target?.value;
    // if (event.key == 'Enter' && (!this.searchResults || this.searchResults.length == 0)) {
    //   try {
    //     await this.barcodeProcess(inputValue);
    //     event.target.value = '';
    //     event.target.blur();
    //   } catch (err) {
    //     this.cms.showToast(err, 'Cảnh báo', { ...this.toastDefaultConfig, status: 'warning' });
    //   }
    // }
    return true;
  }

  searchInputPlaceholder = '';
  lastSearchCount = 0;

  async onSearchInputKeyup(event: any) {
    this.cms.takeUntilCallback('commerce-pos-advance-search', 300, async () => {
      const inputValue: string = event.target?.value;
      if ((event.key.length == 1 && /[a-z0-9\ ]/i.test(event.key)) || (event.key.length > 1 && ['Backspace'].indexOf(event.key) > -1)) {
        this.searchFromCategory(inputValue);
      }
    });
    return true;
  }

  async searchFromCategory(inputValue: string) {

    if (/\w+/.test(inputValue)) {
      this.lastSearchCount++;
      const currentSearchCount = this.lastSearchCount;

      await this.cms.waitFor(1000, 60, async () => this.cstate.products && this.cstate.products.length > 0);
      let rs = this.cstate.products.filter(f => new RegExp('^' + inputValue.toUpperCase()).test(f.Sku)).slice(0, 256);
      let rsByKeyword = this.cstate.products.filter(f => this.cms.smartFilter(f.Keyword, inputValue.toLowerCase())).slice(0, 256);
      for (const item of rsByKeyword) {
        if (rs.findIndex(f => f.Code == item.Code && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(item.Unit) && this.cms.getObjectId(f.Container) == this.cms.getObjectId(item.Container)) < 0) {
          rs.push(item);
        }
      }

      if (currentSearchCount == this.lastSearchCount) {
        this.cstate.searchResults = rs;
        if (this.cstate.searchResults[0]) {
          this.cstate.searchResults[0].active = true;
          this.searchResultActiveIndex = 0;
          setTimeout(() => {
            this.searchListViewport.scrollToIndex(0, 'smooth');
          }, 0);
        }

        // Get inventory for top 10 results
        const top10 = this.cstate.searchResults.slice(0, 10);
        top10.forEach(m => m.Inventory = null);

        if (top10.length > 0) {
          this.apiService.getPromise<any[]>('/warehouse/goods-in-containers', {id: top10.map(m => m.id), includeAccessNumbers: false}).then(goodsInContainerList => {
            console.log(goodsInContainerList);
            for (const goodsInContainer of goodsInContainerList) {
              // const goods = this.productSearchIndex[`${goodsInContainer.Goods}-${goodsInContainer.Unit}-${goodsInContainer.Container}`];
              const goods = top10.find(f => f.Code === goodsInContainer.Goods && this.cms.getObjectId(f.Unit) === goodsInContainer.Unit && this.cms.getObjectId(f.Container) === goodsInContainer.Container);
              if (goods) {
                goods.Inventory = goodsInContainer.Inventory;
              }
            }
          });
        }

      } else {
        console.log('search results was lated');
      }

    } else {
      this.cstate.searchResults = this.cstate.products;
    }

    return true;
  }


}
