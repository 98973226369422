import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../../../../../lib/base-component";
import {IoWorkflowHttpRequestNodeModel} from "../../../../io.model";
import {IoWorkflowHttpRequestNodeFormComponent} from "../io-workflow-http-request-node-form/io-workflow-http-request-node-form.component";

@Component({
  selector: 'ngx-io-workflow-http-request-node',
  templateUrl: './io-workflow-http-request-node.component.html',
  styleUrls: ['./io-workflow-http-request-node.component.scss']
})
export class IoWorkflowHttpRequestNodeComponent extends BaseComponent implements OnInit {
  @Input() props: IoWorkflowHttpRequestNodeModel;
  @Output() onChange: EventEmitter<IoWorkflowHttpRequestNodeModel> = new EventEmitter();

  editNode() {
    this.cms.openDialog(IoWorkflowHttpRequestNodeFormComponent, {
      context: {
        data: [this.props],
        onDialogSave: data => {
          console.log(data);
          this.props = data[0];
          this.onChange.emit(data[0]);
        }
      }
    });
    return true;
  }
}
