import {Component, ElementRef, OnChanges, OnInit, ViewChild} from '@angular/core';
import {DataManagerFormComponent} from "../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../models/model";
import {RootServices} from "../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../services/common.service";
import {AdminProductService} from "../../../admin-product/admin-product.service";
import {HttpErrorResponse} from "@angular/common/http";
import Quill from 'quill';
import {Mention, MentionBlot} from 'quill-mention';
import {takeUntil} from "rxjs/operators";
import {HelpdeskTicketMessageModel, HelpdeskTicketModel} from "../../../../models/helpdesk.model";
import {environment} from "../../../../../environments/environment";
import {ContactModel} from "../../../../models/contact.model";
import {Select2Option} from "../../../../lib/custom-element/select2/select2.component";

Quill.register('modules/mention', Mention);
Quill.register(MentionBlot);

@Component({
  selector: 'ngx-helpdesk-ticket-form',
  templateUrl: './helpdesk-ticket-form.component.html',
  styleUrls: ['./helpdesk-ticket-form.component.scss']
})
export class HelpdeskTicketFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'HelpdeskTicketFormComponent';
  idKey = 'Code';
  apiPath = '/helpdesk/tickets';
  baseFormUrl = '/helpdesk/ticket/form';
  listUrl = 'helpdesk/ticket/list';

  @ViewChild('editorContainer', {static: false}) editorContainer!: ElementRef;
  quillEditor: Quill;

  env = environment;

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<HelpdeskTicketFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
    // this.env.api.baseUrl
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  /** Select2Option */
  select2OptionForObject = {
    ...this.select2OptionForContact,
    containerCss: `
        width: 100%
      `,
    withThumbnail: true,
  };

  select2OptionForTags: Select2Option = {
    placeholder: 'Chọn thẻ...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    multiple: true,
    tags: true,
  };
  /** End Select2Option */

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  editorInitialized = false;


  async init(): Promise<boolean> {
    return super.init().then(rs => {

      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async loadMessage(ticketFormGroup: FormGroup, option?: { offset: number, limit: any }) {
    let offset = 0;
    let limit = 20;
    if (option?.offset) {
      offset = option.offset;
    }
    if (option?.limit) {
      limit = option.limit;
    }
    if (ticketFormGroup && ticketFormGroup.get('Code').value) {
      const messages = await this.apiService.getPromise<HelpdeskTicketMessageModel[]>('/helpdesk/ticket/messages', {
        eq_Ticket: ticketFormGroup.get('Code').value,
        sort_DateOfCreated: 'asc',
        offset, limit,
      });
      ticketFormGroup.get('Messages').setValue(messages);
      return true;
    }
    throw new Error('Không dduer điều kiện tải tin nhắn Ticket');
  }

  async formLoad(formData: HelpdeskTicketModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {


      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
      this.newMessageTitle = itemFormData.Title;
      this.loadMessage(newForm, {offset: 0, limit: 'nolimit'});

    }).then(rs => {
      this.cms.waitFor(300, 1000, () => this.editorContainer?.nativeElement).then(() => {
        if (this.editorContainer) {
          // const mentionModule = {
          //   mention: {
          //     allowedChars: /^[A-Za-z\s]*$/,
          //     mentionDenotationChars: ['@', '#'],
          //     source: (searchTerm: string, renderList: any) => {
          //       const values = [
          //         {id: 1, value: 'John Doe'},
          //         {id: 2, value: 'Jane Smith'},
          //         {id: 3, value: 'Eric Phạm'},
          //       ];
          //       const matches = values.filter((v) =>
          //         v.value.toLowerCase().includes(searchTerm.toLowerCase())
          //       );
          //       renderList(matches);
          //     },
          //   },
          // };
          //
          // this.quillEditor = new Quill($('#editorContainer')[0], {
          //   theme: 'snow',
          //   placeholder: 'Viết bình luận và gõ @ để nhắc tên...',
          //   modules: {
          //     toolbar: [['bold', 'italic', 'underline']],
          //     ...mentionModule,
          //   },
          // });
          // this.editorInitialized = true;
          // this.quillEditor.on('text-change', () => {
          //   const html = this.quillEditor.root.innerHTML;
          //   const delta = this.quillEditor.getContents();
          //   const plain = this.quillEditor.getText();
          //
          //   console.log('HTML:', html);
          //   console.log('Delta:', delta);
          //   console.log('Plain text:', plain);
          // });
        }
      });
      return rs;
    });

  }

  onEditLoaded(ticketFormGroup, ele) {
    console.log(ele);

    const userList = [
      {id: 1, value: 'John Doe'},
      {id: 2, value: 'Jane Smith'},
      {id: 3, value: 'Eric Phạm'},
    ];

    const docList = [
      {id: 1, value: 'Document 1'},
      {id: 2, value: 'Document 2'},
      {id: 3, value: 'Document 3'},
    ];

    const mentionModule = {
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['@', '#'],
        source: (searchTerm: string, renderList: any, mentionChar: string) => {
          let values;
          if (mentionChar === '@') {
            values = userList;
          } else if (mentionChar === '#') {
            values = docList;
          }
          const matches = values.filter((v) =>
            v.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
          renderList(matches);
        },
      },
    };

    this.quillEditor = new Quill(ele, {
      theme: 'snow',
      placeholder: 'Viết bình luận và gõ @ để nhắc tên...',
      modules: {
        toolbar: [['bold', 'italic', 'underline']],
        ...mentionModule,
      },
    });
    this.editorInitialized = true;
    this.quillEditor.on('text-change', () => {
      const html = this.quillEditor.root.innerHTML;
      const delta = this.quillEditor.getContents();
      const plain = this.quillEditor.getText();

      console.log('HTML:', html);
      console.log('Delta:', delta);
      console.log('Plain text:', plain);
    });
  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      Code: [],
      Name: [null, Validators.required],
      Title: [],
      Description: [],
      Object: [],
      ObjectName: [],
      ObjectPhone: [],
      ObjectEmail: [],
      ObjectAddress: [],
      DateOfCreate: [],
      LastMessageSent: [],
      Rating: [],
      Priority: [],
      Source: [],
      Tags: [],
      State: [],
      MessageState: ['PENDING'],
      LastMessageTitle: [],
      LastMessageContent: [],
      Messages: [[]],
    });
    if (data) {
      newForm.patchValue(data);
      newForm.get('LastMessageTitle').setValue(data.Title);
    }
    return newForm;
  }

  onObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {
        if (selectedData.Code) {
          formGroup.get('ObjectName').setValue(selectedData.Name);
          if (selectedData['Phone'] && selectedData['Phone']['restricted']) formGroup.get('ObjectPhone')['placeholder'] = selectedData['Phone']['placeholder']; else formGroup.get('ObjectPhone').setValue(selectedData['Phone']);
          if (selectedData['Email'] && selectedData['Email']['restricted']) formGroup.get('ObjectEmail')['placeholder'] = selectedData['Email']['placeholder']; else formGroup.get('ObjectEmail').setValue(selectedData['Email']);
          if (selectedData['Address'] && selectedData['Address']['restricted']) formGroup.get('ObjectAddress')['placeholder'] = selectedData['Address']['placeholder']; else formGroup.get('ObjectAddress').setValue(selectedData['FullAddress']);
        }
      }
    }
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  newMessageTitle = '';
  messageSending = false;

  onSendMessageClick(ticketFormGroup: FormGroup) {
    this.messageSending = true;
    if (!this.quillEditor.getText().trim()) {
      this.cms.showError('Chưa có nội dung tin nhắn');
      return false;
    }
    this.apiService.postPromise<HelpdeskTicketMessageModel[]>('/helpdesk/ticket/messages', {}, [{
      Ticket: ticketFormGroup.get('Code').value,
      Title: ticketFormGroup.get('LastMessageTitle').value,
      Content: this.quillEditor.root.innerHTML,
      Attachments: [],
      State: ticketFormGroup.get('MessageState').value,
    }]).then(rs => {
      this.cms.showToast('Đã gửi tin nhắn', 'Gửi tin nhắn thành công', {status: 'success'});
      this.messageSending = false;
      const messages = ticketFormGroup.get('Messages');
      messages.value.push(rs[0]);
      messages.setValue(messages.value);
      // ticketFormGroup.get('LastMessageTitle').setValue('');
      this.quillEditor.setContents([]);
      ticketFormGroup.get('State').setValue(rs[0]['State']);
      ticketFormGroup.get('LastMessageSent').setValue(rs[0]['DateOfCreated']);
    });
    return false;
  }

  getRawFormData(): { array: Model[] } {
    const rawData = super.getRawFormData();
    for (const key in rawData.array) {
      delete rawData.array[key].Messages;
    }
    return rawData;
  }

  async save(): Promise<Model[]> {
    return super.save().then(rs => {
      this.adminProductService.updateGroupList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  }
}
