import {UserModel} from './user.model';
import {ContactModel} from './contact.model';
import {FileStoreModel} from './file.model';
import {UserPhoneExtensionModel} from './user-phone-extension.model';
import {IModel, Model} from './model';

export class LoginInfoModel extends Model {

  user?: UserModel;
  contact?: ContactModel;
  distribution?: { fileStores: { [key: string]: FileStoreModel }, cookie: string };
  system?: { version?: string };
  phoneExtensions?: UserPhoneExtensionModel[];
  configuration?: {
    locale?: string,
    timezone?: string,
    defaultCurrency?: string,
  };
  roles?: IModel[];

}
