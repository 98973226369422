import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../../services/api.service';
import { RootServices } from '../../../../../services/root.services';
import { CommonService } from '../../../../../services/common.service';
import { B2bSalesOrderPrintComponent } from '../b2b-sales-order-print/b2b-sales-order-print.component';
import { AgGridDataManagerListComponent } from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { agMakeSelectionColDef } from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgTextCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/text.component';
import { AgDateCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/date.component';
import { agMakeTagsColDef } from '../../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeCurrencyColDef } from '../../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeStateColDef } from '../../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeCommandColDef } from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { B2bOrderModel } from '../../../b2b-center.model';
import { B2bSalesOrderFormComponent } from '../b2b-sales-order-form/b2b-sales-order-form.component';
import { B2bCenterService } from '../../../b2b-center.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PageModel } from '../../../../../models/page.model';
import { B2bCenterStateMap } from '../../../b2b-center.state.map';
import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeIdColDef } from '../../../../../lib/custom-element/ag-list/column-define/id.define';
import { agMakeDatetimeColDef } from '../../../../../lib/custom-element/ag-list/column-define/datetime.define';

@Component({
  selector: 'ngx-b2b-sales-order-list',
  templateUrl: './b2b-sales-order-list.component.html',
  styleUrls: ['./b2b-sales-order-list.component.scss']
})
export class B2bSalesOrderListComponent extends AgGridDataManagerListComponent<B2bOrderModel, B2bSalesOrderFormComponent> implements OnInit {

  componentName: string = 'B2bSalesOrderListComponent';
  formPath = '/b2b-center/sales/order/form';
  apiPath = '/b2b-center/sales/orders';
  idKey = ['Code'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'B2bCenter', text: 'B2b Center' },
    Feature: { id: 'SalesOrder', text: 'Đơn bán hàng B2B' }
  };

  printDialog = B2bSalesOrderPrintComponent;
  formDialog = B2bSalesOrderFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<B2bSalesOrderListComponent>,
    public datePipe: DatePipe,
    public b2bCenterService: B2bCenterService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    // Add page choosed
    this.b2bCenterService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
      this.actionButtonList.unshift({
        type: 'select2',
        name: 'page',
        status: 'success',
        label: 'Select page',
        icon: 'plus',
        title: this.cms.textTransform(this.cms.translate.instant('Common.createNew'), 'head-title'),
        size: 'medium',
        select2: {
          data: pageList, option: {
            placeholder: 'Chọn shop...',
            allowClear: false,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            keyMap: {
              id: 'id',
              text: 'text',
            },
          }
        },
        asyncValue: this.b2bCenterService.currentpage$,
        change: (value: any, option: any) => {
          this.onChangePage(value);
        },
        disabled: () => {
          return false;
        },
        click: () => {
          // this.gotoForm();
          return false;
        },
      });
    });

    return super.init().then(async state => {

      this.actionButtonList.unshift({
        type: 'button',
        name: 'pullOrders',
        status: 'success',
        label: 'Tải đơn hàng',
        title: 'Tải đơn hàng trên sàn B2B Center về',
        size: 'medium',
        icon: 'cloud-download-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => {
          return !this.cms.getObjectId(this.b2bCenterService.currentpage$.value);
        },
        click: (event, option, context, btnEle) => {
          btnEle.disabled = true;
          this.b2bCenterService.pullSalesOrders().then(() => {
            this.refresh().then(() => {
              btnEle.disabled = false;
            }).catch(err => {
              btnEle.disabled = false;
              return Promise.reject(err);
            });
          }).catch(err => {
            btnEle.disabled = false;
            return Promise.reject(err);
          });
        }
      });

      const processingMap = B2bCenterStateMap.b2bCenterSalesOrderStateMap;
      await this.cms.waitForLanguageLoaded();
      await this.b2bCenterService.currentpage$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeIdColDef(this.cms),
          headerName: 'Mã đơn',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        // {
        //   headerName: 'ID B2B',
        //   field: 'B2bOrderCode',
        //   width: 140,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'left',
        // },
        // {
        //   headerName: 'ID Mua',
        //   field: 'Code',
        //   width: 140,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'left',
        // },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Bên mua',
          field: 'PurchaseObject',
          // pinned: 'left',
          width: 200,
          // cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  item['label'] = item['ShortName'] || item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày đặt hàng',
          field: 'DateOfOrder',
          width: 180,
          filter: 'agDateColumnFilter',
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày tạo',
          field: 'DateOfCreated',
          width: 180,
          filter: 'agDateColumnFilter',
          // filterParams: {
          //   inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          // },
          // cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Số tiền',
          field: 'Amount',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            // this.preview([data]);
            if (this.cms.getObjectId(data.State) == 'PROCESSING') {
              this.openForm([this.makeId(data)]);
            } else {
              this.preview([data]);
            }
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            {
              name: 'pullOrder',
              title: 'Tải thông tin đơn hàng trên B2B Center',
              status: 'success',
              icon: 'cloud-download-outline',
              outline: false,
              ghost: true,
              appendTo: 'head',
              action: async (params, btnConfig) => {
                return this.b2bCenterService.pullSalesOrders([params?.data?.Code]).then(() => {
                  this.refreshItems([this.makeId(params?.data)]).then(rs => {
                    this.cms.showToast('Đã tải đơn hàng từ B2B Center', 'Tải đơn hàng thành công');
                  });
                  return true;
                }).catch(err => {
                  return true;
                  // return Promise.reject(err);
                });
              }
            }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includePurchaseContact'] = true;
    params['includePurchaseObject'] = true;
    params['includeCreator'] = true;
    params['includeRelativeVouchers'] = true;
    // params['includeStateLogs'] = true;
    params['page'] = this.cms.getObjectId(this.b2bCenterService.currentpage$.value);
    // params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: B2bOrderModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(B2bSalesOrderFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: B2bOrderModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.b2bCenterService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce('b2b_center_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  async preview(data: B2bOrderModel[], source?: string) {
    this.cms.openDialog(this.printDialog, {
      context: {
        showLoading: true,
        title: 'Xem trước',
        // data: data,
        // id: data.map(m => m[this.idKey]),
        id: data.map(item => this.makeId(item)),
        sourceOfDialog: 'list',
        mode: 'print',
        idKey: this.idKey,
        // approvedConfirm: true,
        onChange: async (data: B2bOrderModel, printComponent: B2bSalesOrderPrintComponent) => {

          printComponent.close();
          if (this.cms.getObjectId(data.State) === 'PROCESSING') {
            this.openForm([this.makeId(data)]);
          } else {
            this.refresh();
          }

        },
        onSaveAndClose: () => {
          this.refresh();
        },
      },
    });
    return false;
  }
}
