import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class HelpdeskStateMap {

    static OPEN: ProcessMap = {
        state: 'OPEN',
        label: 'Mở',
        id: 'OPEN',
        text: 'Mở',
        confirmLabel: 'Mở ticket',
        status: 'danger',
        color: 'red',
        outline: true,
        confirmTitle: 'Mở ticket',
        confirmText: 'Bạn có muốn mở ticket',
        responseTitle: 'Mở ticket',
        responseText: 'Mở ticket thành công',
    };
    static ONHOLD: ProcessMap = {
        state: 'ONHOLD',
        label: 'Tạm hoãn',
        id: 'ONHOLD',
        text: 'Tạm hoãn',
        confirmLabel: 'Tạm hoãn',
        status: 'warning',
        color: 'orange',
        outline: true,
        confirmTitle: 'Tạm hoãn',
        confirmText: 'Bạn có muốn chuyển sang Tạm hoãn',
        responseTitle: 'Tạm hoãn',
        responseText: 'Đã chuyển sang Tạm hoãn',
    };
    static PENDING: ProcessMap = {
        state: 'PENDING',
        label: 'Chờ xử lý',
        id: 'PENDING',
        text: 'Chờ xử lý',
        confirmLabel: 'Chờ xử lý',
        status: 'info',
        color: 'lightblue',
        outline: true,
        confirmTitle: 'Chờ xử lý',
        confirmText: 'Bạn có muốn Chờ xử lý',
        responseTitle: 'Chờ xử lý',
        responseText: 'Đã chuyển sang Chờ xử lý',
    };
    static SOLVED: ProcessMap = {
        state: 'SOLVED',
        label: 'Đã giải quyết',
        id: 'SOLVED',
        text: 'Đã giải quyết',
        confirmLabel: 'Đã giải quyết',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmTitle: 'Đã giải quyết',
        confirmText: 'Bạn có muốn chuyển sang Đã giải quyết',
        responseTitle: 'Đã giải quyết',
        responseText: 'Đã chuyển sang Đã giải quyết',
    };
    static CLOSED: ProcessMap = {
        state: 'CLOSED',
        label: 'Đã đóng',
        id: 'CLOSED',
        text: 'Đã đóng',
        confirmLabel: 'Đóng ticket',
        status: 'basic',
        color: 'white',
        outline: true,
        confirmTitle: 'Đóng ticket',
        confirmText: 'Bạn có muốn đóng ticket',
        responseTitle: 'Đóng ticket',
        responseText: 'Đã đóng ticket thành công',
    };

    static ticketStateMap: { [key: string]: ProcessMap } = {
        OPEN: {
            ...HelpdeskStateMap.OPEN,
        },
        PENDING: {
            ...stateMap.PENDING,
        },
        ONHOLD: {
            ...HelpdeskStateMap.ONHOLD,
        },
        SOLVED: {
            ...HelpdeskStateMap.SOLVED,
        },
        CLOSED: {
            ...HelpdeskStateMap.CLOSED,
        },
        "": {
            ...HelpdeskStateMap.OPEN,
        },
    };


}
