import {AgGridModule} from "@ag-grid-community/angular";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GoogleMapsModule} from "@angular/google-maps";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NbAccordionModule, NbActionsModule, NbAutocompleteModule, NbBadgeModule, NbButtonGroupModule, NbButtonModule, NbCardModule, NbChatModule, NbCheckboxModule, NbDatepickerModule, NbDialogModule, NbIconModule, NbInputModule, NbListModule, NbMenuModule, NbProgressBarModule, NbRadioModule, NbRouteTabsetModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbStepperModule, NbTabsetModule, NbTagModule, NbToastrModule, NbTooltipModule, NbUserModule, NbWindowModule} from "@nebular/theme";
import {InputMaskModule} from "@ngneat/input-mask";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {NgxQRCodeModule} from "@techiediaries/ngx-qrcode";
import {MentionModule} from "angular-mentions";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {ChartModule} from "angular2-chartjs";
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {Ng2SmartTableModule} from "ng2-smart-table";
import {NgxBarcodeModule} from "ngx-barcode";
import {NgxEchartsModule} from "ngx-echarts";
import {SortablejsModule} from "ngx-sortablejs";
import {NgxUploaderModule} from "../vendor/ngx-uploader/src/public_api";
import {HttpLoaderFactory} from "./app.module";
import {CustomElementModule} from "./lib/custom-element/custom-element.module";
import {IMaskModule} from "angular-imask";
import {ComponentLibModule} from "./lib/component/component.module";
import {TreeModule} from "angular-tree-component";

export const commonModules = [
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbDialogModule.forRoot(),
  NbWindowModule.forRoot(),
  NbToastrModule.forRoot(),
  NbChatModule.forRoot({
    messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
  }),
  NbCardModule,
  NbRouteTabsetModule,
  NbIconModule,
  NbTagModule,
  NbTooltipModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbInputModule,
  NbCheckboxModule,
  NbStepperModule,
  NbButtonModule,
  NbButtonGroupModule,
  NbListModule,
  NbAccordionModule,
  NbUserModule,
  NbSelectModule,
  NbActionsModule,
  NbRadioModule,
  NbBadgeModule,
  NbAutocompleteModule,
  NbProgressBarModule,
  BrowserModule,
  BrowserAnimationsModule,
  // HttpClientModule,
  // AppRoutingModule,
  // ThemeModule.forRoot(),
  NgxEchartsModule,
  ChartModule,
  // LeafletModule,
  InputMaskModule,
  CKEditorModule,
  CurrencyMaskModule,
  IMaskModule,
  // TreeModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  // AngularFireModule.initializeApp(environment.firebase),
  CustomElementModule,
  ComponentLibModule,
  ScrollingModule,
  CommonModule,
  Ng2SmartTableModule,
  MentionModule,
  FormsModule,
  ReactiveFormsModule,
  AgGridModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  SortablejsModule.forRoot({
    animation: 200,
  }),
  // NgxMaskModule.forRoot(options),
  NgxUploaderModule,
  NgxBarcodeModule.forRoot(),
  NgxQRCodeModule,
  GoogleMapsModule,
  AutocompleteLibModule,
  TreeModule.forRoot(),
  // NgxCurrencyDirective,
  // CurrencyMaskModule,

  // WorkflowDesignerModule,
];

export const commonProviders = [
  // { provide: CurrencyPipe, useValue: {} },
];
