import {Select2Option} from '../../../../../lib/custom-element/select2/select2.component';
import {CurrencyPipe} from '@angular/common';
import {DeploymentVoucherModel} from '../../../../../models/deployment.model';
import {DeploymentVoucherFormComponent} from '../../../../deployment/deployment-voucher/deployment-voucher-form/deployment-voucher-form.component';
import {ShowcaseDialogComponent} from '../../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component';
import {ProductModel, ProductSearchIndexModel} from '../../../../../models/product.model';
import {ContactModel} from '../../../../../models/contact.model';
import {CommercePosCashVoucherModel, CommercePosOrderModel, CommercePosReturnDetailModel, CommercePosReturnModel} from '../../../../../models/commerce-pos.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {NbDialogRef, NbGlobalPhysicalPosition} from "@nebular/theme";
import {BaseComponent} from "../../../../../lib/base-component";
import {ApiService} from "../../../../../services/api.service";
import {CommonService} from "../../../../../services/common.service";
import screenfull from 'screenfull';
import {concatMap, filter, finalize, take, takeUntil} from "rxjs/operators";
import {Model, SystemConfigModel} from "../../../../../models/model";
import {CurrencyMaskConfig} from "ng2-currency-mask";
import {B2bPurchasePopBillPrintComponent} from '../purchase-pop-order-print/b2b-purchase-pop-bill-print.component';
import {ContactAllListComponent} from '../../../../contact/contact-all-list/contact-all-list.component';
import {DialogFormAction, DialogFormComponent, DialogFormControl} from '../../../../../lib/component/dialog/dialog-form/dialog-form.component';
import {BehaviorSubject, Subject} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {PurchasePopDeploymentVoucherPrintComponent} from '../purchase-pop-deployment-voucher-print/purchase-pop-deployment-voucher-print.component';
import {ImagesViewerComponent} from '../../../../../lib/custom-element/my-components/images-viewer/images-viewer.component';
import {AccBankAccountModel} from '../../../../../models/accounting.model';
import {MktMemberCardModel} from '../../../../../models/marketing.model';
import {AgDynamicListComponent} from '../../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component';
import {agMakeSelectionColDef} from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import {agMakeImageColDef} from '../../../../../lib/custom-element/ag-list/column-define/image.define';
import {AgTextCellRenderer} from '../../../../../lib/custom-element/ag-list/cell/text.component';
import {AgDateCellRenderer} from '../../../../../lib/custom-element/ag-list/cell/date.component';
import {RootServices} from '../../../../../services/root.services';
import {ColDef} from '@ag-grid-community/core';
import {AdminProductService} from '../../../../admin-product/admin-product.service';
import {B2bPurchasePopChooseProductComponent} from './choose-product/b2b-purchase-pop-choose-product.component';
import {AccountingService} from "../../../../accounting/accounting.service";
import {ProductFormComponent} from "../../../../admin-product/product/product-form/product-form.component";
import {ProductListComponent} from "../../../../admin-product/product/product-list/product-list.component";
import {B2bNewPurchaseProductFormComponent} from "../b2b-new-purchase-product-form/b2b-new-purchase-product-form.component";
import {MediaCaptureComponent} from "../../../../../lib/custom-element/my-components/media-capture/media-capture.component";
import {B2bOrderModel} from "../../../b2b-center.model";

declare const openDatabase;
declare var window;

class OrderModel {
  [key: string]: any;

  SalesObject?: string;
  SalesObjectName?: string;
  SalesObjectPhone?: string;
  SalesObjectEmail?: string;
  SalesObjectAddress?: string;
  SalesObjectIdentifiedNumber?: string;
  Note?: string;
  DateOfOrder?: Date;
  DateOfCreated?: Date;
  Details: {
    PurchaseProductSku: string,
    PurchaseProduct: string,
    PurchaseUnit: string,
    Description: string,
    Quantity: number,
    Price: number,
    ToMoney: number,
    Discount?: number,
    AccessNumbers?: string[],
    FeaturePicture?: string
  }[] = [];
  Total: number = 0;

  constructor() {
  }
}

@Component({
  selector: 'ngx-b2b-purchase-pop-gui',
  templateUrl: './b2b-purchase-pop-gui.component.html',
  styleUrls: ['./b2b-purchase-pop-gui.component.scss'],
  providers: [CurrencyPipe]
})
export class B2bPurchasePopGuiComponent extends BaseComponent implements AfterViewInit, OnDestroy, OnInit {

  feature = {
    Module: { id: 'B2bCenter', text: 'B2b Center' },
    Feature: { id: 'PurchasePopGui', text: 'Máy đặt hàng' }
  };

  /** Component name */
  componentName = 'B2bPurchasePopGuiComponent';
  title: string = 'Máy đặt hàng B2B';
  currentDate = new Date();
  apiPath = '/b2b-center/purchase/orders';

  status = '';

  loading = false;
  processing = false;
  progress = 0;
  progressStatus = 'success';
  progressLabel = '0%';

  paymentState = 'CLOSEORDER';

  cashList = [
    this.cms.currencyPipe.transform(500000, 'VND'),
    this.cms.currencyPipe.transform(200000, 'VND'),
    this.cms.currencyPipe.transform(100000, 'VND'),
    this.cms.currencyPipe.transform(50000, 'VND'),
    this.cms.currencyPipe.transform(20000, 'VND'),
    this.cms.currencyPipe.transform(10000, 'VND'),
  ];
  filteredOptions$ = new BehaviorSubject<any>(this.cashList);

  @ViewChild('SalesObjectPhone', {static: true}) objectPhoneEleRef: ElementRef;
  @ViewChild('SalesObjectName', {static: true}) objectNameEleRef: ElementRef;
  @ViewChild('SalesObjectAddress', {static: true}) objectAddressEleRef: ElementRef;
  @ViewChild('Search', {static: true}) searchEleRef: ElementRef;
  @ViewChild('orderDetailTable', {static: true}) orderDetailTableRef: ElementRef;
  @ViewChild('searchResultsRef', {static: true}) searchResultsRef: ElementRef;
  @ViewChild('customerEle', {static: true}) customerEle: ElementRef;
  @ViewChild('searchListViewport', {static: true}) searchListViewport: CdkVirtualScrollViewport;
  @ViewChild('DecreaseForTotal', {static: true}) decreaseForTotalEleRef: ElementRef;
  @ViewChild('CashReceipt', {static: true}) cashReceiptEleRef: ElementRef;
  @ViewChild('autoInputCashReceipt') autoInputCashReceiptEleRef: ElementRef;

  get isFullscreenMode() {
    return screenfull.isFullscreen;
  }

  quantityFormat: CurrencyMaskConfig = {...this.cms.getNumberMaskConfig(), precision: 2};
  toMoneyCurencyFormat: CurrencyMaskConfig = {...this.cms.getCurrencyMaskConfig(), precision: 0, allowNegative: true};

  order: OrderModel = new OrderModel;

  paymentMethod = [
    {id: 'CASH', text: 'Tiền mặt', status: 'success'},
    {id: 'DEBT', text: 'Công nợ', status: 'warning'},
    {id: 'BANKTRANSFER', text: 'Chuyển khoản', status: 'primary'},
    {id: 'MIXED', text: 'Hỗn hợp', status: 'danger'},
  ];

  historyOrderIndex: number = 0;
  historyOrders: FormGroup[] = [];
  orderForm: FormGroup = this.makeNewOrderForm();

  systemConfigs: SystemConfigModel;
  shortcutKeyContext: string = 'main';

  searchResults: ProductModel[] = null;
  searchResultActiveIndex = 0;

  masterPriceTable: { [key: string]: ProductModel } = {};
  webdb: any;

  // searchInput = '';

  bankAccountList: AccBankAccountModel[] = [];
  select2OptionForContact = {
    ...this.cms.makeSelect2AjaxOption('/contact/contacts', {
      includeIdText: true,
      includeGroups: true,
      sort_SearchRank: 'desc',
    }, {
      placeholder: 'F6 - Nhà cung cấp...',
      limit: 10,
      prepareResultItem: (item) => {
        if (!item['originText']) {
          item['originText'] = item['text'];
        }
        item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        item.thumbnail = item?.Avatar?.Thumbnail;
        return item;
      },
      containerCss: `
        width: 100%
      `
    }),
    withThumbnail: true,
  };
  select2OptionForBankAccount: Select2Option = {
    placeholder: 'Tài khoản ngân hàng...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };
  barCodeProcessQueue$ = new Subject<string>();
  barcodeProcessHistory$ = new Subject<any>();

  sortablejsOptions = {
    handle: '.sorting-handle',
    onUpdate: (event: any) => {
      console.log('sortablejs->onUpdate: ', event);
      const detailsControls = this.getDetails(this.orderForm).controls;
      const activeIndex = detailsControls.findIndex(f => f['isActive']);
      const activeItem = detailsControls[activeIndex] as FormGroup;
      this.activeDetail(this.orderForm, activeItem, activeIndex, {follow: false});
      const details = this.getDetails(this.orderForm).value;
      this.getDetails(this.orderForm).setValue(details);
    }
  };

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public prdService: AdminProductService,
    public currencyPipe: CurrencyPipe,
    public accountingService: AccountingService,
    public ref?: NbDialogRef<B2bPurchasePopGuiComponent>,
    public formBuilder?: FormBuilder,
  ) {
    super(rsv, cms, router, apiService, ref);
    window['posGui'] = this;
    this.cms.systemConfigs$.pipe(takeUntil(this.destroy$)).subscribe(settings => {
      this.systemConfigs = settings;
    });

    this.historyOrders.push(this.orderForm);
    this.barCodeProcessQueue$.pipe(
      takeUntil(this.destroy$),
      finalize(() => console.log('stopped processing queue')),
      concatMap(barcode => {
        return this.barcodeProcess(barcode);
      })
    ).subscribe(result => {
      console.log('Barcode Queue result: ', result);
    });

    // Download price table progress
    this.prdService.downloadPriceTableProgress$.pipe(takeUntil(this.destroy$)).subscribe((progress) => {
      if (progress) {
        this.loading = true;
        this.progress = parseInt(progress.loaded / progress.total * 100 as any);
        this.progressLabel = 'Đang tải thông tin sản phẩm...' + this.progress + '%';
        this.progressStatus = 'danger';
      } else {
        this.loading = false;
        this.progress = 0;
        this.progressLabel = 'Sẵn sàng';
        this.progressStatus = 'success';
        if (this.searchResults && this.searchResults.length > 0) {
          this.prdService.downloadPriceTableComplete$.pipe(takeUntil(this.destroy$), filter(f => f === true), take(1)).toPromise().then(() => {
            this.onSearchInputKeyup({key: ' ', target: {value: this.searchEleRef?.nativeElement?.value}}, {skipUpdateGoodsInfo: true});
          });
        }
      }
    });
  }

  pushBarcodeScanHistory(data: any) {
    this.barcodeProcessHistory$.next(data);
  }

  barcodeProcessQueue(barcode: string) {
    this.barCodeProcessQueue$.next(barcode);
  }

  toastDefaultConfig = {
    position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
  };

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
    screenfull.onchange(event => {
      console.log(event);
    });

    // Keep layout is fullscreen
    this.cms.layout$.next('fullscreen');
    this.cms.layout$.pipe(takeUntil(this.destroy$)).subscribe(layout => {
      if (layout != 'fullscreen') {
        this.cms.layout$.next('fullscreen');
      }
    });
    setTimeout(() => {
      this.cms.layout$.next('fullscreen');
    }, 15000);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    $('html').css({fontSize: 'initial'});
  }

  onResume() {
    super.onResume();
  }

  onSalesObjectChange(formGroup: FormGroup, selectedData: ContactModel) {
    if (selectedData && !selectedData['doNotAutoFill']) {
      if (selectedData.Code) {
        if (!formGroup['isProcessing']) {
          formGroup.get('SalesObjectName').setValue(selectedData.Name);
          formGroup.get('SalesObjectPhone').setValue(selectedData.Phone);
          formGroup.get('SalesObjectAddress').setValue(selectedData.Address);
          formGroup.get('Title').setValue(`Đăt hàng ${selectedData.Name} ngày ` + new Date().toLocaleDateString());
        }

        if (selectedData.Code == 'POSCUSTOMER') {
        } else {
          // Load current debt
          if (formGroup['voucherType'] == 'B2BPURCHASEORDER' && formGroup.get('State').value != this.paymentState) {
          }
        }

      }
    }
    if (!formGroup['isProcessing'] && !selectedData) {
      // Clear
      formGroup.get('SalesObjectName').setValue('');
      formGroup.get('SalesObjectPhone').setValue('');
      formGroup.get('SalesObjectAddress').setValue('');
    }

    // });

    (document.activeElement as HTMLElement).blur();
  }

  private goodsList: ProductSearchIndexModel[] = [];
  // private productSearchIndex: { [key: string]: ProductSearchIndexModel } = {};
  private updateGoodsInfoProcessing = false;

  async updateGoodsInfo() {
    return this.prdService.updateGoodsInfo();
  }

  async init() {
    this.loading = true;
    const result = await super.init().then(async status => {
      const loginId = await this.cms.loginInfo$.pipe(filter(f => !!f), take(1)).toPromise().then(loginInfo => loginInfo?.user?.Code);
      const systemConfig = await this.cms.systemConfigs$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise();
      this.bankAccountList = await this.apiService.getPromise<AccBankAccountModel[]>('/accounting/bank-accounts', {limit: 'nolimit', select: "id=>Code,text=>CONCAT(Owner;' ';AccountNumber;' ';Bank;' ';Branch)"});

      // Notification
      this.prdService.downloadPriceTableComplete$.pipe(takeUntil(this.destroy$), filter(f => f === true), take(1)).toPromise().then(() => {
        this.cms.showToast('Hệ thống đã sẵn sàng để bán hàng !', 'POP đã sẵn sàng', {...this.toastDefaultConfig, status: 'success'});
      });

      return status;
    });

    // Listen price table update

    this.loading = false;
    return result;
  }

  makeNewOrderForm(data?: CommercePosOrderModel) {
    const newForm = this.formBuilder.group<any>({
      // Type: ['POP'],
      Code: [],
      Title: [],
      BarCode: [],
      SalesObject: [],
      SalesObjectName: [],
      SalesObjectPhone: [],
      SalesObjectEmail: [],
      SalesObjectAddress: [],
      // SalesObjectIdenfiedNumber: [],
      Note: [],
      SubNote: [],
      Amount: [0],
      CashReceipt: [null],
      DecreaseForTotal: [null],
      CashBack: [null],
      State: ['NEW'],
      DateOfOrder: [],
      Created: [null],
      Details: this.formBuilder.array([]),
      Returns: [],
      Thread: [],
      RelativeVouchers: [data?.Returns ? [{id: data.Returns, text: data.Returns, type: 'COMMERCEPOSRETURN'}] : null],
      DebitFunds: [],
      // FinalReceipt: [],
      IsDebt: [false],
      PaymentMethod: [this.paymentMethod.find(f => this.cms.getObjectId(f) == 'CASH')],
      ReceiptBankAccount: [],
      CashTransferAmount: [],
      CashAmount: [],
      Attachments: [[]],
    });
    newForm['voucherType'] = 'B2BPURCHASEORDER';
    newForm['isReceipt'] = true;
    newForm['isProcessing'] = null;
    newForm['returnsObj'] = data?.returnsObj;
    if (data) {
      if (data.SalesObject) {
        data.SalesObject = {
          id: this.cms.getObjectId(data.SalesObject),
          text: this.cms.getObjectText(data.SalesObject) || data.SalesObjectName,
          Phone: data.SalesObjectPhone,
          Email: data.SalesObjectEmail,
          Address: data.SalesObjectAddress,
        };
      }
      if (typeof data.PaymentMethod === 'string') {
        data.PaymentMethod = this.paymentMethod.find(f => this.cms.getObjectId(f) === data.PaymentMethod);
      }
      if (data.DateOfOrder) {
        data.DateOfOrder = new Date(data.DateOfOrder).toISOString().slice(0, 10);
      }
      newForm.patchValue(data);
      if (data.Details) {
        const details = (this.getDetails(newForm) as FormArray).controls;
        for (const detail of data.Details) {
          details.push(this.makeNewOrderDetail(detail));
        }
        this.calculateTotal(newForm);
      }

    }
    if (data?.CashReceipt) {
      if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(data.CashReceipt, 'VND');
    } else {
      if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = 0;
    }

    // newForm['modified'] = false;
    // newForm.valueChanges.subscribe(value => {
    //   console.log('form value modified', value);
    //   newForm['modified'] = true;
    // });

    return newForm;
  }

  async makeNewReturnsForm(data?: CommercePosReturnModel, orderId?: string) {

    let order;
    let newForm;
    if (orderId) {
      order = await this.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath + '/' + orderId, {includeDetails: true, includeRelativeVouchers: true}).then(rs => rs[0]);
    }

    if (order) {
      newForm = this.formBuilder.group<any>({
        Code: [],
        Title: [],
        BarCode: [],
        Order: [order.Code || null],
        SalesObject: [order.SalesObject || null],
        SalesObjectName: [order.SalesObjectName || null],
        SalesObjectPhone: [order.objectPhone || null],
        SalesObjectEmail: [order.SalesObjectEmail || null],
        SalesObjectAddress: [order.SalesObjectAddress || null],
        Note: [],
        SubNote: [],
        Amount: [0],
        // CashBack: [0],
        CashReceipt: [0],
        State: [null],
        DateOfReturn: [new Date()],
        Created: [null],
        RelativeVouchers: [[{id: order.Code, text: order.Title || order.Code, type: 'B2BPURCHASEORDER'}]],
        Details: this.formBuilder.array([]),
        IsDebt: [false],
        PaymentMethod: [this.paymentMethod.find(f => this.cms.getObjectId(f) == 'CASH')],
        ReceiptBankAccount: [],
        CashTransferAmount: [],
        CashAmount: [],
        Thread: [],
      });
      if (order.Details) {
        const details = (this.getDetails(newForm) as FormArray).controls;
        for (const detail of order.Details) {
          details.push(this.makeNewOrderDetail(detail));
        }
        this.calculateTotal(newForm);
      }
    } else {
      newForm = this.formBuilder.group<any>({
        Code: [],
        BarCode: [],
        SalesObject: [],
        SalesObjectName: [],
        SalesObjectPhone: [],
        SalesObjectEmail: [],
        SalesObjectAddress: [],
        SalesObjectIdenfiedNumber: [],
        Note: [],
        SubNote: [],
        Amount: [0],
        CashBack: [0],
        CashReceipt: [0],
        State: [null],
        DateOfOrder: [new Date()],
        Created: [null],
        Details: this.formBuilder.array([]),
        IsDebt: [false],
        PaymentMethod: [this.paymentMethod.find(f => this.cms.getObjectId(f) == 'CASH')],
        ReceiptBankAccount: [],
        CashTransferAmount: [],
        CashAmount: [],
        RelativeVouchers: [[]],
        Thread: [],
      });
    }
    newForm['voucherType'] = 'COMMERCEPOSRETURN';

    if (data) {
      // if (data.SalesObject) {
      //   data.SalesObject = {
      //     id: this.cms.getObjectId(data.SalesObject),
      //     text: this.cms.getObjectText(data.SalesObject) || data.SalesObjectName,
      //     Phone: data.SalesObjectPhone,
      //     Email: data.SalesObjectEmail,
      //     Address: data.SalesObjectAddress,
      //   };
      // }
      if (typeof data.PaymentMethod === 'string') {
        data.PaymentMethod = this.paymentMethod.find(f => this.cms.getObjectId(f) === data.PaymentMethod);
      }
      newForm.patchValue(data);
      if (data.Details) {
        const details = (this.getDetails(newForm) as FormArray).controls;
        for (const detail of data.Details) {
          details.push(this.makeNewOrderDetail(detail));
        }
        this.calculateTotal(newForm);
      }
    }

    newForm['isProcessing'] = null;

    // newForm['modified'] = false;
    // newForm.valueChanges.subscribe(value => {
    //   console.log('form value modified', value);
    //   newForm['modified'] = true;
    // });

    return newForm;
  }

  makeNewOrderDetail(detail?: CommercePosReturnDetailModel) {
    const business = [];
    let productType = this.cms.getObjectId(detail.Type);
    if (!productType) {
      if (detail.PurchaseProduct) {
        const productInfo = this.prdService.productMap[this.cms.getObjectId(detail.PurchaseProduct)];
        if (productInfo) {
          productType = this.cms.getObjectId(productInfo.Type);

        }
      }
    }
    if (productType) {
      if (productType != 'SERVICE') {
        business.push(this.accountingService.accountingBusinessList$.value?.find(f => this.cms.getObjectId(f) == 'PURCHASEWAREHOUSE'));
      } else {
        business.push(this.accountingService.accountingBusinessList$.value?.find(f => this.cms.getObjectId(f) == 'PURSESERVICE4SALEDM'));
      }
    }
    return this.formBuilder.group<any>({
      SystemUuid: [detail.SystemUuid || null],
      PurchaseProductSku: [detail.Sku || null],
      Type: [detail.Type || null],
      PurchaseProduct: [detail.PurchaseProduct || null],
      PurchaseUnit: [detail.PurchaseUnit || 'n/a'],
      Description: [detail.Description || null],
      Quantity: [detail.Quantity || 0],
      Price: [detail.Price || 0],
      DiscountPercent: [detail.DiscountPercent || 0],
      DiscountPrice: [detail.DiscountPrice || 0],
      DiscountDescription: [detail.DiscountDescription],
      ListedPrice: [detail.ListedPrice || detail.Price || 0],
      ToMoney: [detail.Quantity * detail.Price || 0],
      FeaturePicture: [detail.PurchaseImage && detail.PurchaseImage[0] || null],
      PurchaseImage: [detail.PurchaseImage],
      AccessNumbers: [detail.AccessNumbers || null],
      Discount: [detail.Discount || 0],
      FindOrder: [detail.FindOrder || 0],
      Container: [detail.Container || 0],
      RelativeVouchers: [detail.RelativeVouchers || []],
      Inventory: [detail.Inventory],
      Publisher: [detail.Publisher],
      Variants: [detail.Variants],
      Business: [business],
    });
  }

  getDetails(formGroup: FormGroup) {
    return formGroup.get('Details') as FormArray;
  }

  returnVoucherMap: { [key: string]: FormGroup } = {};

  async makeNewOrder(data?: CommercePosOrderModel, returnsObj?: any, option?: { force?: boolean, location?: string, autoActiveForm?: boolean }) {

    option = {
      ...option,
      autoActiveForm: typeof option?.autoActiveForm == 'undefined' ? true : option.autoActiveForm
    };

    const endOrderForm = this.historyOrders[this.historyOrders.length - 1];
    if (!returnsObj && !option?.force && endOrderForm && this.orderForm['voucherType'] != 'COMMERCEPOSRETURN' && endOrderForm.getRawValue()['State'] == 'NEW' && endOrderForm.getRawValue()['Details'].length == 0) {
      this.historyOrderIndex = this.historyOrders.length - 1;
      this.orderForm = this.historyOrders[this.historyOrders.length - 1];
      if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
      return this.orderForm;
    }

    let orderForm = null;
    if (this.orderForm.get('State').value !== this.paymentState) {
      // this.save(this.orderForm);
    }

    if (returnsObj) {
      if (typeof returnsObj === 'string') {
        const returnsData = await this.apiService.getPromise<CommercePosOrderModel[]>('/purchase/return-vouchers/' + returnsObj, {includeDetails: true, renderBarCode: true, includeSalesObject: true, includeUnit: true}).then(rs => rs[0]);
        returnsObj = await this.makeNewReturnsForm(returnsData);
        this.returnVoucherMap[returnsData.Code] = returnsObj;
      }

      let debitFunds = 0;
      // if (!returnsObj.IsDebt) {
      const returnsDetails = returnsObj && (returnsObj.get('Details') as FormArray).controls || null;
      if (returnsDetails) {
        for (const detail of returnsDetails) {
          debitFunds += detail.get('Price').value * detail.get('Quantity').value;
        }
      }
      // }

      let returnObject = null;
      if (returnsObj.get('SalesObject').value) {
        returnObject = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.cms.getObjectId(returnsObj.get('SalesObject').value), {includeIdText: true}).then(rs => rs[0]);
      }

      orderForm = this.makeNewOrderForm({...data, SalesObject: returnObject.SalesObject, SalesObjectName: data?.SalesObjectName || returnObject.SalesObjectName, SalesObjectPhone: data?.SalesObjectPhone || returnObject.SalesObjectPhone, SalesObjectEmail: data?.SalesObjectEmail || returnObject.SalesObjectEmail, SalesObjectAddress: data?.SalesObjectAddress || returnObject.SalesObjectAddress, returnsObj, Code: null, Returns: returnsObj.get('Code').value, DebitFunds: debitFunds});
    } else {
      orderForm = this.makeNewOrderForm(data);
    }
    this.calculateTotal(orderForm);
    if (option?.location == 'HEAD') {
      this.historyOrders.unshift(orderForm);
      if (option.autoActiveForm) {
        this.historyOrderIndex = 0;
        this.orderForm = orderForm;
      } else {
        this.historyOrderIndex++;
      }
    } else {
      let position: number = null;
      if (data) {
        position = this.historyOrders.length > 0 ? this.historyOrders.length - 1 : 0;
        this.historyOrders.splice(position, 0, orderForm);
      } else {
        this.historyOrders.push(orderForm);
        position = this.historyOrders.length - 1;
      }
      if (option.autoActiveForm) {
        this.historyOrderIndex = position;
        this.orderForm = orderForm;
      }
      // this.historyOrders.push(orderForm);
      // if (option.autoActiveForm) {
      //   this.historyOrderIndex = this.historyOrders.length - 1;
      //   this.orderForm = orderForm;
      // }
    }
    if (!data || !data.Code) {
      // await this.save(orderForm);
    }
    if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
    return orderForm;
  }

  async makeNewReturns(data?: CommercePosReturnModel, order?: string, option?: { force?: boolean, location?: string, autoActiveForm?: boolean }) {

    option = {
      ...option,
      autoActiveForm: typeof option?.autoActiveForm == 'undefined' ? true : option.autoActiveForm
    };

    // const endOrderForm = this.historyOrders[this.historyOrders.length - 1];
    // if (!returns && !option?.force && endOrderForm && this.orderForm['voucherType'] != 'COMMERCEPOSRETURN' && endOrderForm.getRawValue()['State'] == 'NOTJUSTPENDINGRECEIPT' && endOrderForm.getRawValue()['Details'].length == 0) {
    //   this.historyOrderIndex = this.historyOrders.length - 1;
    //   this.orderForm = this.historyOrders[this.historyOrders.length - 1];
    //   return this.orderForm;
    // }

    let orderForm = null;
    if (this.orderForm.get('State').value !== this.paymentState) {
      // this.save(this.orderForm);
    }

    // if (order) {
    //   const returnsObj = await this.apiService.getPromise<CommercePosOrderModel[]>('/commerce-pos/orders/' + order, { includeDetails: true, includeRelativeVouchers: true }).then(rs => rs[0]);

    //   let debitFunds = 0;

    //   if (returnsObj && returnsObj?.Details) {
    //     for (const detail of returnsObj.Details) {
    //       debitFunds += detail.Price * detail.Quantity;
    //     }
    //   }

    //   if (returnsObj.SalesObject) {
    //     returnsObj.SalesObject = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.cms.getObjectId(returnsObj.SalesObject), { includeIdText: true }).then(rs => rs[0]);
    //   }

    //   orderForm = this.makeNewReturnsForm({ ...data, SalesObject: returnsObj.SalesObject, returnsObj, Code: null, Returns: order, DebitFunds: debitFunds });
    // } else {
    orderForm = this.returnVoucherMap[data.Code];
    if (!orderForm) {
      orderForm = await this.makeNewReturnsForm(data);
    }
    // }
    this.calculateTotal(orderForm);
    if (option?.location == 'HEAD') {
      this.historyOrders.unshift(orderForm);
      if (option.autoActiveForm) {
        this.historyOrderIndex = 0;
        this.orderForm = orderForm;
      } else {
        this.historyOrderIndex++;
      }
    } else {
      this.historyOrders.push(orderForm);
      if (option.autoActiveForm) {
        this.historyOrderIndex = this.historyOrders.length - 1;
        this.orderForm = orderForm;
      }
    }
    if (!data || !data.Code) {
      // await this.save(orderForm);
    }
    return orderForm;
  }

  toggleFullscreen() {
    if (!this.isFullscreenMode) {
      screenfull.request();
    } else {
      screenfull.exit();
    }
  }

  ngAfterViewInit() {

  }

  calculateToMoney(detail: FormGroup) {
    if (detail) {
      detail.get('ToMoney').setValue(parseFloat(detail.get('Quantity').value) * parseFloat(detail.get('Price').value));
    }
  }

  calculateTotal(orderForm: FormGroup) {
    let total = 0;
    for (const detail of this.getDetails(orderForm).controls) {
      total += parseFloat(detail.get('Price').value) * parseFloat(detail.get('Quantity').value);
    }

    orderForm.get('Amount').setValue(total);
    // const discount = this.orderForm.get('DecreaseForTotal');
    // this.orderForm.get('FinalReceipt').setValue(parseFloat(total || 0 as any) - parseFloat((discount.value || 0)));

    this.onCashReceiptChanged(orderForm);
    return total;
  }

  async onSearchInputKeydown(event: any) {
    // console.log(event);

    const inputValue: string = event.target?.value;
    if (event.key == 'Enter' && (!this.searchResults || this.searchResults.length == 0)) {
      try {
        await this.barcodeProcess(inputValue);
        event.target.value = '';
        event.target.blur();
      } catch (err) {
        this.cms.showToast(err, 'Cảnh báo', {...this.toastDefaultConfig, status: 'warning'});
      }
    }
    return true;
  }

  lastSearchCount = 0;

  searchMatchedSupplier(objectId: string, item) {
    if (this.isFilterByObject && objectId) {
      if (!Array.isArray(item.RelativeObjectIds)) {
        return false;
      }
      const position = item.RelativeObjectIds.indexOf(objectId);
      if (position < 0) {
        return false;
      }
      if (Array.isArray(item.RelativeObjects)) {
        item.MatchedRelativeObject = item.RelativeObjects[position];
        item.LastPurchasePrice = item.MatchedRelativeObject?.price;
      }
    }
    return true;
  }

  async onSearchInputKeyup(event: any, option?: { skipUpdateGoodsInfo: boolean }) {
    // console.log(event);
    if (!option || option.skipUpdateGoodsInfo === false)
      if (!this.prdService.productSearchIndexsGroupByIdUnitAndContainer || this.prdService.productSearchIndexsGroupByIdUnitAndContainer.length == 0) {
        this.cms.takeOnce('update-goods-info-on-lose-price', 3000).then(status => {
          // Request update goods info
          const reDownloadPrice = this.cms.toastService.show('Đang tải lại bảng giá, bạn vui lòng chờ trong giây lát', 'Đang tải lại bảng giá', {status: 'warning', duration: 0});
          this.prdService.updateGoodsInfo((loaded, total) => {
            console.log(loaded, total);
          }).then(status => {
            reDownloadPrice.close();
            this.cms.toastService.show('Bảng giá đã được tải, mời bạn tiếp tục bán hàng', 'Tải bảng giá thành công', {status: 'success'});
          }).catch(err => {
            reDownloadPrice.close();
            this.cms.toastService.show('Tải bảng giá thất bại, hãy kiểm tra lại kết nối mạng', 'Tải bảng giá thất bại', {status: 'danger'});
            this.cms.showError(err);
          });
        });
      }
    const objectId = this.cms.getObjectId(this.orderForm.get('SalesObject').value);
    this.cms.takeUntilCallback('commerce-pos-search', 300, async () => {
      const inputValue: string = event.target?.value;
      if (event.key) {
        if (!(event.key.length == 1 && /[a-z0-9\ ]/i.test(event.key)) && !(event.key.length > 1 && ['Backspace'].indexOf(event.key) > -1)) {
          return false;
        }
      }
      if (objectId || /\w+/.test(inputValue)) {
        this.lastSearchCount++;
        const currentSearchCount = this.lastSearchCount;

        // if(!this.goodsList || this.goodsList.length == 0) {
        await this.cms.waitFor(1000, 60, async () => this.prdService.productSearchIndexsGroupByIdUnitAndContainer && this.prdService.productSearchIndexsGroupByIdUnitAndContainer.length > 0);
        // }
        // if (this.goodsList && this.goodsList.length > 0) {
        // Search in local memory

        let rs = this.prdService.productSearchIndexsGroupByIdUnitAndContainer.filter(f => this.searchMatchedSupplier(objectId, f) && new RegExp('^' + inputValue.toUpperCase()).test(f.Sku)).slice(0, 256);
        // rs = rs.concat(rs, this.goodsList.filter(f => this.cms.smartFilter(f.Keyword, inputValue.toLowerCase())).slice(0, 256));
        let rsByKeyword = this.prdService.productSearchIndexsGroupByIdUnitAndContainer.filter(f => this.searchMatchedSupplier(objectId, f) && this.cms.smartFilter(f.Keyword, inputValue.toLowerCase())).slice(0, 256);
        for (const item of rsByKeyword) {
          if (rs.findIndex(f => f.Code == item.Code && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(item.Unit) && this.cms.getObjectId(f.Container) == this.cms.getObjectId(item.Container)) < 0) {
            rs.push(item);
          }
        }

        if (currentSearchCount == this.lastSearchCount) {
          this.searchResults = rs;
          if (this.searchResults[0]) {
            this.searchResults[0].active = true;
            this.searchResultActiveIndex = 0;
            setTimeout(() => {
              this.searchListViewport.scrollToIndex(0, 'smooth');
            }, 0);
          }

          // Get inventory for top 10 results
          const top10 = this.searchResults.slice(0, 10);
          top10.forEach(m => m.Inventory = null);

          if (top10.length > 0) {
            this.apiService.getPromise<any[]>('/warehouse/goods-in-containers', {id: top10.map(m => m.id), includeAccessNumbers: false}).then(goodsInContainerList => {
              console.log(goodsInContainerList);
              for (const goodsInContainer of goodsInContainerList) {
                // const goods = this.productSearchIndex[`${goodsInContainer.Goods}-${goodsInContainer.Unit}-${goodsInContainer.Container}`];
                const goods = top10.find(f => f.Code === goodsInContainer.Goods && this.cms.getObjectId(f.Unit) === goodsInContainer.Unit && this.cms.getObjectId(f.Container) === goodsInContainer.Container);
                if (goods) {
                  goods.Inventory = goodsInContainer.Inventory;
                }
              }
            });
          }

        } else {
          console.log('search results was lated');
        }
        // } else {
        //   // If goods list indexing then search by server
        //   this.apiService.getPromise<ProductModel[]>('/warehouse/goods', {
        //     includeCategories: true,
        //     includeFeaturePicture: true,
        //     includeUnit: true,
        //     includeContainer: true,
        //     sort_GoodsName: 'asc',
        //     sort_UnitNo: 'asc',
        //     search: inputValue,
        //     limit: 20
        //   }).then(rs => {
        //     if (currentSearchCount == this.lastSearchCount) {
        //       this.searchResults = rs.map(goods => {
        //         // goods.Price = this.masterPriceTable[`${goods.Goods}-${this.cms.getObjectId(goods.Unit)}`]?.Price;
        //         if (!goods.Price) {
        //           goods.Price = this.productUnitMap[`${goods.Goods}-${this.cms.getObjectId(goods.Unit)}`]?.Price;
        //         }
        //         return goods;
        //       });
        //       if (rs[0]) {
        //         rs[0].active = true;
        //         this.searchResultActiveIndex = 0;
        //         setTimeout(() => {
        //           this.searchListViewport.scrollToIndex(0, 'smooth');
        //         }, 0);
        //       }
        //     } else {
        //       console.log('search results was lated');
        //     }
        //   });
        // }

      } else {
        this.searchResults = null;
      }

    });
    return true;
  }

  onObjectPhoneInput(orderForm: FormGroup, event: any) {
    this.cms.takeUntil('commerce-pos-seach-contact-by-phone', 600).then(() => {
      const phone = this.orderForm.get('SalesObjectPhone').value;
      if (phone && phone.length > 9) {
        this.apiService.getPromise<ContactModel[]>('/contact/contacts', {search: phone, sort_Id: 'desc'}).then(rs => {
          if (rs.length > 0) {
            const contact = rs[0];
            if (contact) {
              if (contact.Code) {
                this.orderForm.get('SalesObject').setValue({id: contact.Code, text: `${contact.Code} - ${contact.Name}`});
              }
              if (contact.Name) {
                this.orderForm.get('SalesObjectName').setValue(contact.Name);
              }
            }
          }
        });
      }
    });
  }

  onObjectNameInput(orderForm: FormGroup, event: any) {
    this.cms.takeUntil('commerce-pos-save-contact-name', 3000).then(() => {
    });
  }

  inputValue: string = '';
  isBarcodeProcessing = new BehaviorSubject<number>(0);
  barcodeQueue: { inputValue: string, option?: { searchByFindOrder?: boolean, searchBySku?: boolean, product?: ProductModel } }[] = [];
  barcodeProcessCount = -1;

  async barcodeProcess(inputValue: string, option?: { searchByFindOrder?: boolean, searchBySku?: boolean, product?: ProductModel, onHadPrimise?: (promise: Promise<any>) => void }) {
    // await new Promise(resolve => setTimeout(() => resolve(true), 1000));
    // this.barcodeProcessCount++;
    // const queueId = this.barcodeProcessCount;

    // Wait for previous barcode process finish
    // await this.isBarcodeProcessing.pipe(filter(f => {
    //   console.log(`Barcode processing queue check: ${f} === ${queueId}`);
    //   return f === queueId;
    // }), take(1)).toPromise();

    try {
      this.inputValue = inputValue = inputValue && inputValue.trim() || inputValue;
      const detailsControls = this.getDetails(this.orderForm).controls;
      const systemConfigs = await this.cms.systemConfigs$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise().then(settings => settings);
      const coreId = systemConfigs.ROOT_CONFIGS.coreEmbedId;
      let productId = null;
      let accessNumber = null;
      let sku = null;
      let unit = null;
      let unitSeq = null;
      let unitId = null;
      let existsProduct: FormGroup = null;
      let product: ProductModel = option?.product || null;
      let memberCard: MktMemberCardModel = null;

      if (!product) {
        if (option?.searchBySku || /^[a-z]+\d+/i.test(inputValue)) {
          // Search by sku
          product = this.prdService.skuBaseUnitMap[inputValue.toUpperCase()];
          // if (this.goodsList) {
          //   const products = this.goodsList.filter(f => f.Sku == inputValue.toUpperCase());
          //   for (const prod of products) {
          //     const productInfo = this.productUnitMap[prod.Code + '-' + this.cms.getObjectId(prod.Unit)];

          //     // Tìm vị trí tương ứng với đơn vị tính cơ bản
          //     if (productInfo && this.cms.getObjectId(productInfo.WarehouseUnit) == this.cms.getObjectId(prod.Unit)) {
          //       product = prod;
          //       break;
          //     }
          //   }
          // }
          if (!product) {
            product = await this.apiService.getPromise<ProductModel[]>('/purchase/products', {includeUnit: true, includePrice: true, eq_Sku: inputValue, includeInventory: true}).then(rs => {
              return rs[0];
            });
          }

          if (!product) {
            this.cms.showToast(`Sku không tồn tại !`, 'Sku không tồn tại !', {...this.toastDefaultConfig, status: 'danger'});
            // resolve(true);
            // return;
            throw new Error(`Sku không tồn tại !`);
          }
          productId = product.Code;
          unit = product.Unit;
          unitId = this.cms.getObjectId(product.Unit);
        } else {
          if (option?.searchByFindOrder || inputValue.length < 5) {
            // Tìm hàng hóa theo số nhận thức
            product = this.prdService.findOrderMap[inputValue];
            // if (goodsInContainer && goodsInContainer.Goods && goodsInContainer.Unit) {
            //   productId = goodsInContainer.Goods;
            //   product = this.productMap[productId];
            //   unitId = goodsInContainer.Unit;
            //   product.Unit = unit = { id: goodsInContainer.Unit, text: goodsInContainer.UnitLabel };
            //   product.Container = goodsInContainer.Container;
            // }
            if (!product) {
              product = await this.apiService.getPromise<ProductModel[]>('/purchase/products', {
                includeUnit: true,
                includePrice: false,
                includeInventory: true,
                findOrder: inputValue,
              }).then(rs => {
                return rs[0];
              });
            }
            if (product) {
              unit = product.Unit;
              unitId = unitId || this.cms.getObjectId(unit);
              // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
              if (!product.Price) {
                const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
                if (productUnitMap) {
                  product.Price = productUnitMap.Price;
                  product.DiscountPercent = productUnitMap.DiscountPercent;
                  product.DiscountDescription = productUnitMap.DiscountDescription;
                  product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
                }
              }
              // product.FindOrder = inputValue.trim();
              product.FindOrder = product.ContainerFindOrder || inputValue.trim();
              productId = product.Code;
            }
          } else {
            if (/^9\d+/.test(inputValue)) {// <8 để phân biệt với barcode chuẩn quốc tế
              // Đây là barcode vị trí hàng hóa
              let tmpcode = inputValue.substring(1);
              const findOrderLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              const findOrder = tmpcode.substring(0, findOrderLength);
              tmpcode = tmpcode.substring(findOrderLength);
              const unitSeqLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              unitSeq = tmpcode.substring(0, unitSeqLength);
              tmpcode = tmpcode.substring(unitSeqLength);
              productId = tmpcode;

              product = this.prdService.productUnitMap[this.prdService.findOrderMap[findOrder].Code + '-' + this.cms.getObjectId(this.prdService.findOrderMap[findOrder].Unit)];

              if (product && unitSeq) {
                unit = this.prdService.unitMap[unitSeq];
                if (unit) {
                  unitId = unit.Code;
                  // product.Unit = { ...unit, id: unit.Code, text: unit.Name };
                  product.Unit = unit;
                }
              }

              if (product) {
                // throw new Error('Không tìm thấy hàng hóa !');
                unitId = this.cms.getObjectId(product.Unit);
                // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
                if (!product.Price) {
                  // product.Price = this.productUnitMap[`${product.Code}-${unitId}`]?.Price;
                  const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
                  if (productUnitMap) {
                    product.Price = productUnitMap.Price;
                    product.DiscountPercent = productUnitMap.DiscountPercent;
                    product.DiscountDescription = productUnitMap.DiscountDescription;
                    product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
                  }
                }
                productId = product.Code;
                product.FindOrder = findOrder;
              }

            } else {
              if (new RegExp('^127' + coreId + '\\d+').test(inputValue)) {
                accessNumber = inputValue;
              } else {
                if (new RegExp('^182' + coreId).test(inputValue)) {
                  setTimeout(() => {

                    this.cms.openDialog(ShowcaseDialogComponent, {
                      context: {
                        title: 'Máy bán hàng',
                        content: 'Bạn có muốn mở lại phiếu đạ mua hàng ' + inputValue,
                        actions: [
                          {
                            label: 'ESC - Trở về',
                            status: 'basic',
                            action: () => {
                            }
                          },
                          {
                            label: 'Mở lại phiếu đặt mua hàng (Enter)',
                            keyShortcut: 'Enter',
                            status: 'info',
                            focus: true,
                            action: async () => {
                              this.loadVoucher(inputValue);
                            }
                          },
                        ],
                        onClose: () => {
                        },
                      }
                    });
                  }, 50);
                  throw new Error('Trường hợp tạo phiếu trả hàng');
                } else if (new RegExp('^129' + coreId).test(inputValue)) {
                  setTimeout(() => {
                    this.shortcutKeyContext = 'returnspaymentconfirm';
                    this.cms.openDialog(ShowcaseDialogComponent, {
                      context: {
                        title: 'Máy bán hàng',
                        content: 'Bạn có muốn tiếp tục bán hàng từ phiếu trả hàng ' + inputValue + ' hay hoàn tiền cho khách',
                        actions: [
                          {
                            label: 'Tiếp tục bán (F4)',
                            keyShortcut: 'F4',
                            status: 'success',
                            action: async () => {
                              this.makeNewOrder(null, inputValue);
                            }
                          },
                          {
                            label: 'Mở lại bill (Enter)',
                            keyShortcut: 'Enter',
                            status: 'info',
                            focus: true,
                            action: async () => {
                              this.loadVoucher(inputValue);
                            }
                          },
                        ],
                        onClose: () => {
                        },
                      }
                    });
                  }, 50);
                  throw new Error('Trường hợp tạo phiếu bán hàng từ phiếu trả hàng');
                } else if (new RegExp('^113' + coreId).test(inputValue)) {
                  setTimeout(() => {
                    this.cms.openDialog(ShowcaseDialogComponent, {
                      context: {
                        title: 'Máy bán hàng',
                        content: 'Xem lại phiếu triển khai và đơn hàng liên quan',
                        actions: [
                          {
                            label: 'Mở lại bill liên quan (F10)',
                            keyShortcut: 'F10',
                            status: 'primary',
                            action: async () => {
                              const deploymentVoucher = await this.apiService.getPromise<DeploymentVoucherModel[]>('/deployment/vouchers/' + inputValue, {includeRelativeVouchers: true}).then(rs => rs[0]);
                              this.loadVoucher(deploymentVoucher?.RelativeVouchers?.find(f => f.type == 'B2BPURCHASEORDER')?.id);
                            }
                          },
                          {
                            label: 'Xem lại (Enter)',
                            keyShortcut: 'Enter',
                            status: 'success',
                            focus: true,
                            action: async () => {
                              this.cms.previewVoucher('DEPLOYMENT80', inputValue);
                            }
                          },
                        ],
                        onClose: () => {
                        },
                      }
                    });
                  }, 50);
                  throw new Error('Trường hợp xem lại phiếu triển khai');
                } else if (new RegExp('^118' + coreId).test(inputValue)) {
                  product = await this.apiService.getPromise<ProductModel[]>('/purchase/products/' + inputValue, {
                    includeUnit: true,
                    includePrice: false,
                    includeInventory: true,
                  }).then(rs => {
                    return rs[0];
                  });
                  if (product) {
                    productId = product.Code;
                    unitId = this.cms.getObjectId(product.WarehouseUnit);
                    // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
                    if (!product.Price) {
                      // product.Price = this.productUnitMap[`${product.Code}-${unitId}`]?.Price;
                      const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
                      if (productUnitMap) {
                        product.Price = productUnitMap.Price;
                        product.DiscountPercent = productUnitMap.DiscountPercent;
                        product.DiscountDescription = productUnitMap.DiscountDescription;
                        product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
                      }
                    }
                  }
                } else if (new RegExp('^141' + coreId).test(inputValue)) {
                  if (this.cms.getObjectId(this.orderForm.get('State').value) == this.paymentState) {
                    throw new Error('Không thẻ qué thẻ thành viên do đơn đã thanh toán !');
                  }
                  memberCard = await this.apiService.getPromise<ProductModel[]>('/marketing/member-cards/' + inputValue, {
                    includeContact: true,
                  }).then(rs => {
                    return rs[0];
                  }).catch(err => {
                    this.cms.showToast(`ID Thẻ ${inputValue} không tồn tại !`, 'ID Thẻ không tồn tại !', {...this.toastDefaultConfig, status: 'success'});
                    return Promise.reject(err);
                  });
                  if (memberCard) {
                    if (this.cms.getObjectId(memberCard.Contact)) {
                      this.orderForm.get('SalesObject').setValue(memberCard.Contact);
                      this.orderForm.get('SalesObjectName').setValue(this.cms.getObjectText(memberCard.Contact));
                      if (memberCard.DefaultThread) {
                        this.orderForm.get('Thread').setValue(memberCard.DefaultThread);
                      }
                      if (memberCard.DefaultPaymentMethod) {
                        const paymentMethod = this.paymentMethod.find(f => f.id == this.cms.getObjectId(memberCard.DefaultPaymentMethod));
                        this.orderForm.get('PaymentMethod').setValue(paymentMethod);
                        this.cms.showToast('Đã chuyển sang phương thức thanh toán mặc định của thẻ', 'Đã chuyển sang phương thức thanh toán: ' + this.cms.getObjectText(paymentMethod), {...this.toastDefaultConfig, status: 'warning'});
                      }
                      this.cms.showToast(`Khách hàng ${this.cms.getObjectText(memberCard.Contact)} đã được điền vào bill !`, 'Quét thẻ thành viên !', {...this.toastDefaultConfig, status: 'success'});
                    }
                  }
                  throw new Error('Trường hợp quét thẻ thành viên');
                }
                // else {

                // }

                let unitIdLength = null;

                if (!product) {

                  const extracted = this.cms.extractGoodsBarcode(inputValue);
                  accessNumber = extracted.accessNumber;
                  productId = extracted.productId;
                  unitSeq = extracted.unitSeq;
                  unit = this.prdService.unitMap[unitSeq];
                  unitId = this.cms.getObjectId(unit);

                  product = this.prdService.productUnitMap[productId + '-' + unitId];

                  if (!product) {
                    // Barcode quốc tế
                    product = this.prdService.barcodeMap[inputValue.toUpperCase()];
                    productId = product.Code;
                    unitId = this.cms.getObjectId(product.BaseUnit);
                  }

                  if (product && unitSeq) {
                    unit = this.prdService.unitMap[unitSeq];
                    if (unit) {
                      unitId = this.cms.getObjectId(unit);
                      // product.Unit = { ...unit, id: unit.Code, text: unit.Name };
                      product.Unit = unit;
                    }
                  }
                }

                // }
              }
            }

            // get access number inventory
            if (new RegExp('^127' + coreId).test(accessNumber)) {
              const getProductByAccessNumberPromise = this.apiService.getPromise<ProductModel[]>('/purchase/products', {
                accessNumber: accessNumber,
                includeUnit: true,
                includePrice: false,
                includeInventory: true,
                includePreviousOrder: this.orderForm['voucherType'] == 'COMMERCEPOSRETURN',
              }).then(rs => {

                product = rs[0];

                if (!product) {
                  this.cms.showToast(`Số truy xuất ${accessNumber} không tồn tại !`, 'Số truy xuất không tồn tại !', {...this.toastDefaultConfig, status: 'warning'});
                  existsProduct.get('AccessNumbers').setValue((existsProduct.get('AccessNumbers').value || []).filter(f => f != accessNumber));
                  throw new Error(`Số truy xuất ${accessNumber} không tồn tại !`);
                }

                const addReturnGoodsPromise = new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    try {
                      const existsProductIndex = detailsControls.findIndex(f => this.cms.getObjectId(f.get('PurchaseProduct').value) === productId && this.cms.getObjectId(f.get('PurchaseUnit').value) == unitId);
                      existsProduct = detailsControls[existsProductIndex] as FormGroup;
                      if (existsProduct) {

                        existsProduct.get('Container').setValue(product.Container);

                        if (this.orderForm['voucherType'] == 'COMMERCEPOSRETURN') {
                          if (product.Inventory && product.Inventory > 0) {
                            this.cms.showToast(`${product.Name} (${product.Unit.Name}) đang có trong kho! không thể trả hàng với hàng hóa chưa xuất kho !`, 'Hàng hóa chưa xuất bán !', {status: 'warning'});
                            existsProduct.get('AccessNumbers').setValue((existsProduct.get('AccessNumbers').value || []).filter(f => f != accessNumber));


                            // return;
                          } else {
                            // Update price by previous voucher sales price
                            if (product['LastAccEntry'] && product['LastWarehouseEntry']) {
                              existsProduct.get('Price').setValue(product['LastAccEntry']['SalesPrice']);

                              this.calculateToMoney(existsProduct);
                              this.calculateTotal(this.orderForm);


                              // Auto set object
                              if (product['LastAccEntry']['Object']) {
                                const object = this.orderForm.get('Object');
                                if (!object.value) {
                                  await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + product['LastAccEntry']['Object'], {includeIdText: true, limit: 1}).then(rs => {
                                    object.setValue(rs[0]);
                                  });
                                } else {
                                  if (this.cms.getObjectId(product['LastAccEntry']['Object']) != this.cms.getObjectId(object.value)) {

                                    this.cms.showToast('Liên hệ trên đơn bán hàng phải giống với trên đơn trả hàng !', 'Không đúng liên hệ đã mua hàng trước đó', {...this.toastDefaultConfig, status: 'warning'});
                                    return false;

                                  }
                                }
                              }

                              // Set relative vouchers
                              const detailsRelativeVouchers = existsProduct.get('RelativeVouchers');
                              const detailsRelativeVouchersData = detailsRelativeVouchers.value || [];

                              if (!detailsRelativeVouchersData.some(f => this.cms.getObjectId(f) == product['LastAccEntry']['Voucher'])) {
                                detailsRelativeVouchersData.push({
                                  type: 'B2BPURCHASEORDER',
                                  id: product['LastAccEntry']['Voucher'],
                                  text: product['LastAccEntry']['Voucher'],
                                  VoucherDate: product['LastWarehouseEntry']['VoucherDate'],
                                  'Object': {
                                    id: product['LastWarehouseEntry']['Object'],
                                    text: product['LastWarehouseEntry']['ObjectName']
                                  }
                                });
                                detailsRelativeVouchers.setValue([...detailsRelativeVouchersData]);
                              }

                              const relativeVouchers = this.orderForm.get('RelativeVouchers');
                              const relativeVouchersData = relativeVouchers.value || [];
                              if (!relativeVouchersData.some(f => this.cms.getObjectId(f) == product['LastAccEntry']['Voucher'])) {
                                relativeVouchersData.push({
                                  type: 'B2BPURCHASEORDER',
                                  id: product['LastAccEntry']['Voucher'],
                                  text: product['LastAccEntry']['Voucher'],
                                  VoucherDate: product['LastWarehouseEntry']['VoucherDate'],
                                  'Object': {
                                    id: product['LastWarehouseEntry']['Object'],
                                    text: product['LastWarehouseEntry']['ObjectName']
                                  }
                                });
                                relativeVouchers.setValue([...relativeVouchersData]);

                                await new Promise(resolve => setTimeout(() => resolve(true), 1000));
                              }

                              // Trả hàng về vị trí trước đó đã xuất bán
                              existsProduct.get('Container').setValue(product['LastWarehouseEntry']['Container']);

                            }

                          }
                        } else {
                          if (!product.Inventory || product.Inventory < 1) {
                            this.cms.showToast(`${product.Name} (${product.Unit.Name}) (${accessNumber}) không có trong kho`, 'Hàng hóa không có trong kho !', {...this.toastDefaultConfig, status: 'warning'});
                            existsProduct.get('AccessNumbers').setValue((existsProduct.get('AccessNumbers').value || []).filter(f => f != accessNumber));
                            // return;
                          }
                        }
                      }
                    } catch (err) {
                      reject(err);
                    }
                    resolve(true);
                  }, 1000);
                });
                option?.onHadPrimise(addReturnGoodsPromise);

                return product;
              });

              option?.onHadPrimise && option.onHadPrimise(getProductByAccessNumberPromise);

              if (!unitId || !product) { // Nếu tem cũ không có unit sequence thì phải lấy thông tin sản phẩm bằng số truy xuất ngay từ đầu
                await getProductByAccessNumberPromise;
              }
              if (product) {
                productId = product.Code;
                unitId = unitId || this.cms.getObjectId(product.Unit);
                if (!product.Price) {
                  // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
                  // product.Price = this.productUnitMap[`${product.Code}-${unitId}`]?.Price;
                  const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
                  if (productUnitMap) {
                    product.Price = productUnitMap.Price;
                    product.DiscountPercent = productUnitMap.DiscountPercent;
                    product.DiscountDescription = productUnitMap.DiscountDescription;
                    product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
                  }
                }
              } else {
                product = await this.apiService.getPromise<ProductModel[]>('/purchase/products/' + productId, {
                  includeUnit: true,
                  includePrice: false,
                  includeInventory: true,
                  unitSeq: unitSeq
                }).then(rs => {
                  return rs[0];
                });
                unitId = this.cms.getObjectId(product.Unit);
                if (!product.Price) {
                  // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
                  // product.Price = this.productUnitMap[`${product.Code}-${unitId}`]?.Price;
                  const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
                  if (productUnitMap) {
                    product.Price = productUnitMap.Price;
                    product.DiscountPercent = productUnitMap.DiscountPercent;
                    product.DiscountDescription = productUnitMap.DiscountDescription;
                    product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
                  }
                }
              }
            }
          }
        }
      } else {
        productId = product.Code;
        unitId = this.cms.getObjectId(product.Unit);
      }

      if (!product) { // Nếu không lấy đươc thông tin sản phẩm theo số truy xuất
        // Case 2: Search by product id
        productId = inputValue.length < 9 ? `118${coreId}${inputValue}` : inputValue;
        accessNumber = null;
        product = await this.apiService.getPromise<ProductModel[]>('/purchase/products/' + productId, {
          includeUnit: true,
          includePrice: false,
          includeInventory: true
        }).then(rs => {
          return rs[0];
        });
        if (product) {
          unitId = this.cms.getObjectId(product.Unit);
          // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
          if (!product.Price) {
            // product.Price = this.masterPriceTable[`${product.Code}-${unitId}`]?.Price;
            // product.Price = this.productUnitMap[`${product.Code}-${unitId}`]?.Price;
            const productUnitMap = this.prdService.productUnitMap[`${product.Code}-${unitId}`];
            if (productUnitMap) {
              product.Price = productUnitMap.Price;
              product.DiscountPercent = productUnitMap.DiscountPercent;
              product.DiscountDescription = productUnitMap.DiscountDescription;
              product.ListedPrice = productUnitMap.ListedPrice || productUnitMap.Price;
            }
          }
        }
        // }
      }

      if (this.orderForm.value?.State == this.paymentState) {
        this.cms.showToast('Bạn phải hủy phiếu mới thêm hàng hóa vào được!', 'Đơn hàng đã thanh toán !', {...this.toastDefaultConfig, status: 'warning'});
        throw new Error('Bạn phải hủy phiếu mới thêm hàng hóa vào được!');
      }

      console.log(accessNumber, productId, product);
      let existsProductIndex = detailsControls.findIndex(f => this.cms.getObjectId(f.get('PurchaseProduct').value) === productId && this.cms.getObjectId(f.get('PurchaseUnit').value) == unitId);
      existsProduct = detailsControls[existsProductIndex] as FormGroup;
      if (existsProduct) {
        const quantityControl = existsProduct.get('Quantity');
        const priceControl = existsProduct.get('Price');
        const toMoney = existsProduct.get('ToMoney');
        const accessNumbersContorl = existsProduct.get('AccessNumbers');
        const accessNumbers = accessNumbersContorl.value || [];
        if (accessNumber) {
          if (!accessNumbers.find(f => f == accessNumber)) {
            quantityControl.setValue(parseFloat(quantityControl.value) + 1);
            toMoney.setValue(quantityControl.value * priceControl.value);
            if (accessNumber) {
              accessNumbersContorl.setValue([...accessNumbers, accessNumber]);
            }
            // this.calculateTotal(this.orderForm);

            this.playIncreasePipSound();
            this.calculateToMoney(existsProduct);
            this.calculateTotal(this.orderForm);
            this.activeDetail(this.orderForm, existsProduct, existsProductIndex);
          } else {
            this.playErrorPipSound();
            this.cms.showToast('Mã truy xuất đã được quét trước đó rồi, mời bạn quét tiếp các mã khác !', 'Trùng mã truy xuất !', {...this.toastDefaultConfig, status: 'warning'});
          }
        } else {
          quantityControl.setValue(parseFloat(quantityControl.value) + 1);
          this.calculateToMoney(existsProduct);
          this.calculateTotal(this.orderForm);

          this.activeDetail(this.orderForm, existsProduct, existsProductIndex);
          this.playIncreasePipSound();
        }
      } else {
        existsProduct = this.makeNewOrderDetail({
          PurchaseProductSku: product?.Sku || productId,
          Type: product?.Type || null,
          PurchaseProduct: productId,
          PurchaseUnit: product?.Unit || 'n/a',
          Description: product?.Name || productId,
          Quantity: 1,
          Price: product.LastPurchasePrice || 0,
          // DiscountPercent: product?.DiscountPercent || 0,
          // ListedPrice: product?.ListedPrice || product?.Price || 0,
          // DiscountDescription: product?.DiscountDescription,
          ToMoney: (product?.Price * 1) || 0,
          PurchaseImage: product.Pictures,
          AccessNumbers: accessNumber ? [accessNumber] : null,
          Discount: 0,
          FindOrder: product?.FindOrder || product?.Container?.FindOrder,
          Container: product?.Container,
          Inventory: product?.Inventory,
        });
        existsProductIndex = detailsControls.length - 1;

        // if (product?.Price) {
        // Nếu đã có giá (trường hợp quét số truy xuất)
        this.calculateToMoney(existsProduct);
        detailsControls.push(existsProduct);
        this.calculateTotal(this.orderForm);
        this.activeDetail(this.orderForm, existsProduct, existsProductIndex);
        this.playNewPipSound();
        // } else {
        //   // Nếu chưa có giá (trường hợp quét ID sản phẩm)
        //   if (product) {
        //
        //     if (!product.Unit || !this.cms.getObjectId(product.Unit) || this.cms.getObjectId(product.Unit) == 'n/a') {
        //       this.playErrorPipSound();
        //       this.cms.showToast('Không thể bán hàng với hàng hóa chưa được cài đặt đơn vị tính !', 'Sản phẩm chưa cài đặt đơn vị tính !', {...this.toastDefaultConfig, status: 'danger'});
        //       throw new Error('Không thể bán hàng với hàng hóa chưa được cài đặt đơn vị tính !');
        //     }
        //
        //     existsProduct.get('Description').setValue(product.Name);
        //     existsProduct.get('Sku').setValue(product.Sku);
        //     existsProduct.get('Unit').setValue(product.Unit);
        //     existsProduct.get('FeaturePicture').setValue(product.FeaturePicture?.Thumbnail);
        //
        //     // price = parseFloat(price?.Price || 0);
        //     // existsProduct.get('Price').setValue(price);
        //     // existsProduct.get('ToMoney').setValue(price * existsProduct.get('Quantity').value);
        //
        //     this.calculateToMoney(existsProduct);
        //     detailsControls.push(existsProduct);
        //     this.calculateTotal(this.orderForm);
        //     this.activeDetail(this.orderForm, existsProduct, 0);
        //     this.playNewPipSound();
        //
        //     // await this.apiService.getPromise<any[]>('/sales/master-price-tables/getProductPriceByUnits', {
        //     //   product: productId,
        //     //   includeUnit: true
        //     // }).catch(err => {
        //     //   this.cms.showToast('Không thể bán hàng với hàng hóa chưa có giá bán !', 'Hàng hóa chưa có giá bán !', {...this.toastDefaultConfig, status: 'danger'});
        //     //   return [];
        //     // }).then(prices => prices.find(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(product.Unit))).then(price => {
        //     //   if (price || true) { // Cho phép chọn sản phẩm không có giá bán
        //     //     price = parseFloat(price?.Price || 0);
        //     //     existsProduct.get('Price').setValue(price);
        //     //     existsProduct.get('ToMoney').setValue(price * existsProduct.get('Quantity').value);
        //     //
        //     //     this.calculateToMoney(existsProduct);
        //     //     detailsControls.push(existsProduct);
        //     //     this.calculateTotal(this.orderForm);
        //     //     this.activeDetail(this.orderForm, existsProduct, 0);
        //     //     this.playNewPipSound();
        //     //   } else {
        //     //     this.cms.showToast('Không thể bán hàng với hàng hóa chưa có giá bán !', 'Hàng hóa chưa có giá bán !', {...this.toastDefaultConfig, status: 'danger'});
        //     //   }
        //     // });
        //   } else {
        //     this.playErrorPipSound();
        //     this.cms.showToast('Hàng hóa không tồn tại !', 'Hàng hóa không tồn tại !', {...this.toastDefaultConfig, status: 'danger'});
        //     throw new Error('Hàng hóa không tồn tại !');
        //   }
        // }
      }
      // this.isBarcodeProcessing.next(queueId + 1);
      // console.log('Barcode process sucess for queue: ' + queueId);
      console.log('barcode processed');
      this.barcodeProcessHistory$.next({
        Type: product ? 'PRODUCT' : 'OTHER',
        Barcode: inputValue,
        AccessNumber: accessNumber || inputValue,
        Product: product.Code,
        FeaturePicture: product.FeaturePicture,
        Description: product?.Name || 'Unknown',
        Unit: this.cms.getObjectId(product.Unit),
        UnitLabel: this.cms.getObjectText(product.Unit),
        RelativeOrder: this.orderForm?.value.Code,
      });
      return existsProduct;
    } catch (err) {
      console.error(err);
      // this.isBarcodeProcessing.next(queueId + 1);
      let voucherType = 'PRODUCT';
      let text = null;

      if (/^182/.test(inputValue)) {
        voucherType = 'B2BPURCHASEORDER';
        text = 'Đơn hàng';
      }
      if (/^129/.test(inputValue)) {
        voucherType = 'COMMERCEPOSRETURN';
        text = 'Phiếu trả hàng';
      }
      if (/^141/.test(inputValue)) {
        voucherType = 'MEMBERCARD';
        text = 'Thẻ thành viên';
      }
      if (/^113/.test(inputValue)) {
        voucherType = 'DEPLOYMENT';
        text = 'Phiếu triển khai';
      }

      this.barcodeProcessHistory$.next({
        Type: 'OTHER',
        VoucherType: voucherType,
        Barcode: inputValue,
        Description: text,
        RelativeOrder: this.orderForm?.value.Code,
      });
      return null;
    }
  }

  destroyOrder(event?: any) {
    this.cms.showDialog('POS', 'Bạn có muốn hủy phiếu này không ?', [
      {
        label: 'ESC - Trở về',
        status: 'primary',
        action: () => {
          setTimeout(() => {
            // event.target.blur();
            if ("activeElement" in document) {
              (document.activeElement as HTMLElement).blur();
            }
          }, 500);
        }
      },
      {
        label: 'Enter - Xác nhận',
        keyShortcut: 'Enter',
        status: 'danger',
        focus: true,
        action: () => {
          const apiPath = this.orderForm['voucherType'] == 'B2BPURCHASEORDER' ? '/b2b-center/purchase/orders' : '/purchase/return-vouchers';
          this.apiService.putPromise(apiPath, {changeState: 'UNRECORDED', includeRelativeVouchers: true}, [{Code: this.orderForm.get('Code').value}]).then(rs => {
            this.orderForm.get('State').setValue('UNRECORDED');
            this.historyOrderIndex = this.historyOrders.findIndex(f => f === this.orderForm);
            if (this.historyOrderIndex > -1) {
            }

            setTimeout(() => {
              if ("activeElement" in document) {
                (document.activeElement as HTMLElement).blur();
              }
            }, 500);

          });

        }
      },
    ]);
    return false;
  }

  dateOfPrevious = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

  async onPreviousOrderClick() {
    if (this.processing) {
      console.log('an other process is processing...');
      return;
    }
    this.processing = true;
    this.status = 'Tải đơn hàng trước...';
    this.historyOrderIndex = this.historyOrders.findIndex(f => f === this.orderForm);
    if (this.historyOrderIndex > 0) {
      // this.save(this.historyOrders[this.historyOrderIndex]).then(rs => {
      //   console.log('Đã lưu nháp');
      // }).catch(err => {
      //   console.warn('Lưu nháp không thành công', err);
      // });
      this.historyOrderIndex--;
      this.orderForm = this.historyOrders[this.historyOrderIndex];
      if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
    } else {
      const params: any = {
        sort_Created: 'desc',
        limit: 'nolimit',
        // includeDetails: true,
        // includeRelativeVouchers: true,
        // includeObject: true,
        mergeReturnVouchers: true
      };
      console.log('Load voucher to:' + this.dateOfPrevious);
      if (this.historyOrders[0] && this.historyOrders[0].get('Code').value) {
        // params.lt_Code = this.historyOrders[0].get('Code').value;
        params.gt_VoucherDate = this.dateOfPrevious.toISOString();
        params.le_Created = this.historyOrders[0].get('Created').value;
        params.ne_Code = this.historyOrders[0].get('Code').value;
      }
      // this.loading = true;
      this.progressStatus = 'danger';
      this.progress = 0;
      await this.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath, params).then(async rs => {
        console.log(rs);
        let i = 0;
        this.progress = 0;
        for (const _order of rs) {
          i++;
          if (_order.Type == 'B2BPURCHASEORDER') {
            const order = await this.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath + '/' + _order.Code, {includeDetails: true, renderBarCode: true, includeSalesObject: true, includeUnit: true, includeRelativeVouchers: true}).then(rs => rs[0]);
            await this.makeNewOrder(order, (order?.RelativeVouchers || []).find(f => f.type == 'COMMERCEPOSRETURN')?.id || null, {force: true, location: 'HEAD', autoActiveForm: false});
          }
          if (_order.Type == 'COMMERCEPOSRETURN') {

            const returns = await this.apiService.getPromise<CommercePosOrderModel[]>('/purchase/return-vouchers/' + _order.Code, {includeDetails: true, renderBarCode: true, includeSalesObject: true, includeUnit: true, includeRelativeVouchers: true}).then(rs => rs[0]);
            await this.makeNewReturns(returns, null, {force: true, location: 'HEAD', autoActiveForm: false});
          }
          const progress = parseInt((i / rs.length * 100) as any);
          if (progress <= 100) {
            this.progress = progress;
          } else {
            this.progress = 100;
            this.progressStatus = 'success';
          }
          this.progressLabel = 'Tải đơn hàng ' + this.cms.datePipe.transform(this.dateOfPrevious, 'shortDate') + ' (' + i + '/' + rs.length + ')';
        }
        this.progress = 0;
        if (rs.length > 0) {
          // this.save(this.historyOrders[this.historyOrderIndex]).then(rs => {
          //   console.log('Đã lưu nháp');
          // }).catch(err => {
          //   console.warn('Lưu nháp không thành công', err);
          // });
          this.historyOrderIndex--;
          this.orderForm = this.historyOrders[this.historyOrderIndex];
          if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
        }
      });
      this.dateOfPrevious.setDate(this.dateOfPrevious.getDate() - 1);
      // this.loading = false;
    }
    this.status = '';
    this.processing = false;
  }

  async onNextOrderClick() {
    if (this.processing) {
      console.log('an other process is processing...');
      return;
    }
    this.processing = true;
    this.status = 'Tải đơn hàng sau...';
    this.historyOrderIndex = this.historyOrders.findIndex(f => f === this.orderForm);
    if (this.historyOrderIndex < this.historyOrders.length - 1) {
      // this.save(this.historyOrders[this.historyOrderIndex]).then(rs => {
      //   console.log('Đã lưu nháp');
      // }).catch(err => {
      //   console.warn('Lưu nháp không thành công', err);
      // });
      this.historyOrderIndex++;
      this.orderForm = this.historyOrders[this.historyOrderIndex];
      if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
    }
    this.processing = false;
    this.status = '';
  }

  isSaving = false;

  onSaveBtnClick(orderForm: FormGroup) {
    this.isSaving = true;
    this.save(orderForm).then(rs => {
      this.cms.showToast('Đơn hàng đã được lưu', 'Lưu đơn hàng thành công', {status: 'info', ...this.toastDefaultConfig});
      this.isSaving = false;
      return rs;
    }).catch(err => {
      this.isSaving = false;
      return Promise.reject(err);
    });
    return false;
  }

  isFilterByObject = true;

  onFilterByObjectBtnClick(orderForm: FormGroup) {
    this.isFilterByObject = !this.isFilterByObject;
    this.onSearchInputKeyup({key: ' ', target: {value: this.searchEleRef?.nativeElement?.value}}, {skipUpdateGoodsInfo: true});
    return false;
  }

  focusToQuantity(detailIndex: number) {
    const activeEle = $(this.orderDetailTableRef.nativeElement.children[detailIndex]);
    activeEle[0].scrollIntoView();

    const quantityEle = activeEle.find('.pos-quantity')[0] as HTMLInputElement;
    quantityEle.focus();
    quantityEle.select();
    // let timeout = null;

    // timeout = setTimeout(() => {
    //   // auto blue after 5s
    //   quantityEle.blur();
    // }, 3000);

    // quantityEle.onkeyup = () => {
    //   // console.log(123);
    //   clearTimeout(timeout);
    //   timeout = setTimeout(() => {
    //     // auto blue after 5s
    //     quantityEle.blur();
    //   }, 3000);
    // };

  }

  isShowCashPad = false;

  onCashReceiptFocus($event) {
    this.autoBlur($event, 10000);
    this.isShowCashPad = true;

  }

  onCashReceiptBlur($event) {
    this.clearAutoBlur($event);
    setTimeout(() => {
      this.isShowCashPad = false;
    }, 300);
  }

  onCashReceipSearch(keywork: string) {
    this.setCashReceipt(keywork);
    this.filteredOptions$.next(this.cashList.filter(f => this.cms.smartFilter(f, keywork)));
  }

  setCashReceipt(value: any) {
    // this.autoInputCashReceiptEleRef.nativeElement.value = value;
    if (typeof value == 'string') {
      value = Number(value.replace(this.toMoneyCurencyFormat.decimal, '.').replace(/[^0-9]/g, ''));
    }
    this.orderForm.get('CashReceipt').setValue(value);
    this.onCashReceiptChanged(this.orderForm);
    this.isShowCashPad = false;
  }

  autoBlur(event: any, timeout?: number, context?: string) {
    console.log('autoBlur event: ', event);
    const control = event.currentTarget;
    if (control.blurTimeoutProcess) {
      clearTimeout(control.blurTimeoutProcess);
    }
    control.blurTimeoutProcess = setTimeout(() => {
      // auto blue after 5s
      if (context == 'posSearchInput') {
        if (!this.searchResults || this.searchResults.length == 0) {
          this.blurAll();
        }
      } else {
        control.blur();
      }
    }, timeout || 5000);

    control.onkeyup = () => {
      // console.log(123);
      clearTimeout(control.blurTimeoutProcess);
      control.blurTimeoutProcess = setTimeout(() => {
        // auto blue after 5s
        if (context == 'posSearchInput') {
          if (!this.searchResults || this.searchResults.length == 0) {
            this.blurAll();
          }
        } else {
          control.blur();
        }
      }, timeout || 5000);
    };
  }

  clearAutoBlur(event: any, context?: string) {
    console.log('clearAutoBlur event: ', event);
    const control = event.currentTarget;
    if (control.blurTimeoutProcess) {
      clearTimeout(control.blurTimeoutProcess);
    }

    // if (context == 'posSearchInput') {
    //   this.blurAll();
    // }
  }


  barcode = '';
  findOrderKeyInput = '';
  searchInputPlaceholder = '';
  promiseAll = [];

  onKeyboardEvent(event: KeyboardEvent) {
    if (this.cms.dialogStack.length > 0) {
      return;
    }
    console.log('onKeyboardEvent: ', event);
    if (this.searchResults && this.searchResults.length > 0) {
      if (event.key == 'ArrowDown') {
        if (this.cms.dialogStack.length === 0) {
          if (this.searchResultActiveIndex < this.searchResults.length - 1) {
            this.searchResultActiveIndex++;
            this.searchListViewport.scrollToIndex(this.searchResultActiveIndex > 1 ? (this.searchResultActiveIndex - 1) : 0, 'smooth');
          }
          event.preventDefault();
        }
        return false;
      }
      if (event.key == 'ArrowUp') {
        if (this.cms.dialogStack.length === 0) {
          if (this.searchResultActiveIndex > 0) {
            this.searchResultActiveIndex--;
            this.searchListViewport.scrollToIndex(this.searchResultActiveIndex, 'smooth');
          }
          event.preventDefault();
        }
        return false;
      }
      if (event.key == 'Enter') {
        if (this.cms.dialogStack.length === 0) {
          const product = this.searchResults[this.searchResultActiveIndex];
          this.onChooseProduct(product);
        }
        event.preventDefault();
        return true;
      }
    }

    if (event.key == 'Escape') {
      this.shortcutKeyContext = 'main';
      if (this.cms.dialogStack.length === 0) {
        this.blurAll();
      }
      return true;
    }

    if (this.cms.dialogStack.length === 0) {
      if (event.key == 'F6') {
        console.log(this.customerEle);
        $(this.customerEle['controls'].selector.nativeElement)['select2']('open');
        return false;
      }

      // Toggle debt
      if (event.key == 'F7') {
        this.switchPaymentMethod(this.orderForm);
        return false;
      }

      // if (event.key == 'F10') {
      //   if (this.cms.dialogStack.length === 0) {
      //     this.onMakeNewReturnsForm();
      //     return false;
      //   }
      // }
      if (event.key == 'F10') {
        if (this.cms.dialogStack.length === 0) {
          this.openCreateProductDialog(this.orderForm);
          return false;
        }
      }
      if (event.key == 'F11') {
        if (this.cms.dialogStack.length === 0) {
          // const cashReceiptEle = $('#CashReceipt');
          const cashReceiptEle = $(this.autoInputCashReceiptEleRef.nativeElement);
          const decreaseForTotalEle = $('#DecreaseForTotal');
          if (decreaseForTotalEle.is(':focus')) {
            cashReceiptEle[0].focus();
            cashReceiptEle.select();
          } else {
            // this.decreaseForTotalEleRef.nativeElement.focus();
            decreaseForTotalEle[0].focus();
            decreaseForTotalEle.select();
          }
          event.preventDefault();
          return false;
        }
      }

      // Payment/re-print
      if (event.key == 'F9') {
        if (this.orderForm.value?.State == this.paymentState) {
          this.print(this.orderForm, {printType: 'RETAILINVOICE'});
        } else {
          this.cms.detectiveSimilarityAction('pos_detect_duoble_F9_pressed', 300, (pressedCount) => {
            console.log('F9 similarity pressed count: ' + pressedCount);
            if (pressedCount == 1) {
              this.payment(this.orderForm, {skipPrint: false, printType: 'RETAILINVOICE'});
            }
            if (pressedCount == 2) {
              this.payment(this.orderForm, {skipPrint: true, printType: 'RETAILINVOICE', asyncPayment: true});
            }
          });
        }
        event.preventDefault();

        return true;
      }
      if (event.key == 'F8') {
        if ($(this.objectPhoneEleRef.nativeElement).is(':focus')) {
          this.objectNameEleRef.nativeElement.focus();
        } else if ($(this.objectNameEleRef.nativeElement).is(':focus')) {
          this.objectAddressEleRef.nativeElement.focus();
        } else {
          this.objectPhoneEleRef.nativeElement.focus();
        }
        event.preventDefault();
        // }
        return true;
      }
      if (event.key == 'F4') {
        this.destroyOrder();
        event.preventDefault();
        return false;
        // }
        return true;
      }

      // Change quantity
      if (event.key == 'F2') {
        const details = this.getDetails(this.orderForm).controls;
        let activeDetailIndex = details.findIndex(f => f['isActive'] === true);

        this.focusToQuantity(activeDetailIndex);
        event.preventDefault();
        return false;
      }

      // Change price
      if (event.key == 'F12') {
        if (this.orderForm.value?.State == this.paymentState) {
          this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', {...this.toastDefaultConfig, status: 'warning'});
          this.playErrorPipSound();
          return false;
        }

        const details = this.getDetails(this.orderForm).controls;
        let activeDetail = details.find(f => f['isActive'] === true);

        // Controls
        const controls: DialogFormControl[] = [];
        controls.push({
          name: 'Description',
          label: 'Mô tả',
          placeholder: 'Mô tả thêm cho việc thay đổi giá bán',
          type: 'text',
          initValue: activeDetail.get('Description').value,
        });
        controls.push({
          name: 'Quantity',
          label: 'Số lượng',
          placeholder: 'Số lượng',
          type: 'number',
          initValue: activeDetail.get('Quantity').value,
        });

        const product = this.prdService.productMap[this.cms.getObjectId(activeDetail.get('PurchaseProduct').value)];
        const productType = this.cms.getObjectId(activeDetail.get('Type').value) || this.cms.getObjectId(product?.Type) || 'PRODUCT';
        if (productType == 'WATERCOLORPAINT') {
          const variants = activeDetail.get('Variants').value as Model[];
          const variant = variants ? variants.find(f => f.id == 'COLOR') : null;
          controls.push({
            name: 'VariantValue',
            label: 'Mã màu',
            placeholder: 'Mã màu sơn nước đã chốt với khách',
            type: 'text',
            initValue: variant?.value,
            validators: [Validators.required],
          });
        }
        if (productType == 'CERAMICTILES') {
          const variants = activeDetail.get('Variants').value as Model[];
          const variant = variants ? variants.find(f => f.id == 'TILESCODE') : null;
          controls.push({
            name: 'VariantValue',
            label: 'Mẫu gạch',
            placeholder: 'Mã số gạch men đã chốt với khách',
            type: 'text',
            initValue: variant?.value,
            validators: [Validators.required],
          });
        }

        controls.push({
          name: 'ListedPrice',
          label: 'Giá niêm yết (!!giá mới sẽ tự đẩy vào hàng đợi)',
          placeholder: this.cms.currencyPipe.transform(activeDetail.get('ListedPrice').value, 'VND'),
          type: 'currency',
          // initValue: null,
          // disabled: true,
        });
        controls.push({
          name: 'DiscountPercent',
          label: '% Chiết khấu',
          placeholder: '% Chiết khấu',
          type: 'number',
          initValue: activeDetail.get('DiscountPercent').value,
        });
        controls.push({
          name: 'Price',
          label: 'Giá sau chiết khấu',
          placeholder: 'Giá sau chiết khấu',
          type: 'currency',
          initValue: activeDetail.get('Price').value,
          focus: true,
        });
        controls.push({
          name: 'ToMoney',
          label: 'Thành tiền',
          placeholder: 'Thành tiền',
          type: 'currency',
          initValue: activeDetail.get('ToMoney').value,
        });
        controls.push({
          name: 'Publisher',
          label: 'CTV',
          placeholder: 'Chọn CTV...',
          type: 'select2',
          initValue: activeDetail.get('Publisher').value,
          // focus: true,
          option: {
            ...this.cms.makeSelect2AjaxOption('/collaborator/publishers', {}, {
              prepareResultItem: (item) => ({id: item.Code, text: item.Name, PublisherRefId: item.Publisher}),
            }),
            placeholder: 'Chọn CTV...',
            allowClear: true,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            withThumbnail: false,
            keyMap: {
              id: 'id',
              text: 'text',
            },
          }
        });

        this.cms.openDialog(DialogFormComponent, {
          context: {
            title: 'Chi tiết',
            width: '600px',
            onInit: async (form, dialog) => {
              let oldListedPrice = activeDetail.get('ListedPrice').value;
              const priceControl = form.get('Price');
              const listedPriceControl = form.get('ListedPrice');
              const discountPercentControl = form.get('DiscountPercent');
              const quanityControl = form.get('Quantity');
              const toMoneyControl = form.get('ToMoney');

              priceControl.valueChanges.pipe(takeUntil(dialog.destroy$)).subscribe(price => {
                const listedPrice = (listedPriceControl.value || oldListedPrice);
                // const price = toMoneyControl.value / quanityControl.value;
                toMoneyControl.setValue(priceControl.value * quanityControl.value, {emitEvent: false});
                discountPercentControl.setValue((listedPrice - price) / listedPrice * 100, {emitEvent: false});
              });
              quanityControl.valueChanges.pipe(takeUntil(dialog.destroy$)).subscribe(quantity => {
                toMoneyControl.setValue(priceControl.value * quantity, {emitEvent: false});
              });
              toMoneyControl.valueChanges.pipe(takeUntil(dialog.destroy$)).subscribe(toMoney => {
                const price = toMoney / quanityControl.value;
                const listedPrice = (listedPriceControl.value || oldListedPrice);
                priceControl.setValue(price, {emitEvent: false});
                discountPercentControl.setValue((listedPrice - price) / listedPrice * 100, {emitEvent: false});
              });
              discountPercentControl.valueChanges.pipe(takeUntil(dialog.destroy$)).subscribe(discountPercent => {
                const listedPrice = (listedPriceControl.value || oldListedPrice);
                const price = listedPrice - listedPrice * discountPercent / 100;
                priceControl.setValue(price, {emitEvent: false});
                toMoneyControl.setValue(price * quanityControl.value, {emitEvent: false});
              });
              listedPriceControl.valueChanges.pipe(takeUntil(dialog.destroy$)).subscribe(listedPriceValue => {
                const listedPrice = (listedPriceValue || oldListedPrice);
                const price = listedPrice - listedPrice * discountPercentControl.value / 100;
                priceControl.setValue(price, {emitEvent: false});
                toMoneyControl.setValue(price * quanityControl.value, {emitEvent: false});
              });

              return true;
            },
            controls: controls,
            actions: [
              {
                label: 'Esc - Trở về',
                status: 'basic',
                keyShortcut: 'Escape',
                action: async () => {
                  return true;
                },
              },
              {
                label: 'Enter - Xác nhận',
                status: 'success',
                keyShortcut: 'Enter',
                action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {
                  const listedPriceControl = activeDetail.get('ListedPrice');
                  if (form.get('ListedPrice').value) {
                    listedPriceControl.setValue(form.get('ListedPrice').value);
                  }
                  activeDetail.get('Price').setValue(form.get('Price').value);
                  activeDetail.get('Quantity').setValue(form.get('Quantity').value);
                  activeDetail.get('DiscountPercent').setValue(form.get('DiscountPercent').value);
                  activeDetail.get('Description').setValue(form.get('Description').value);
                  activeDetail.get('Publisher').setValue(form.get('Publisher').value);

                  const productType = this.cms.getObjectId(activeDetail.get('Type').value) || this.cms.getObjectId(product?.Type) || 'PRODUCT';

                  if (productType == 'WATERCOLORPAINT') {
                    if (form.get('VariantValue').value) {
                      activeDetail.get('Variants').setValue([
                        {
                          id: 'COLOR',
                          text: 'Màu',
                          value: form.get('VariantValue').value,
                        }
                      ]);
                    }
                  }
                  if (productType == 'CERAMICTILES') {
                    if (form.get('VariantValue').value) {
                      activeDetail.get('Variants').setValue([
                        {
                          id: 'TILESCODE',
                          text: 'Mẫu gạch',
                          value: form.get('VariantValue').value,
                        }
                      ]);
                    }
                  }

                  this.calculateToMoney(activeDetail as FormGroup);
                  this.calculateTotal(this.orderForm);

                  if (form.get('ListedPrice').value) {
                    // Push to price update queue
                    this.apiService.putPromise('/sales/master-price-table-details', {}, [
                      {
                        MasterPriceTable: 'default',
                        Product: this.cms.getObjectId(activeDetail.get('PurchaseProduct').value),
                        Unit: this.cms.getObjectId(activeDetail.get('PurchaseUnit').value),
                        Price: listedPriceControl.value,
                        RelativeVoucher: {id: this.orderForm.get('Code').value, text: this.orderForm.get('Title').value || 'Commerce POS Order'},
                        RequestContext: 'COMMERCEPOS',
                      }
                    ]).then(rs => {
                      console.log(rs);
                      this.cms.showToast('Đã đẩy sản phẩm vào hàng đợi cập nhật giá', 'Đẩy vào hàng đợi cập nhật giát', {...this.toastDefaultConfig, status: 'info'});
                    }).catch(err => {
                      console.error(err);
                      return Promise.reject(err);
                    });
                  }
                  return true;
                },
              },
              {
                label: 'Đồng bộ',
                status: 'info',
                action: async () => {
                  return this.apiService.getPromise<ProductModel[]>('/admin-product/products' + this.cms.getObjectId(activeDetail.get('PurchaseProduct').value)).then(rs => {
                    const productInfo = rs[0];
                    if (productInfo) {
                      // activeDetail.get('Description').setValue(productInfo.Name);
                      activeDetail.get('PurchaseImage').setValue(productInfo.Pictures);
                    }
                    return true;
                  });
                },
              },
            ],
          },
        });

        event.preventDefault();
        return false;
      }

      // Forcus to serach
      if (event.key == 'F3') {
        this.searchEleRef.nativeElement.focus();
        event.preventDefault();
        return true;
      }
      if (event.key == 'F5') {
        if (this.cms.dialogStack.length === 0) {
          this.makeNewOrder();
        }
        event.preventDefault();
        return true;
      }

      if (event.key == '+') {
        if (this.cms.dialogStack.length === 0) {
          const details = this.getDetails(this.orderForm).controls;
          const activeDetail = details.find(f => f['isActive'] === true) as FormGroup;
          if (activeDetail) {
            this.onIncreaseQuantityClick(this.orderForm, activeDetail);
          }
        }
        return false;
      }
      if (event.key == '-') {
        if (this.cms.dialogStack.length === 0) {
          const details = this.getDetails(this.orderForm).controls;
          const activeDetail = details.find(f => f['isActive'] === true) as FormGroup;
          if (activeDetail) {
            this.onDecreaseQuantityClick(this.orderForm, activeDetail);
          }
        }
        return false;
      }

      if (event.key == 'Delete') {
        if (this.cms.dialogStack.length === 0) {
          const details = this.getDetails(this.orderForm).controls;
          let activeDetailIndex = details.findIndex(f => f['isActive'] === true);
          // if (activeDetailIndex > -1) {
          //   details.splice(activeDetailIndex, 1);
          //   this.calculateTotal(this.orderForm);
          //   const nextActive = details[activeDetailIndex] as FormGroup;
          //   if (nextActive) {
          //     this.activeDetail(this.orderForm, nextActive, activeDetailIndex);
          //   } else {
          //     if (details.length > 0) {
          //       activeDetailIndex = 0;
          //       this.activeDetail(this.orderForm, details[0] as FormGroup, activeDetailIndex);
          //     }
          //   }
          // }
          this.removeDetail(this.orderForm, activeDetailIndex);
        }
      }
    } else {
      // Skip system function key
      if (['F12', 'F5', 'F3'].indexOf(event.key) > -1) {
        return false;
      }
    }

    if (event.key == 'F4') {
      if (this.shortcutKeyContext == 'returnspaymentconfirm') {
        this.makeNewOrder(null, this.inputValue);
      }
      return true;
    }

    if ((this.searchResults == null || this.searchResults.length == 0) && (document.activeElement as HTMLElement).tagName == 'BODY') {
      if (event.key == 'ArrowLeft') {
        if (this.cms.dialogStack.length === 0) {
          this.onPreviousOrderClick();
          event.preventDefault();
        }
        return true;
      }
      if (event.key == 'ArrowRight') {
        if (this.cms.dialogStack.length === 0) {
          this.onNextOrderClick();
          event.preventDefault();
        }
        return true;
      }

      if (event.key == 'ArrowDown') {
        if (this.cms.dialogStack.length === 0) {

          this.findOrderKeyInput = '';
          this.searchInputPlaceholder = '';
          (document.activeElement as HTMLElement).blur();

          const details = this.getDetails(this.orderForm).controls;
          let activeDetailIndex = details.findIndex(f => f['isActive'] === true);
          if (activeDetailIndex < 0) {
            activeDetailIndex = 0;
          } else {
            activeDetailIndex++;
          }
          const activeDetail = this.getDetails(this.orderForm).controls[activeDetailIndex] as FormGroup;
          if (activeDetail) {
            this.activeDetail(this.orderForm, activeDetail, activeDetailIndex);
          }
          // const nextDetail = details[activeDetailIndex];
          // if (nextDetail) {
          //   nextDetail['isActive'] = true;

          //   const activeDetailRef = this.orderDetailTableRef.nativeElement.children[activeDetailIndex + 1];
          //   activeDetailRef && $(activeDetailRef)[0] && $(activeDetailRef)[0].scrollIntoView();

          //   for (const detail of details) {
          //     if (detail !== nextDetail) {
          //       detail['isActive'] = false;
          //     }
          //   }
          // }
        }

        return false;
      }

      if (event.key == 'ArrowUp') {
        if (this.cms.dialogStack.length === 0) {

          this.findOrderKeyInput = '';
          this.searchInputPlaceholder = '';
          (document.activeElement as HTMLElement).blur();

          const details = this.getDetails(this.orderForm).controls;
          let activeDetailIndex = details.findIndex(f => f['isActive'] === true);
          if (activeDetailIndex > details.length - 1) {
            activeDetailIndex = details.length - 1;
          } else {
            activeDetailIndex--;
          }
          if (activeDetailIndex > -1) {
            const activeDetail = this.getDetails(this.orderForm).controls[activeDetailIndex] as FormGroup;
            if (activeDetail) {
              this.activeDetail(this.orderForm, activeDetail, activeDetailIndex);
            }
            // const nextDetail = details[activeDetailIndex];
            // nextDetail['isActive'] = true;

            // const activeDetailRef = this.orderDetailTableRef.nativeElement.children[activeDetailIndex + 1];
            // activeDetailRef && $(activeDetailRef)[0] && $(activeDetailRef)[0].scrollIntoView();

            // for (const detail of details) {
            //   if (detail !== nextDetail) {
            //     detail['isActive'] = false;
            //   }
            // }
          }
        }
        return false;
      }
    } else {
      // Control for search results

    }

    if ("activeElement" in document) {
      if ((document.activeElement as HTMLElement).id == 'posSearchInput') {
        return true;
      }
      if ((document.activeElement as HTMLElement).id == 'SalesObjectPhone') {
        return true;
      }
      if ((document.activeElement as HTMLElement)?.classList?.value.indexOf('pos-quantity') > -1) {
        if (event.key == 'Enter') {
          (document.activeElement as HTMLElement).blur();
          this.findOrderKeyInput = '';
          this.searchInputPlaceholder = '';
          return true;
        }
      }
    }

    // Barcode scan
    if (this.cms.dialogStack.length === 0) {
      if ((/^[0-9a-z]$/i.test(event.key) || ['Enter'].indexOf(event.key) > -1) && (document.activeElement as HTMLElement).tagName == 'BODY') {

        this.cms.barcodeScanDetective(event.key, barcode => {
          if (barcode?.length > 8) {
            this.barcodeProcessQueue(barcode);
          }
          // .then(status => {
          //   console.log('Barcode processed');
          // });
        });

      }

      if (/^[0-9a-z]$/i.test(event.key) && (document.activeElement as HTMLElement).tagName == 'BODY') {
        this.findOrderKeyInput += event.key;
        this.searchInputPlaceholder = this.findOrderKeyInput + ' - tìm theo vị trí hàng hóa, sku...';
      }
      if (event.key == 'Backspace') {
        this.findOrderKeyInput = this.findOrderKeyInput.slice(0, -1);
        this.searchInputPlaceholder = this.findOrderKeyInput + ' - tìm theo vị trí hàng hóa, sku...';
      }

      if (event.key == 'Enter' && this.findOrderKeyInput) {
        setTimeout(() => {
          if (/[a-z]/i.test(this.findOrderKeyInput)) {
            try {
              this.barcodeProcess(this.findOrderKeyInput, {searchBySku: true});
            } catch (err) {
              this.cms.showToast(err, 'Cảnh báo', {...this.toastDefaultConfig, status: 'warning'});
            }
          } else {
            if (this.findOrderKeyInput && this.findOrderKeyInput.length < 6) {
              try {
                this.barcodeProcess(this.findOrderKeyInput, {searchByFindOrder: true});
              } catch (err) {
                this.cms.showToast(err, 'Cảnh báo', {...this.toastDefaultConfig, status: 'warning'});
              }
            }
          }
          this.findOrderKeyInput = '';
          this.searchInputPlaceholder = '';
        }, 300);
      }
    }

    // Disable F7 key for else
    if (event.key == 'F7') {
      return false;
    }

    if (['F12', 'F5', 'F3'].indexOf(event.key) > -1) {
      return false;
    }

    return true;
  }

  blurAll() {
    this.searchResults = null;
    this.searchEleRef.nativeElement.value = '';
    this.searchInputPlaceholder = '';
    this.barcode = '';
    this.findOrderKeyInput = '';
    (document.activeElement as HTMLElement).blur();
    this.isShowCashPad = false;
  }

  onKeyupEvent(event: KeyboardEvent) {


    return true;
  }

  activeDetail(orderForm: FormGroup, activeDetail: FormGroup, index: number, option?: { follow: boolean }) {
    const details = this.getDetails(orderForm).controls;

    activeDetail['isActive'] = true;

    for (const detail of details) {
      if (detail !== activeDetail) {
        detail['isActive'] = false;
      }
    }
    let follow = true;
    if (option && option.follow === false) {
      follow = false;
    }
    if (follow) {
      setTimeout(() => {
        $(this.orderDetailTableRef.nativeElement?.children[index])[0]?.scrollIntoView();
      }, 0);
    }
  }

  removeDetail(orderForm: FormGroup, index: number) {
    if (orderForm.value?.State == this.paymentState) {
      this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', {...this.toastDefaultConfig, status: 'warning'});
      this.playErrorPipSound();
      return false;
    }

    const removedRelativeVouchers = this.getDetails(orderForm).controls[index]?.value?.RelativeVouchers?.map(m => this.cms.getObjectId(m)) || [];
    const detailsForm = this.getDetails(orderForm);
    detailsForm.controls.splice(index, 1);
    this.calculateTotal(orderForm);

    // Remove relative voucher
    const relativeVouchers = orderForm.get('RelativeVouchers');
    let relativeVouchersData = relativeVouchers.value;
    for (const removedRelativeVoucher of removedRelativeVouchers) {
      if (this.getDetails(orderForm).controls?.findIndex(f => f.get('RelativeVouchers').value.some(s => this.cms.getObjectId(s) == removedRelativeVoucher) < 0)) {
        relativeVouchersData = relativeVouchersData.filter(f => this.cms.getObjectId(f) != removedRelativeVoucher);
      }
    }
    relativeVouchers.setValue(relativeVouchersData);
    if (detailsForm.controls[index]) {
      this.activeDetail(orderForm, detailsForm.controls[index] as FormGroup, index);
    } else {
      this.activeDetail(orderForm, detailsForm.controls[detailsForm.controls.length - 1] as FormGroup, detailsForm.controls.length - 1);
    }

    return true;
  }

  onQuantityKeydown(orderForm: FormGroup, detail: FormGroup, event, numberFormat: CurrencyMaskConfig) {
    if (['Backspace'].indexOf(event.key) < 0 && !/[0-9\.]/.test(event.key)) {
      event.preventDefault();
      return false;
    }
    const check = (event.target?.value + event.key).match(/\./g);
    if (check && check.length > 1) {
      event.preventDefault();
      return false;
    }
    this.calculateToMoney(detail);
    this.calculateTotal(orderForm);
    return true;
  }

  onQuantityChanged(orderForm: FormGroup, detail: FormGroup, event, numberFormat: CurrencyMaskConfig) {
    this.calculateToMoney(detail);
    this.calculateTotal(orderForm);
    console.log('change quantity accepted');
    return true;
  }

  onIncreaseQuantityClick(orderForm: FormGroup, detail: FormGroup) {
    if (orderForm.value?.State == this.paymentState) {
      this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', {...this.toastDefaultConfig, status: 'warning'});
      this.playErrorPipSound();
      return false;
    }
    const quantityControl = detail.get('Quantity');
    quantityControl.setValue(parseInt(quantityControl.value) + 1);
    this.calculateToMoney(detail);
    this.calculateTotal(orderForm);
    this.playNewPipSound();
  }

  onDecreaseQuantityClick(orderForm: FormGroup, detail: FormGroup) {
    if (orderForm.value?.State == this.paymentState) {
      this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', {...this.toastDefaultConfig, status: 'warning'});
      this.playErrorPipSound();
      return false;
    }
    const quantityControl = detail.get('Quantity');
    if (quantityControl.value > 1) {
      quantityControl.setValue(parseInt(quantityControl.value) - 1);
      this.calculateToMoney(detail);
      this.calculateTotal(orderForm);
      this.playDecreasePipSound();
    } else {
      this.playErrorPipSound();
      this.cms.showToast('Chỉ có thể bán hàng với số lượng lớn hơn 0', 'Số lượng phải lớn hơn 0', {...this.toastDefaultConfig, status: 'warning'});
    }
  }

  removeCashBack(orderForm: FormGroup) {
    orderForm['cashBack'] = 0;
    orderForm.get('CashBack').setValue(0);
  }

  // onCashReceipt(orderForm: FormGroup, event) {
  //   console.log(event);
  //   if (event?.id) {
  //     orderForm.get('CashReceipt').setValue(event.id);
  //   } else {
  //     orderForm.get('CashReceipt').setValue(event);
  //   }
  //   this.onCashReceiptChanged(orderForm, 'CashReceipt');
  // }

  onCashReceiptChanged(orderForm: FormGroup, source?: string) {
    // const cashReceiptControl = formGroup.get('CashReceipt');
    // const cashBackControl = formGroup.get('CashBack');
    // const totolControl = formGroup.get('Total');
    // cashBackControl.setValue(cashReceiptControl.value - totolControl.value);

    const cashReceiptControl = orderForm.get('CashReceipt');
    const decreaseForTotalControl = orderForm.get('DecreaseForTotal');
    const totalAmount = orderForm.get('Amount');
    const cashAmount = orderForm.get('CashAmount');
    const cashTransferAmount = orderForm.get('CashTransferAmount');
    const receivableDebt = orderForm['ReceivableDebt'] || 0;
    if (cashReceiptControl && totalAmount && decreaseForTotalControl && this.cms.getObjectId(orderForm.get('PaymentMethod').value) === 'CASH') {
      let cashBack = cashReceiptControl.value - ((totalAmount.value) - decreaseForTotalControl.value - (orderForm['returnsObj'] && this.cms.getObjectId(orderForm['returnsObj'].get('`PaymentMethod`').value) === 'CASH' ? orderForm['returnsObj'].get('Amount').value : 0));

      if (cashBack > 0) {
        orderForm['cashBack'] = cashBack;
        orderForm.get('CashBack').setValue(cashBack);
      } else {
        orderForm['cashBack'] = 0;
      }
    }
    if (cashTransferAmount && totalAmount && decreaseForTotalControl && this.cms.getObjectId(orderForm.get('PaymentMethod').value) === 'BANKTRANSFER') {
      const cashBack = cashTransferAmount.value - (totalAmount.value) - decreaseForTotalControl.value;
      if (cashBack > 0) {
        orderForm['cashBack'] = cashBack;
      } else {
        orderForm['cashBack'] = 0;
      }
    }
    if (cashAmount && cashTransferAmount && totalAmount && decreaseForTotalControl && this.cms.getObjectId(orderForm.get('PaymentMethod').value) === 'MIXED') {
      const cashBack = cashAmount.value + cashTransferAmount.value - (receivableDebt + totalAmount.value - decreaseForTotalControl.value);
      orderForm['cashBack'] = cashBack;
    }
    orderForm.get('CashBack').setValue(orderForm['cashBack']);

    if (orderForm['voucherType'] == 'B2BPURCHASEORDER') {
      if (orderForm['returnsObj'] && this.cms.getObjectId(orderForm['returnsObj'].get('PaymentMethod').value) === 'DEBT') {
        orderForm['isReceipt'] = true;
        orderForm.get('CashReceipt').enable();
      } else {
        if (orderForm['returnsObj'] && orderForm['returnsObj'].get('Amount').value >= orderForm.value?.Amount - orderForm.value?.DecreaseForTotal) {
          orderForm['isReceipt'] = false;
          orderForm.get('CashReceipt').disable();
        } else {
          orderForm['isReceipt'] = true;
          orderForm.get('CashReceipt').enable();
        }
      }
    }

    if (source && source == 'CashReceipt') {
      // this.orderForm.get('CashReceipt').setValue();
    }

  }

  async payment(orderForm: FormGroup, option?: { printType?: 'PRICEREPORT' | 'RETAILINVOICE', skipPrint?: boolean, asyncPayment?: boolean }) {
    // if (this.promiseAll.length > 0) {
    //   await new Promise(async resolve => {
    //     let isResolved = false;
    //     setTimeout(() => {
    //       if (!isResolved) {
    //         isResolved = true;
    //         resolve(true);
    //       }
    //     }, 30000);
    //     this.loading = true;
    //     await Promise.all(this.promiseAll);
    //     this.promiseAll = [];
    //     // await new Promise(resolve => setTimeout(() => resolve(true), 1000));
    //     this.loading = false;
    //     if (!isResolved) {
    //       isResolved = true;
    //       resolve(true);
    //     }
    //   })
    // }
    const data = orderForm.getRawValue();
    if (!data?.Details?.length) {
      this.cms.showToast('Bạn phải thêm hàng hóa vào đơn hàng trước khi thanh toán !', 'Chưa có hàng hóa nào trong đơn hàng !', {...this.toastDefaultConfig, status: 'warning', duration: 5000});
      return false;
    }
    // delete data.DateOfOrder;
    if (data.DateOfOrder) {
      const dateOfOrder = new Date(data.DateOfOrder);
      dateOfOrder.setTimeToNow();
      data.DateOfOrder = dateOfOrder.toISOString();
    }
    orderForm['isProcessing'] = true;
    setTimeout(() => {
      orderForm['isProcessing'] = false;
    }, 500);

    option = {
      printType: 'PRICEREPORT',
      ...option,
    };

    this.blurAll();

    if (orderForm['voucherType'] == 'B2BPURCHASEORDER') {

      this.cms.openDialog(B2bPurchasePopBillPrintComponent, {
        context: {
          skipPreview: true,
          printType: option?.printType,
          instantPayment: true,
          data: [data],
          onSaveAndClose: (newOrder: CommercePosOrderModel, printComponent) => {
            // if (typeof newOrder.SalesObject == 'string') {
            //   newOrder.SalesObject = {id: newOrder.SalesObject, text: `${newOrder.SalesObject} - ${newOrder.SalesObjectName}`};
            // }
            // newOrder.SalesObject = {...orderForm.get('SalesObject').value, ...newOrder.SalesObject};
            // if (!newOrder.SalesObject || !newOrder.SalesObject.id) {
            //   newOrder.SalesObject = null;
            // }
            if (typeof newOrder.PaymentMethod == 'string') {
              newOrder.PaymentMethod = this.paymentMethod.find(f => this.cms.getObjectId(f) === newOrder.PaymentMethod);
            }

            if (newOrder.DateOfOrder) {
              newOrder.DateOfOrder = new Date(newOrder.DateOfOrder).toISOString().slice(0, 10);
            }
            orderForm.patchValue(newOrder);
            this.cms.showToast(option?.skipPrint ? `Đã chốt đơn đặt hàng ${newOrder.Code}!` : `Đã chốt đơn đặt hàng ${newOrder.Code}`, 'Đã chốt đơn', {...this.toastDefaultConfig, status: 'success', duration: 8000});
            if (!option?.asyncPayment && this.historyOrders[this.historyOrders.length - 1] == orderForm) {
              // this.makeNewOrder();
            }
            console.log(this.historyOrders);

            // Decrease inventory
            // for (const detail of data.Details) {
            //   const goods = this.productSearchIndex[`${this.cms.getObjectId(detail.Product)}-${this.cms.getObjectId(detail.Unit)}-${this.cms.getObjectId(detail.Container)}`];
            //   if (goods) {
            //     goods.Inventory -= parseFloat(detail.Quantity);
            //   }
            // }

            this.playPaymentSound();
          },
          onClose: () => {
          },
          onAfterInit: (component: B2bPurchasePopBillPrintComponent) => {
            if (option?.skipPrint) {
              component?.close();
            } else {
              setTimeout(() => {
                if (!component.isProcessing) {
                  component?.close();
                }
              }, 15000);
            }
          }
        }
      });
      if (option?.asyncPayment) {
        this.makeNewOrder();
      }
    } else {
      // Trả hàng
    }
  }

  async saveAsPriceReport(orderForm: FormGroup, option?: { printType?: 'PRICEREPORT', skipPrint?: boolean }) {
    orderForm.get('Title').setValue(`Báo giá khách POS: ${orderForm.get('SalesObjectName').value} - ${this.cms.datePipe.transform(new Date(), 'short')}`);
    const data = orderForm.getRawValue();
    if (!data?.Details?.length) {
      this.cms.showToast('Bạn phải thêm hàng hóa vào đơn hàng trước khi báo giá !', 'Chưa có hàng hóa nào trong đơn hàng !', {...this.toastDefaultConfig, status: 'warning', duration: 5000});
      return false;
    }
    // delete data.DateOfOrder;
    orderForm['isProcessing'] = true;
    setTimeout(() => {
      orderForm['isProcessing'] = false;
    }, 500);

    option = {
      printType: 'PRICEREPORT',
      ...option,
    };

    this.blurAll();

    if (orderForm['voucherType'] == 'B2BPURCHASEORDER') {

      this.cms.openDialog(B2bPurchasePopBillPrintComponent, {
        context: {
          skipPreview: true,
          printType: option?.printType,
          type: 'PRICEREPORT',
          instantPayment: true,
          data: [data],
          onSaveAndClose: (newOrder: CommercePosOrderModel, printComponent) => {
            if (typeof newOrder.SalesObject == 'string') {
              newOrder.SalesObject = {id: newOrder.SalesObject, text: `${newOrder.SalesObject} - ${newOrder.SalesObjectName}`};
            }
            newOrder.SalesObject = {...orderForm.get('SalesObject').value, ...newOrder.SalesObject};
            if (!newOrder.SalesObject || !newOrder.SalesObject.id) {
              newOrder.SalesObject = null;
            }
            if (newOrder.DateOfOrder) {
              newOrder.DateOfOrder = new Date(newOrder.DateOfOrder).toISOString().slice(0, 10);
            }
            orderForm.patchValue(newOrder);
            this.cms.showToast(option?.skipPrint ? `Đã báo giá cho đơn hàng ${newOrder.Code}` : `Đã báo giá cho đơn hàng ${newOrder.Code}`, 'Đã báo giá', {...this.toastDefaultConfig, status: 'success', duration: 8000});
            // this.makeNewOrder();
            console.log(this.historyOrders);
            // this.playPaymentSound();
          },
          onClose: () => {
          },
          onAfterInit: (component: B2bPurchasePopBillPrintComponent) => {
            // if (option?.skipPrint) {
            //   component?.close();
            // } else {
            //   setTimeout(() => {
            //     if (!component.isProcessing) {
            //       component?.close();
            //     }
            //   }, 15000);
            // }
          }
        }
      });
    }
  }

  async save(orderForm: FormGroup): Promise<CommercePosOrderModel> {
    // if (orderForm.get('Code').value && !orderForm['modified']) {
    //   console.log('voucher was not modified => not need save');
    //   return orderForm.value;
    // }
    const voucherType = orderForm['voucherType'];
    const apiPath = voucherType == 'B2BPURCHASEORDER' ? this.apiPath : '/purchase/return-vouchers';
    let order = orderForm.getRawValue();
    delete order.BarCode;
    if (orderForm && orderForm['isProcessing'] !== true && order.State != this.paymentState) {
      return this.cms.takeUntil('commerce-pos-order-save', 500).then(status => {
        if (order.Code) {
          return this.apiService.putPromise(apiPath + '/' + order.Code, {renderBarCode: true, includeRelativeVouchers: true}, [order]).then(rs => {
            if (rs[0].Details) {
              for (const index in rs[0].Details) {
                const detailForm = (orderForm.get('Details') as FormArray).controls[index] as FormGroup;
                detailForm.get('SystemUuid').setValue(rs[0].Details[index].SystemUuid);
              }
            }
            return rs[0];
          });
        } else {
          orderForm['isProcessing'] = true;
          return this.apiService.postPromise<B2bOrderModel[]>(apiPath, {renderBarCode: true, includeRelativeVouchers: true}, [order]).then(rs => {
            const newOrder = rs[0];
            if (newOrder.DateOfOrder) {
              newOrder.DateOfOrder = new Date(newOrder.DateOfOrder).toISOString().slice(0, 10);
            }
            orderForm.patchValue(newOrder);
            orderForm['isProcessing'] = false;
            // orderForm['modified'] = false;
            if (newOrder.Details) {
              for (const index in newOrder.Details) {
                const detailForm = (orderForm.get('Details') as FormArray).controls[index] as FormGroup;
                detailForm.get('SystemUuid').setValue(newOrder.Details[index].SystemUuid);
              }
            }
            return newOrder;
          }).catch(err => {
            orderForm['isProcessing'] = false;
            return Promise.reject(err);
          });
        }
      });
    }
    console.log('Order Form đang khởi tạo => chưa lưu đơn !');
    return null;
  }

  async saveAsPriceReportx() {
    const voucherType = this.orderForm['voucherType'];
    if (voucherType != 'B2BPURCHASEORDER') {
      return Promise.reject('Phiếu hiện tại không phải đơn hàng POS');
    }
    const apiPath = this.apiPath;
    this.orderForm.get('Title').setValue(`Báo giá khách POS: ${this.cms.getObjectText(this.orderForm.get('SalesObject').value)}} - ${this.cms.datePipe.transform(this.orderForm.get('Created').value, 'short')}`);
    let order = this.orderForm.getRawValue();
    delete order.BarCode;
    if (this.orderForm && this.orderForm['isProcessing'] !== true && order.State != this.paymentState) {
      return this.cms.takeUntil('commerce-pos-order-save', 500).then(status => {
        if (order.Code) {
          return this.apiService.putPromise(apiPath + '/' + order.Code, {renderBarCode: true, includeRelativeVouchers: true}, [order]).then(rs => {
            if (rs[0].Details) {
              for (const index in rs[0].Details) {
                const detailForm = (this.orderForm.get('Details') as FormArray).controls[index] as FormGroup;
                detailForm.get('SystemUuid').setValue(rs[0].Details[index].SystemUuid);
              }
            }
            return rs[0];
          });
        } else {
          this.orderForm['isProcessing'] = true;
          return this.apiService.postPromise<B2bOrderModel[]>(apiPath, {renderBarCode: true, includeRelativeVouchers: true}, [order]).then(rs => {
            const newOrder = rs[0];
            if (newOrder.DateOfOrder) {
              newOrder.DateOfOrder = new Date(newOrder.DateOfOrder).toISOString().slice(0, 10);
            }
            this.orderForm.patchValue(newOrder);
            this.orderForm['isProcessing'] = false;
            // orderForm['modified'] = false;
            if (newOrder.Details) {
              for (const index in newOrder.Details) {
                const detailForm = (this.orderForm.get('Details') as FormArray).controls[index] as FormGroup;
                detailForm.get('SystemUuid').setValue(newOrder.Details[index].SystemUuid);
              }
            }
            return newOrder;
          });
        }
      });
    }
    console.log('Order Form đang khởi tạo => chưa lưu đơn !');
    return null;
  }

  async print(orderForm: FormGroup, option?: { printType?: 'PRICEREPORT' | 'RETAILINVOICE' }) {
    if (orderForm.get('State').value !== this.paymentState) {
      this.cms.showToast('Bạn chỉ có thể in lại phiếu đã chốt', 'Không thể in bill !', {...this.toastDefaultConfig, status: 'warning'});
      return false;
    }
    option = {
      printType: 'PRICEREPORT',
      ...option
    };
    if (orderForm['voucherType'] == 'B2BPURCHASEORDER') {
      return new Promise(resovle => {

        this.cms.openDialog(B2bPurchasePopBillPrintComponent, {
          context: {
            skipPreview: true,
            printType: option.printType,
            data: [orderForm.getRawValue()],
            onSaveAndClose: (newOrder, printComponent) => {
              this.cms.showToast('Đã tạo phiếu chi hoàn tiền cho phiếu trả hàng !', 'Đã tạo phiếu chi !', {...this.toastDefaultConfig, status: 'success', duration: 8000});
            }
          }
        });
      });
    } else if (orderForm['voucherType'] == 'COMMERCEPOSRETURN') {
      // Trả hàng
      // this.cms.openDialog(CommercePosReturnsPrintComponent, {
      //   context: {
      //     skipPreview: true,
      //     data: [orderForm.getRawValue()],
      //     onSaveAndClose: (newOrder, printComponent) => {
      //     },
      //     onAfterInit: (component: CommercePosReturnsPrintComponent) => {
      //       component.onContinueOrder.pipe(take(1)).toPromise().then(async rs => {
      //         const newOrderForm = await this.makeNewOrder(null, orderForm.get('Code').value);
      //         this.orderForm = newOrderForm;
      //       });
      //     }
      //   }
      // });
    }
    return true;
  }

  async printOrder(orderId: string) {
    const order = await this.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath + '/' + orderId, {includeDetails: true, renderBarCode: true, includeRelativeVouchers: true}).then(rs => rs[0]);
    if (order.State !== this.paymentState) {
      this.cms.showToast('Bạn chỉ có thể in lại phiếu đã chốt', 'Không thể in bill !', {...this.toastDefaultConfig, status: 'warning'});
      return false;
    }
    return new Promise(resovle => {
      this.cms.openDialog(B2bPurchasePopBillPrintComponent, {
        context: {
          skipPreview: true,
          data: [order],
          onSaveAndClose: (newOrder, printComponent) => {
            resovle(true);
          }
        }
      });
    });
  }

  async loadVoucher(voucherId: string) {
    if (/^182/.test(voucherId)) {
      const voucher = await this.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath + '/' + voucherId, {includeDetails: true, renderBarCode: true, includeSalesObject: true, includeUnit: true, includeRelativeVouchers: true}).then(rs => rs[0]);
      if (voucher && voucher.SalesObject?.id) {
        voucher.SalesObject.text = `${voucher.SalesObject.id} - ${voucher.SalesObject.text}`;
      }
      this.makeNewOrder(voucher, null, {force: true});
    } else if (/^129/.test(voucherId)) {
      const voucher = await this.apiService.getPromise<CommercePosOrderModel[]>('/purchase/return-vouchers/' + voucherId, {includeDetails: true, renderBarCode: true, includeSalesObject: true, includeUnit: true, includeRelativeVouchers: true}).then(rs => rs[0]);
      if (voucher && voucher.SalesObject?.id) {
        voucher.SalesObject.text = `${voucher.SalesObject.id} - ${voucher.SalesObject.text}`;
      }
      this.orderForm = await this.makeNewReturnsForm(voucher);
    }
  }

  async returnsPayment(returns: string) {
    const returnsObj = await this.apiService.getPromise<CommercePosReturnModel[]>('/purchase/return-vouchers/' + returns, {includeDetails: true, includeRelativeVouchers: true}).then(rs => rs[0]);
    let debitFunds = 0;
    if (returnsObj && returnsObj?.Details) {
      for (const detail of returnsObj.Details) {
        debitFunds += detail.Price * detail.Quantity;
      }
    }
    if (returnsObj.State !== this.paymentState) {
      this.cms.showToast('Phiếu trả hàng chưa được duyệt', 'Không thể trả hàng !', {...this.toastDefaultConfig, status: 'warning'});
      return false;
    }

    if (returnsObj.RelativeVouchers) {
      const refPayment = returnsObj.RelativeVouchers.find(f => f.type == 'CPOSPAYMENT');
      // Kiểm tra lại nó không cho hoàn tiền từ phiếu trả hàng
      // if (refPayment) {
      //   this.cms.showToast('Phiếu trả hàng này đã được hoàn tiền bởi phiếu chi ' + refPayment.id, 'Máy bán hàng', { status: 'danger', position: NbGlobalPhysicalPosition.BOTTOM_LEFT });
      //   return false;
      // }
      // const refOrder = returnsObj.RelativeVouchers.find(f => f.type == 'B2BPURCHASEORDER');
      // if (refOrder) {
      //   this.cms.showToast('Phiếu trả hàng này đã được cấn trừ trong đơn hàng ' + refOrder.id, 'Máy bán hàng', { status: 'danger', position: NbGlobalPhysicalPosition.BOTTOM_LEFT });
      //   return false;
      // }
    }

    const returnsPaymentVoucher: CommercePosCashVoucherModel = {
      Type: 'CPOSPAYMENT',
      SalesObject: returnsObj.SalesObject,
      SalesObjectName: returnsObj.SalesObjectName,
      SalesObjectPhone: returnsObj.SalesObjectPhone,
      SalesObjectEmail: returnsObj.SalesObjectEmail,
      SalesObjectAddress: returnsObj.SalesObjectAddress,
      DateOfVoucher: new Date(),
      Description: 'Hoàn tiền cho phiếu trả hàng' + returnsObj.Code,
      Returns: returnsObj.Code,
      RelativeVouchers: [{id: returnsObj.Code, text: returnsObj.Note || returnsObj.Code, type: 'COMMERCEPOSRETURN'}],
      Details: [
        {
          Description: 'Hoàn tiền cho phiếu trả hàng ' + returnsObj.Code,
          DebitAccount: '131',
          CreditAccount: '111',
          Amount: debitFunds,
          RelativeVoucher: returnsObj.Code
        }
      ]
    };

    return new Promise(resovle => {
      // Trả hàng
      // this.cms.openDialog(CommercePosPaymnentPrintComponent, {
      //   context: {
      //     skipPreview: true,
      //     data: [returnsPaymentVoucher],
      //     onSaveAndClose: async (newReturnsPayment, printComponent) => {
      //       printComponent.close();
      //       this.historyOrderIndex = this.historyOrders.length - 1;
      //       this.orderForm = this.historyOrders[this.historyOrderIndex] || await this.makeNewOrder();
      //       if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
      //     },
      //     onClose: () => {
      //     },
      //   }
      // });
    });
  }

  onChooseProduct(product: ProductModel) {
    (document.activeElement as HTMLElement).blur();
    this.searchEleRef.nativeElement.value = '';
    this.searchResults = null;
    this.barcodeProcess(null, {product: product});
  }

  chooseCustomer() {
    this.cms.openDialog(ContactAllListComponent, {
      context: {
        inputMode: 'dialog',
        onDialogChoose: async (chooseItems: ContactModel[]) => {
          console.log(chooseItems);
          const contact = chooseItems[0];
          if (contact) {
            this.orderForm.get('SalesObject').setValue({id: contact.Code, text: `${contact.Code} - ${contact.Name}`});
            this.orderForm.get('SalesObjectName').setValue(contact.Name);
            this.orderForm.get('SalesObjectPhone').setValue(contact.Phone);
          }
        },
        onAfterInit: (component: ContactAllListComponent) => {
          const commandColumn: ColDef = component.columnDefs.find(f => f.field == 'Command');
          if (commandColumn) {
            commandColumn.width = 200;
            commandColumn.maxWidth = 200;
            commandColumn.resizable = true;
            // commandColumn.cellRendererParams['buttons'] = [];
            // if (commandColumn.cellRendererParams['buttons']) {
            commandColumn.cellRendererParams['buttons'].push({
              name: 'Call',
              label: 'Gọi điện',
              status: 'danger',
              icon: 'phone-call-outline',
              outline: true,
              action: async (params: any, button: any) => {
                // this.cms.showToast('Hãy nhất máy khi điện thoại bàn reo, cuộc gọi sẽ được kết nối với khách hàng !');
                component.click2call(params.data);
                return true;
              }
            });
          }
        }
      }
    });
  }

  distributeMemberCard(orderForm: FormGroup) {
    this.cms.openDialog(DialogFormComponent, {
      context: {
        title: 'Phát hành thẻ thành viên',
        width: '600px',
        onInit: async (form, dialog) => {
          return true;
        },
        controls: [
          {
            name: 'MemberCard',
            label: 'ID thẻ thành viên',
            placeholder: 'Lấy thẻ thành viên chưa sử dụng quét và ID trên thẻ...',
            type: 'text',
            focus: true,
            initValue: '',
          },
          {
            name: 'DefaultThread',
            label: 'Luồng (công trình/dự án) mặc định',
            placeholder: 'Luồng sẽ được tự gán cho đơn hàng khi quét thẻ thành viên...',
            type: 'text',
            // initValue: '',
          },
          {
            name: 'DefaultPaymentMethod',
            type: 'select2',
            label: 'Luồng (công trình/dự án) mặc định',
            placeholder: 'Luồng sẽ được tự gán cho đơn hàng khi quét thẻ thành viên...',
            option: {
              data: this.paymentMethod,
              placeholder: 'Chọn phương thức thanh toán mặc định...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              withThumbnail: false,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
        ],
        actions: [
          {
            label: 'Trở về',
            icon: 'back',
            status: 'basic',
            action: async () => {
              return true;
            },
          },
          {
            label: 'Cấp phát',
            icon: 'npm-outline',
            status: 'success',
            // keyShortcut: 'Enter',
            action: async (form: FormGroup) => {

              let memberCard: string[] = form.get('MemberCard').value.trim();
              let defaultThread: string[] = form.get('DefaultThread').value.trim();
              let defaultPaymentMethod: string = this.cms.getObjectId(form.get('DefaultPaymentMethod').value).trim();

              if (memberCard) {
                let toastRef = null;
                try {
                  // ids = [...new Set(ids)];
                  this.loading = true;
                  if (this.cms.getObjectId(orderForm.value.SalesObject)) {
                    await this.apiService.putPromise('/marketing/member-cards/' + memberCard, {assign: true, contact: this.cms.getObjectId(orderForm.value.SalesObject)}, [{Code: memberCard, DefaultThread: defaultThread, DefaultPaymentMethod: defaultPaymentMethod}]);
                    // toastRef.close();
                    toastRef = this.cms.showToast('Thẻ thành viên đã được cấp phát cho khách hàng ' + this.cms.getObjectText(orderForm.value.SalesObject), 'Cấp phát thẻ thành công', {...this.toastDefaultConfig, status: 'success', duration: 10000});
                  }
                  this.loading = false;
                } catch (err) {
                  console.error(err);
                  this.loading = false;
                  toastRef.close();
                  toastRef = this.cms.showToast('Chưa thể cấp phát thẻ thành viên', 'Lỗi cấp phát thẻ thành viên', {...this.toastDefaultConfig, status: 'danger', duration: 30000});
                }
              }

              return true;
            },
          },
        ],
      },
    });
  }

  switchPaymentMethod(orderForm: FormGroup) {
    const paymnetMethodControl = orderForm.get('PaymentMethod');

    if (orderForm.value?.State == this.paymentState) {
      this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', {...this.toastDefaultConfig});
      return false;
    }

    const index = this.paymentMethod.findIndex(f => this.cms.getObjectId(f) === this.cms.getObjectId(paymnetMethodControl.value));
    if (index < 0 || index === this.paymentMethod.length - 1) {
      paymnetMethodControl.setValue(this.paymentMethod[0]);
    } else {
      paymnetMethodControl.setValue(this.paymentMethod[index + 1]);
    }

    this.cms.showToast('Phiếu này sẽ ghi nhận doanh thu ' + this.cms.getObjectText(paymnetMethodControl.value) + ' !', 'Ghi nhận doanh thu ' + this.cms.getObjectText(paymnetMethodControl.value), {...this.toastDefaultConfig, status: paymnetMethodControl.value?.status, duration: 5000});
    this.onCashReceiptChanged(orderForm);
    return true;
    // const debtControl = orderForm.get('IsDebt');
    // debtControl.setValue(!debtControl.value);
    // if (orderForm.value?.State == this.paymentState) {
    //   this.cms.showToast('Không thể thay đổi thông tin trên phiếu đã duyệt, hãy hủy phiếu trước khi thay đổi !', 'Phiếu đã duyệt', { ...this.toastDefaultConfig, });
    //   return false;
    // }
    // if (orderForm['voucherType'] == 'B2BPURCHASEORDER') {
    //   if (debtControl.value) {
    //     this.cms.showToast('Phiếu này sẽ ghi nhận doanh thu công nợ !', 'Ghi nhận doanh thu công nợ', { ...this.toastDefaultConfig, status: 'primary', duration: 1000 });
    //   } else {
    //     this.cms.showToast('Phiếu này sẽ ghi nhận doanh thu tiền mặt !', 'Ghi nhận doanh thu tiền mặt', { ...this.toastDefaultConfig, status: 'success', duration: 1000 });
    //   }
    // } else if (orderForm['voucherType'] == 'COMMERCEPOSRETURN') {
    //   if (debtControl.value) {
    //     this.cms.showToast('Phiếu này sẽ ghi giảm doanh thu công nợ !', 'Ghi giảm doanh thu công nợ', { ...this.toastDefaultConfig, status: 'primary', duration: 1000 });
    //   } else {
    //     this.cms.showToast('Phiếu này sẽ ghi giảm doanh thu tiền mặt !', 'Ghi giảm doanh thu tiền mặt', { ...this.toastDefaultConfig, status: 'success', duration: 1000 });
    //   }
    // }
    // if (debtControl.value) {
    //   orderForm.get('CashReceipt').disable();
    // } else {
    //   orderForm.get('CashReceipt').enable();
    // }
    // (document.activeElement as HTMLElement).blur();
    // return true;
  }

  async onMakeNewReturnsForm() {
    this.orderForm = await this.makeNewReturnsForm();
    // this.save(this.orderForm);
    this.historyOrders.push(this.orderForm);
    this.historyOrderIndex = this.historyOrders.length - 1;

    (document.activeElement as HTMLElement).blur();
    if (this.autoInputCashReceiptEleRef) this.autoInputCashReceiptEleRef.nativeElement.value = this.cms.currencyPipe.transform(this.orderForm.get('CashReceipt').value, 'VND');
  }

  async openDeploymentForm(orderForm: FormGroup) {
    const orderData: CommercePosOrderModel = orderForm.getRawValue();

    if (orderData?.Details?.length == 0) {
      this.cms.showToast('Không có gì để triển khai', 'Không thể triển khai cho đơn hàng rỗng !', {...this.toastDefaultConfig, status: 'warning', duration: 10000});
      return false;
    }

    await this.save(orderForm);
    this.cms.openDialog(DeploymentVoucherFormComponent, {
      context: {
        printDialog: PurchasePopDeploymentVoucherPrintComponent,
        data: [
          {
            ...orderData,
            IsDebt: false,
            Code: null,
            Title: `Triển khai cho đơn hàng POS ${orderData.Code}, khách hàng: ${orderData.SalesObjectName}`,
            DeploymentDate: new Date(),
            RelativeVouchers: [{
              type: 'B2BPURCHASEORDER',
              id: orderData.Code,
              text: orderData.Title || `Đơn hàng POS - ${orderData.Code}`,
            }],
            DirectReceiverName: orderData.SalesObjectName,
            DirectReceiverPhone: orderData.SalesObjectPhone,
            DeliveryAddress: orderData.SalesObjectAddress,
            Details: orderData.Details.map(detail => {
              const product = this.prdService.productUnitMap[this.cms.getObjectId(detail.PurchaseProduct) + '-' + this.cms.getObjectId(detail.PurchaseUnit)];
              product.id = product?.Code;
              product.text = product?.Name;
              detail.PurchaseProduct = product as any;
              return detail;
            }).filter(f => f.Product?.Type != 'SERVICE')
          }
        ],
        onDialogSave(newData) {
          const relativeVouchers = orderForm.get('RelativeVouchers');
          const relativeVouchersData: any[] = relativeVouchers.value || [];
          if (relativeVouchersData.findIndex(f => f.id == newData[0].Code) < 0) {
            relativeVouchersData.push({
              type: 'DEPLOYMENT',
              id: newData[0].Code,
              text: newData[0].Title
            });
          }
        },
      }
    });
  }

  preview(type: string, id: string) {
    this.cms.previewVoucher(type, id);
  }

  previewGoodsThumbnail(detail: FormGroup) {
    let images: any = detail.get('PurchaseImage').value;
    if (images && !Array.isArray(images)) {
      images = [images];
    }
    if (images && images.length > 0) {
      this.cms.openDialog(ImagesViewerComponent, {
        context: {
          images: images.map(m => m.OriginImage),
          imageIndex: 0
        }
      });
    }
  }

  async setThread(orderForm: FormGroup) {

    const threadBtns: DialogFormAction[] = [];

    // Load relative threads
    const objectId = this.cms.getObjectId(this.orderForm.get('SalesObject').value);
    if (objectId) {
      const contact = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + objectId, {includeDetailsAsKeyValue: true}).then(rs => rs[0]);
      if (contact?.Details?.THREAD) {
        for (const t in contact.Details.THREAD) {
          const thread = contact.Details.THREAD[t];
          threadBtns.push({
            label: thread,
            // icon: 'back',
            status: 'info',
            action: async () => {
              orderForm.get('Thread').setValue(thread);
              this.cms.showToast('Đơn hàng đã được gán vào luồng `' + thread + '`', 'Đã gán luồng cho đơn hàng', {...this.toastDefaultConfig, status: 'success', duration: 5000});
              return true;
            },
          });
        }
      }
    }

    this.cms.openDialog(DialogFormComponent, {
      context: {
        title: 'Gán luồng (theo dự án/công trình...)',
        width: '80vw',
        onInit: async (form, dialog) => {
          return true;
        },
        controls: [
          {
            name: 'Thread',
            label: 'Luồng mới',
            placeholder: 'Sử dụng để báo cáo theo dự án/công trình...',
            type: 'text',
            focus: true,
            initValue: orderForm.value['Thread'],
          },
        ],
        actions: [
          {
            label: 'Trở về',
            icon: 'back',
            status: 'basic',
            action: async () => {
              return true;
            },
          },
          {
            label: 'Gán',
            icon: 'npm-outline',
            status: 'success',
            keyShortcut: 'Enter',
            action: async (form: FormGroup) => {

              let thread: string[] = form.get('Thread').value.trim();

              if (thread) {
                orderForm.get('Thread').setValue(thread);
                this.cms.showToast('Đơn hàng đã được gán vào luồng `' + thread + '`', 'Đã gán luồng cho đơn hàng', {...this.toastDefaultConfig, status: 'success', duration: 5000});
              }
              return true;
            },
          },
          ...threadBtns,
        ],
      },
    });
  }

  openBarcodeProcessHistory() {
    this.cms.openDialog(AgDynamicListComponent, {
      context: {
        title: 'Lịch sử quét mã',
        // rowModelType: 'clientSide',
        // rowData: this.barcodeProcessHistory$,
        apiPath: '/purchase/barcode-scan-history',
        prepareApiParams: (params) => {
          // params['sort_Id'] = 'desc';
          params['includeProduct'] = true;
          return params;
        },
        idKey: ['Id'],
        height: '95vh',
        width: '95vw',
        columnDefs: [
          {
            ...agMakeSelectionColDef(this.cms),
            headerName: 'Stt',
            field: 'Id',
            width: 100,
            valueGetter: 'node.data.Id',
            // sortingOrder: ['desc', 'asc'],
            initialSort: 'desc',
            headerCheckboxSelection: true,
          },
          {
            headerName: 'Số truy xuất',
            field: 'AccessNumber',
            width: 150,
            filter: 'agTextColumnFilter',
            pinned: 'left',
          },
          {
            ...agMakeImageColDef(this.cms, null, (rowData) => {
              return rowData.Pictures?.map(m => m['LargeImage']);
            }),
            headerName: 'Hình',
            // pinned: 'left',
            field: 'FeaturePicture',
            valueGetter: 'node.data.FeaturePicture',
            width: 100,
          },
          {
            headerName: 'Mô tả',
            field: 'Description',
            // pinned: 'left',
            width: 300,
            filter: 'agTextColumnFilter',
            cellRenderer: AgTextCellRenderer,
          },
          {
            headerName: 'ĐVT',
            field: 'Unit',
            valueGetter: 'node.data.Unit',
            // pinned: 'left',
            width: 100,
            filter: 'agTextColumnFilter',
            cellRenderer: AgTextCellRenderer,
          },
          {
            headerName: 'Barcode',
            field: 'Barcode',
            width: 200,
            filter: 'agTextColumnFilter',
            // pinned: 'left',
          },
          {
            headerName: 'Đơn hàng liên quan',
            field: 'RelativeOrder',
            width: 150,
            filter: 'agTextColumnFilter',
            // pinned: 'left',
          },
          {
            headerName: 'Thời gian',
            field: 'DateOfScan',
            width: 180,
            filter: 'agDateColumnFilter',
            filterParams: {
              inRangeFloatingFilterDateFormat: 'DD/MM/YY',
            },
            cellRenderer: AgDateCellRenderer,
          },
        ]
      }
    });
  }

  chooseProduct() {
    let dialog = null;
    dialog = this.cms.openDialog(B2bPurchasePopChooseProductComponent, {
      context: {
        title: '',
        width: '98vw',
        height: '95vh',
        searchKey: this.searchEleRef.nativeElement.value,
        object: this.orderForm.get('SalesObject').value,
        onChooseProduct: (choosedProduct) => {
          console.log(choosedProduct);
          this.onChooseProduct(choosedProduct);
          dialog && dialog.close();

          // Reactive main screen
          this.searchEleRef.nativeElement.focus();
          this.searchEleRef.nativeElement.blur();
        },
      }
    });
    this.blurAll();
  }

  outOfStockReport(goodsInfo?: Model) {

    if (goodsInfo) {
      this.apiService.postPromise<Model[]>('/warehouse/goods-queues', {silent: true}, [{
        Goods: goodsInfo.Product,
        Unit: goodsInfo.Unit,
        UnitLabel: this.cms.getObjectText(goodsInfo.Unit),
        Name: goodsInfo.Description,
        Sku: goodsInfo.Sku,
        Queue: 'OUTOFSTOCK',
        Thumbnail: goodsInfo.Image,
        Container: goodsInfo.Container,
        ContainerShelf: this.cms.getObjectId(goodsInfo.Container?.Shelf),
        ContainerShelfName: goodsInfo.Container.ShelfName,
        ContainerFindOrder: goodsInfo.Container.FindOrder,
        Inventory: goodsInfo.Inventory,
      }]).then(rs => {

        this.cms.showToast('Đã báo hết hàng', 'Hàng hóa đã được đưa vào hàng đợi', {status: 'success'});

      }).catch(err => {

        if (err.error.errorCode == 1062) {
          this.cms.showToast('Hàng hóa đã có trong hàng đợi !', 'Đã có trong hàng đợi', {status: 'warning'});
        } else {
          this.cms.showError(err);
        }
        return Promise.reject(err);

      });
    } else {
      let dialog = null;
      dialog = this.cms.openDialog(B2bPurchasePopChooseProductComponent, {
        context: {
          title: 'Báo hết hàng',
          width: '98vw',
          height: '95vh',
          searchKey: this.searchEleRef.nativeElement.value,
          onChooseProduct: (choosedProduct) => {
            console.log(choosedProduct);

            dialog && dialog.close();
            // Reactive main screen
            this.searchEleRef.nativeElement.focus();
            this.searchEleRef.nativeElement.blur();

            this.apiService.postPromise<Model[]>('/warehouse/goods-queues', {silent: true}, [{
              Goods: choosedProduct.Code,
              Unit: choosedProduct.Unit,
              UnitLabel: this.cms.getObjectText(choosedProduct.Unit),
              Name: choosedProduct.Name,
              Sku: choosedProduct.Sku,
              Queue: 'OUTOFSTOCK',
              Thumbnail: choosedProduct.FeaturePicture,
              Pictures: choosedProduct.Pictures,
              Container: choosedProduct.Container,
              ContainerShelf: this.cms.getObjectId(choosedProduct.Container?.Shelf),
              ContainerShelfName: choosedProduct.Container.ShelfName,
              ContainerFindOrder: choosedProduct.Container.FindOrder,
              Inventory: choosedProduct.Inventory,
            }]).then(rs => {

              this.cms.showToast('Đã báo hết hàng', 'Hàng hóa đã được đưa vào hàng đợi', {status: 'success'});

            }).catch(err => {

              if (err.error.errorCode == 1062) {
                this.cms.showToast('Hàng hóa đã có trong hàng đợi !', 'Đã có trong hàng đợi', {status: 'warning'});
              } else {
                this.cms.showError(err);
              }
              return Promise.reject(err);

            });

          },
        }
      });
      this.blurAll();
    }
    return true;
  }

  openProductListDialog(orderForm: FormGroup) {
    this.cms.openDialog(ProductListComponent, {
      context: {
        stateIndex: 'pop_choose_product',
        inputMode: 'dialog',
        width: '99vw',
        height: '99vh',
        onInit: (component) => {
          console.log(component.actionButtonList);
          component.actionButtonList = component.actionButtonList.filter(f => ['close', 'refresh', 'reset', 'add', 'edit', 'choose'].indexOf(f.name) > -1);
        },
        onDialogChoose: async (products) => {
          console.log(products);
          for (const product of products) {
            if (product.UnitConversions.length > 1) {
              await new Promise((resolve, reject) => {
                this.cms.showDialog('Chọn đơn vị tính', 'Chọn đơn vị tính để thêm vào đơn đặt mua hàng',
                  [
                    {
                      label: 'Trở về',
                      status: 'basic',
                      action: async () => {
                        resolve(false);
                        return true;
                      },
                    },
                    ...product.UnitConversions.map(m => ({
                      label: this.cms.getObjectText(m.Unit),
                      status: 'info',
                      action: async () => {
                        this.onChooseProduct({
                          ...product,
                          Image: product.Pictures,
                          id: product.Code,
                          text: product.Name,
                          Unit: {
                            id: this.cms.getObjectId(m),
                            text: this.cms.getObjectText(m),
                          },
                        });
                        resolve(true);
                        return true;
                      },
                    })),
                  ], () => {
                    resolve(false);
                  });
              });
            } else {
              if (product.UnitConversions[0]) {
                this.onChooseProduct({
                  ...product,
                  id: product.Code,
                  text: product.Name,
                  Image: product.Pictures,
                  Unit: {
                    id: this.cms.getObjectId(product.UnitConversions[0]),
                    text: this.cms.getObjectText(product.UnitConversions[0]),
                  },
                });
              }
            }
          }
        },
      }
    });
  }

  openCreateProductDialog(orderForm: FormGroup) {
    let comp = null;
    comp = this.cms.openDialog(B2bNewPurchaseProductFormComponent, {
      context: {
        onDialogSave: (newProducts) => {
          console.log(newProducts);
          comp.close();
          if (newProducts[0]) {
            if (newProducts[0].UnitConversions.length > 1) {
              this.cms.showDialog('Chọn đơn vị tính', 'Chọn đơn vị tính để thêm vào đơn đặt mua hàng',
                [
                  {
                    label: 'Trở về',
                    status: 'basic',
                    action: async () => true,
                  },
                  ...
                    newProducts[0].UnitConversions.map(m => ({
                      label: this.cms.getObjectText(m.Unit),
                      status: 'info',
                      action: async () => {
                        this.onChooseProduct({
                          ...newProducts[0],
                          id: newProducts[0].Code,
                          text: newProducts[0].Name,
                          Unit: m.Unit,
                          LastPurchasePrice: newProducts[0].Price,
                        });

                        return true;
                      },
                    })),
                ]
              );
            } else {
              if (newProducts[0].UnitConversions[0]) {
                this.onChooseProduct({
                  ...newProducts[0],
                  id: newProducts[0].Code,
                  text: newProducts[0].Name,
                  Unit: newProducts[0].UnitConversions[0].Unit,
                  LastPurchasePrice: newProducts[0].Price,
                });
              }
            }
          }
        },
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
    return false;
  }

  click2call(order: FormGroup) {
    let toast = this.cms.showToast('Nhất máy nội bộ của bạn khi nó đỗ chuông để bắt đầu gọi !', 'Đang kết nối tới số nội bộ !', {...this.toastDefaultConfig, status: 'danger', duration: 0});
    this.apiService.putPromise(this.apiPath + '/' + order.get('Code').value, {click2call: true, DestPhone: order.get('SalesObjectPhone').value}, [{Code: order.get('Code').value}]).then(rs => {
      toast.close();
      this.cms.showToast('Hệ thống đang gọi tới số của khách hàng ' + order.get('SalesObjectPhone').value + ', vui lòng giữ máy !', 'Đang gọi cho khách hàng', {...this.toastDefaultConfig, status: 'success', duration: 15000});
      console.log(rs);
    });
  }

  onUploadBtnClick(orderForm: FormGroup) {
    const attachmentsControl = orderForm.get('Attachments');
    const attachmentsValue = attachmentsControl.value || [];
    this.cms.openDialog(MediaCaptureComponent, {
      context: {
        maxFileCount: 6,
        onUploaded: (files) => {
          for (const file of files) {
            attachmentsValue.push(file);
            attachmentsControl.setValue(attachmentsValue);
          }
        },
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
    return false;
  }

  playNewPipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-08b.wav');
    sound.play();
  }

  playIncreasePipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
    sound.play();
  }

  playDecreasePipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
    sound.play();
  }

  playErrorPipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-03.wav');
    sound.play();
  }

  playPaymentSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/benboncan_till-with-bell.wav');
    sound.play();
  }
}
