import {Component, OnInit} from '@angular/core';
import {DataManagerFormComponent} from "../../../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../../../models/model";
import {RootServices} from "../../../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ApiService} from "../../../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../../../services/common.service";
import {AdminProductService} from "../../../../../admin-product/admin-product.service";
import {ITreeState} from "angular-tree-component";
import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../../../../../environments/environment";

@Component({
  selector: 'ngx-io-workflow-webhook-node-form',
  templateUrl: './io-workflow-webhook-node-form.component.html',
  styleUrls: ['./io-workflow-webhook-node-form.component.scss']
})
export class IoWorkflowWebhookNodeFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'IoWorkflowWebhookNodeFormComponent';
  idKey = 'SystemUuid';
  apiPath = '/io/workflow/nodes';
  baseFormUrl = '/io/webhook/form';
  env = environment;

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<IoWorkflowWebhookNodeFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  input = {
    params: {token: 'j23h-5k23j-4k23-hj4-k23j-h4k2-3h4k-32h4-k2j4'},
    headers: {method: 'POST'},
    body: [
      {
        name: 'abc', phone: '023452354', address: '123 abc', details:
          [
            {product: '235346342', name: 'xyz1', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz2', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz3', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz4', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz5', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
          ],
      },
    ]
  };
  inputTreeOptions = {};
  inputTreeState: ITreeState = {};

  output = {
    params: {token: 'j23h-5k23j-4k23-hj4-k23j-h4k2-3h4k-32h4-k2j4'},
    headers: {method: 'POST'},
    body: [
      {
        name: 'abc', phone: '023452354', address: '123 abc', details:
          [
            {product: '235346342', name: 'xyz1', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz2', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz3', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz4', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
            {product: '235346342', name: 'xyz5', unit: 'CAI', quantity: 100, price: 250000, toMoney: 25000000},
          ],
      },
    ]
  };
  outputTreeOptions = {};
  outputTreeState: ITreeState = {};

  onInputTreeNodeForce(e) {

  }

  getRequestId(callback: (id?: string[]) => void) {
    callback(this.inputId);
  }


  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    return super.init();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      SystemUuid: [],
      Type: [],
      Name: [],
      Description: [],

      // hook info
      HttpMethod: [],
      Path: [],
      WebhookUrl: {value: null, disabled: true},
      AuthType: [],
      Credential: [],
      Respond: [],

    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  listenHook() {
    return true;
  }

  // Hàm lấy giá trị theo đường dẫn, hỗ trợ '.' và '[]'
  private getValueByPath(obj: any, path: string): any {
    const regex = /(?:\.?([\w$]+)|\[['"]?([\w$]+)['"]?\])/g;
    let match: RegExpExecArray | null;
    while ((match = regex.exec(path))) {
      const key = match[1] || match[2]; // Ưu tiên key từ '.' hoặc từ '[]'
      if (obj && obj.hasOwnProperty(key)) {
        obj = obj[key];
      } else {
        return undefined; // Không tìm thấy giá trị
      }
    }
    return obj;
  }

  dynamicExpressionPipe(template: string, context: any): string {
    if (!template) return '';

    return template.replace(/{{\s*([\w.\[\]'"]+)\s*}}/g, (_, path) => {
      try {
        // Chuyển đường dẫn thành giá trị, hỗ trợ cả '.' và '[]'
        const value = this.getValueByPath(context, path);
        return value !== undefined ? JSON.stringify(value) : '';
      } catch {
        return ''; // Trả về chuỗi rỗng nếu có lỗi
      }
    });
  }

  async save(): Promise<Model[]> {
    const results = this.getRawFormData().array;
    this.onAfterUpdateSubmit(results);
    return results;
  }
}
