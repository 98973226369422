<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
      (keydown.enter)="$event.preventDefault()" autocomplete="off">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Thông tin Core BM' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <label class="label">Thông tin khách hàng</label>
            <div class="row dashed-radius-border">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Customer" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false" [customIcons]="objectControlIcons">
                  <ngx-select2 formControlName="Customer" [select2Option]="select2OptionForContact" (selectChange)="onCustomerChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="CustomerName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="CustomerName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="CustomerPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="CustomerPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="CustomerEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="CustomerEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="CustomerAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="CustomerAddress" placeholder="{{'Common.address' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="CustomerTaxCode" label="Mã số thuế" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="CustomerTaxCode" placeholder="{{'Mã số thuế' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Note'), 'warning')">
                  <label class="label"><span class="valid">Ghi chú</span></label>
                  <input type="text" nbInput fullWidth formControlName="Note" placeholder="Ghi chú">
                </div>
              </div>
            </div>
            <br>
            <label class="label">Thông tin triển khai</label>
            <div class="dashed-radius-border tab-set-wrapper">
              <nb-tabset>
                <nb-tab tabTitle="{{'Hosting' | translate | headtitlecase}} (*)">
                  <div class="row">
                    <div class="col-sm-6">
                      <ngx-form-group [formGroup]="formItem" name="Hosting" label="Hosting" [array]="array" [index]="i" [allowCopy]="false">
                        <ngx-select2 [data]="hostingList" formControlName="Hosting" [select2Option]="hostingListConfig">
                        </ngx-select2>
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-6">
                      <ngx-form-group [formGroup]="formItem" name="DomainName" label="Tên miền" [array]="array" [index]="i" [allowCopy]="false">
                        <input type="text" nbInput fullWidth formControlName="DomainName" placeholder="Tên miền">
                      </ngx-form-group>
                    </div>
                  </div>
                </nb-tab>
                <nb-tab tabTitle="{{'Tổng đài PBX' | translate | headtitlecase}}">
                  <div class="row">
                    <div class="col-sm-6">
                      <ngx-form-group [formGroup]="formItem" name="Pbx" label="Tổng đài" [array]="array" [index]="i" [allowCopy]="false">
                        <ngx-select2 [data]="pbxList" formControlName="Pbx" [select2Option]="select2OptionForPbxList" (ngModelChange)="onPbxChange($event, formItem, i)">
                        </ngx-select2>
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-6">
                      <ngx-form-group [formGroup]="formItem" name="PbxGateway" label="Gateway" [array]="array" [index]="i" [allowCopy]="false">
                        <nb-select size="medium" formControlName="PbxGateway" fullWidth selected="">
                          <nb-option value="">Chọn gateway...</nb-option>
                          <nb-option *ngFor="let gateway of gatewaylist" [value]="gateway.id">{{ gateway.text }}</nb-option>
                        </nb-select>
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-3">
                      <ngx-form-group [formGroup]="formItem" name="PbxDomainName" label="Tên miền" [array]="array" [index]="i" [allowCopy]="false">
                        <input type="text" nbInput fullWidth formControlName="PbxDomainName" placeholder="Tên miền">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-3">
                      <ngx-form-group [formGroup]="formItem" name="PbxPstnNumber" label="Số đấu nối" [array]="array" [index]="i" [allowCopy]="false">
                        <input type="text" nbInput fullWidth formControlName="PbxPstnNumber" placeholder="Số đấu nối">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-3">
                      <ngx-form-group [formGroup]="formItem" name="PbxExtensions" label="Số mở rộng" [array]="array" [index]="i" [allowCopy]="false">
                        <input type="text" nbInput fullWidth formControlName="PbxExtensions" placeholder="Số mở rộng, vd: 101,102,103,104,105/101-105">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-3">
                      <ngx-form-group [formGroup]="formItem" name="PbxPrivateNumber" label="Số liên tổng đài" [array]="array" [index]="i" [allowCopy]="false">
                        <input type="text" nbInput fullWidth formControlName="PbxPrivateNumber" placeholder="Số liên tổng đài">
                      </ngx-form-group>
                    </div>
                  </div>
                </nb-tab>
              </nb-tabset>
            </div>
            <nb-progress-bar [value]="progressBarValue" [status]="progressBarStatus">{{ processBarlabel }}</nb-progress-bar>
            <br>
            <label class="label">Thông tin chung</label>
            <div class="row dashed-radius-border">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Tên core..." [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên core (tự dộng tạo)...">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Mô tả" [array]="array" [index]="i" [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="Description"
                         placeholder="Mô tả (tự dộng tạo)...">
                </ngx-form-group>
              </div>
            </div>
            <!--<br>
            <label class="label">Thông tin kết nối</label>
            <div class="row dashed-radius-border">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ApiUrl" label="Api Url" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="Api Url">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ApiVersion" label="Api Version" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="ApiVersion" placeholder="Api Version">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Version" label="Version" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="Version" placeholder="Version">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="License" label="License" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <input type="text" nbInput fullWidth formControlName="License" placeholder="License">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="ApiUser" label="Api User" [array]="array" [index]="i" [allowCopy]="false">
                    <input type="text" nbInput fullWidth formControlName="ApiUser" placeholder="Api User">
                  </ngx-form-group>
              </div>
              <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="ApiPassword" label="Api Password" [array]="array" [index]="i" [allowCopy]="false">
                    <input type="password" nbInput fullWidth formControlName="ApiPassword" placeholder="Api Password">
                  </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Enabled" label="Kích hoạt" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <nb-checkbox formControlName="Enabled">Kích hoạt</nb-checkbox>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AutoUpdate" label="Kích hoạt" [array]="array" [index]="i"
                                [allowCopy]="false">
                  <nb-checkbox formControlName="AutoUpdate">Auto update</nb-checkbox>
                </ngx-form-group>
              </div>
            </div>
            -->
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
