import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class WarehouseStateMap {


  static INQUEUE: ProcessMap = {
    state: 'INQUEUE',
    label: 'Trong hàng đợi',
    confirmLabel: 'Đẩy vào hàng đợi',
    status: 'warning',
    color: 'orange',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy vào hàng đợi',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy vào hàng đợi',
  };

  static SENT2B2BORDER: ProcessMap = {
    state: 'SENT2B2BORDER',
    label: 'Đặt hàng B2B',
    confirmLabel: 'Đẩy qua B2B',
    status: 'primary',
    color: 'blue',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy qua B2B Center',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy qua B2B Center',
  };

  static SENT2INVENTORYADJUST: ProcessMap = {
    state: 'SENT2INVENTORYADJUST',
    label: 'Kiểm kho',
    confirmLabel: 'Đẩy qua kiểm kho',
    status: 'danger',
    color: 'red',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy qua kiểm kho',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy qua kiểm kho',
  };

  // static INVENTORYADJUST: ProcessMap = {
  //   state: 'INVENTORYADJUST',
  //   label: 'Kiểm kho',
  //   confirmLabel: 'Đẩy qua kiểm kho',
  //   status: 'danger',
  //   color: 'red',
  //   outline: true,
  //   confirmTitle: 'Chuyển trạng thái',
  //   confirmText: 'Bạn có muốn đẩy qua kiểm kho',
  //   responseTitle: 'Chuyển trạng thái',
  //   responseText: 'Đã đẩy qua kiểm kho',
  // };

  static OUTQUEUE: ProcessMap = {
    state: 'OUTQUEUE',
    label: 'Ngoài hàng đợi',
    confirmLabel: 'Đẩy ra khỏi hàng đợi',
    status: 'basic',
    color: 'white',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy ra khỏi hàng đợi',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy ra khỏi hàng đợi',
  };

  static goodsQueueStateMap: { [key: string]: ProcessMap } = {
    'INQUEUE': {
      ...WarehouseStateMap.INQUEUE,
      nextState: 'SENT2B2BORDER',
      nextStates: [
        WarehouseStateMap.SENT2B2BORDER,
      ],
    },
    'SENT2B2BORDER': {
      ...WarehouseStateMap.SENT2B2BORDER,
      nextState: 'SENT2INVENTORYADJUST',
      nextStates: [
        WarehouseStateMap.SENT2INVENTORYADJUST,
      ],
    },
    'SENT2INVENTORYADJUST': {
      ...WarehouseStateMap.SENT2INVENTORYADJUST,
      nextState: 'OUTQUEUE',
      nextStates: [
        WarehouseStateMap.OUTQUEUE,
      ],
    },
    'OUTQUEUE': {
      ...WarehouseStateMap.OUTQUEUE,
      nextState: 'INQUEUE',
      nextStates: [
        WarehouseStateMap.INQUEUE,
      ],
    },
  };

}
