import {Component, OnInit} from '@angular/core';
import {DataManagerFormComponent} from "../../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../../models/model";
import {RootServices} from "../../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../../services/common.service";
import {AdminProductService} from "../../../../admin-product/admin-product.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Select2Option} from "../../../../../lib/custom-element/select2/select2.component";
import {filter, take, takeUntil} from "rxjs/operators";

@Component({
  selector: 'ngx-system-webhook-form',
  templateUrl: './system-webhook-form.component.html',
  styleUrls: ['./system-webhook-form.component.scss']
})
export class SystemWebhookFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'SystemApiTokenFormComponent';
  idKey = 'SystemUuid';
  apiPath = '/system/hook/webhooks';
  baseFormUrl = '/system/hook/webhook/form';

  // eventList = [];
  moduleList: any[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<SystemWebhookFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  select2OptionForModule: Select2Option = {
    placeholder: 'Chọn module...',
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2OptionForEvent: Select2Option = {
    placeholder: 'Chọn sự kiện...',
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    await this.apiService.getPromise<any[]>(this.apiPath + '/module-events').then(definedModules => {
      this.moduleList = definedModules.filter(f => f.events && f.events.length > 0);
    });
    return super.init();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    let newForm: FormGroup;
    newForm = this.formBuilder.group<any>({
      SystemUuid: [],
      Module: [null, Validators.required],
      Event: [null, Validators.required],
      Method: [null, Validators.required],
      Target: [null, Validators.required],
      AuthType: [],
      AuthData: [null, (control: AbstractControl) => {
        if (newForm && newForm.get('AuthType').value == 'JWT' && !control.value) {
          return {invalidName: true, required: true, text: 'trường bắt buộc'};
        }
      }],
    });
    if (data) {
      newForm.patchValue(data);
    }
    newForm.get('AuthType').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log(newForm.valid);
    });
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  async save(): Promise<Model[]> {
    return super.save().then(rs => {
      this.adminProductService.updateGroupList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  }

  generateSecretString(formItem: FormGroup) {
    const length = 32; // Độ dài tối thiểu là 32 ký tự
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = "*&!@%^#$";
    const allChars = uppercase + lowercase + numbers + specialChars;

    // Đảm bảo có ít nhất một ký tự từ mỗi nhóm yêu cầu
    let result = "";
    result += uppercase[Math.floor(Math.random() * uppercase.length)];
    result += lowercase[Math.floor(Math.random() * lowercase.length)];
    result += numbers[Math.floor(Math.random() * numbers.length)];
    result += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Thêm các ký tự ngẫu nhiên để đạt độ dài yêu cầu
    for (let i = result.length; i < length; i++) {
      result += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Xáo trộn chuỗi để tránh vị trí cố định của các ký tự đầu tiên
    result = result.split('').sort(() => Math.random() - 0.5).join('');
    formItem.get('AuthData').setValue(result);
    return result;
  }

}
