<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading" style="max-height: initial; margin: 0 auto; width: 92mm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {}}" [size]="size" [icon]="favicon" [title]="''" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area">
        <div class="bill" *ngFor="let order of data">
          <div class="bill-header">
            <div class="bill-register-info">
              <div class="bill-register-logo"><img [src]="registerInfo?.posBillLogo || registerInfo.voucherLogo"></div>
              <div class="bill-register-name">{{ registerInfo.companyName }}</div>
              <div class="bill-register-tax-code" *ngIf="registerInfo.companyTaxCode">MST: {{ registerInfo.companyTaxCode }}</div>
              <div class="bill-register-email" *ngIf="registerInfo.email">Email: {{ registerInfo.email }}</div>
              <div class="bill-register-website" *ngIf="registerInfo.website">Website: {{ registerInfo.website }}</div>
              <div class="bill-register-tel" *ngIf="registerInfo.tel">ĐT: {{ registerInfo.tel }}</div>
              <div class="bill-register-address" *ngIf="registerInfo.address">ĐC: {{ registerInfo.address }}</div>
            </div>
            <div class="bill-title">{{ title }}</div>
            <div class="bill-info">{{ order.Code }} - {{ order.DateOfSale | date:'short' }} - <span class="bill-state" *ngIf="order.State == 'APPROVED'">đã duyệt</span></div>
            <div class="barcode" style="text-align: center;">
              <div>
                <ngx-barcode [bc-value]="order.Code" [bc-display-value]="true" [bc-format]="'CODE128'" [bc-height]="25" [bc-width]="1.5" [bc-margin]="2" [bc-text-align]="'center'" [bc-text-position]="'bottom'"></ngx-barcode>
              </div>
            </div>
            <div class="bill-head-info">
              <div class="bill-object-name">Khách hàng: {{ order.ObjectName || '' }}</div>
              <div class="bill-object-phone">Số điện thoại: {{ order.ObjectPhone || '' }}</div>
              <div class="bill-object-address">Địa chỉ: {{ order.ObjectAddress || '' }}</div>
              <div class="bill-object-email">Email: {{ order.ObjectEmail || '' }}</div>
            </div>
          </div>
          <div class="bill-body">
            <table style="width: 100%;" border="0" cellPadding="2">
              <thead>
              <td>Hàng hóa/Dịch vụ</td>
              <td style="text-align: right;" class="nowrap">Đ.Giá x SL</td>
              <td style="text-align: right;" class="nowrap">Thành tiền</td>
              </thead>
              <tr *ngFor="let detail of order.Details">
                <td>
                  <div>{{ detail.Description }} - SKU:{{ detail.Sku }}
                    <ng-container *ngIf="detail.Variants && detail.Variants.length > 0">
                      <div>
                        <span style="white-space: nowrap;" *ngFor="let variant of detail.Variants">{{ variant | objecttext }}: {{ variant.value }}</span>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="detail.AccessNumbers">Số truy xuất:{{ detail.AccessNumbers | objectstext }}</div>
                </td>
                <td style="text-align: right">
                  <div *ngIf="detail.DiscountPercent > 0" class="bill-price"><span style="text-decoration: line-through;">{{ detail.ListedPrice | currency:'VND' }}</span></div>
                  <div class="bill-price">{{ detail.Price | currency:'VND' }}</div>
                  <div class="bill-quantity" style="white-space: nowrap;">x {{ detail.Quantity | number }} {{ detail.Unit | objecttext }}</div>
                </td>
                <td style="text-align: right; white-space: nowrap;">
                  <div class="bill-tomoney">{{ detail.ToMoney | currency:'VND' }}</div>
                </td>
              </tr>
              <tr>
                <td style="text-align: right; font-weight: bold;">{{ (order.PaymentMethod | objectid) === 'DEBT' ? 'Công nợ: ' : 'Tổng cộng: ' }}</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.Total | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="order.Returns">
                <td style="text-align: right; font-weight: bold;">Cấn trừ tiền trả hàng (phiếu {{ order.Returns }}):</td>
                <td colspan="2" style="text-align: right; font-weight: bold;">-{{ order.DebitFunds | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="order.DecreaseForTotal > 0">
                <td style="text-align: right; font-weight: bold;">Giảm giá tổng đơn:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">-{{ order.DecreaseForTotal | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="order.Returns">
                <td *ngIf="order.Total - order.DecreaseForTotal >= order.DebitFunds" style="text-align: right; font-weight: bold;">Phải thu:</td>
                <td colspan="2" *ngIf="order.Total - order.DecreaseForTotal >= order.DebitFunds" style="text-align: right; font-weight: bold;">{{ order.Total - order.DebitFunds - order.DecreaseForTotal | currency:'VND' }}</td>
                <td *ngIf="order.Total - order.DecreaseForTotal < order.DebitFunds" style="text-align: right; font-weight: bold;">Trả lại:</td>
                <td colspan="2" *ngIf="order.Total - order.DecreaseForTotal < order.DebitFunds" style="text-align: right; font-weight: bold;">{{ order.DebitFunds - order.Total + order.DecreaseForTotal | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'CASH' && order.DecreaseForTotal > 0">
                <td style="text-align: right; font-weight: bold;">Còn lại:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ (order.Total - order.DecreaseForTotal) | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'MIXED'">
                <td style="text-align: right; font-weight: bold;">Tiền mặt:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.CashAmount | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'MIXED' && order.CashTransferAmount">
                <td style="text-align: right; font-weight: bold;">Chuyển khoản:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.CashTransferAmount | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'MIXED' && order.CashBack">
                <td style="text-align: right; font-weight: bold;">Thối lại:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.CashBack | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'MIXED' && order.Debit > 0">
                <td style="text-align: left; font-weight: bold;">Công nợ:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.Debit | currency:'VND' }}</td>
              </tr>
              <tr *ngIf="(order.PaymentMethod | objectid) === 'MIXED' && order.Credit > 0">
                <td style="text-align: left; font-weight: bold;">Ứng trước:</td>
                <td colspan="2" style="text-align: right; font-weight: bold; white-space: nowrap;">{{ order.Credit | currency:'VND' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
              </tr>
            </table>
          </div>
          <br>
          <div class="bill-footer" style="text-align: left;">
            <div>Quý khách giữ lại tem mã vạch trên hàng hóa trong trường hợp đổi trả!</div>
            <div style="font-style: italic; padding-top: 3px; padding-bottom: 3px; font-weight: bold; font-size: 14px;">Cảm ơn Quý Khách và hẹn gặp lại</div>
            <div>Phiếu được in từ phần mềm<br>ProBox One - Phần mềm quản trị cộng tác viên chuyên sâu.</div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <!-- <button nbButton hero status="danger" (click)="close()">
            <nb-icon pack="eva" icon="close"></nb-icon>
        </button> -->
        <button #paymentBtn nbButton [disabled]="isProcessing" hero *ngIf="type == 'PRICEREPORT' && data[0].State != 'PRICEREPORT'" status="primary" (click)="saveAndPrint(0)">
          <nb-icon pack="eva" icon="printer-outline"></nb-icon>
          {{ 'Báo giá (Enter)' | translate | headtitlecase }}
        </button>
        <button #paymentBtn nbButton [disabled]="isProcessing" hero *ngIf="data[0].State != 'APPROVED' && type != 'PRICEREPORT'" status="success" (click)="payment(0)">
          <nb-icon pack="eva" icon="printer-outline"></nb-icon>
          {{ 'In (Enter)' | translate | headtitlecase }}
        </button>
        <button #printBtn nbButton hero *ngIf="data[0].State == 'APPROVED' && type != 'PRICEREPORT'" status="primary" (click)="print(0, 'RETAILINVOICE')">
          <nb-icon pack="eva" icon="printer-outline"></nb-icon>
          {{ 'In HD (F9)' | translate | headtitlecase }}
        </button>
        <button #priceReportPrintBtn nbButton hero *ngIf="data[0].State == 'APPROVED' || data[0].State == 'PRICEREPORT'" status="info" (click)="print(0, 'PRICEREPORT')">
          <nb-icon pack="eva" icon="printer-outline"></nb-icon>
          {{ 'In BG (Enter)' | translate | headtitlecase }}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>