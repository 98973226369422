import { agMakeCheckboxColDef } from './../../../../lib/custom-element/ag-list/column-define/checkbox.define';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { CommonService } from '../../../../services/common.service';
import { IdentityCardVerificationPrintComponent } from '../identity-card-verification-print/identity-card-verification-print.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { filter, take } from 'rxjs/operators';
import { PageModel } from '../../../../models/page.model';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { AppModule } from '../../../../app.module';
import { AgDynamicListComponent } from '../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { ContactModel } from '../../../../models/contact.model';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'ngx-identity-card-verification-list',
  templateUrl: './identity-card-verification-list.component.html',
  styleUrls: ['./identity-card-verification-list.component.scss']
})
export class IdentityCardVerificationListComponent extends AgGridDataManagerListComponent<ContactModel, DataManagerFormComponent<ContactModel>> implements OnInit {

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Contact', text: 'Liên hệ' },
    Feature: { id: 'IdentityCardVerification', text: 'Xác minh CCCD' }
  };

  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }

  componentName: string = 'IdentityCardVerificationListComponent';
  formPath = '/contact/identity-card-verification/form';
  apiPath = '/contact/identity-card-verifications';
  idKey = ['Code'];

  printDialog = IdentityCardVerificationPrintComponent;
  // formDialog = IdentityCardVerificationFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  queue = [];


  // static processingMap = AppModule.processMaps.common;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<IdentityCardVerificationListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {
      await this.cms.waitForLanguageLoaded();

      this.actionButtonList = this.actionButtonList.filter(f => ['add', 'delete', 'edit'].indexOf(f.name) < 0);

      // this.actionButtonList.unshift({
      //   type: 'button',
      //   name: 'queueAction',
      //   status: 'info',
      //   label: 'Hàng đợi',
      //   title: 'Xem hàng đợi',
      //   size: 'medium',
      //   icon: 'layers-outline',
      //   disabled: () => {
      //     return this.queue.length == 0;
      //   },
      //   click: () => {
      //     // this.queueParams = {};
      //     // this.queueParams['eq_' + this.idKey[0]] = '[' + this.queue.map(m => this.makeId(m)).join(',') + ']';
      //     // this.reset();
      //   }
      // });

      // Add page choosed

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          headerName: 'Profile',
          field: 'User',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Số điện thoại',
          field: 'Phone',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Email',
          field: 'Email',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/groups', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ghi chú',
          field: 'Note',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCheckboxColDef(this, this.cms),
          headerName: 'CCCD',
          field: 'IsIdentityCardVerfied',
          width: 100,
          pinned: 'right',
          cellRendererParams: {
            disabled: true,
          }
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'verify',
              title: 'Xác minh',
              icon: 'credit-card-outline',
              status: 'danger',
              outline: false,
              action: async (params) => {
                this.preview([params?.data]);
                return true;
              }
            }
          ],
            {
              onInit: (params, cellCompoent?: ICellRendererAngularComp) => {
                if (params?.buttons) {
                  for (const i in params.buttons) {
                    params.buttons[i].status = params?.data?.IsIdentityCardVerfied ? 'success' : 'danger';
                  }
                }
              }
            }
          ),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }
  queueParams = {};
  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeGroups'] = true;
    params['includePhoneVerification'] = true;
    params['includeEmailVerification'] = true;
    params['includeIdentityCardInfo'] = true;
    return {
      ...params,
      ...this.queueParams
    };
  }

  /** Implement required */
  // openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
  //   this.cms.openDialog(IdentityCardVerificationFormComponent, {
  //     context: {
  //       inputMode: 'dialog',
  //       inputId: ids,
  //       onDialogSave: (newData: ContactModel[]) => {
  //         if (onDialogSave) onDialogSave(newData);
  //       },
  //       onDialogClose: () => {
  //         if (onDialogClose) onDialogClose();
  //       },
  //     },
  //   });
  //   return false;
  // }

  onGridReady(params) {
    super.onGridReady(params);
  }

  // onChangePage(page: PageModel) {
  //   if (page !== null) {
  //     this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
  //     this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
  //       this.refresh();
  //     });
  //   }
  // }

  // async preview<T>(data: ContactModel[], source?: string, userConfig?: Partial<NbDialogConfig<Partial<T> | string>>) {
  //   window.open(this.apiPath + '/'+this.makeId(data[0])+'?renderPdfQuotation=download', '_blank');
  //   return false;
  // }
}
