import {CurrencyPipe} from "@angular/common";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NbToastrService, NbDialogService, NbDialogRef, NbThemeService} from "@nebular/theme";
import {GridApi, ColumnApi, ColDef, IRowNode, HeaderCheckboxSelectionCallbackParams} from "@ag-grid-community/core";
import {AgTextCellRenderer} from "../../../../../lib/custom-element/ag-list/cell/text.component";
import {agMakeImageColDef} from "../../../../../lib/custom-element/ag-list/column-define/image.define";
import {agMakeSelectionColDef} from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import {DataManagerFormComponent} from "../../../../../lib/data-manager/data-manager-form.component";
import {CollaboratorBasicStrategyModel, CollaboratorBasicStrategyProductModel} from "../../../../../models/collaborator.model";
import {ProductUnitModel, ProductModel} from "../../../../../models/product.model";
import {ApiService} from "../../../../../services/api.service";
import {CommonService} from "../../../../../services/common.service";
import {RootServices} from "../../../../../services/root.services";
import {AgDynamicListComponent} from "../../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import {B2bCenterService} from "../../../b2b-center.service";
import {Select2Option} from "../../../../../lib/custom-element/select2/select2.component";
import {agMakeTextColDef} from "../../../../../lib/custom-element/ag-list/column-define/text.define";
import {AgSelect2Filter} from "../../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import {B2bRefServerProductListComponent} from "../b2b-server-ref-product-list/b2b-server-ref-product-list.component";
import {agMakeCommandColDef} from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import {ProductListComponent} from "../../../../admin-product/product/product-list/product-list.component";
import {agMakeIdColDef} from "../../../../../lib/custom-element/ag-list/column-define/id.define";
import {AgCellButton} from "../../../../../lib/custom-element/ag-list/cell/button.component";
import {DialogFormComponent} from "../../../../../lib/component/dialog/dialog-form/dialog-form.component";
import {agMakeCurrencyColDef} from "../../../../../lib/custom-element/ag-list/column-define/currency.define";
import {agMakeNumberColDef} from "../../../../../lib/custom-element/ag-list/column-define/number.define";
import {AdminProductService} from "../../../../admin-product/admin-product.service";
import {UnitModel} from "../../../../../models/unit.model";
import {CustomHeader} from "../../../../../lib/custom-element/ag-list/header/custom.component";
import {agMakeCheckboxColDef} from "../../../../../lib/custom-element/ag-list/column-define/checkbox.define";
import {map, take} from "rxjs/operators";

class ActionModel {
  id: string;
  text: string;
  status: string;
}

@Component({
  selector: 'ngx-b2b-import-product-from-platform',
  templateUrl: './import-b2b-product.component.html',
  styleUrls: ['./import-b2b-product.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class B2bImportProductFromPlatform extends DataManagerFormComponent<CollaboratorBasicStrategyModel> implements OnInit, OnDestroy {

  feature = {
    Module: {id: 'B2bCenter', text: 'B2B Center'},
    Feature: {id: 'ProductMap', text: 'Liên kết sản phẩm'}
  };

  componentName: string = 'B2bImportProductFromPlatform';
  idKey = ['Code'];
  apiPath = 'xxx';
  baseFormUrl = '/collaborator/basic-strategy/form';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  @ViewChild('file', {static: true}) file: ElementRef;

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    private http: HttpClient,
    public ref?: NbDialogRef<B2bImportProductFromPlatform>,
    public b2bCenterService?: B2bCenterService,
    public adminProductSrevice?: AdminProductService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'ID',
        field: 'Id',
        width: 100,
        valueGetter: 'node.data.RefProduct',
        // sortingOrder: ['desc', 'asc'],
        initialSort: 'desc',
        headerCheckboxSelection: true,
      },
      {
        ...agMakeImageColDef(this.cms, null, (rowData) => {
          return rowData.Pictures?.map(m => m['LargeImage']);
        }),
        headerName: 'Hình đối tác',
        pinned: 'left',
        field: 'RefFeaturePicture',
        width: 100,
      },
      {
        ...agMakeImageColDef(this.cms, null, (rowData) => {
          return rowData.Pictures?.map(m => m['LargeImage']);
        }),
        headerName: 'Hình nội bộ',
        pinned: 'left',
        field: 'FeaturePicture',
        width: 100,
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'ID SP đối tác',
        field: 'RefProduct',
        width: 140,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
        valueGetter: params => this.cms.getObjectId(params?.data?.RefProduct),
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'ID SP nội bộ',
        field: 'Product',
        width: 140,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
        valueGetter: params => this.cms.getObjectId(params?.data?.Product),
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Sku Đối tác',
        field: 'RefSku',
        // pinned: 'left',
        width: 120,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Sku nội bộ',
        field: 'Sku',
        // pinned: 'left',
        width: 120,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Tên sản phẩm đối tác',
        field: 'RefProductName',
        // pinned: 'left',
        width: 250,
        filterParams: {
          textMatcher: ({filter, value, filterText}) => {
            return this.cms.smartFilter(value, filterText);
          }
        }
      },
      // {
      //   ...agMakeTextColDef(this.cms),
      //   headerName: 'Tên sản phẩm nội bộ',
      //   field: 'ProductName',
      //   // pinned: 'left',
      //   width: 250,
      // },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Tên sản phẩm nội bộ',
        field: 'ProductName',
        width: 250,
        filterParams: {
          textMatcher: ({filter, value, filterText}) => {
            return this.cms.smartFilter(value, filterText);
          }
        },
        cellRendererParams: {
          coalesceButton: {
            label: 'Chọn sản phẩm liên kết',
            // icon: '',
            status: 'danger',
            outline: true,
            disabled: (data, params) => false,
            action: async (params, config, btn) => {
              this.chooseProductForMap(params);
              return false;
            },
          } as AgCellButton
        }
      },
      {
        // ...agMakeTagsColDef(this.cms, (tag) => {
        // }),
        ...agMakeTextColDef(this.cms),
        headerName: 'ĐVT đối tác',
        field: 'RefUnit',
        width: 150,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            ajax: {
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                const params = settings.data;
                const rs = this.refUnitList.filter(f => this.cms.smartFilter(this.cms.getObjectText(f), params['term']));
                success({
                  data: rs,
                  total: rs.length,
                });

                return null;
              },
              delay: 300,
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    // item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            },
          }
        },
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'ĐVT nội bộ',
        field: 'Unit',
        width: 150,
        cellRendererParams: {
          coalesceButton: {
            label: 'Liên kết',
            // icon: '',
            status: 'danger',
            outline: true,
            disabled: (data, params) => false,
            action: async (params, config, btn) => {
              // if (!params.node?.data?.Id || !params?.node?.data?.Contact) {
              //   this.cms
              //   console.log(params);
              // } else {
              const previusStatus = btn.disabled;
              btn.disabled = true;
              this.cms.openDialog(DialogFormComponent, {
                context: {
                  title: 'Liên kết đơn vị tính',
                  width: '600px',
                  onInit: async (form, dialog) => {
                    return true;
                  },
                  controls: [
                    {
                      name: 'Unit',
                      label: 'ĐVT nội bộ',
                      placeholder: 'Chọn ĐVT nội bộ...',
                      type: 'select2',
                      focus: true,
                      initValue: '',
                      option: {
                        ...this.cms.makeSelect2AjaxOption('/admin-product/units', {includeIdText: true}, {
                          placeholder: 'Chọn ĐVT nội bộ...', limit: 10, prepareResultItem: (item) => {
                            return item;
                          }
                        }),
                        multiple: false,
                        allowClear: true,
                      }
                    },
                  ],
                  actions: [
                    {
                      label: 'Trở về',
                      icon: 'back',
                      status: 'basic',
                      action: async () => {
                        return true;
                      },
                    },
                    {
                      label: 'Gán',
                      icon: 'link-2-outline',
                      status: 'success',
                      keyShortcut: 'Enter',
                      action: async (form, dialog: DialogFormComponent) => {

                        // Update for this row
                        const row = {...params.node.data};
                        row.Unit = form.get('Unit').value;
                        row.UnitLabel = this.cms.getObjectText(form.get('Unit').value);
                        // params.node.setData(row);
                        // params.node.setData();
                        // console.log(form);

                        // Update for relative rows
                        params.api.forEachNode((rowNode, index) => {
                          if (this.cms.getObjectId(rowNode.data.RefUnit) == this.cms.getObjectId(row.RefUnit)) {
                            let action = rowNode.data.Action;
                            if (this.cms.getObjectId(rowNode.data.Product) && this.cms.getObjectId(rowNode.data.RefProduct) && this.cms.getObjectId(rowNode.data.RefUnit)) {
                              action = {
                                id: 'MAP',
                                text: 'Liên kết',
                                status: 'primary',
                              };
                            } else {
                              if (this.cms.getObjectId(rowNode.data.RefProduct) && this.cms.getObjectId(rowNode.data.RefUnit)) {
                                action = {
                                  id: 'CREATENEW',
                                  text: 'Tạo mới',
                                  status: 'success',
                                };
                              }
                            }
                            rowNode.setData({...rowNode.data, Unit: row.Unit, UnitLabel: this.cms.getObjectText(row.Unit), Action: action});
                          }
                        });
                        btn.disabled = previusStatus;
                        return true;
                      },
                    },
                  ],
                  onDialogClose: () => {
                    btn.disabled = previusStatus;
                  },
                },
              });
              // }
              return false;
            },
          } as AgCellButton
        },
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            ajax: {
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                const params = settings.data;
                const rs = this.adminProductSrevice.unitList$.value.filter(f => this.cms.smartFilter(this.cms.getObjectText(f), params['term']));
                success({
                  data: rs,
                  total: rs.length,
                });

                return null;
              },
              delay: 300,
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    // item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            },
          }
        },
      },
      {
        headerName: 'Danh mục SP đối tác',
        field: 'RefCategories',
        // pinned: 'left',
        width: 200,
        cellRenderer: AgTextCellRenderer,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.makeSelect2AjaxOption('/admin-product/categories', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
              placeholder: 'Chọn danh mục...', limit: 10, prepareResultItem: (item) => {
                item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                return item;
              }
            }),
            multiple: true,
            logic: 'OR',
            allowClear: true,
          }
        },
      },
      {
        headerName: 'Nhóm SP đối tác',
        field: 'RefGroups',
        // pinned: 'left',
        width: 200,
        cellRenderer: AgTextCellRenderer,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.makeSelect2AjaxOption('/admin-product/groups', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
              placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                return item;
              }
            }),
            multiple: true,
            logic: 'OR',
            allowClear: true,
          }
        },
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Barcode đối tác',
        field: 'RefBarcode',
        // pinned: 'left',
        width: 180,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeIdColDef(this.cms),
        headerName: 'ID Page đối tác',
        field: 'RefPage',
        width: 130,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Tên Page đối tác',
        field: 'RefPageName',
        width: 250,
        filter: 'agTextColumnFilter',
        filterParams: {
          textMatcher: ({filter, value, filterText}) => {
            return this.cms.smartFilter(value, filterText);
          }
        },
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'ID SP Sàn',
        field: 'B2bProduct',
        width: 250,
        filter: 'agTextColumnFilter',
        valueGetter: params => this.cms.getObjectId(params?.data?.B2bProduct),
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Sku SP Sàn',
        field: 'B2bSku',
        width: 120,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Unit SP Sàn',
        field: 'B2bUnit',
        width: 250,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá niêm yết',
        field: 'ListedSalesPrice',
        width: 180,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeNumberColDef(this.cms),
        headerName: '%CK',
        field: 'BasicSalesCommissionRatio',
        width: 120,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá bán niêm yết',
        field: 'ListedSalesPrice',
        // pinned: 'left',
        width: 160,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCheckboxColDef(this as any, this.cms, (params, checked) => {
          console.log(checked);
        }),
        headerName: 'Chọn',
        field: 'ImportListedSalesPrice',
        width: 100,
        headerComponent: CustomHeader,
        headerComponentParams: {
          enabledCheckbox: true,
        },
        type: 'leftAligned',
        cellClass: ['ag-cell-items-center', 'ag-cell-justify-start'],
      },

      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá bán',
        field: 'SalesPrice',
        // pinned: 'left',
        width: 160,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCheckboxColDef(this as any, this.cms, (params, checked) => {
          console.log(checked);
        }),
        headerName: 'Chọn',
        field: 'ImportSalesPrice',
        width: 100,
        headerComponent: CustomHeader,
        headerComponentParams: {
          enabledCheckbox: true,
        },
        type: 'leftAligned',
        cellClass: ['ag-cell-items-center', 'ag-cell-justify-start'],
      },

      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá mua niêm yết',
        field: 'ListedPurchasePrice',
        // pinned: 'left',
        width: 160,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCheckboxColDef(this as any, this.cms, (params, checked) => {
          console.log(checked);
        }),
        headerName: 'Chọn',
        field: 'ImportListedPurchasePrice',
        width: 100,
        headerComponent: CustomHeader,
        headerComponentParams: {
          enabledCheckbox: true,
        },
        type: 'leftAligned',
        cellClass: ['ag-cell-items-center', 'ag-cell-justify-start'],
      },

      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá mua',
        field: 'PurchasePrice',
        // pinned: 'left',
        width: 160,
        filter: 'agTextColumnFilter',
      },
      {
        ...agMakeCheckboxColDef(this as any, this.cms, (params, checked) => {
          console.log(checked);
        }),
        headerName: 'Chọn',
        field: 'ImportPurchasePrice',
        width: 100,
        headerComponent: CustomHeader,
        headerComponentParams: {
          enabledCheckbox: true,
        },
        type: 'leftAligned',
        cellClass: ['ag-cell-items-center', 'ag-cell-justify-start'],
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Trạng thái',
        field: 'Status',
        width: 512,
        // pinned: 'right',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Hành động',
        field: 'Action',
        width: 120,
        pinned: 'right',
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            logic: 'OR',
            allowClear: true,
            data: [
              {id: 'CREATENEW', text: 'Tạo mới'},
              {id: 'MAP', text: 'Liên kết'},
              {id: 'MAPPED', text: 'Liên kết cũ'},
              {id: 'PENDING', text: 'Chờ xủ lý'},
              {id: 'SUCCESS', text: 'Thành công'},
              {id: 'SKIP', text: 'Bỏ qua'},
              {id: 'ERROR', text: 'Lỗi'},
            ],
          }
        },
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (row) => {
          if (row) {
            if (this.cms.getObjectId(row.Action) == 'CREATENEW') {
              if (row.RefProduct?.WarehouseUnit) {
                if (this.cms.getObjectId(row.RefProduct.WarehouseUnit) == this.cms.getObjectId(row.RefUnit)) {
                  this.cms.showError('Bạn không được xóa dòng chứa đơn vị tính cơ bản của sản phẩm sẽ được tạo mới !');
                  return;
                }
              }
            }
          }
          this.gridApi.applyTransaction({remove: [row]});
        }, false, [
          {
            name: 'map',
            title: 'Map',
            icon: 'link-2-outline',
            status: 'primary',
            outline: false,
            action: async (params) => {
              this.chooseProductForMap(params);
              return true;
            }
          },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */
  }

  dialogMap: { [key: string]: NbDialogRef<B2bRefServerProductListComponent> } = {};

  ngOnDestroy(): void {
    super.ngOnDestroy();
    for (const key in Object.keys(this.dialogMap)) {
      this.dialogMap[key]?.close();
    }
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      disabled: () => this.loading,
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({remove: selectedNodes.map(m => m.data)});

        return true;
      }
    });

    const unitMap = {};
    // let dialogMap: { [key: string]: NbDialogRef<B2bRefServerProductListComponent> } = {};
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm sản phẩm từ sàn ProBox B2B Center',
      status: 'success',
      label: 'Thêm sản phẩm từ sàn ProBox B2B Center',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => this.loading || !this.cms.getObjectId(this.array.controls[0].get('Page').value),
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        const $this = this;
        const refPage = this.cms.getObjectId(this.array.controls[0].get('Page').value);
        if (this.dialogMap[refPage]) {
          this.dialogMap[refPage].componentRef.instance.show();
        } else {
          this.dialogMap[refPage] = this.cms.openDialog(B2bRefServerProductListComponent, {
            context: {
              reuseDialog: true,
              stateIndex: refPage,
              title: 'Danh sách sản phẩm của đối tác ' + this.cms.getObjectText(this.array.controls[0].get('Page').value),
              height: '95vh',
              width: '95vw',
              extendsParams: {
                refpage: refPage,
                // includeMapForPage: this.cms.getObjectId(this.b2bCenterService.currentpage$.value)
              },
              onDialogChoose: (chooseRefProducts) => {
                this.addItemToList(chooseRefProducts);
                this.refUnitList = Object.keys(this.refUnitMap).reduce((rs, curr) => {
                  rs.push(this.refUnitMap[curr]);
                  return rs;
                }, []);
              },
            }
          });
        }

        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm sản phẩm từ file id sản phẩm được đối tác cung cấp',
      status: 'primary',
      label: 'Thêm sản phẩm từ file id',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => this.loading || !this.cms.getObjectId(this.array.controls[0].get('Page').value),
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        const $this = this;

        this.file.nativeElement.click();

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'addAll',
      title: 'Thêm tất cả sản phẩm',
      status: 'danger',
      label: 'Thêm tất cả sản phẩm từ đối tác',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => this.loading || !this.cms.getObjectId(this.array.controls[0].get('Page').value),
      click: async (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        const $this = this;

        let chooseRefProducts = [];
        const limit = 100;
        let offset = 0;
        // let toast = null;
        let loaded = 0;
        let total = 0;
        this.progress = 1;
        do {
          // const ids = productIds.slice(offset, offset + limit);
          // toast = this.cms.showToast(offset + '-' + (offset + limit) + ' sản phẩm', 'Đang thêm sản phẩm', { status: 'info', duration: 0 });
          chooseRefProducts = await this.apiService.getObservable<any[]>('/b2b-center/server/products', {
            limit: limit,
            offset: offset,
            sort_Id: 'desc',
            // eq_RefCode: '[' + ids.join(',') + ']',
            includeCategories: true,
            includeGroups: true,
            includeWarehouseUnit: true,
            embedUnitConversions: true,
            includeCreator: true,
            includeLastUpdateBy: true,
            page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value),
            refpage: this.cms.getObjectId(this.array.controls[0].get('Page').value),
            includeMapForPage: this.cms.getObjectId(this.b2bCenterService.currentpage$.value),
          }).pipe(
            map((res) => {
              if (!total) {
                total = +res.headers.get('x-total-count');
              }
              let data = res.body;
              return {data, total};
            }),
          ).toPromise().then(rs => rs.data);
          console.log(chooseRefProducts);
          await this.addItemToList(chooseRefProducts);

          loaded += chooseRefProducts.length;

          this.progressStatus = 'warning';
          this.progress = parseInt(((loaded / total) * 100) as any);
          this.progressLabel = loaded + '/' + total + ' - ' + this.progress + '%';

          offset += limit;
          // if (toast) {
          //   toast.close();
          // }
          // if(this.destroy$.pipe( take(1)))
          // break;
        } while (chooseRefProducts.length == limit);
        this.refUnitList = Object.keys(this.refUnitMap).reduce((rs, curr) => {
          rs.push(this.refUnitMap[curr]);
          return rs;
        }, []);
        // if (toast) {
        //   toast.close();
        // }
        this.cms.showToast('Đã thêm tất cả sản phẩm từ đối tác', 'Hoàn tất', {status: 'success', duration: 30000});
        this.progressStatus = 'success';


        return true;
      }
    });

    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'addAll',
    //   title: 'Thêm tất cả sản phẩm',
    //   status: 'danger',
    //   label: 'Thêm tất cả sản phẩm từ đối tác',
    //   iconPack: 'eva',
    //   icon: 'plus-square-outline',
    //   size: 'medium',
    //   disabled: () => this.loading || !this.cms.getObjectId(this.array.controls[0].get('Page').value),
    //   click: async (event) => {

    //   }
    // });
    component.actionButtonList = actionButtonList;
  }

  /** End Ag-Grid */
  refUnitMap: { [key: string]: UnitModel } = {};
  refUnitList: UnitModel[] = [];

  async addItemToList(chooseRefProducts: any[], option?: { requiredAction: ActionModel }) {
    // this.loading = true;
    try {
      const $this = this;
      const unitMap = {};
      console.log(chooseRefProducts);
      const productUnitList = [];

      const refProductIds = chooseRefProducts.map(m => this.cms.getObjectId(m.RefCode));
      const currentMap = await $this.apiService.getPromise<any[]>('/b2b-center/product/maps', {limit: 'nolimit', eq_RefProduct: '[' + refProductIds.join(',') + ']'}).then(rs => rs.reduce((result, item, index) => {
        result[this.cms.getObjectId(item.RefProduct) + '-' + this.cms.getObjectId(item.RefUnit)] = item;
        return result;
      }, {}));

      const refProductSkus = chooseRefProducts.map(m => this.cms.getObjectId(m.RefSku));
      const refSkuMap = await $this.apiService.getPromise<any[]>('/admin-product/products', {limit: 'nolimit', eq_Sku: '[' + refProductSkus.join(',') + ']', includeUnitConversions: true}).then(rs => rs.reduce((result, item, index) => {
        result[item.Sku] = item;
        return result;
      }, {}));

      // chooseRefProducts.forEach(refProduct => {
      for (const r in chooseRefProducts) {

        const refProduct = chooseRefProducts[r];
        // refProduct.UnitConversions.forEach(unitConversion => {
        for (const u in refProduct.UnitConversions) {
          const unitConversion = refProduct.UnitConversions[u];

          let existsProduct: any = {};
          const existsMap = currentMap[refProduct.RefCode + '-' + this.cms.getObjectId(unitConversion)];
          if (existsMap) {
            existsProduct = {
              Product: {...existsMap, id: this.cms.getObjectId(existsMap.Product), text: existsMap.ProductName, Code: existsMap.Product, Name: existsMap.ProductName},
              ProductName: existsMap.ProductName,
              Sku: existsMap.Sku,
              Unit: existsMap.Unit,
              UnitLabel: existsMap.UnitLabel,
              FeaturePicture: existsMap.FeaturePicture,
              Pictures: existsMap.Pictures,
              Action: {id: 'MAPPED', text: 'Liên kết cũ'},
            };
          } else {


            // Detecte local product by b2b share map
            if (unitConversion.RefProduct && unitConversion.RefUnit) {
              const existProductByProductAndUnit = this.adminProductSrevice.productUnitMap[this.cms.getObjectId(unitConversion.RefProduct) + '-' + this.cms.getObjectId(unitConversion.RefUnit)];
              // const existProductBySku = refSkuMap[existProductByProductAndUnit.Sku];
              if (existProductByProductAndUnit) {
                // const mapUnit = existProductBySku.UnitConversions.find(f => this.cms.getObjectId(f.Unit) === this.cms.getObjectId(unitConversion))
                // if (mapUnit) {
                existsProduct = {
                  Product: {...existProductByProductAndUnit, id: existProductByProductAndUnit.Code, text: existProductByProductAndUnit.Name},
                  ProductName: existProductByProductAndUnit.Name,
                  Sku: existProductByProductAndUnit.Sku,
                  Unit: unitConversion.RefUnit,
                  UnitLabel: unitConversion.RefUnitLabel,
                  FeaturePicture: existProductByProductAndUnit.FeaturePicture,
                  Pictures: existProductByProductAndUnit.Pictures,
                  Action: {id: 'MAP', text: 'Liên kết'},
                };
                // }
              }
            } else {
              const existProductBySku = refSkuMap[refProduct.RefSku];
              if (existProductBySku) {
                const mapUnit = existProductBySku.UnitConversions.find(f => this.cms.getObjectId(f.Unit) === this.cms.getObjectId(unitConversion));
                if (mapUnit) {
                  existsProduct = {
                    Product: {...existProductBySku, id: existProductBySku.Code, text: existProductBySku.Name},
                    ProductName: existProductBySku.Name,
                    Sku: existProductBySku.Sku,
                    Unit: mapUnit.Unit,
                    UnitLabel: this.cms.getObjectText(mapUnit.Unit),
                    FeaturePicture: existProductBySku.FeaturePicture,
                    Pictures: existProductBySku.Pictures,
                    Action: {id: 'MAP', text: 'Liên kết'},
                  };
                }
              }
            }
          }

          const map: any = {
            // ...product,
            // id: product.Code,
            // text: product.Name,
            Action: {
              id: 'CREATENEW',
              text: 'Tạo mới',
              status: 'primary',
            },

            ...existsProduct,

            RefFeaturePicture: refProduct.FeaturePicture,
            RefPictures: refProduct.Pictures,
            RefSku: refProduct.RefSku,
            RefName: refProduct.RefName,
            RefProduct: {
              ...refProduct,
              id: refProduct.RefCode,
              text: refProduct.Name,
            },
            RefProductName: refProduct.Name,
            RefUnit: unitConversion,
            RefUnitLabel: this.cms.getObjectText(unitConversion),
            RefCategories: refProduct.Categories,
            RefGroups: refProduct.Groups,
            RefBarcode: refProduct.Barcode,

            ListedSalesPrice: unitConversion.ListedPrice,
            BasicSalesCommissionRatio: unitConversion.CommissionRatio,
            SalesPrice: unitConversion.Price,

            B2bProduct: refProduct.Code,
            B2bProductName: refProduct.Name,
            B2bSku: refProduct.Sku,
            B2bUnit: unitConversion,
            B2bUnitLabel: this.cms.getObjectText(unitConversion),

            Page: $this.b2bCenterService.currentpage$.value,
            PageName: $this.cms.getObjectText($this.b2bCenterService.currentpage$.value),
            RefPage: $this.array.controls[0].get('Page').value,
            RefPageName: $this.cms.getObjectText($this.array.controls[0].get('Page').value),
          };

          // Verify map
          if (this.cms.getObjectId(map.Action) == 'CREATENEW') {
            const sameRefProduct = productUnitList.find(f => this.cms.getObjectId(f.RefProduct) == this.cms.getObjectId(map.RefProduct) && this.cms.getObjectId(f.RefUnit) !== this.cms.getObjectId(map.RefUnit));
            if (sameRefProduct) {
              if (sameRefProduct.Product) {
                map.Sku = sameRefProduct.Sku;
                map.Product = sameRefProduct.Product;
                map.ProductName = sameRefProduct.ProductName;
                map.FeaturePicture = sameRefProduct.FeaturePicture;
                map.Pictures = sameRefProduct.Pictures;
                map.Action = {
                  id: 'MAP',
                  text: 'Liên kết',
                  status: 'primary',
                };
              }
            }
          }
          if (this.cms.getObjectId(map.Action) == 'MAP') {
            if (!this.cms.getObjectId(map.RefProduct) || !this.cms.getObjectId(map.Product) || !this.cms.getObjectId(map.RefUnit) || !this.cms.getObjectId(map.Unit)) {
              map.Action = {
                id: 'PENDING',
                text: 'Chờ xử lý',
                status: 'warning',
              };
            }
          }
          if (this.cms.getObjectId(map.Action) == 'CREATENEW') {
            if (!this.cms.getObjectId(map.RefProduct) || !this.cms.getObjectId(map.RefUnit) || !this.cms.getObjectId(map.Unit)) {
              map.Action = {
                id: 'PENDING',
                text: 'Chờ xử lý',
                status: 'warning',
              };
            }
          }

          // Skip duplicate
          const idKey = this.cms.getObjectId(map.RefProduct) + '-' + this.cms.getObjectId(map.RefUnit);
          if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
            productUnitList.push(map);
            this.refUnitMap[this.cms.getObjectId(map.RefUnit)] = map.RefUnit;
          }
        }
      }

      for (const p in productUnitList) {
        if (!this.cms.getObjectId(productUnitList[p].Unit)) {
          // Find previous map
          const previousMap = unitMap[$this.cms.getObjectId(productUnitList[p].RefUnit)] || await $this.apiService.getPromise('/b2b-center/product/maps', {eq_Page: $this.cms.getObjectId($this.b2bCenterService.currentpage$.value), eq_RefPage: $this.cms.getObjectId($this.array.controls[0].get('Page').value), eq_RefUnit: $this.cms.getObjectId(productUnitList[p].RefUnit), limit: 1}).then(rs => rs[0]);
          if (previousMap) {
            unitMap[this.cms.getObjectId(previousMap.RefUnit)] = previousMap;
            for (const c in productUnitList) {
              if (this.cms.getObjectId(productUnitList[c].RefUnit) == this.cms.getObjectId(previousMap.RefUnit)) {
                if (!productUnitList[c].Unit) {
                  productUnitList[c].Unit = previousMap.Unit;
                  productUnitList[c].UnitLabel = this.cms.getObjectText(previousMap.Unit);


                  if (this.cms.getObjectId(productUnitList[c].Action) == 'PENDING') {
                    if (this.cms.getObjectId(productUnitList[c].RefProduct) && this.cms.getObjectId(productUnitList[c].RefUnit) && this.cms.getObjectId(productUnitList[c].Unit)) {
                      productUnitList[c].Action = {
                        id: 'CREATENEW',
                        text: 'Tạo mới',
                        status: 'primary',
                      };
                    }
                  }
                }
              }
            }
          }
        }

      }


      const newRowNodeTrans = $this.gridApi.applyTransactionAsync({
        add: productUnitList,
      }, (res) => {
        // this.loading = false;
        res.add[0].rowIndex;
        // this.gridApi.ensureIndexVisible(res.add[res.add.length - 1].rowIndex);
      });
      console.log('New Row Node Trans: ', newRowNodeTrans);
    } catch (err) {
      // this.loading = false;
    }
  }

  progressStatus = 'primary';
  progress = 0;
  progressLabel = '';

  onFileChange(files: File[]) {
    console.log(files);
    const file = files[0];
    // return this.http.get(file, {responseType: 'text'});

    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      console.log(fileReader.result);
      const productIds: string[] = (fileReader.result as string).split(',');
      console.log(productIds);

      let chooseRefProducts = [];
      const limit = 20;
      let offset = 0;
      // let toast = null;
      let loaded = 0;
      const total = productIds.length;
      this.progress = 1;
      do {
        const ids = productIds.slice(offset, offset + limit);
        // toast = this.cms.showToast(offset + '-' + (offset + limit) + ' sản phẩm', 'Đang thêm sản phẩm', { status: 'info', duration: 0 });
        chooseRefProducts = await this.apiService.getPromise<any[]>('/b2b-center/server/products', {
          // limit: limit,
          // offset: offset,
          sort_Id: 'desc',
          eq_RefCode: '[' + ids.join(',') + ']',
          includeCategories: true,
          includeGroups: true,
          includeWarehouseUnit: true,
          embedUnitConversions: true,
          includeCreator: true,
          includeLastUpdateBy: true,
          page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value),
          refpage: this.cms.getObjectId(this.array.controls[0].get('Page').value),
        });
        console.log(chooseRefProducts);
        await this.addItemToList(chooseRefProducts);

        loaded += chooseRefProducts.length;

        this.progressStatus = 'warning';
        this.progress = parseInt(((loaded / total) * 100) as any);
        this.progressLabel = loaded + '/' + total + ' - ' + this.progress + '%';

        offset += limit;
        // if (toast) {
        //   toast.close();
        // }
      } while (offset < productIds.length);
      this.refUnitList = Object.keys(this.refUnitMap).reduce((rs, curr) => {
        rs.push(this.refUnitMap[curr]);
        return rs;
      }, []);
      // if (toast) {
      //   toast.close();
      // }
      this.cms.showToast('Đã thêm tất cả sản phẩm trong file id', 'Hoàn tất', {status: 'success', duration: 30000});
      this.progressStatus = 'success';

    };
    fileReader.readAsText(file);
  }

  loadList(callback?: (list: CollaboratorBasicStrategyProductModel[]) => void) {
    if (this.gridApi) {
      let products: CollaboratorBasicStrategyProductModel[] = (this.array.controls[0].get('Products').value || []).map((detail: CollaboratorBasicStrategyProductModel) => {
        return detail;
      });
      this.gridApi.setRowData(products);
    }
  }

  select2OptionForPage: Select2Option = {
    ...this.cms.makeSelect2AjaxOption('/b2b-center/server/pages', {page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value)}, {
      prepareResultItem: (item) => {
        item.name = item.id + ' - ' + item.text;
        return item;
      }
    }),
    placeholder: 'Chọn đối tác B2B...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeProducts'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group<any>({
      Code: {value: '', disabled: true},
      // Title: ['', Validators.required],
      Page: [null, Validators.required],
      // DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      // DateOfStart: [],
      // DateOfEnd: [],
      Products: [[]],
    });
    if (data) {
      // data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Extract date range
      // if (item.DateRange) {
      //   item.DateOfStart = item.DateRange[0];
      //   item.DateOfEnd = item.DateRange[1];
      // }

      // Get details data from ag-grid
      item.Products = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          // rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
          rawDetail[prop] = rowNode.data[prop];
        }
        item.Products.push(rawDetail);
      });
    }
    return data;
  }

  async save(): Promise<ProductModel[]> {
    return super.save();
  }

  async mappingProcess() {

    this.onProcessing();

    const table: any[] = [];

    this.gridApi.forEachNode((rowNode, index) => {
      if (['CREATENEW', 'MAP'].indexOf(this.cms.getObjectId(rowNode.data.Action)) > -1) {
        table.push(rowNode.data);
      }
    });

    let loaded = 0;
    const total = table.length;

    this.progressStatus = 'danger';
    this.progress = 1;
    this.progressLabel = loaded + '/' + total + ' - ' + this.progress + '%';

    for (const m in table) {
      try {
        const row = table[m];
        let currentNode: IRowNode = this.gridApi.getRowNode(this.cms.getObjectId(`${this.cms.getObjectId(row.RefProduct)}-${this.cms.getObjectId(row.RefUnit)}`));
        if (this.cms.getObjectId(row.Action) == 'CREATENEW') {
          const hasNewProduct = table.find(f => this.cms.getObjectId(f.RefProduct) == this.cms.getObjectId(row.RefProduct) && f.Product);
          let newProduct = null;
          if (hasNewProduct) {
            newProduct = hasNewProduct.Product;
          } else {
            try {
              // Create new product
              const newProductInfo = {
                ...row.RefProduct,
                Sku: row.RefSku,
                Name: row.RefName,
                Code: null,
                Id: null,
              };
              const refRows = table.filter(f => this.cms.getObjectId(f.RefProduct) == this.cms.getObjectId(row.RefProduct));
              const warehouseUnit = refRows.find(f => this.cms.getObjectId(f.RefUnit) == this.cms.getObjectId(row.RefProduct.WarehouseUnit))?.Unit;
              if (!warehouseUnit) {
                // Skip
                // this.cms.showToast('Bạn phải liên kết đơn vị tính cơ bản cho thông tin sản phẩm sẽ được tạo mới', 'Chưa liên kết ĐVT cho sản phẩm tạo mới', { status: 'warning' });
                throw new Error('Bạn phải liên kết đơn vị tính cơ bản cho thông tin sản phẩm sẽ được tạo mới');
              }
              newProductInfo.WarehouseUnit = warehouseUnit;
              if (newProductInfo.UnitConversions && newProductInfo.UnitConversions.length > 0) {
                const newUnitConversions = [];
                for (const u in newProductInfo.UnitConversions) {
                  const refRow = refRows.find(f => this.cms.getObjectId(f.RefUnit) == this.cms.getObjectId(newProductInfo.UnitConversions[u].Unit));
                  const unit = refRow?.Unit;
                  if (refRow) {
                    if (unit) {
                      newUnitConversions.push(newProductInfo.UnitConversions[u]);
                    } else {
                      // Skip
                      // this.cms.showToast('Bạn phải liên kết đơn vị tính chuyển đổi cho thông tin sản phẩm sẽ được tạo mới', 'Chưa liên kết ĐVT cho sản phẩm tạo mới', { status: 'warning' });
                      throw new Error('Bạn phải liên kết đơn vị tính chuyển đổi cho thông tin sản phẩm sẽ được tạo mới');
                      // continue;
                    }
                  } else {
                    console.warn('Không tìm thấy dòng có đơn vị tính chuyển đổi tương ứng ' + newProductInfo.RefCode + '-' + this.cms.getObjectId(newProductInfo.UnitConversions[u].Unit));
                    // Remove this unit out of unit conversions

                  }
                  newProductInfo.UnitConversions[u].Unit = unit;
                }
                newProductInfo.UnitConversions = newUnitConversions;
              } else {

              }

              newProduct = await this.apiService.postPromise<ProductModel[]>('/admin-product/products', {}, [{
                ...newProductInfo,
                Sku: row.RefSku,
                Name: row.RefName,
                Code: null,
                Id: null,
              }]).then(rs => rs[0]).catch(err => {
                currentNode.setData({...currentNode.data, Action: {id: 'CREATENEW', text: 'Lỗi tạo sản phẩm mới'}, Status: this.cms.parseErrObjectToText(err)});
                this.gridApi.ensureIndexVisible(currentNode.rowIndex);
                return Promise.reject(err);
              });

            } catch (err) {
              this.cms.showError(err);
              continue;
            }
          }
          row.Product = {
            ...newProduct,
            id: newProduct.Code,
            text: newProduct.Name,
          };
          table.forEach(item => {
            if (this.cms.getObjectId(item.RefProduct) == this.cms.getObjectId(row.RefProduct)) {
              item.Product = row.Product;
            }
          });
          row.ProductName = row.Product.Name;
          row.FeaturePicture = row.Product.FeaturePicture;
          row.Pictures = row.Product.Pictures;
          row.Sku = row.Product.Sku;
          // row.Unit = row.RefUnit;
          // row.UnitLabel = row.RefUnitLabel;
        }
        row.RefProduct = this.cms.getObjectId(row.RefProduct);
        await this.apiService.putPromise<any[]>('/b2b-center/product/maps', {}, [row]).then(rs => {
          currentNode.setData({...row, Action: {id: 'SUCCESS', text: 'Thành công'}, Status: 'Đã xử lý'});
          this.gridApi.ensureIndexVisible(currentNode.rowIndex);
          return rs;
        }).catch(err => {
          currentNode.setData({...currentNode.data, Action: {id: 'ERROR', text: 'Lỗi liên kết'}, Status: this.cms.parseErrObjectToText(err)});
          this.gridApi.ensureIndexVisible(currentNode.rowIndex);
          return Promise.reject(err);
        });


      } catch (err) {
        console.error(err);
        this.onProcessed();
      }

      loaded++;
      this.progress = parseInt(((loaded / total) * 100) as any);
      this.progressLabel = loaded + '/' + total + ' - ' + this.progress + '%';
      this.progressStatus = 'success';
    }
    this.cms.showToast('Các trạng thái và hành động được thể hiện trên từng dòng, nếu hành động thất bại bạn có thể kiểm tra và cho xử lý lại', 'Đã xử lý liên kết sản phẩm', {status: 'success', duration: 4500});
    this.onProcessed();
    this.onDialogSave && this.onDialogSave(table);
    return true;
  }

  chooseProductForMap(params: any) {
    let hasChooseItem = null;
    this.cms.openDialog(ProductListComponent, {
      context: {
        title: 'Chọn sản phẩm liên kết với ' + params?.data?.RefProductName + ' (' + this.cms.getObjectText(params?.data?.RefUnit) + ')',
        height: '95vh',
        width: '95wh',
        isKeepState: false,
        onInit: (component) => {
          this.cms.waitFor(300, 1000, async () => component.gridColumnApi?.getColumns().length > 0).then(() => {// Detect grid real ready
            component.gridApi.setFilterModel({
              Name: {
                filter: this.cms.convertUnicodeToNormal(params?.node?.data?.RefProductName.replace(/\.|\+|\-|\;|\&|\*|\$|\#|\@|\!|\(|\)|\%|\^|\_|\=|\/|\\|\"|\:/g, ' ')).replace(/[^a-z0-9]/ig, ' '),
                filterType: 'text',
                type: 'contains',
              },
            });
          });
        },
        onDialogChoose: async (chooseItems) => {
          console.log(chooseItems);
          hasChooseItem = chooseItems;
          if (chooseItems.length > 0) {
            const product: ProductModel = chooseItems[0];
            let unit = null;
            if (product.UnitConversions.length > 1) {
              unit = await new Promise((resolve, reject) => {
                this.cms.showDialog('Chọn ĐVT', 'Chọn sản phẩm liên kết với ' + params?.data?.RefProductName + ' (ĐVT: ' + this.cms.getObjectText(params?.data?.RefUnit) + ')', [
                  {
                    label: 'Trở về',
                    status: 'basic',
                    outline: true,
                    action: async () => {
                      resolve(null);
                      return true;
                    }
                  },
                  ...(product.UnitConversions.map(unit => ({
                    label: this.cms.getObjectText(unit),
                    status: 'info',
                    action: async () => {
                      resolve(unit);
                    }
                  })))
                ], () => {
                  resolve(null);
                });
              });
              if (!unit) {
                this.cms.showError('Bạn chưa chọn ĐVT liên kết');
                return;
              }
            } else {
              unit = product.UnitConversions[0];
            }

            // if (unit) {

            let currentNode: IRowNode = this.gridApi.getRowNode(this.cms.getObjectId(`${this.cms.getObjectId(params.data.RefProduct)}-${this.cms.getObjectId(params.data.RefUnit)}`));
            let nodeData = currentNode.data;
            nodeData.Product = chooseItems[0].Code;
            nodeData.ProductName = chooseItems[0].Name;
            nodeData.FeaturePicture = chooseItems[0].FeaturePicture;
            nodeData.Pictures = chooseItems[0].Pictures;
            nodeData.Sku = chooseItems[0].Sku;
            nodeData.Unit = unit;
            nodeData.UnitLabel = this.cms.getObjectText(unit);
            if (nodeData.RefProduct && nodeData.RefUnit && nodeData.Product && nodeData.Unit) {
              nodeData.Action = {
                id: 'MAP',
                text: 'Liên kết',
                status: 'success',
              };
            } else {
              nodeData.Action = {
                id: 'SKIP',
                text: 'Bỏ qua',
                status: 'basic',
              };
            }
            currentNode.setData(nodeData);
            // }

            // Auto set local product for similar rows
            this.gridApi.forEachNode(async rowNode => {
              if (this.cms.getObjectId(nodeData.RefProduct) == this.cms.getObjectId(rowNode.data.RefProduct) && this.cms.getObjectId(rowNode.data.RefUnit) !== this.cms.getObjectId(nodeData.RefUnit)) {
                let action = rowNode.data.Action;

                unit = await new Promise((resolve, reject) => {
                  this.cms.showDialog('Chọn ĐVT', 'Chọn sản phẩm liên kết với ' + rowNode?.data?.RefProductName + ' (ĐVT: ' + this.cms.getObjectText(rowNode?.data?.RefUnit) + ')', [
                    {
                      label: 'Trở về',
                      status: 'basic',
                      outline: true,
                      action: async () => {
                        resolve(null);
                        true;
                      }
                    },
                    ...(product.UnitConversions.map(unit => ({
                      label: this.cms.getObjectText(unit),
                      status: 'info',
                      action: async () => {
                        resolve(unit);
                      }
                    })))
                  ], () => {
                    resolve(null);
                  });
                });
                if (!unit) {
                  this.cms.showError('Bạn chưa chọn ĐVT liên kết');
                  return;
                }

                if (this.cms.getObjectId(rowNode.data.RefProduct) && this.cms.getObjectId(rowNode.data.RefUnit) && this.cms.getObjectId(unit)) {
                  action = {
                    id: 'MAP',
                    text: 'Liên kết',
                    status: 'primary'
                  };
                } else {
                  action = {
                    id: 'PENDING',
                    text: 'Chờ xử lý',
                    status: 'warning'
                  };
                }
                rowNode.setData({
                  ...rowNode.data,
                  Product: nodeData.Product,
                  Sku: nodeData.Sku,
                  Unit: unit,
                  UnitLabel: this.cms.getObjectText(unit),
                  ProductName: nodeData.ProductName,
                  FeaturePicture: nodeData.FeaturePicture,
                  Pictures: nodeData.Pictures,
                  Action: action,
                });
              }
            });
          }
        },
        onDialogClose: () => {
          if (hasChooseItem) {
            return;
          }
          if (this.cms.getObjectId(params.node?.data?.RefProduct) && this.cms.getObjectId(params.node?.data?.RefUnit)) {
            this.cms.showDialog('Liên kết sản phẩm', 'Không có sản phẩm nào được chon, bạn có muốn tạo mới sản phẩm khi xử lý không ?', [
              {
                label: 'Không',
                status: 'basic',
                outline: true,
                action: async () => true,
              },
              {
                label: 'Cho phép tạo mới',
                status: 'primary',
                action: async () => {
                  params.node.setDataValue('Action', {
                    id: 'CREATENEW',
                    text: 'Tạo mới',
                    status: 'primary',
                  });
                  return true;
                },
              },
            ]);
          }
        },
      }
    });
  }

}
