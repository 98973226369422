import {CurrencyPipe, DatePipe} from "@angular/common";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {NbDialogService, NbToastrService, NbThemeService, NbDialogRef} from "@nebular/theme";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {filter, take} from "rxjs/operators";
import {AppModule} from "../../../../../../app.module";
import {AgDateCellRenderer} from "../../../../../../lib/custom-element/ag-list/cell/date.component";
import {AgTextCellRenderer} from "../../../../../../lib/custom-element/ag-list/cell/text.component";
import {agMakeCommandColDef} from "../../../../../../lib/custom-element/ag-list/column-define/command.define";
import {agMakeSelectionColDef} from "../../../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeStateColDef} from "../../../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeTextColDef} from "../../../../../../lib/custom-element/ag-list/column-define/text.define";
import {AgGridDataManagerListComponent} from "../../../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {CollaboratorAdvancedSaleCommissionConfigurationModel} from "../../../../../../models/collaborator.model";
import {ContactModel} from "../../../../../../models/contact.model";
import {PageModel} from "../../../../../../models/page.model";
import {ApiService} from "../../../../../../services/api.service";
import {CommonService} from "../../../../../../services/common.service";
import {RootServices} from "../../../../../../services/root.services";
import {CollaboratorService} from "../../../../collaborator.service";
import {CollaboratorStateMap} from "../../../../collaborator.state.map";
import {CollaboratorAdvancedSaleCommissionConfigurationFormComponent} from "../advanced-sale-commission-configuration-form/collaborator-advanced-sale-commission-configuration-form.component";
import {CollaboratorAdvancedSaleCommissionConfigurationPrintComponent} from "../advanced-sale-commission-configuration-print/advanced-sale-commission-configuration-print.component";

@Component({
  selector: 'ngx-collaborator-advanced-sale-commission-configuration-list',
  templateUrl: './collaborator-advanced-sale-commission-configuration-list.component.html',
  styleUrls: ['./collaborator-advanced-sale-commission-configuration-list.component.scss'],
  providers: [CurrencyPipe, DatePipe],
})
export class CollaboratorAdvancedSaleCommissionConfigurationListComponent extends AgGridDataManagerListComponent<CollaboratorAdvancedSaleCommissionConfigurationModel, CollaboratorAdvancedSaleCommissionConfigurationFormComponent> implements OnInit {

  feature = {
    Module: {id: 'Collaborator', text: 'CRM'},
    Feature: {id: 'SalesAdvanceCommissionConfig', text: 'Cấu hình chiết khấu nâng cao cho sales'}
  };

  componentName: string = 'CollaboratorAdvancedSaleCommissionConfigurationListComponent';
  formPath = '';
  apiPath = '/collaborator/advanced-sale-commission-configurations';
  idKey: string[] = ['Code'];
  formDialog = CollaboratorAdvancedSaleCommissionConfigurationFormComponent;
  printDialog = CollaboratorAdvancedSaleCommissionConfigurationPrintComponent;
  currentPage: PageModel;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;
  // @Input() gridHeight = 'calc(100vh - 230px)';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorAdvancedSaleCommissionConfigurationListComponent>,
    public datePipe: DatePipe,
    public collaboratorService: CollaboratorService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  runningState = {
    ...AppModule.approvedState,
    nextState: 'RUNNING',
    outlilne: true,
    status: 'danger',
    label: 'Đang chạy',
    nextStates: [
      {...AppModule.notJustApprodedState, status: 'warning'},
    ],
  };

  async init() {
    return super.init().then(async state => {
      // Add page choosed
      this.collaboratorService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'pbxdomain',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Collaborator.Page.title', {action: this.cms.translateText('Common.choose'), definition: ''}), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList, option: {
              placeholder: 'Chọn trang...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.collaboratorService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            return false;
          },
        });
      });

      await this.cms.waitForLanguageLoaded();

      const processingMap = {
        ...AppModule.processMaps.common,
        "APPROVED": {
          ...AppModule.approvedState,
          nextState: 'RUNNING',
          status: 'success',
          nextStates: [
            {...AppModule.unrecordedState, status: 'warning'},
            {...this.runningState, status: 'success'},
          ],
        },
        "RUNNING": {
          ...this.runningState,
          nextState: 'COMPLETE',
          nextStates: [
            {...AppModule.completeState, status: 'basic'},
            {...AppModule.unrecordedState, status: 'warning'},
          ],
        },
      };

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Page',
          field: 'Page',
          // pinned: 'left',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          headerName: 'Bắt đầu',
          field: 'DateOfStart',
          width: 150,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
          cellRendererParams: {
            format: 'shortDate'
          },
        },
        {
          headerName: 'Bắt đầu',
          field: 'DateOfStart',
          width: 150,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
          cellRendererParams: {
            format: 'shortDate'
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Title',
          // pinned: 'left',
          width: 900,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeStateColDef(this.cms, CollaboratorStateMap.strategyStateMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, (data) => {
            this.deleteConfirm([data.Code]);
          }, false, []),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['page'] = this.collaboratorService?.currentpage$?.value;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(ContactFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: ContactModel[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }
}
