import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbThemeService, NbDialogRef } from '@nebular/theme';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { ContractStateMap } from '../../../contract/contract.state.map';
import { ContractFormComponent } from '../../../contract/contract/contract-form/contract-form.component';
import { ContractListComponent } from '../../../contract/contract/contract-list/contract-list.component';
import { AccManagerAccountFormComponent } from '../acc-manager-account-form/acc-manager-account-form.component';
import { AccManagerAccountPrintComponent } from '../acc-manager-account-print/acc-manager-account-print.component';
import { AccManagerImportFbAccountFormComponent } from '../acc-manager-import-fb-account-form/acc-manager-import-fb-account-form.component';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { HttpErrorResponse } from '@angular/common/http';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';

@Component({
  selector: 'ngx-acc-manager-account-list',
  templateUrl: './acc-manager-account-list.component.html',
  styleUrls: ['./acc-manager-account-list.component.scss']
})
export class AccManagerAccountListComponent extends AgGridDataManagerListComponent<Model, ContractFormComponent> implements OnInit {

  componentName: string = 'ContractListComponent';
  formPath = '/contract/contract/form';
  apiPath = '/acc-manager/accounts';
  idKey = ['SystemUuid'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Ecard', text: 'Hợp đồng' },
    Feature: { id: 'Ecard', text: 'Hợp đồng' }
  };

  printDialog = AccManagerAccountPrintComponent;
  formDialog = AccManagerAccountFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  static processingMap = {};

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ContractListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    const processingMap = ContractStateMap.contractStateMap;

    return super.init().then(async state => {


      this.actionButtonList.unshift({
        type: 'button',
        name: 'registerProBoxVnAccount',
        status: 'danger',
        label: 'Tạo tài khoản ProBox.vn',
        title: 'Tạo tài khoản ProBox.vn',
        size: 'medium',
        icon: 'flash-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.loading = true;
          this.apiService.putPromise(this.apiPath, { generateProBoxVnAccounts: true }, this.selectedItems.map(m => ({ SystemUuid: m.SystemUuid }))).then(rs => {
            this.loading = false;
            console.log(rs);
            this.cms.showToast('Đã tạo tài khoản ProBox.vn', 'Tạo tài khoản ProBox.vn', { status: 'success' });
          }).catch(err => {
            this.loading = false;
            this.cms.showError(err);
            return Promise.reject(err);
          });
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'importFbAccount',
        status: 'primary',
        label: 'Import tài khoản facebook',
        title: 'Import tài khoản facebook',
        size: 'medium',
        icon: 'flash-outline',
        // disabled: () => {
        //   return this.selectedIds.length == 0;
        // },
        hidden: () => this.isChoosedMode,
        click: () => {
          this.cms.openDialog(AccManagerImportFbAccountFormComponent, {
            context: {
              title: 'Import tài khoản facebook',
              onImport: async (accounts) => {
                console.log(accounts);
                this.loading = true;
                for (const account of accounts) {
                  const data = {
                    Name: account.Name,
                    Email: account.Email,
                    Birthday: account.Birthday,
                    Avatar: account.Avatar,
                    Banner: account.Banner,
                    AccountPlatforms: [
                      {
                        Platform: 'FACEBOOK',
                        Uuid: account.Uuid,
                        Username: account.Username,
                        Password: account.Password,
                        Auth2Fa: account.Auth2Fa,
                        Auth2FaApp: account.Auth2FaApp,
                        Proxy: account.Proxy,
                        Cookie: account.Cookie,
                        Token: account.Token,
                        BioLink: 'https://facebook.com/' + account.Uuid,
                      }
                    ],
                  };


                  const rs = await this.apiService.postPromise<Model[]>(this.apiPath, {}, [data]).catch(err => {
                    // this.loading = false;
                    this.cms.showError('Lỗi import tài khoản facebook : ' + account.Email);
                    return false;
                  });
                  if (rs) {
                    this.cms.showToast('Đã import tài khoản facebook : ' + account.Email, 'Import tài khoản facebook', { status: 'info' });
                    console.log('Impot result: ', rs);
                  }
                }
                this.loading = false;
                this.refresh();
              },
            },
          });
          // this.apiService.putPromise(this.apiPath, { generateProBoxVnAccounts: true }, this.selectedItems.map(m => ({ SystemUuid: m.SystemUuid }))).then(rs => {
          //   console.log(rs);
          // });
        }
      });

      await this.cms.waitForLanguageLoaded();

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Avatar',
          pinned: 'left',
          field: 'Avatar',
          width: 100,
        },
        // {
        //   headerName: 'Mã',
        //   field: 'Code',
        //   width: 140,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'left',
        // },
        // {
        //   headerName: 'Bên A',
        //   field: 'AObject',
        //   // pinned: 'left',
        //   width: 200,
        //   cellRenderer: AgTextCellRenderer,
        //   filter: AgSelect2Filter,
        //   filterParams: {
        //     select2Option: {
        //       ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
        //         placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
        //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        //           return item;
        //         }
        //       }),
        //       multiple: true,
        //       logic: 'OR',
        //       allowClear: true,
        //     }
        //   },
        // },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Email',
          field: 'Email',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Platform Uuid',
          field: 'FbUuid',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          valueGetter: params => {
            // if (params.data && params.data.AccountPlatforms) {
            //   const uuid = params.data.AccountPlatforms.find(f => f.Platform == 'FACEBOOK')?.Uuid;
            //   const link = '<a target="__blank" href="https://facebook.com/' + uuid + '">' + uuid + '</a>';
            //   return link;
            // }
            if (params.data && params.data.AccountPlatforms) {
              let html = '';
              for (const account of params.data.AccountPlatforms) {
                const link = account.Platform + ': <a target="__blank" href="' + (account.Platform == 'FACEBOOK' ? 'https://facebook.com' : (account.Platform == 'PROBOXVN' ? 'https://probox.vn' : '')) + '/' + account.Uuid + '">' + account.Uuid + '</a>';
                html += link + '<br>';
              }
              return html;
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Bio link',
          field: 'BioLink',
          width: 400,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          valueGetter: params => {
            if (params.data && params.data.AccountPlatforms) {
              let html = '';
              for (const account of params.data.AccountPlatforms) {
                const link = account.Platform + ': <a target="__blank" href="' + account.BioLink + '">' + account.BioLink + '</a>';
                html += link + '<br>';
              }
              return html;
            }
          },
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            // this.preview([data]);
            if (this.cms.getObjectId(data.State) == 'PROCESSING') {
              this.openForm([data.Code]);
            } else {
              this.preview([data]);
            }
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 300,
        },
        // {
        //   headerName: 'Số điện thoại',
        //   field: 'Phone',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Email',
        //   field: 'Email',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Website',
        //   field: 'Website',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Đại chỉ',
        //   field: 'Address',
        //   width: 500,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        {
          headerName: 'SystemUuid',
          field: 'SystemUuid',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
        }
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: Model[] | HttpErrorResponse) => void): void {
    params['includeAccountPlatforms'] = true;
    return super.executeGet(params, success, error, complete);
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeRelativeVouchers'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(ContractFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: Model[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

}
