import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class B2bServerStateMap {

    static pageStateMap: { [key: string]: ProcessMap } = {
        ACTIVE: {
            state: 'ACTIVE',
            label: 'Common.active',
            status: 'success',
            outline: true,
            nextState: 'INACTIVE',
            nextStateLabel: 'Common.changeToInactive',
            confirmText: 'Common.inactiveConfirm',
            responseTitle: 'Common.inactivated',
            responseText: 'Common.inactiveSuccess',
        },
        INACTIVE: {
            state: 'INACTIVE',
            label: 'Common.inactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        NOTJUSTACTIVE: {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        "": {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
    };

    static commissionConfigStateMap = {
        APPROVED: {
            ...stateMap.APPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.RUNNING,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        RUNNING: {
            ...stateMap.RUNNING,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'COMPLETED',
            nextStates: [
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
    };
}
