<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [ngStyle]="{width: width, height: height}" [nbSpinner]="isProcessing">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sản phẩm mới' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Pictures" label="{{'Hình' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-files-input formControlName="Pictures" [config]="{style: {height: '11.5rem', padding: '0'}, thumbnailStype: {height: '10.8rem', width: '100%'}}"></ngx-files-input>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Name" label="{{'Tên' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Tên' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="WarehouseUnit" label="{{'Đơn vị tính' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="WarehouseUnit" [data]="adminProductService.unitList$ | async" [select2Option]="select2OptionForUnit"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Container" label="{{'Vị trí (nằm trong kệ)' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Container" [select2Option]="select2OptionForContainer"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Categories" label="Danh mục" [array]="array" [index]="i">
                  <ngx-select2 formControlName="Categories" [select2Option]="select2OptionForCategories" [data]="adminProductService.categoryList$ | async"></ngx-select2>
                </ngx-form-group>
              </div>
<!--              <div class="col-sm-12">-->
<!--                <ngx-form-group [formGroup]="formItem" name="Groups" label="Nhóm" [array]="array" [index]="i" >-->
<!--                  <ngx-select2 formControlName="Groups" [select2Option]="select2OptionForGroups" [data]="adminProductService.groupList$ | async">-->
<!--                  </ngx-select2>-->
<!--                </ngx-form-group>-->
<!--              </div>-->
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Price" label="{{'Giá' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Price"[imask]="moneyMask" unmask="typed" placeholder="{{'Giá' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save() && false">{{ 'Common.saveAndClose' | translate | headtitlecase }}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
