import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class PurchaseStateMap {

  static INQUEUE: ProcessMap = {
    state: 'INQUEUE',
    label: 'Hàng đợi',
    id: 'INQUEUE',
    text: 'Hàng đợi',
    confirmLabel: 'Hàng đợi',
    status: 'warning',
    color: 'yellow',
    outline: true,
    confirmTitle: 'Chuyển sang hàng đợi',
    confirmText: 'Bạn có muốn chuyển sang trạng thái đợi ?',
    responseTitle: 'Đã chuyển sang hàng đợi',
    responseText: 'Đã chuyển sang hàng đợi thành công',
  };

  static PENDINGRECEIPT: ProcessMap = {
    state: 'PENDINGRECEIPT',
    label: 'Chờ nhận hàng',
    id: 'PENDINGRECEIPT',
    text: 'Chờ nhận hàng',
    confirmLabel: 'Chờ nhận hàng',
    status: 'info',
    color: 'lightblue',
    outline: true,
    confirmTitle: 'Chuyển sang Chờ nhận hàng',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Chờ nhận hàng ?',
    responseTitle: 'Đã chuyển sang Chờ nhận hàng',
    responseText: 'Đã chuyển sang Chờ nhận hàng thành công',
  };


  static purchaseOrderStateMap: { [key: string]: ProcessMap } = {
    "NOTJUSTAPPROVED": {
      ...stateMap.NOTJUSTAPPROVED,
      nextStates: [
        PurchaseStateMap.INQUEUE,
        PurchaseStateMap.PENDINGRECEIPT,
      ],
    },
    "INQUEUE": {
      ...PurchaseStateMap.INQUEUE,
      nextStates: [
        PurchaseStateMap.PENDINGRECEIPT,
        stateMap.UNRECORDED,
      ],
    },
    "PENDINGRECEIPT": {
      ...PurchaseStateMap.PENDINGRECEIPT,
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    },
    "APPROVED": {
      ...stateMap.APPROVED,
      nextState: 'NOTJUSTAPPROVED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    "UNRECORDED": {
      ...stateMap.UNRECORDED,
      nextStates: [
        PurchaseStateMap.INQUEUE,
        PurchaseStateMap.PENDINGRECEIPT,
      ],
    },
    "": {
      ...stateMap.NOTJUSTAPPROVED,
      nextStates: [
        stateMap.APPROVED,
      ],
    },
  };

  static purchaseVoucherStateMap: { [key: string]: ProcessMap } = {
    "APPROVED": {
      ...stateMap.APPROVED,
      nextState: 'COMPLETE',
      nextStates: [
        // { ...AppModule.completeState, status: 'success' },
        stateMap.UNRECORDED,
      ],
    },
    "COMPLETE": {
      ...stateMap.COMPLETED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    "UNRECORDED": {
      ...stateMap.UNRECORDED,
      nextState: 'APPROVED',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    },
    "NOTJUSTAPPROVED": {
      ...stateMap.NOTJUSTAPPROVED,
      state: 'NOTJUSTAPPROVED',
      nextState: 'APPROVED',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    },
    "": {
      ...stateMap.NOTJUSTAPPROVED,
      state: 'NOTJUSTAPPROVED',
      nextState: 'APPROVED',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    },
  };


}
