<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <div [formGroup]="formItem">
        <div class="ticket-layout">
          <div class="conversation-area">
            <nb-card size="small" class="conversation-header">
              <nb-card-header size="small">
                <div style="display: flex; align-items: center; flex-direction: row; flex-wrap: nowrap; gap: 0.5rem;">
                  <button nbButton size="small" status="primary" hero>
                    <nb-icon pack="eva" icon="archive-outline"></nb-icon>
                  </button>
                  <button nbButton size="small" status="warning" hero>
                    <nb-icon pack="eva" icon="alert-triangle-outline"></nb-icon>
                  </button>
                  <button nbButton size="small" status="info" hero>
                    <nb-icon pack="eva" icon="bookmark-outline"></nb-icon>
                  </button>
                  <button nbButton size="small" status="success" hero>
                    <nb-icon pack="eva" icon="calendar-outline"></nb-icon>
                  </button>
                  <button nbButton size="small" status="basic" hero>
                    <nb-icon pack="eva" icon="bell-outline"></nb-icon>
                  </button>
                </div>
              </nb-card-header>
            </nb-card>

            <div class="conversations">
              <nb-card size="small" class="conversation-item" *ngFor="let message of formItem.get('Messages').value">
                <nb-card-header>
                  <div class="title-wrap">
                    <div class="avatar" style="width: 32px; height: 32px; border-radius: 50%; overflow: hidden;" [ngStyle]="{backgroundImage: 'url(' + message.CreatorAvatar?.Thumbnail + ')'}"></div>
                    <div class="title" style="flex: 1">{{ message.Creator | objecttext }}</div>
                  </div>
                </nb-card-header>
                <nb-card-body>
                  <div [innerHTML]="message.Content"></div>
                </nb-card-body>
              </nb-card>
              <nb-card size="small" class="conversation-input">
                <nb-card-header>
                  <div class="title-wrap">
                    <div class="avatar" style="width: 32px; height: 32px; border-radius: 50%; overflow: hidden;" [ngStyle]="{backgroundImage: 'url(' + (cms.loginInfo$ | async)?.user?.Avatar + ')'}"></div>
                    <div class="title">{{ (cms.loginInfo$ | async)?.user?.Name }}</div>
                    <div class="title-input"><input type="text" nbInput formControlName="LastMessageTitle" fullWidth placeholder="Tiêu đề..."></div>
                  </div>
                </nb-card-header>
                <nb-card-body>
                  <div #editorContainer class="editor" (elementReady)="onEditLoaded(formItem, editorContainer)"></div>
                </nb-card-body>
                <nb-card-footer>
                  <div style="display: flex; flex-wrap: nowrap; flex-direction: row-reverse; gap: 0.5rem">
                    <button nbButton status="danger" (click)="onSendMessageClick(formItem)">
                      Gửi phản hồi
                      <nb-icon pack="eva" icon="paper-plane-outline"></nb-icon>
                    </button>
                    <nb-select placeholder="Chọn trạng thái" formControlName="MessageState">
                      <nb-option value="OPEN">Mở</nb-option>
                      <nb-option value="PENDING">Chờ xử lý</nb-option>
                      <nb-option value="ONHOLD">Tạm hoãn</nb-option>
                      <nb-option value="SOLVED">Đã giải quyết</nb-option>
                      <nb-option value="CLOSED">Đã đóng</nb-option>
                    </nb-select>
                  </div>
                </nb-card-footer>
              </nb-card>
            </div>
          </div>
          <div class="info-area">
            <nb-card class="small">
              <nb-card-header size="small">
                <div style="display: flex; align-items: center; flex-direction: row; flex-wrap: nowrap; gap: 0.5rem; justify-content: space-between;">
                  <div>Thông tin Ticket</div>
                  <div style="display: flex; align-items: center; flex-direction: row; flex-wrap: nowrap; gap: 0.5rem; justify-content: space-between;">
                    <button nbButton size="small" status="success" hero (click)="save() && false">
                      <nb-icon pack="eva" icon="save-outline"></nb-icon>
                    </button>
                    <button nbButton size="small" status="danger" hero (click)="goback() && false">
                      <nb-icon pack="eva" icon="close"></nb-icon>
                    </button>
                  </div>
                </div>
              </nb-card-header>
              <nb-card-body>
                <div class="row">
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Code" label="{{'Ticket ID' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" nbInput fullWidth placeholder="{{'ID' | translate | headtitlecase}}" value="{{formItem.get('Code').value}}" disabled>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Title" label="{{'Tiêu đề' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Title' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="DateOfCreate" label="{{'Ngày tạo' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" nbInput fullWidth placeholder="{{'Ngày tạo' | translate | headtitlecase}}" value="{{formItem.get('DateOfCreate').value | date:'short'}}" disabled>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="LastMessageSent" label="{{'Nhắn tin lần cuối' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" nbInput fullWidth placeholder="{{'Nhắn tin lần cuối' | translate | headtitlecase}}" value="{{formItem.get('LastMessageSent').value | date:'short'}}" disabled>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="State" label="{{'Trạng thái' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <nb-select placeholder="Chọn trạng thái" formControlName="State" fullWidth>
                        <nb-option value="OPEN">Mở</nb-option>
                        <nb-option value="PENDING">Chờ xử lý</nb-option>
                        <nb-option value="ONHOLD">Tạm hoãn</nb-option>
                        <nb-option value="SOLVED">Đã giải quyết</nb-option>
                        <nb-option value="CLOSED">Đã đóng</nb-option>
                      </nb-select>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="Priority" label="{{'Mức ưu tiên' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <nb-select placeholder="Chọn mức ưu tiên" formControlName="Priority" fullWidth>
                        <nb-option value="URGENT">Khẩn cấp</nb-option>
                        <nb-option value="HIGHT">Cao</nb-option>
                        <nb-option value="MEDDIUM">Vừa</nb-option>
                        <nb-option value="LOW">Thấp</nb-option>
                      </nb-select>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Rating" label="{{'Đánh giá' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" nbInput fullWidth formControlName="Rating" placeholder="{{'Đánh giá' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Source" label="{{'Nguồn/kênh' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <nb-select placeholder="Chọn nguồn/kênh" formControlName="Source" fullWidth>
                        <nb-option value="LOCAL">Nội bộ</nb-option>
                        <nb-option value="WEBFORM">Web form</nb-option>
                        <nb-option value="ZALOOA">Zalo OA</nb-option>
                        <nb-option value="TELEGRAM">Telegram</nb-option>
                        <nb-option value="FACEBOOK">Facebook</nb-option>
                      </nb-select>
                    </ngx-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Tags" label="{{'Thẻ' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <ngx-select2 formControlName="Tags" [select2Option]="select2OptionForTags"></ngx-select2>
                    </ngx-form-group>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
            <nb-card class="small">
              <nb-card-header size="small">
                <div style="display: flex; align-items: center; flex-direction: row; flex-wrap: nowrap; gap: 0.5rem; justify-content: space-between;">
                  <div>Thông tin người yêu cầu</div>
                  <div style="display: flex; align-items: center; flex-direction: row; flex-wrap: nowrap; gap: 0.5rem; justify-content: space-between;">
                  </div>
                </div>
              </nb-card-header>
              <nb-card-body class="scrollable-container">
                <div class="row">
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="Object" label="{{'Khách hàng' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <ngx-select2 formControlName="Object" [select2Option]="select2OptionForObject" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="ObjectName" label="{{'Tên' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" formControlName="ObjectName" nbInput fullWidth placeholder="{{'Tên' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="{{'Số điện thoại' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" formControlName="ObjectPhone" nbInput fullWidth placeholder="{{'Số điện thoại' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="{{'Email' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" formControlName="ObjectEmail" nbInput fullWidth placeholder="{{'Email' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="{{'Địa chỉ' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                      <input type="text" formControlName="ObjectAddress" nbInput fullWidth placeholder="{{'Địa chỉ' | translate | headtitlecase}}">
                    </ngx-form-group>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
