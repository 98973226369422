import {AgGridModule} from "@ag-grid-community/angular";
import {CommonModule, CurrencyPipe, DecimalPipe} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NbCheckboxModule, NbIconModule, NbButtonModule, NbInputModule, NbSelectModule, NbCardModule, NbMenuModule, NbListModule, NbUserModule, NbSpinnerModule, NbTabsetModule, NbProgressBarModule, NbRadioModule} from "@nebular/theme";
import {TranslateModule} from "@ngx-translate/core";
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {Select2Module} from "../../../vendor/ng2select2/lib/ng2-select2";
import {NgxUploaderModule} from "../../../vendor/ngx-uploader/src/public_api";
import {CustomElementModule} from "../custom-element/custom-element.module";
import {ResourcePermissionEditComponent} from "./resource-permission-edit/resource-permission-edit.component";
import {AgDynamicListComponent} from "./ag-dymanic-list/ag-dymanic-list.component";
import {GoogleMapComponent} from "./google-map/google-map.component";
import {RelativeVoucherComponent} from "./voucher/relative-voucher/relative-voucher.component";
import {DialogFormComponent} from "./dialog/dialog-form/dialog-form.component";
import {DynamicListDialogComponent} from "./dialog/dynamic-list-dialog/dynamic-list-dialog.component";
import {PlayerDialogComponent} from "./dialog/player-dialog/player-dialog.component";
import {ProgressDialogComponent} from "./dialog/progress-dialog/progress-dialog.component";
import {ShowcaseDialogComponent} from "./dialog/showcase-dialog/showcase-dialog.component";
import {DialogSortingFormComponent} from "./dialog/sorting-form/sorting-form.component";
import {GoogleMapsModule} from "@angular/google-maps";
import {SortablejsModule} from "ngx-sortablejs";
import {SysParameterListComponent} from "./configuration/parameter-list/parameter-list.component";
import {SystemConfigurationBoardComponent} from "./configuration/system-configuration-board/system-configuration-board.component";
import {SystemLocaleConfigComponent} from "./configuration/system-configuration-board/system-locale-config/system-locale-config.component";
import {ThemeConfigBoardComponent} from "./configuration/theme-config-board/theme-config-board.component";
import {ThemeConfigComponent} from "./configuration/theme-config-board/theme-config/theme-config.component";
import {UserConfigBoardComponent} from "./configuration/user-config-board/user-config-board.component";
import {UserLocaleConfigComponent} from "./configuration/user-config-board/user-locale-config/user-locale-config.component";

export const componentsLib = [
  ResourcePermissionEditComponent,
  AgDynamicListComponent,
  GoogleMapComponent,
  RelativeVoucherComponent,
  DialogFormComponent,
  DynamicListDialogComponent,
  PlayerDialogComponent,
  ShowcaseDialogComponent,
  DialogSortingFormComponent,
  ProgressDialogComponent,
  UserLocaleConfigComponent,
  UserConfigBoardComponent,
  SystemConfigurationBoardComponent,
  SysParameterListComponent,
  ThemeConfigBoardComponent,
  ThemeConfigComponent,
  SystemLocaleConfigComponent,
];

@NgModule({
  declarations: componentsLib,
  exports: componentsLib,
  imports: [
    CommonModule,
    Select2Module,
    NbCheckboxModule,
    NbIconModule,
    NbButtonModule,
    FormsModule,
    NbInputModule,
    AgGridModule,
    NbSelectModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule,
    CurrencyMaskModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    NbCardModule,
    CustomElementModule,
    NbMenuModule,
    NbListModule,
    NbUserModule,
    GoogleMapsModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbProgressBarModule,
    NbRadioModule,
    SortablejsModule.forRoot({
      animation: 200,
    }),
    // SalesModule,
  ],
  entryComponents: [
    // ...saleComponents,
  ],
  providers: [
    {provide: CurrencyPipe, useValue: {}},
    {provide: DecimalPipe, useValue: {}},
  ],
})
export class ComponentLibModule {
}
