import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class B2bCenterStateMap {

  static SENT: ProcessMap = {
    state: 'SENT',
    label: 'Đã gửi',
    id: 'SENT',
    text: 'Đã gửi',
    confirmLabel: 'Gửi đơn hàng',
    status: 'info',
    color: 'ligthblue',
    outline: true,
    confirmTitle: 'Gửi đơn hàng',
    confirmText: 'Bạn có muốn gửi đơn hàng này tới bên bán ?',
    responseTitle: 'Đã gửi đơn hàng',
    responseText: 'Đã gửi đơn hàng thành công',
  };
  static RECEIVED: ProcessMap = {
    state: 'RECEIVED',
    label: 'Đã nhận',
    id: 'RECEIVED',
    text: 'Đã nhận',
    confirmLabel: 'Tải đơn hàng',
    status: 'primary',
    color: 'blue',
    outline: true,
    confirmTitle: 'Tải đơn hàng',
    confirmText: 'Bạn có muốn tải đơn hàng này tới bên mua ?',
    responseTitle: 'Đã tải đơn hàng',
    responseText: 'Đã tải đơn hàng thành công',
  };
  static PURCHASECOMPLETED: ProcessMap = {
    state: 'PURCHASECOMPLETED',
    label: 'Đã hoàn tất mua hàng',
    id: 'PURCHASECOMPLETED',
    text: 'Đã hoàn tất mua hàng',
    confirmLabel: 'Hoàn tất mua hàng',
    status: 'basic',
    color: 'gray',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn hoàn tất mua hàng',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã hoàn tất',
  };
  static SALESCOMPLETED: ProcessMap = {
    state: 'SALESCOMPLETED',
    label: 'Đã hoàn tất bán hàng',
    id: 'SALESCOMPLETED',
    text: 'Đã hoàn tất bán hàng',
    confirmLabel: 'Hoàn tất bán hàng',
    status: 'basic',
    color: 'gray',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn hoàn tất bán hàng',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã hoàn tất',
  };

  static PENDINGRECEIPT: ProcessMap = {
    state: 'PENDINGRECEIPT',
    label: 'Chờ nhận hàng',
    id: 'PENDINGRECEIPT',
    text: 'Chờ nhận hàng',
    confirmLabel: 'Chờ nhận hàng',
    status: 'info',
    color: 'lightblue',
    outline: true,
    confirmTitle: 'Chuyển sang Chờ nhận hàng',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Chờ nhận hàng ?',
    responseTitle: 'Đã chuyển sang Chờ nhận hàng',
    responseText: 'Đã chuyển sang Chờ nhận hàng thành công',
  };

  static pageStateMap: { [key: string]: ProcessMap } = {
    ACTIVE: {
      state: 'ACTIVE',
      label: 'Common.active',
      status: 'success',
      outline: true,
      nextState: 'INACTIVE',
      nextStateLabel: 'Common.changeToInactive',
      confirmText: 'Common.inactiveConfirm',
      responseTitle: 'Common.inactivated',
      responseText: 'Common.inactiveSuccess',
    },
    INACTIVE: {
      state: 'INACTIVE',
      label: 'Common.inactive',
      status: 'danger',
      outline: true,
      nextState: 'ACTIVE',
      nextStateLabel: 'Common.active',
      confirmText: 'Common.activeConfirm',
      responseTitle: 'Common.activated',
      responseText: 'Common.activeSuccess',
    },
    NOTJUSTACTIVE: {
      state: 'NOTJUSTACTIVE',
      label: 'Common.notjustactive',
      status: 'danger',
      outline: true,
      nextState: 'ACTIVE',
      nextStateLabel: 'Common.active',
      confirmText: 'Common.activeConfirm',
      responseTitle: 'Common.activated',
      responseText: 'Common.activeSuccess',
    },
    "": {
      state: 'NOTJUSTACTIVE',
      label: 'Common.notjustactive',
      status: 'danger',
      outline: true,
      nextState: 'ACTIVE',
      nextStateLabel: 'Common.active',
      confirmText: 'Common.activeConfirm',
      responseTitle: 'Common.activated',
      responseText: 'Common.activeSuccess',
    },
  };

  static b2bCenterPurchaseOrderStateMap: { [key: string]: ProcessMap } = {
    NEW: {
      ...stateMap.NEW,
      nextState: 'SENT',
      nextStates: [
        B2bCenterStateMap.SENT,
        stateMap.INQUEUE,
      ],
    },
    INQUEUE: {
      ...stateMap.INQUEUE,
      nextState: 'SENT',
      nextStates: [
        B2bCenterStateMap.SENT,
        stateMap.UNRECORDED,
      ],
    },
    // PENDINGRECEIPT: {
    //   ...B2bCenterStateMap.PENDINGRECEIPT,
    //   nextState: 'CLOSEORDER',
    //   nextStates: [
    //     B2bCenterStateMap.SENT,
    //     stateMap.CLOSEORDER,
    //     stateMap.INQUEUE,
    //   ],
    // },
    SENT: {
      ...B2bCenterStateMap.SENT,
      nextState: 'RECEIVED',
      nextStates: [
        B2bCenterStateMap.RECEIVED,
        stateMap.UNRECORDED,
      ],
    },
    RECEIVED: {
      ...B2bCenterStateMap.RECEIVED,
      nextState: 'PROCESSING',
      nextStates: [
        stateMap.PROCESSING,
        stateMap.UNRECORDED,
      ],
    },
    PROCESSING: {
      ...stateMap.PROCESSING,
      nextState: 'CLOSEORDER',
      nextStates: [
        stateMap.CLOSEORDER,
        stateMap.UNRECORDED,
      ],
    },
    CLOSEORDER: {
      ...stateMap.CLOSEORDER,
      label: 'NCC Chốt đơn',
      nextState: 'DEPLOYED',
      nextStates: [
        stateMap.DEPLOYED,
        stateMap.UNRECORDED,
      ],
    },
    DEPLOYED: {
      ...stateMap.DEPLOYED,
      nextState: 'PURCHASECOMPLETED',
      nextStates: [
        B2bCenterStateMap.PURCHASECOMPLETED,
        stateMap.UNRECORDED,
      ],
    },
    PURCHASECOMPLETED: {
      ...B2bCenterStateMap.PURCHASECOMPLETED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'SENT',
      nextStates: [
        stateMap.INQUEUE,
        B2bCenterStateMap.SENT,
        stateMap.DEPLOYED,
        B2bCenterStateMap.PURCHASECOMPLETED,
      ],
    },
  };

  static b2bCenterSalesOrderStateMap: { [key: string]: ProcessMap } = {
    NEW: {
      ...stateMap.NEW,
      nextState: 'SENT',
      nextStates: [
        B2bCenterStateMap.SENT,
      ],
    },
    SENT: {
      ...B2bCenterStateMap.SENT,
      nextState: 'RECEIVED',
      nextStates: [
        B2bCenterStateMap.RECEIVED,
        stateMap.UNRECORDED,
      ],
    },
    RECEIVED: {
      ...B2bCenterStateMap.RECEIVED,
      nextState: 'PROCESSING',
      nextStates: [
        stateMap.PROCESSING,
        stateMap.UNRECORDED,
      ],
    },
    PROCESSING: {
      ...stateMap.PROCESSING,
      nextState: 'CLOSEORDER',
      nextStates: [
        stateMap.CLOSEORDER,
        stateMap.UNRECORDED,
      ],
    },
    CLOSEORDER: {
      ...stateMap.CLOSEORDER,
      label: 'Chốt đơn',
      nextState: 'DEPLOYED',
      nextStates: [
        stateMap.DEPLOYED,
        stateMap.UNRECORDED,
      ],
    },
    DEPLOYED: {
      ...stateMap.DEPLOYED,
      nextState: 'SALESCOMPLETED',
      nextStates: [
        B2bCenterStateMap.SALESCOMPLETED,
        stateMap.UNRECORDED,
      ],
    },
    SALESCOMPLETED: {
      ...B2bCenterStateMap.SALESCOMPLETED,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.UNRECORDED,
      ],
    },
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'SENT',
      nextStates: [
        B2bCenterStateMap.SENT,
        stateMap.DEPLOYED,
        B2bCenterStateMap.SALESCOMPLETED,
      ],
    },
  };

  static salesCommissionConfigStateMap = {
    APPROVED: {
      ...stateMap.APPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.RUNNING,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    RUNNING: {
      ...stateMap.RUNNING,
      nextState: 'UNRECORDED',
      nextStates: [
        stateMap.COMPLETED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    COMPLETED: {
      ...stateMap.COMPLETED,
      nextState: 'COMPLETED',
      nextStates: [],
    } as ProcessMap,
    UNRECORDED: {
      ...stateMap.UNRECORDED,
      nextState: 'APPROVED',
      nextStates: [
        stateMap.APPROVED,
      ],
    } as ProcessMap,
    NOTJUSTAPPROVED: {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
    '': {
      ...stateMap.NOTJUSTAPPROVED,
      nextState: 'RUNNING',
      nextStates: [
        stateMap.APPROVED,
        stateMap.UNRECORDED,
      ],
    } as ProcessMap,
  };


  // Queue state map
  static INQUEUE: ProcessMap = {
    state: 'INQUEUE',
    label: 'Trong hàng đợi',
    confirmLabel: 'Đẩy vào hàng đợi',
    status: 'warning',
    color: 'orange',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy vào hàng đợi',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy vào hàng đợi',
  };

  static SENT2B2BORDER: ProcessMap = {
    state: 'SENT2B2BORDER',
    label: 'Đặt hàng B2B',
    confirmLabel: 'Đẩy qua B2B',
    status: 'primary',
    color: 'blue',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy qua B2B Center',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy qua B2B Center',
  };

  static B2BORDER: ProcessMap = {
    state: 'B2BORDER',
    label: 'Đã đặt hàng B2B',
    confirmLabel: 'Đặt hàng B2B',
    status: 'primary',
    color: 'blue',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn chuyển sang "Đã đặt hàng B2B"',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã chuyễn sang "Đã đặt hàng B2B"',
  };

  static SENT2INVENTORYADJUST: ProcessMap = {
    state: 'SENT2INVENTORYADJUST',
    label: 'Kiểm kho',
    confirmLabel: 'Đẩy qua kiểm kho',
    status: 'danger',
    color: 'red',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy qua kiểm kho',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy qua kiểm kho',
  };

  static OUTQUEUE: ProcessMap = {
    state: 'OUTQUEUE',
    label: 'Ngoài hàng đợi',
    confirmLabel: 'Đẩy ra khỏi hàng đợi',
    status: 'basic',
    color: 'white',
    outline: true,
    confirmTitle: 'Chuyển trạng thái',
    confirmText: 'Bạn có muốn đẩy ra khỏi hàng đợi',
    responseTitle: 'Chuyển trạng thái',
    responseText: 'Đã đẩy ra khỏi hàng đợi',
  };

  static purchaseQueueStateMap: { [key: string]: ProcessMap } = {
    'INQUEUE': {
      ...B2bCenterStateMap.INQUEUE,
      nextState: 'OUTQUEUE',
      nextStates: [
        B2bCenterStateMap.SENT2B2BORDER,
        B2bCenterStateMap.OUTQUEUE,
      ],
    },
    'SENT2B2BORDER': {
      ...B2bCenterStateMap.SENT2B2BORDER,
      nextState: 'OUTQUEUE',
      nextStates: [
        B2bCenterStateMap.SENT2INVENTORYADJUST,
        B2bCenterStateMap.OUTQUEUE,
      ],
    },
    'B2BORDER': {
      ...B2bCenterStateMap.B2BORDER,
      nextState: 'OUTQUEUE',
      nextStates: [
        B2bCenterStateMap.SENT2INVENTORYADJUST,
        B2bCenterStateMap.OUTQUEUE,
      ],
    },
    'SENT2INVENTORYADJUST': {
      ...B2bCenterStateMap.SENT2INVENTORYADJUST,
      nextState: 'OUTQUEUE',
      nextStates: [
        B2bCenterStateMap.SENT2INVENTORYADJUST,
        B2bCenterStateMap.OUTQUEUE,
      ],
    },
    'OUTQUEUE': {
      ...B2bCenterStateMap.OUTQUEUE,
      nextState: 'INQUEUE',
      nextStates: [
        B2bCenterStateMap.INQUEUE,
      ],
    },
  };
  // End queue state map

}
