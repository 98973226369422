import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../../../../../lib/base-component";
import {IoWorkflowHttpRequestNodeModel, IoWorkflowWebhookNodeModel} from "../../../../io.model";
import {IoWorkflowHttpRequestNodeFormComponent} from "../../http-request/io-workflow-http-request-node-form/io-workflow-http-request-node-form.component";
import {IoWorkflowWebhookNodeFormComponent} from "../io-workflow-webhook-node-form/io-workflow-webhook-node-form.component";

@Component({
  selector: 'ngx-io-workflow-webhook-node',
  templateUrl: './io-workflow-webhook-node.component.html',
  styleUrls: ['./io-workflow-webhook-node.component.scss']
})
export class IoWorkflowWebhookNodeComponent extends BaseComponent implements OnInit {
  @Input() props: IoWorkflowWebhookNodeModel;
  @Output() onChange: EventEmitter<IoWorkflowWebhookNodeModel> = new EventEmitter();

  editNode() {
    this.cms.openDialog(IoWorkflowWebhookNodeFormComponent, {
      context: {
        data: [this.props],
        onDialogSave: data => {
          console.log(data);
          this.props = data[0];
          this.onChange.emit(data[0]);
        }
      }
    });
    return true;
  }
}
