import {WarehouseGoodsContainerFormComponent} from '../../goods-container/warehouse-goods-container-form/warehouse-goods-container-form.component';
import {ShowcaseDialogComponent} from '../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component';
import {Component, OnInit, Input} from '@angular/core';
import {BaseComponent} from '../../../../lib/base-component';
import {CommonService} from '../../../../services/common.service';
import {Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {FormControl} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {ProductModel} from '../../../../models/product.model';
import {WarehouseGoodsContainerModel, GoodsModel, WarehouseGoodsInContainerModel} from '../../../../models/warehouse.model';
import {rejects} from 'assert';
import {RootServices} from '../../../../services/root.services';
import {Select2Options} from "../../../../../vendor/ng2select2.copy/lib/ng2-select2.interface";
import {Select2Option} from "../../../../lib/custom-element/select2/select2.component";
import {AdminProductService} from "../../../admin-product/admin-product.service";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'ngx-assign-new-containers-form',
  templateUrl: './assign-new-containers-form.component.html',
  styleUrls: ['./assign-new-containers-form.component.scss'],
})
export class AssignNewContainerFormComponent extends BaseComponent implements OnInit {

  feature = {
    Module: {id: 'Warehouse', text: 'Kho bãi'},
    Feature: {id: 'Goods', text: 'Hàng hóa'},
  };

  componentName: string = 'AssignContainerFormComponent';
  @Input() inputMode: 'dialog' | 'page' | 'inline';
  @Input() inputGoodsList: ProductModel[];
  @Input() onDialogSave: (newData: ProductModel[]) => void;
  @Input() onDialogClose: () => void;

  containersFormControl = new FormControl();

  goodsContainerList: (WarehouseGoodsContainerModel & { id?: string, text?: string })[] = [];
  // select2OptionForShelf = {
  //   placeholder: 'Chọn kệ...',
  //   allowClear: true,
  //   width: '100%',
  //   dropdownAutoWidth: true,
  //   minimumInputLength: 0,
  //   keyMap: {
  //     id: 'id',
  //     text: 'text',
  //   },
  //   ajax: {
  //     transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
  //       console.log(settings);
  //       this.apiService.getPromise('/warehouse/goods-containers', {filter_Name: settings.data['term'] ? settings.data['term'] : '', includeIdText: true, eq_Type: 'SHELF', limit: 20}).then(rs => {
  //         success(rs);
  //       }).catch(err => {
  //         console.error(err);
  //         failure();
  //       });
  //     },
  //     delay: 300,
  //     processResults: (data: any, params: any) => {
  //       // console.info(data, params);
  //       return {
  //         results: data,
  //       };
  //     },
  //   },
  // };
  select2OptionForShelf: Select2Option = {
    ...this.cms.select2OptionForTemplate,
  };

  processing = false;

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public adminProductService: AdminProductService,
    public ref?: NbDialogRef<AssignNewContainerFormComponent>,
  ) {
    super(rsv, cms, router, apiService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async init() {
    // this.goodsContainerList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', { includePath: true, limit: 'nolimit' })).map(item => ({ id: item.Code, text: item.Path })).sort((a, b) => a.text.localeCompare(b.text));
    // this.goodsContainerList = (await this.apiService.getPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', { includeIdText: true, includeFindOrder: true, sort_Path: 'asc', limit: 'nolimit', select: 'Code, Name, FindOrder', 'Warehouse' }));
    this.adminProductService.containerList$.pipe(takeUntil(this.destroy$), filter(f => Array.isArray(f))).subscribe(containerList => {
      this.goodsContainerList = containerList.filter(f => ['SHELF', 'CUPBOARD', 'FLOOR', 'LARGE_DRAWERS'].indexOf(this.cms.getObjectId(f.Type)) > -1).map(m => ({...m, text: m.Path}));
    });
    return super.init();
  }

  async createAndAssign() {
    let choosedContainer: string = this.cms.getObjectId(this.containersFormControl.value);
    this.processing = true;
    let rs = null;
    for (const inputGoods of this.inputGoodsList) {
      if (choosedContainer) {
        rs = await this.apiService.postPromise<WarehouseGoodsContainerModel[]>('/warehouse/goods-containers', {}, [{
          Warehouse: this.containersFormControl.value?.Warehouse,
          Type: 'DRAWERS',
          Parent: choosedContainer as any,
          Name: inputGoods.Name + ' (' + this.cms.getObjectText(inputGoods.WarehouseUnit) + ')'
        }]).then(async rs => {
          const goods = await this.apiService.putPromise<GoodsModel[]>('/warehouse/goods', {id: inputGoods.Code, updateContainerDescription: true, assignContainers: rs[0].Code}, [
            {
              Code: inputGoods.Code,
              WarehouseUnit: this.cms.getObjectId(inputGoods.WarehouseUnit)
            }
          ]);
          rs[0].Path = goods[0].Containers[0]['Path'];
          return rs;
        }).catch(err => {
          this.processing = false;
        });
      }

    }
    this.onDialogSave(rs.map(m => {
      m.Containers = m.Containers;
      return m;
    }));
    this.processing = false;
    this.close();

  }

  revokeCategories() {
    const choosedContainers: (WarehouseGoodsContainerModel & { id?: string, text?: string })[] = this.containersFormControl.value;
    if (choosedContainers && choosedContainers.length > 0) {
      this.processing = true;
      const ids = [];
      const updateList: GoodsModel[] = [];
      for (let p = 0; p < this.inputGoodsList.length; p++) {
        const product: GoodsModel = {Goods: this.inputGoodsList[p].Unit, Unit: this.cms.getObjectId(this.inputGoodsList[p].Unit)};
        ids.push(product.Code);
        // product.Containers = product.Containers.filter(container => !choosedContainers.some(choosed => this.cms.getObjectId(choosed) === this.cms.getObjectId(container['Container'])));

        updateList.push(product);
      }
      this.apiService.putPromise<GoodsModel[]>('/warehouse/goods', {id: ids, revokeContainers: choosedContainers.map(container => this.cms.getObjectId(container)).join(',')}, updateList).then(rs => {
        this.onDialogSave(rs);
        this.processing = false;
        this.close();
      });
    }
  }

  close() {
    this.ref.close();
  }

}
