import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";
import {Component, OnDestroy} from "@angular/core";
import {AgCellButton} from "./button.component";
import {NbButton, NbButtonComponent} from "@nebular/theme";

@Component({
  selector: 'ag-buttons-cell-renderer',
  template: `
    <button *ngFor="let item of params.buttons" nbButton [ghost]="item.ghost" [outline]="item.outline === false ? false : true" [status]="item.status || 'basic'" #btn (click)="itemAction(params, item, btn) && false" [size]="item.size || 'small'" [disabled]="processingMap[item.name] || (item.disabled && item.disabled(params?.node?.data, params))">
      <ng-container *ngIf="item.title; else elseBlock">
        <nb-icon *ngIf="item.icon" pack="eva" [icon]="item.icon" [nbTooltip]="item.title"></nb-icon>
        <ng-container *ngIf="item.label">{{ item.label || '' | translate | headtitlecase }}</ng-container>
      </ng-container>
      <ng-template #elseBlock>
        <nb-icon *ngIf="item.icon" pack="eva" [icon]="item.icon"></nb-icon>
        <ng-container *ngIf="item.label">{{ item.label || '' | translate | headtitlecase }}</ng-container>
      </ng-template>
    </button>
  `,
})
export class AgButtonsCellRenderer implements ICellRendererAngularComp, OnDestroy {
  // status = 'basic';
  refresh(params: ICellRendererParams): boolean {
    // throw new Error('Method not implemented.');
    this.params.value = this.params.getValue();
    if (this.params.onRefresh) {
      this.params.onRefresh(params, this);
    }
    return true;
  }

  public params: any;

  public processingMap: { [key: string]: boolean } = {};

  agInit(params: any): void {
    this.params = params;
    // if (this.params?.status) {
    //     this.status = this.params?.status;
    // }
    if (params.onInit) {
      params.onInit(params, this);
    }

    if (params.buttons) {
      for (const button of this.params.buttons) {
        if (typeof button.process == 'undefined') {
          button.processing = false;
        }
      }
    }
  }

  btnClickedHandler(button: any) {
    return this.params.clicked(button);
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }

  // processing = false;

  async itemAction(params, item: AgCellButton, btn: NbButtonComponent) {
    if (item.action) {
      // this.processing = true;
      this.processingMap[item.name] = true;
      try {
        const rs = await item.action(params, item);
        this.processingMap[item.name] = false;
        return rs;
      } catch (err) {
        this.processingMap[item.name] = false;
        return Promise.reject(err);
      }
    }
  }
}