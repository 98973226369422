import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbThemeService, NbToastrService, NbDialogRef } from '@nebular/theme';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { ProductGroupListComponent } from '../../../admin-product/product-group/product-group-list/product-group-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { SimpleDataManagerFormComponent } from '../simple-data-manager-form/simple-data-manager-form.component';
import { Model } from '../../../../models/model';
import { SimpleDataManagerPrintComponent } from '../simple-data-manager-print/simple-data-manager-print.component';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { AppModule } from '../../../../app.module';
import { CodeTemplateStateMap } from '../../code-template.state.map';

@Component({
  selector: 'ngx-system-api-doc-list',
  templateUrl: './simple-data-manager-list.component.html',
  styleUrls: ['./simple-data-manager-list.component.scss']
})
export class SimpleDataManagerListComponent extends AgGridDataManagerListComponent<Model, SimpleDataManagerFormComponent> implements OnInit {

  componentName: string = 'SimpleDataManagerListComponent';
  apiPath = '/code-template/simple-data-managers';
  formPath = '/code-template/simple-data-manager/form';
  idKey = ['Code'];
  formDialog = SimpleDataManagerFormComponent;
  printDialog = SimpleDataManagerPrintComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<SimpleDataManagerListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      const processingMap = CodeTemplateStateMap.simpleDataManagerStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 800,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
