import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { IGetRowsParams, IRowNode } from 'ag-grid-community';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef } from '@ag-grid-community/core';
import { RootServices } from '../../../../services/root.services';
import { UserModel } from '../../../../models/user.model';
import { UserFormComponent } from '../user-form/user-form.component';
import { agMakeCheckboxColDef } from '../../../../lib/custom-element/ag-list/column-define/checkbox.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { AgDynamicListComponent } from '../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component';

@Component({
  selector: 'ngx-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends AgGridDataManagerListComponent<UserModel, UserFormComponent> implements OnInit {

  componentName: string = 'UserListComponent';
  formPath = '/user/user/form';
  apiPath = '/user/users';
  idKey = ['Code'];
  formDialog = UserFormComponent;

  reuseDialog = true;
  static _dialog: NbDialogRef<UserListComponent>;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() height = '100%';
  @Input() width = '100%';
  @Input() gridHeight = '100%';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<UserListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;

  }

  async init() {
    return super.init().then(async state => {

      // const processingMap = AppModule.processMaps['cashVoucher'];
      await this.cms.waitForLanguageLoaded();

      // Defined action buttons
      this.actionButtonList.unshift({
        type: 'button',
        name: 'assignGroupsFromSpecialUser',
        status: 'info',
        label: 'Gán vai trò mẫu',
        title: 'Gán vai trò từ 1 người dùng khác',
        size: 'medium',
        icon: 'layers-outline',
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          const nodes: IRowNode[] = [];
          this.gridApi.forEachNode(node => nodes.push(node));
          this.cms.openDialog(UserListComponent, {
            context: {
              stateIndex: 'assign_roles_form_template_user',
              width: '90vw',
              height: '90vh',
              onDialogChoose: (chooseItems) => {
                console.log(chooseItems);
                if (chooseItems && chooseItems[0] && chooseItems[0].Groups) {
                  this.cms.showDialog('Gán vai trò', 'Bạn có muốn gán vài trò cho các người dùng đã chọn', [
                    {
                      label: 'Trở về',
                      status: 'basic',
                      outline: true,
                      action: () => {

                      },
                    },
                    {
                      label: 'Gán',
                      status: 'danger',
                      action: () => {
                        this.loading = true;
                        this.apiService.putPromise<UserModel[]>(this.apiPath, {}, this.selectedItems.map(m => ({
                          Code: m.Code,
                          Groups: chooseItems[0].Groups,
                        }))).then(rs => {
                          this.loading = false;
                          this.refreshItems(this.selectedIds);
                          this.cms.showToast('Đã gán vai trò từ người dùng mẫu', 'Gán vai trò', { status: 'success' })
                        }).catch(err => {
                          this.loading = false;
                          return Promise.reject(err);
                        });
                      },
                    },
                  ])
                }
              },
              onInit: (component) => {
                component.actionButtonList = component.actionButtonList.filter(f => f.name != 'assignGroupsFromSpecialUser');
              }
            },
            closeOnEsc: false,
          });
        }
      });

      // Define columns
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'Stt',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return [rowData.AvatarThumbnail];
          }),
          headerName: 'Avatar',
          pinned: 'left',
          field: 'AvatarThumbnail',
          width: 100,
        },
        {
          headerName: 'ID',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 300,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Tên đăng nhập',
          field: 'Username',
          width: 300,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Số điện thoại',
          field: 'Phone',
          width: 150,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          width: 320,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/groups', { includeIdText: true }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày kích hoạt',
          field: 'Activated',
          width: 150,
          // autoHeight: true,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 150,
          // autoHeight: true,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Vừa truy cập',
          field: 'Updated',
          width: 150,
          // autoHeight: true,
          pinned: 'right'
        },
        // {
        //   ...agMakeCheckboxColDef(this, this.cms),
        //   headerName: 'Kích hoạt',
        //   field: 'IsActive',
        //   width: 100,
        //   // autoHeight: true,
        //   pinned: 'right'
        // },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeGroups'] = true;
    params['includeParent'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: UserModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(UserFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: UserModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }
}
