import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { RootServices } from '../../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../../services/common.service';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { ProductListComponent } from '../../../../admin-product/product/product-list/product-list.component';
import { B2bProductFormComponent } from '../product-form/product-form.component';
import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { take, filter, takeUntil, map } from 'rxjs/operators';
import { AppModule } from '../../../../../app.module';
import { AgDateCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeImageColDef } from '../../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeSelectionColDef } from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTagsColDef } from '../../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { IdTextModel } from '../../../../../models/common.model';
import { ProductCategoryModel, ProductGroupModel, ProductUnitModel, ProductModel } from '../../../../../models/product.model';
import { WarehouseGoodsContainerModel } from '../../../../../models/warehouse.model';
import { AssignCategoriesFormComponent } from '../../../../admin-product/product/assign-categories-form/assign-categories-form.component';
import { ImportProductDialogComponent } from '../../../../admin-product/product/import-products-dialog/import-products-dialog.component';
import { AgGridDataManagerListComponent } from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { B2bServerProductService } from '../../../b2b-product.service';
import { B2bServerService } from '../../../b2b-server.service';
import { PageModel } from '../../../../../models/page.model';
import * as XLSX from 'xlsx';
import { ProgressDialogComponent } from '../../../../../lib/component/dialog/progress-dialog/progress-dialog.component';

@Component({
  selector: 'ngx-b2b-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class B2bProductListComponent extends AgGridDataManagerListComponent<ProductModel, B2bProductFormComponent> implements OnInit {

  componentName: string = 'B2bProductListComponent';
  formPath = '/b2b-server/product/product/form';
  apiPath = '/b2b-server/products';
  idKey: string[] = ['Code'];
  formDialog = B2bProductFormComponent;

  @Input() reuseDialog = true;
  static _dialog: NbDialogRef<ProductListComponent>;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  // Category list for filter
  categoryList: ProductCategoryModel[] = [];
  groupList: ProductGroupModel[] = [];
  unitList: ProductUnitModel[] = [];
  containerList: WarehouseGoodsContainerModel[] = [];

  @Input() width = '100%';
  @Input() height = '100%';

  shelfList: IdTextModel[];
  @Input() pagingConfig: { display: boolean, perPage: number }

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<ProductListComponent>,
    public b2bProdServ: B2bServerProductService,
    public themeService: NbThemeService,
    public b2bServerService: B2bServerService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    // this.defaultColDef = {
    //   ...this.defaultColDef,
    //   cellClass: 'ag-cell-items-center',
    // }
  }


  onChangePage(page: PageModel) {
    if (page !== null) {
      this.b2bServerService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce('b2b_server_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  protected configSetting(settings: any): ColDef[] {
    const superSettings = super.configSetting(settings);
    // superSettings.push({
    //   ...agMakeTextColDef(this.cms),
    //   headerName: 'Ref Code',
    //   field: 'RefCode',
    //   width: 180,
    // });
    // superSettings.push({
    //   ...agMakeTextColDef(this.cms),
    //   headerName: 'Page',
    //   field: 'Page',
    //   width: 180,
    // });
    return superSettings;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** Config for paging */
  protected configPaging() {
    if (this.pagingConfig) {
      return {
        ...super.configPaging(),
        ...this.pagingConfig,
      };
    }
    return super.configPaging();
  }

  async loadCache() {
    this.categoryList = await this.b2bProdServ.categoryList$.pipe(take(1)).toPromise();
    this.groupList = await this.b2bProdServ.groupList$.pipe(take(1)).toPromise();
    // this.containerList = await this.b2bProdServ.containerList$.pipe(take(1)).toPromise();
    // this.shelfList = this.containerList.filter(f => this.cms.getObjectId(f.Type) == 'SHELF');
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    console.log(this.smartTable);
  }

  async init() {
    await this.b2bProdServ.unitList$.pipe(filter(f => !!f), take(1)).toPromise();
    await this.loadCache();
    return super.init().then(async rs => {

      // Load unit list
      this.b2bProdServ.unitList$.pipe(takeUntil(this.destroy$)).subscribe(unitList => {
        this.unitList = unitList;
      });

      this.b2bServerService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'page',
          status: 'success',
          label: 'Chọn shop',
          icon: 'plus',
          title: 'Shop',
          size: 'medium',
          select2: {
            data: pageList, option: {
              placeholder: 'Tất cả shop...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.b2bServerService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });


      this.actionButtonList.unshift({
        name: 'assignCategories',
        status: 'info',
        label: this.cms.textTransform(this.cms.translate.instant('Common.tag/untag'), 'head-title'),
        icon: 'pricetags',
        title: this.cms.textTransform(this.cms.translate.instant('Common.tag/untag'), 'head-title'),
        size: 'medium',
        disabled: () => this.selectedIds.length === 0,
        hidden: () => this.isChoosedMode,
        click: () => {
          this.openAssignCategoiesDialplog();
          return false;
        },
      });
      this.actionButtonList.unshift({
        name: 'copyProducts',
        status: 'danger',
        label: this.cms.textTransform(this.cms.translate.instant('Copy'), 'head-title'),
        icon: 'copy-outline',
        title: this.cms.textTransform(this.cms.translate.instant('Copy'), 'head-title'),
        size: 'medium',
        disabled: () => this.selectedIds.length === 0,
        hidden: () => this.isChoosedMode,
        click: () => {
          this.cms.openDialog(B2bProductFormComponent, {
            context: {
              showLoading: true,
              inputMode: 'dialog',
              inputId: this.selectedItems.map(item => this.makeId(item)),
              isDuplicate: true,
              onDialogSave: (newData: ProductModel[]) => {
              },
              onDialogClose: () => {
                this.refresh();
              },
            },
          });
          return false;
        },
      });

      // this.actionButtonList.unshift({
      //   name: 'importProducts',
      //   status: 'primary',
      //   label: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
      //   icon: 'download-outline',
      //   title: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
      //   size: 'medium',
      //   disabled: () => false,
      //   hidden: () => false,
      //   click: () => {
      //     this.cms.openDialog(ImportProductDialogComponent, {
      //       context: {
      //         // showLoading: true,
      //         inputMode: 'dialog',
      //         onDialogSave: (newData: ProductModel[]) => {
      //         },
      //         onDialogClose: () => {
      //           this.refresh();
      //         },
      //       },
      //       closeOnEsc: false,
      //       closeOnBackdropClick: false,
      //     });
      //     return false;
      //   },
      // });
      this.actionButtonList.unshift({
        name: 'exportFilteredItems',
        status: 'info',
        label: 'Xuất Excel',
        icon: 'download-outline',
        title: 'Xuất kết quả lọc ra file Excel',
        size: 'medium',
        disabled: () => false,
        hidden: () => this.isChoosedMode,
        click: (event, option, context, controlEle) => {
          controlEle.disabled = true;
          this.exportFilteredItems().then(() => {
            controlEle.disabled = false;
          }).catch(err => {
            controlEle.disabled = false;
            console.error(err);
          });
          return false;
        },
      });

      this.actionButtonList.unshift({
        name: 'rebuildCache',
        status: 'info',
        label: this.cms.textTransform(this.cms.translate.instant('Lập chỉ mục'), 'head-title'),
        icon: 'layers-outline',
        title: this.cms.textTransform(this.cms.translate.instant('Gửi yêu cầu lập chỉ mục tìm kiếm sản phẩm'), 'head-title'),
        size: 'medium',
        disabled: () => false,
        hidden: () => this.isChoosedMode,
        click: () => {
          this.cms.showDialog('Cập nhật chỉ mục tìm kiếm sản phẩm', 'Gửi yêu cầu cập nhật chỉ mục tìm kiếm sản phẩm, hệ thống sẽ tự động lập chỉ mục vào thông báo thay đổi đến các máy bán hàng POS và app Smart-BOT', [
            {
              label: 'Đóng',
              status: 'basic',
              outline: true,
              action: () => {
                return true;
              },
            },
            {
              label: 'Gửi yêu cầu',
              status: 'primary',
              action: () => {
                this.apiService.postPromise('/admin-product/products', { forceRebuildSearchIndex: true }, []).then(rs => {
                  this.cms.showToast('Đã gửi yêu cầu lập chỉ mục tìm kiếm sản phẩm, hệ thống sẽ tự động lập chỉ mục vào thông báo thay đổi đến các máy bán hàng POS và app Smart-BOT', 'Đã gửi yêu cầu', { status: 'success' });
                });
              },
            },
          ])
          return false;
        },
      });

      const processingMap = AppModule.processMaps['commercePos'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
          }),
          headerName: 'ĐVT',
          field: 'UnitConversions',
          width: 200,
          valueGetter: (params: { data: ProductModel }) => {
            const baseUnitId = this.cms.getObjectId(params.data?.WarehouseUnit);
            const baseUnitText = this.cms.getObjectText(params.data?.WarehouseUnit);
            return params.data?.UnitConversions?.map(unit => {
              let text = '';
              if (baseUnitId == unit?.id) {
                text = unit.text;
                unit.icon = 'checkmark-square-outline';
                // unit.status = 'primary';
              } else {
                text = `${unit.text} = ${unit.ConversionRatio} ${baseUnitText}`;
              }
              // unit.toolTip = `${text} (${unit.IsAutoAdjustInventory ? 'Trừ kho tự động' : 'Không tự động trừ kho'}, ${unit.IsManageByAccessNumber ? 'Quản lý theo số truy xuất' : 'Không quản lý theo số truy xuất'})`;
              unit.toolTip = `${text}: ${this.cms.currencyPipe.transform(unit.Price, 'VND')}`;
              unit.status = 'warning';
              // if (unit.IsManageByAccessNumber) {
              //   unit.status = 'danger';
              // }
              // if (!unit.IsAutoAdjustInventory) {
              //   unit.status = 'warning';
              // }
              unit.label = (baseUnitId != unit?.id && `${unit.text} (${baseUnitId == unit?.id ? '*' : unit.ConversionRatio})` || unit.text) + ': ' + this.cms.currencyPipe.transform(unit.Price, 'VND');
              return unit;
            });
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/units', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Barcode',
          field: 'Barcode',
          // pinned: 'left',
          width: 180,
          filter: 'agTextColumnFilter',
        },
        // {
        //   headerName: 'Người tạo',
        //   field: 'Creator',
        //   // pinned: 'left',
        //   width: 200,
        //   cellRenderer: AgTextCellRenderer,
        //   filter: AgSelect2Filter,
        //   filterParams: {
        //     select2Option: {
        //       ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
        //         placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
        //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        //           return item;
        //         }
        //       }),
        //       multiple: true,
        //       logic: 'OR',
        //       allowClear: true,
        //     }
        //   },
        // },
        // {
        //   headerName: 'Ngày tạo',
        //   field: 'Created',
        //   width: 180,
        //   filter: 'agDateColumnFilter',
        //   filterParams: {
        //     inRangeFloatingFilterDateFormat: 'DD/MM/YY',
        //   },
        //   cellRenderer: AgDateCellRenderer,
        // },
        // {
        //   headerName: 'Người cập nhật',
        //   field: 'LastUpdateBy',
        //   // pinned: 'left',
        //   width: 200,
        //   cellRenderer: AgTextCellRenderer,
        //   filter: AgSelect2Filter,
        //   filterParams: {
        //     select2Option: {
        //       ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
        //         placeholder: 'Chọn người cập nhật...', limit: 10, prepareReaultItem: (item) => {
        //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        //           return item;
        //         }
        //       }),
        //       multiple: true,
        //       logic: 'OR',
        //       allowClear: true,
        //     }
        //   },
        // },
        // {
        //   headerName: 'Ngày cập nhật',
        //   field: 'LastUpdate',
        //   width: 180,
        //   filter: 'agDateColumnFilter',
        //   filterParams: {
        //     inRangeFloatingFilterDateFormat: 'DD/MM/YY',
        //   },
        //   cellRenderer: AgDateCellRenderer,
        // },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID SP MB',
          field: 'RefCode',
          width: 180,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Sku SB BM',
          field: 'RefSku',
          width: 180,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Page',
          field: 'Page',
          width: 180,
        },
        {
          ...agMakeCommandColDef(this, this.cms, (data) => {
            this.openForm([data.Code]);
          }, (data) => {
            this.deleteConfirm([data.Code]);
          }, false, [
            {
              name: 'revisions',
              icon: 'clock-outline',
              status: 'warning',
              appendTo: 'head',
              outline: false,
              action: async (params) => {
                this.apiService.getPromise<ProductModel[]>(this.apiPath + '/' + this.makeId(params.node.data), { includeRevisions: true }).then(products => {
                  if (products[0].Revisions) {
                    this.cms.openDialog(ProductListComponent, {
                      context: {
                        title: 'Lịch sử cập nhật của sản phẩm : ' + products[0].Name,
                        height: '95vh',
                        width: '95vw',
                        rowModelType: 'clientSide',
                        idKey: ['Code'],
                        rowData: products[0].Revisions,
                        onInit: (component) => {
                          // component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh', 'confirm'].indexOf(f.name) > -1);
                          const cmdColIndex = component.columnDefs.findIndex(f => f.field == 'Command');
                          component.columnDefs.splice(cmdColIndex - 1, 0, {
                            headerName: 'Ngày lưu trữ',
                            field: 'RevisionDate',
                            width: 180,
                            filter: 'agDateColumnFilter',
                            pinned: 'right',
                            filterParams: {
                              inRangeFloatingFilterDateFormat: 'DD/MM/YY',
                            },
                            cellRenderer: AgDateCellRenderer,
                          });
                        }
                      }
                    })
                  }
                })
                return true;
              }
            }
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      if (this.onInit) {
        this.onInit(this);
      }
      return rs;
    });
  }

  editing = {};
  rows = [];

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeCategories'] = true;
    params['includeGroups'] = true;
    params['includeWarehouseUnit'] = true;
    // params['includeUnits'] = true;
    params['embedUnitConversions'] = true;
    params['includeCreator'] = true;
    params['includeLastUpdateBy'] = true;
    params['includePrice'] = true;
    params['page'] = this.cms.getObjectId(this.b2bServerService.currentpage$.value) || 'all';
    // params['sort_Id'] = 'desc';
    if (this.inputQuery) {
      for (const key in this.inputQuery) {
        params[key] = this.inputQuery[key];
      }
    }
    return params;
  }

  /** Api get funciton */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductModel[] | HttpErrorResponse) => void) {
    params['includeCategories'] = true;
    super.executeGet(params, success, error, complete);
  }

  getList(callback: (list: ProductModel[]) => void) {
    super.getList((rs) => {
      if (callback) callback(rs);
    });
  }

  /** Implement required */
  async openAssignCategoiesDialplog() {
    if (this.selectedIds.length > 0) {
      const editedItems = await this.convertIdsToItems(this.selectedIds);
      this.cms.openDialog(AssignCategoriesFormComponent, {
        context: {
          inputMode: 'dialog',
          inputProducts: this.selectedItems,
          onDialogSave: (newData: ProductModel[]) => {
            // this.refresh();
            this.updateGridItems(editedItems, newData);
          },
          onDialogClose: () => {
          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    }
  }

  openFormDialplog(ids?: string[], onDialogSave?: (newData: ProductModel[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }

  async exportFilteredItems() {


    this.cms.openDialog(ProgressDialogComponent, {
      context: {
        title: 'Tiến trình export thông tin sản phẩm B2B',
        onInit: async (dialog) => {

          return true;
        },
        actions: [
          {
            label: 'Trở về',
            status: 'basic',
            outline: true,
            action: async () => {
              return true;
            },
          },
          {
            label: 'Bắt đầu',
            status: 'danger',
            icon: 'arrow-right-outline',
            outline: true,
            action: async (btn, dialog) => {
              dialog.processing = true;
              dialog.progressStatus = 'danger';

              const data = [];
              const filterModel = this.gridApi.getFilterModel();
              let query = this.parseFilterToApiParams(filterModel);
              query = this.prepareApiParams(query, null);
              // query.page = 'all';
              query.limit = 20;
              query.offset = 0;
              let rs = null;

              let total = 0;
              let loaded = 0;

              dialog.progress = 1;
              do {

                rs = await this.apiService.getObservable<ProductModel[]>(this.apiPath, query).pipe(
                  map((res) => {
                    if (!total) {
                      total = +res.headers.get('x-total-count');
                    }
                    let data = res.body;
                    return data;
                  }),
                ).toPromise();

                for (const i in rs) {
                  rs[i]['WarehouseUnit'] = this.cms.getObjectId(rs[i].WarehouseUnit);
                  rs[i]['WarehouseUnitLabel'] = this.cms.getObjectText(rs[i].WarehouseUnit);
                  for (const u in rs[i].UnitConversions) {
                    rs[i]['Unit' + (parseInt(u) + 1)] = this.cms.getObjectId(rs[i].UnitConversions[u]);
                    rs[i]['UnitLabel' + (parseInt(u) + 1)] = this.cms.getObjectText(rs[i].UnitConversions[u]);
                    rs[i]['ConversionRatio' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].ConversionRatio;
                    rs[i]['IsDefaultSales' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].IsDefaultSales;
                    rs[i]['IsDefaultPurchase' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].IsDefaultPurchase;
                    rs[i]['IsManageByAccessNumber' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].IsManageByAccessNumber;
                    rs[i]['IsAutoAdjustInventory' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].IsAutoAdjustInventory;
                    rs[i]['IsExpirationGoods' + (parseInt(u) + 1)] = rs[i].UnitConversions[u].IsExpirationGoods;
                  }
                  for (const u in rs[i].Properties) {
                    rs[i]['Property' + (parseInt(u) + 1)] = this.cms.getObjectId(rs[i].Properties[u]);
                    rs[i]['PropertyValues' + (parseInt(u) + 1)] = rs[i].Properties[u];
                  }
                  for (const c in rs[i].Categories) {
                    rs[i]['Category' + (parseInt(c) + 1)] = this.cms.getObjectId(rs[i].Categories[c]);
                    rs[i]['CategoryName' + (parseInt(c) + 1)] = this.cms.getObjectText(rs[i].Categories[c]);
                  }
                  rs[i]['FeaturePicture'] = rs[i].FeaturePicture?.OriginImage;
                  for (const p in rs[i].Pictures) {
                    rs[i]['Picture' + (parseInt(p) + 1)] = rs[i].Pictures[p].OriginImage;
                  }
                  data.push(rs[i]);
                  loaded++;
                  dialog.progress = loaded / total * 100;
                  dialog.label = rs[i].Name;
                  dialog.progressLabel = parseInt(dialog.progress + '') + '%';
                }

                query.offset += query.limit;




              } while (rs.length == query.limit);


              const sheet = XLSX.utils.json_to_sheet(data);
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, sheet, 'B2B Product');
              XLSX.writeFile(workbook, 'B2Bproduct-' + (new Date().toLocaleDateString()) + '.xlsx');

              return true;
            },
          },
        ]
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });



  }
}
