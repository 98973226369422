import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class SystemStateMap {

  static ACTIVE: ProcessMap = {
    state: 'ACTIVE',
    label: 'Kích hoạt',
    id: 'ACTIVE',
    text: 'Kích hoạt',
    confirmLabel: 'Kích hoạt',
    status: 'success',
    color: 'green',
    outline: true,
    confirmTitle: 'Kích hoạt',
    confirmText: 'Bạn có muốn kích hoạt ?',
    responseTitle: 'Đã kích hoạt',
    responseText: 'Đã kích hoạt thành công',
  };

  static INACTIVE: ProcessMap = {
    state: 'INACTIVE',
    label: 'Không kích hoạt',
    id: 'INACTIVE',
    text: 'Không kích hoạt',
    confirmLabel: 'Ngưng kích hoạt',
    status: 'basic',
    color: 'white',
    outline: true,
    confirmTitle: 'Ngưng kích hoạt',
    confirmText: 'Bạn có muốn ngưng kích hoạt ?',
    responseTitle: 'Đã đã ngưng kích hoạt',
    responseText: 'Đã đã ngưng kích hoạt thành công',
  };


  static webhookStateMap: { [key: string]: ProcessMap } = {
    "INACTIVE": {
      ...SystemStateMap.INACTIVE,
      nextStates: [
        {...SystemStateMap.ACTIVE, outline: false},
      ],
    },
    "ACTIVE": {
      ...SystemStateMap.ACTIVE,
      nextStates: [
        {...SystemStateMap.INACTIVE, outline: false},
      ],
    },
  };

}
