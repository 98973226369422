import {Component, OnInit} from '@angular/core';
import {DataManagerPrintComponent} from '../../../../../lib/data-manager/data-manager-print.component';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {NbDialogRef} from '@nebular/theme';
import {environment} from '../../../../../../environments/environment.prod';
import {AppModule} from '../../../../../app.module';
import {Model} from '../../../../../models/model';
import {ProcessMap} from '../../../../../models/process-map.model';
import {ApiService} from '../../../../../services/api.service';
import {CommonService} from '../../../../../services/common.service';
import {RootServices} from '../../../../../services/root.services';
import * as XLSX from 'xlsx';
import {SystemApiDocFormComponent} from '../system-api-doc-form/system-api-doc-form.component';

@Component({
  selector: 'ngx-system-api-doc-print',
  templateUrl: './system-api-doc-print.component.html',
  styleUrls: ['./system-api-doc-print.component.scss']
})
export class SystemApiDocPrintComponent extends DataManagerPrintComponent<Model> implements OnInit {

  /** Component name */
  componentName = 'SystemApiDocPrintComponent';
  title: string = '';
  env = environment;
  apiPath = '/code-template/simple-data-managers';
  processMapList: ProcessMap[] = [];
  formDialog = SystemApiDocFormComponent;

  showPicture = true;

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public ref: NbDialogRef<SystemApiDocPrintComponent>,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rsv, cms, router, apiService, ref);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    const result = await super.init().then(rs => {
      this.actionButtonList.unshift({
        name: 'showPicture',
        label: 'Hình ảnh',
        title: 'H.Thị hình',
        status: 'info',
        size: 'medium',
        icon: 'eye-outline',
        click: () => {
          this.showPicture = !this.showPicture;
          return true;
        }
      });
      this.actionButtonList.unshift({
        name: 'downloadPdf',
        label: 'PDF',
        title: 'Download PDF',
        status: 'danger',
        size: 'medium',
        icon: 'download-outline',
        click: () => {
          this.downloadPdf(this.id);
          return true;
        }
      });
      this.actionButtonList.unshift({
        name: 'downaloExcel',
        label: 'Excel',
        title: 'Download Excel',
        status: 'primary',
        size: 'medium',
        icon: 'download-outline',
        click: (event, option) => {
          this.downloadExcel(option?.index);
          return true;
        }
      });
      return rs;
    });
    this.summaryCalculate(this.data);

    return result;
  }

  renderTitle(data: Model) {
    return `PhieuDatHangNCC_${this.getIdentified(data).join('-')}` + (data.DateOfPurchase ? ('_' + this.datePipe.transform(data.DateOfPurchase, 'short')) : '');
  }

  close() {
    this.ref.close();
  }

  renderValue(value: any) {
    if (value && value['text']) {
      return value['text'];
    }
    return value;
  }

  toMoney(detail: Model) {
    if (detail.Type === 'PRODUCT') {
      let toMoney = detail['Quantity'] * detail['Price'];
      detail.Tax = typeof detail.Tax === 'string' ? (this.cms.taxList?.find(f => f.Code === detail.Tax) as any) : detail.Tax;
      if (detail.Tax) {
        if (typeof detail.Tax.Tax == 'undefined') {
          throw Error('tax not as tax model');
        }
        toMoney += toMoney * detail.Tax.Tax / 100;
      }
      return toMoney;
    }
    return 0;
  }

  getTotal() {
    let total = 0;
    return total;
  }

  saveAndClose() {
    if (this.onSaveAndClose) {
    }
    this.close();
    return false;
  }

  exportExcel(type: string) {
    this.close();
    return false;
  }

  get identifier() {
    // return this.data.Code;
    return '';
  }

  async getFormData(ids: string[]) {
    return this.apiService.getPromise<Model[]>(this.apiPath, {id: ids, includeContact: true, includeDetails: true, includeUnit: true, includeRelativeVouchers: true}).then(data => {
      this.summaryCalculate(data);

      for (const item of data) {
        if (!item.Details) {
          item.Details = [];
        }
        this.setDetailsNo(item.Details, (detail: Model) => detail.Type !== 'CATEGORY');
      }

      return data;
    });
  }


  approvedConfirm(data: Model, index: number) {
    if (['COMPLETE'].indexOf(data.State) > -1) {
      this.cms.showDialog(this.cms.translateText('Common.approved'), this.cms.translateText('Common.completedAlert', {object: this.cms.translateText('Sales.PriceReport.title', {definition: '', action: ''}) + ': `' + data.Title + '`'}), [
        {
          label: this.cms.translateText('Common.close'),
          status: 'success',
          action: () => {
            this.onClose(data);
          },
        },
      ]);
      return;
    }
    const params = {id: [data.Code]};
    const processMap = AppModule.processMaps.purchaseVoucher[data.State || ''];
    params['changeState'] = this.processMapList[index]?.nextState;

    this.cms.showDialog(this.cms.translateText('Common.confirm'), this.cms.translateText(processMap?.confirmText, {object: this.cms.translateText('Sales.PriceReport.title', {definition: '', action: ''}) + ': `' + data.Title + '`'}), [
      {
        label: this.cms.translateText('Common.cancel'),
        status: 'primary',
        action: () => {

        },
      },
      {
        label: this.cms.translateText(data.State == 'APPROVED' ? 'Common.complete' : 'Common.approve'),
        status: 'danger',
        action: () => {
          this.loading = true;
          this.apiService.putPromise<Model[]>(this.apiPath, params, [{Code: data.Code}]).then(rs => {
            this.loading = false;
            this.onChange && this.onChange(data);
            this.onClose && this.onClose(data);
            this.close();
            this.cms.toastService.show(this.cms.translateText(processMap?.responseText, {object: this.cms.translateText('Purchase.PrucaseVoucher.title', {definition: '', action: ''}) + ': `' + data.Title + '`'}), this.cms.translateText(processMap?.responseTitle), {
              status: 'success',
            });

          }).catch(err => {
            this.loading = false;
          });
        },
      },
    ]);
  }

  getItemDescription(item: Model) {
    return item?.Title;
  }

  summaryCalculate(data: Model[]) {
    for (const i in data) {
      const item = data[i];
      item['Total'] = 0;
      item['Title'] = this.renderTitle(item);
      if (!item.Details) {
        item.Details = [];
      }
      for (const detail of item.Details) {

        // Generate barcode
        item['Total'] += detail['ToMoney'] = this.toMoney(detail);
      }
      this.processMapList[i] = AppModule.processMaps.purchaseOrder[item.State || ''];
    }
    return data;
  }

  downloadPdf(ids: string[]) {
    window.open(this.apiService.buildApiUrl(this.apiPath, {id: ids, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download'}), '__blank');
  }

  downloadExcel(index: number) {
    // for (const index in ids) {
    const data = this.data[index];
    const details = [];
    let no = 0;
    for (const detail of data.Details) {
      no++;
      details.push({
        STT: no,
        'STT DATA': no,
        'Sku': detail['Product']['Sku'],
        'ProductID': this.cms.getObjectId(detail['Product']),
        'ProductName/Tên Sản Phẩm': detail['Product']['Name'],
        'SupplierSku/Mã SP nội bộ NCC': detail['SupplierSku'],
        'SupplierProductName/Tên SP nội bộ NCC': detail['SupplierProductName'],
        'SupplierProductTaxName/Tên SP theo thuế': detail['ProductTaxName'],
        'SupplierTax/thuế suất %': detail['Tax'],
        'Unit/Mã ĐVT': this.cms.getObjectId(detail['Unit']),
        'UnitName/Tên ĐVT': this.cms.getObjectText(detail['Unit']),
        'Price/Đơn Giá': detail['Price'],
        'Quantity/Số lượng': detail['Quantity'],
        'ToMoney/Thành tiền': detail['ToMoney'],
      });
    }
    const sheet = XLSX.utils.json_to_sheet(details);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Chi tiết đơn đặt mua hàng');
    XLSX.writeFile(workbook, 'DDMH-' + data.Code + ' - ' + data.Title + ' - NCC: ' + this.cms.getObjectId(data.Object) + ' - ' + data.ObjectName + '.xlsx');
  }

  // }

  /**
   * Save a text as file using HTML <a> temporary element and Blob
   * @see https://stackoverflow.com/questions/49988202/macos-webview-download-a-html5-blob-file
   * @param fileName String
   * @param fileContents String JSON String
   * @author Loreto Parisi
   */
  saveBlobAsFile(fileName: string, fileContents: string) {
    if (typeof (Blob) != 'undefined') { // using Blob
      const textFileAsBlob = new Blob([fileContents], {type: 'application/pdf'});
      const downloadLink: any = document.createElement("a");
      downloadLink.download = fileName;
      if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
      } else {
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.onclick = document.body.removeChild(event.target as any);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
      }
      downloadLink.click();
    } else {
      const pp = document.createElement('a');
      pp.setAttribute('href', 'data:application/pdf;charset=utf-8,' + encodeURIComponent(fileContents));
      pp.setAttribute('download', fileName);
      pp.onclick = document.body.removeChild(event.target as any);
      pp.click();
    }
  } // saveBlobAsFile

}
