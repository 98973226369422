import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { RootServices } from '../../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { B2bProductBrandFormComponent } from '../product-brand-form/product-brand-form.component';
import { AgGridDataManagerListComponent } from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgTextCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgSelect2Filter } from '../../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { ProductBrandModel } from '../../../../../models/product.model';

@Component({
  selector: 'ngx-product-brand-list',
  templateUrl: './product-brand-list.component.html',
  styleUrls: ['./product-brand-list.component.scss'],
})
export class B2bProductBrandListComponent extends AgGridDataManagerListComponent<ProductBrandModel, B2bProductBrandFormComponent> implements OnInit {

  componentName: string = 'B2bProductBrandListComponent';
  formPath = '/b2b-server/product/brand/form';
  apiPath = '/b2b-server/product/brands';
  idKey = ['Code'];
  formDialog = B2bProductBrandFormComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<B2bProductBrandListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 180,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 500,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          pinned: 'left',
          width: 200,
        },
        {
          headerName: 'Thương hiệu cha',
          field: 'Parent',
          // pinned: 'left',
          width: 300,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/b2b-center/product/brands', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn thương hiệu cha...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<ProductBrandModel, B2bProductBrandFormComponent>) {
    params['includeParent'] = true;
    return params;
  }

}

