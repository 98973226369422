import {DatePipe} from '@angular/common';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NbDialogRef} from '@nebular/theme';
import {environment} from '../../../../../../environments/environment.prod';
import {DataManagerPrintComponent} from '../../../../../lib/data-manager/data-manager-print.component';
import {CommercePosOrderModel} from '../../../../../models/commerce-pos.model';
import {ProcessMap} from '../../../../../models/process-map.model';
import {WarehouseGoodsContainerModel, WarehouseGoodsDeliveryNoteDetailModel, WarehouseGoodsDeliveryNoteModel} from '../../../../../models/warehouse.model';
import {ApiService} from '../../../../../services/api.service';
import {CommonService} from '../../../../../services/common.service';
import {RootServices} from '../../../../../services/root.services';

@Component({
  selector: 'ngx-b2b-purchase-pop-bill-print',
  templateUrl: './b2b-purchase-pop-bill-print.component.html',
  styleUrls: ['./b2b-purchase-pop-bill-print.component.scss'],
})
export class B2bPurchasePopBillPrintComponent extends DataManagerPrintComponent<any> implements OnInit {

  /** Component name */
  componentName = 'CommercePosBillPrintComponentZ';
  title: string = 'PHIẾU ĐẶT MUA HÀNG';
  env = environment;
  apiPath = '/b2b-center/purchase/orders';
  processMapList: ProcessMap[] = [];
  idKey: ['Code'];
  // formDialog = WarehouseGoodsFormComponent;

  @ViewChild('paymentBtn') paymentBtn: ElementRef;
  @ViewChild('printBtn') printBtn: ElementRef;

  @Input() skipPreview: boolean;
  @Input() order: CommercePosOrderModel;
  @Input() instantPayment: boolean;
  @Input() printType: 'PRICEREPORT' | 'RETAILINVOICE' = 'PRICEREPORT';
  @Input() type: 'PRICEREPORT' | 'COMMERCEPOSORDER' = 'COMMERCEPOSORDER';

  registerInfo: any;
  paymentState = 'CLOSEORDER';

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public ref: NbDialogRef<B2bPurchasePopBillPrintComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, cms, router, apiService, ref);
    this.cms.systemConfigs$.subscribe(registerInfo => {
      this.registerInfo = registerInfo.LICENSE_INFO.register;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    if (this.printType == 'PRICEREPORT') {
      this.title = 'PHIẾU ĐẶT MUA HÀNG';
    }
    if (this.printType == 'RETAILINVOICE') {
      this.title = 'PHIẾU ĐẶT MUA HÀNG';
    }
    const result = await super.init().then(rs => {
      const printActionButn = this.actionButtonList.find(f => f.name == 'print');
      if (printActionButn) {
        printActionButn.disabled = (option) => {
          return ['APPROVED', this.paymentState].indexOf(this.data[0].State) < 0;
        };
      }
      return rs;
    });
    if (this.data[0].State == this.paymentState) {
      this.printBtn && this.printBtn['hostElement']?.nativeElement.focus();
    } else {
      this.paymentBtn && this.paymentBtn['hostElement']?.nativeElement.focus();
    }

    this.summaryCalculate(this.data);
    if (this.instantPayment) {
      if (this.type == 'PRICEREPORT') {
        this.saveAndPrint(0, {print: false});
      } else {
        this.payment(0, {print: false});
      }
    }
    return result;
  }

  renderTitle(data: CommercePosOrderModel) {
    return `QRCode_Kho_Ngan_Ke_${this.getIdentified(data).join('-')}` + (data.DateOfDelivered ? ('_' + this.datePipe.transform(data.DateOfDelivered, 'short')) : '');
  }

  close() {
    super.close();
    // this.ref.close();
  }

  renderValue(value: any) {
    if (value && value['text']) {
      return value['text'];
    }
    return value;
  }

  toMoney(detail: WarehouseGoodsDeliveryNoteDetailModel) {
    if (detail.Type === 'PRODUCT') {
      let toMoney = detail['Quantity'] * detail['Price'];
      detail.Tax = typeof detail.Tax === 'string' ? (this.cms.taxList?.find(f => f.Code === detail.Tax) as any) : detail.Tax;
      if (detail.Tax) {
        if (typeof detail.Tax.Tax == 'undefined') {
          throw Error('tax not as tax model');
        }
        toMoney += toMoney * detail.Tax.Tax / 100;
      }
      return toMoney;
    }
    return 0;
  }

  getTotal() {
    let total = 0;
    // const details = this.data.Details;
    // for (const detail of details) {
    //   total += parseFloat(detail.ToMoney);
    // }
    return total;
  }

  saveAndClose() {
    if (this.onSaveAndClose) {
      // this.onSaveAndClose(this.data.Code);
    }
    this.close();
    return false;
  }

  exportExcel(type: string) {
    this.close();
    return false;
  }

  get identifier() {
    // return this.data.Code;
    return '';
  }

  async getFormData(ids: string[]) {
    return this.apiService.getPromise<WarehouseGoodsContainerModel[]>(this.apiPath, {
      includeWarehouse: true,
      renderBarCode: true,
      includeGroups: true,
      includeUnit: true,
      includeFeaturePicture: true,
      group_Unit: true,
      includeContainers: true,
      id: this.id,
      limit: 'nolimit',
      includeDetails: true, includeSalesObject: true, includeRelativeVouchers: true
    }).then(rs => {
      return rs;
    });
  }


  getItemDescription(item: WarehouseGoodsDeliveryNoteModel) {
    return item?.Description;
  }

  summaryCalculate(data: CommercePosOrderModel[]) {
    if (data) {
      for (const item of data) {
        if (item.Details) {
          let total = 0;
          for (const detail of item.Details) {
            // detail['ToMoney'] = (detail.Quantity * detail.Price);
            total += parseFloat(detail['ToMoney']);
          }
          item['Total'] = total;
        }
        item['Debit'] = (item.Amount - item.DecreaseForTotal) - (item.CashAmount + item.CashTransferAmount);
        item['Credit'] = (item.CashAmount + item.CashTransferAmount) - (item.Amount - item.DecreaseForTotal) - item.CashBack;
      }
    }
  }

  isProcessing = false;

  payment(index: number, option?: { print: boolean }) {
    const params: any = {payment: true, includeRelativeVouchers: true, includeSalesObject: true};
    let order = this.data[index];
    if (order) {
      // order.State = 'APPROVED';
      if (order.Code) {
        // params['id0'] = order.Code;
        this.isProcessing = true;
        if (params.payment) {
          order.State = this.paymentState;
          if (!order.DateOfOrder) {
            order.DateOfOrder = new Date().toISOString();
          }
        }
        this.apiService.putPromise('/b2b-center/purchase/orders/' + order.Code, params, [order]).then(rs => {
          order = this.data[index] = rs[0];
          setTimeout(async () => {
            if (option?.print) {
              await this.print(index, this.printType);
            }
            if (this.onSaveAndClose) this.onSaveAndClose(order, this);
          });
          this.isProcessing = false;
        }).catch(err => {
          this.isProcessing = false;
          return Promise.reject(err);
        });
      } else {
        this.isProcessing = true;
        order.State = this.paymentState;
        this.apiService.postPromise('/b2b-center/purchase/orders', params, [order]).then(rs => {
          this.id = [rs[0].Code];
          order = this.data[index] = rs[0];
          setTimeout(async () => {
            // await this.print(index);
            if (option?.print) {
              await this.print(index, this.printType);
            }
            if (this.onSaveAndClose) this.onSaveAndClose(order, this);
          }, 300);
          this.isProcessing = false;
        }).catch(err => {
          this.isProcessing = false;
          return Promise.reject(err);
        });

      }
    }
    return true;
  }

  saveAndPrint(index: number, option?: { print: boolean }) {
    const params: any = {changeState: 'PRICEREPORT', includeRelativeVouchers: true, includeSalesObject: true};
    let order = this.data[index];
    if (order) {
      // order.State = 'APPROVED';
      if (order.Code) {
        // params['id0'] = order.Code;
        this.isProcessing = true;
        this.apiService.putPromise('/b2b-center/purchase/orders/' + order.Code, params, [order]).then(rs => {
          order = this.data[index] = rs[0];
          setTimeout(async () => {
            if (option?.print) {
              await this.print(index, this.printType);
            }
            if (this.onSaveAndClose) this.onSaveAndClose(order, this);
          });
          this.isProcessing = false;
        }).catch(err => {
          this.isProcessing = false;
          return Promise.reject(err);
        });
      } else {
        this.isProcessing = true;
        this.apiService.postPromise('/b2b-center/purchase/orders', params, [order]).then(rs => {
          this.id = [rs[0].Code];
          order = this.data[index] = rs[0];
          setTimeout(async () => {
            // await this.print(index);
            if (option?.print) {
              await this.print(index, this.printType);
            }
            if (this.onSaveAndClose) this.onSaveAndClose(order, this);
          }, 300);
          this.isProcessing = false;
        }).catch(err => {
          this.isProcessing = false;
          return Promise.reject(err);
        });

      }
    }
    return true;
  }

  onKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'F9') {

      if (!this.instantPayment) {
        if (this.cms.getObjectId(this.data[0].State) == this.paymentState) {
          this.print(0, 'RETAILINVOICE');
        } else {
          this.payment(0);
        }
      } else {
        if (this.cms.getObjectId(this.data[0].State) == this.paymentState || this.cms.getObjectId(this.data[0].State) == 'PRICEREPORT') {
          this.print(0, 'RETAILINVOICE').then(() => {
            this.close();
          });
        } else {
          this.cms.toastService.show('Bạn vui lòng chờ cho hệ thống xử lý xong đơn hàng này !', 'Chưa thể in bill !', {status: 'warning'});
        }
      }
      return false;
    }
    if (event.key == 'Enter') {

      if (!this.instantPayment) {
        if (this.cms.getObjectId(this.data[0].State) == this.paymentState || this.cms.getObjectId(this.data[0].State) == 'PRICEREPORT') {
          this.print(0, 'PRICEREPORT');
        } else {
          this.payment(0);
        }
      } else {
        if (this.cms.getObjectId(this.data[0].State) == this.paymentState || this.cms.getObjectId(this.data[0].State) == 'PRICEREPORT') {
          this.print(0, 'PRICEREPORT').then(() => {
            this.close();
          });
        } else {
          this.cms.toastService.show('Bạn vui lòng chờ cho hệ thống xử lý xong đơn hàng này !', 'Chưa thể in bill !', {status: 'warning'});
        }
      }
      return false;
    }
    return true;
  }

  async print(index?: number, voucherType?: string) {
    // if (voucherType == 'PRICEREPORT') {
    if (this.cms.getObjectId(this.data[0].State) == 'PRICEREPORT') {
      this.title = 'PHIẾU ĐẶT MUA HÀNG';
      // } else if (voucherType == 'RETAILINVOICE') {
    } else if (this.cms.getObjectId(this.data[0].State) == this.paymentState) {
      this.title = 'PHIẾU ĐẶT MUA HÀNG';
    }
    await new Promise(resolve => setTimeout(() => resolve(true), 300));
    return super.print(index);
  }

}
