import {HelpdeskComponent} from './helpdesk.component';
// import { CurrencyMaskModule } from 'ng2-currency-mask';
// import { DialogModule } from '../dialog/dialog.module';
import {HelpdeskDashboardComponent} from './dashboard/helpdesk-dashboard/helpdesk-dashboard.component';
import {HelpdeskOrdersChartComponent} from './charts/orders-chart.component';
import {QuickTicketFormComponent} from './dashboard/quick-ticket-form/quick-ticket-form.component';
import {UserExtensionListComponent} from './user-extensions/user-extension-list/user-extension-list.component';
import {UserExtensionFormComponent} from './user-extensions/user-extension-form/user-extension-form.component';
import {TicketPmsFormComponent} from './dashboard/ticket-pms-form/ticket-pms-form.component';
import {HelpdeskRouteListComponent} from './route/helpdesk-route-list/helpdesk-route-list.component';
import {HelpdeskRouteFormComponent} from './route/helpdesk-route-form/helpdesk-route-form.component';
// import { CKEditorModule } from 'ng2-ckeditor';
import {HelpdeskParamListComponent} from './param/helpdesk-param-list/helpdesk-param-list.component';
import {HelpdeskParamFormComponent} from './param/helpdesk-param-form/helpdesk-param-form.component';
import {HelpdeskActionListComponent} from './action/helpdesk-action-list/helpdesk-action-list.component';
import {HelpdeskActionFormComponent} from './action/helpdesk-action-form/helpdesk-action-form.component';
import {HelpdeskProcedureListComponent} from './procedure/helpdesk-procedure-list/helpdesk-procedure-list.component';
import {HelpdeskProcedureFormComponent} from './procedure/helpdesk-procedure-form/helpdesk-procedure-form.component';
import {NgModule} from '@angular/core';
import {commonModules, commonProviders} from '../../common-modules';
import {HelpdeskRoutingModule} from './helpdesk-routing.module';
import {HelpdeskTicketListComponent} from "./ticket/helpdesk-ticket-list/helpdesk-ticket-list.component";
import {HelpdeskTicketFormComponent} from "./ticket/helpdesk-ticket-form/helpdesk-ticket-form.component";
import {NbLayoutModule} from "@nebular/theme";

@NgModule({
  declarations: [
    HelpdeskComponent,
    // HelpdeskTicketListComponentBk,
    // HelpdeskTicketFormComponentBk,
    HelpdeskDashboardComponent,
    HelpdeskOrdersChartComponent,
    QuickTicketFormComponent,
    UserExtensionListComponent,
    UserExtensionFormComponent,
    TicketPmsFormComponent,
    HelpdeskRouteListComponent,
    HelpdeskRouteFormComponent,
    HelpdeskParamListComponent,
    HelpdeskParamFormComponent,
    HelpdeskActionListComponent,
    HelpdeskActionFormComponent,
    HelpdeskProcedureListComponent,
    HelpdeskProcedureFormComponent,
    HelpdeskTicketListComponent,
    HelpdeskTicketFormComponent,
  ],
  imports: [
    ...commonModules,
    HelpdeskRoutingModule,
    NbLayoutModule,
  ],
  bootstrap: [HelpdeskComponent],
  providers: [
    ...commonProviders,
  ],
})
export class HelpdeskModule { }