<nb-card class="form-detail-item" size="small">
  <nb-card-header>{{ props.Type | objecttext }}</nb-card-header>
  <nb-card-body>
    <div>POST</div>
    <div>Url: /io/webhook/order</div>
  </nb-card-body>
  <nb-card-footer>
    <button type="button" nbButton status="warning" hero size="small" outline (click)="editNode() && false">
      <nb-icon pack="eva" icon="edit"></nb-icon>
      Edit
    </button>
  </nb-card-footer>
</nb-card>
