<div class="dialog-wrap popup" [ngStyle]="{width: width || '90w'}">
  <nb-card [ngStyle]="cardStyle" [nbSpinner]="processing">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div [formGroup]="formGroup" #formEle>
        <div class="row">
          <div [class]="control.class || 'col-sm-12'" *ngFor="let control of controls">
            <div class="form-group" [attr.state]="formGroup.controls[control.name] && formGroup.controls[control.name].status == 'INVALID' ? 'warning' : ''">
              <label class="label">
                <span>{{ control.label }}</span>
                &nbsp;
                <span *ngIf="formGroup.controls[control.name].errors" class="invalid">({{ errorMap[formGroup.controls[control.name].errors.required && 'required'] }})</span>
              </label>
              <input [name]="control.name" *ngIf="!control.type || control.type == 'text'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder">
              <input [name]="control.name" *ngIf="control.type == 'currency'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder" currencyMask [options]="currencyFormat">
              <input [name]="control.name" *ngIf="control.type == 'number'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder" [inputMask]="numberFormat" style="text-align: right">
              <ngx-file-input *ngIf="control.type == 'file'" [name]="control.name" [alowUploadByLink]="true"></ngx-file-input>
              <textarea style="max-height: 100px" class="scrollable-container" [name]="control.name" *ngIf="control.type == 'textarea'" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder"></textarea>
              <ngx-select2 [name]="control.name" [formControlName]="control.name" *ngIf="control.type == 'select2'" [select2Option]="control.option"></ngx-select2>
              <nb-radio-group [name]="control.name" [formControlName]="control.name" *ngIf="control.type == 'radio'">
                <nb-radio *ngFor="let option of control.option.data" [value]="option.id">
                  {{ option.text }}
                </nb-radio>
              </nb-radio-group>
              <ng-container *ngIf="control.type == 'datetime'">
                <input type="text" [owlDateTime]="DateTimePicker" [owlDateTimeTrigger]="DateTimePicker" nbInput fullWidth [name]="control.name" [formControlName]="control.name" [placeholder]="control.placeholder">
                <owl-date-time #DateTimePicker></owl-date-time>
              </ng-container>
              <ng-container *ngIf="control.type == 'date'">
                <input type="text" [owlDateTime]="DateTimePicker" [owlDateTimeTrigger]="DateTimePicker" nbInput fullWidth [name]="control.name" [formControlName]="control.name" [placeholder]="control.placeholder">
                <owl-date-time #DateTimePicker [pickerType]="'calendar'"></owl-date-time>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button #buttonsRef [disabled]="processing || !formGroup.valid" [outline]="item?.outline" *ngFor="let item of actions" nbButton hero [status]="item?.status" (click)="onAction(item, formGroup)" [disabled]="checkButtonDisabled(item, formGroup)"><nb-icon *ngIf="item?.icon" [pack]="'eva'" [icon]="item.icon"></nb-icon>{{ item?.label }}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
