import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {RootServices} from '../../../../services/root.services';
import {Router} from '@angular/router';
import {CommonService} from '../../../../services/common.service';
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {DatePipe, DecimalPipe} from '@angular/common';
import {agMakeSelectionColDef} from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import {AgTextCellRenderer} from '../../../../lib/custom-element/ag-list/cell/text.component';
import {AgSelect2Filter} from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import {AgDateCellRenderer} from '../../../../lib/custom-element/ag-list/cell/date.component';
import {ColDef, IGetRowsParams} from '@ag-grid-community/core';
import {AgGridDataManagerListComponent} from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import {FormGroup} from '@angular/forms';
import {DialogFormAction, DialogFormComponent} from '../../../../lib/component/dialog/dialog-form/dialog-form.component';
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {CommercePosOrderModel} from "../../../../models/commerce-pos.model";
import {CommercePosOrderFormComponent} from "../../../commerce-pos/commerce-pos-order/commerce-pos-order-form/commerce-pos-order-form.component";
import {agMakeImageColDef} from "../../../../lib/custom-element/ag-list/column-define/image.define";
import {agMakeNumberColDef} from "../../../../lib/custom-element/ag-list/column-define/number.define";
import {AdminProductService} from "../../../admin-product/admin-product.service";
import {agMakeButtonsColDef} from "../../../../lib/custom-element/ag-list/column-define/buttons.define";
import {ProductModel, ProductUnitModel} from "../../../../models/product.model";
import {PurchaseOrderVoucherModel} from "../../../../models/purchase.model";
import {ContactModel} from "../../../../models/contact.model";
import {IRowNode} from "ag-grid-community";
import {agMakeStateColDef} from "../../../../lib/custom-element/ag-list/column-define/state.define";
import {WarehouseStateMap} from "../../warehouse.state.map";
import {B2bCenterStateMap} from "../../../b2b-center/b2b-center.state.map";
import {WarehouseInventoryAdjustNoteModel} from "../../../../models/warehouse.model";

@Component({
  selector: 'ngx-warehouse-goods-queue',
  templateUrl: './warehouse-goods-queue.component.html',
  styleUrls: ['./warehouse-goods-queue.component.scss'],
  providers: [DecimalPipe]
})
export class WarehouseGoodsQueueComponent extends AgGridDataManagerListComponent<CommercePosOrderModel, CommercePosOrderFormComponent> implements OnInit {

  feature = {
    Module: {id: 'Warehouse', text: 'Kho bãi'},
    Feature: {id: 'GoodsQueue', text: 'Hàng đợi'},
  };

  componentName: string = 'WarehouseGoodsQueueComponent';
// formPath = '/commerce-pos/commerce-pos-order/form';
  apiPath = '/warehouse/goods-queues';
  idKey = ['Id'];
// formDialog = CommercePosOrderFormComponent;
// printDialog = CommercePosOrderPrintComponent;

// AG-Grid config
  public rowHeight: number = 50;
// @Input() gridHeight = '100%';
// @Input() suppressRowClickSelection = false;

  paymentMethodMap = {
    CASH: {id: 'CASH', text: 'Tiền mặt'},
    BANKTRANSFER: {id: 'BANKTRANSFER', text: 'Chuyển khoản'},
    DEBT: {id: 'DEBT', text: 'Công nợ'},
    MIXED: {id: 'MIXED', text: 'Hỗn hợp'},
  };

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<WarehouseGoodsQueueComponent>,
    public datePipe: DatePipe,
    public productService: AdminProductService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node?.data?.Id',
          initialSort: 'asc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          field: 'Thumbnail',
          width: 100,
        },
        {
          headerName: 'ID',
          field: 'Goods',
          width: 140,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          width: 120,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 350,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
        },
        {
          headerName: 'Kho',
          field: 'Warehouse',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/warehouses', {onlyIdText: true}, {
                placeholder: 'Chọn kho...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Kệ',
          field: 'ContainerShelf',
          pinned: 'right',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', {onlyIdText: true, eq_Type: 'SHELF'}, {
                placeholder: 'Chọn kệ...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Vị trí',
          field: 'Container',
          width: 300,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', {onlyIdText: true}, {
                placeholder: 'Chọn vị trí...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = '[' + item['FindOrder'] + ']' + item['text'] + ' (' + item['id'] + ')';
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', {includeIdText: true, includeGroups: true, sort_Name: 'asc'}, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Tồn kho',
          field: 'Inventory',
          pinned: 'right',
          width: 120,
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Tồn thực tế',
          field: 'RealInventory',
          pinned: 'right',
          width: 120,
        },
        {
          headerName: 'ĐVT',
          field: 'Unit',
          pinned: 'right',
          width: 100,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              placeholder: 'Chọn ĐVT...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              withThumbnail: false,
              keyMap: {
                id: 'id',
                text: 'text',
              },
              multiple: true,
              logic: 'OR',
              data: this.productService.unitList$.value,
            }
          },
        },
        {
          headerName: 'SL Yêu cầu đặt hàng',
          field: 'RequestOrderQuantity',
          width: '250px',
        },
        {
          ...agMakeButtonsColDef(this.cms, [
            {
              name: 'inventoryAdjust',
              label: 'Kiểm kho',
              status: 'danger',
              outline: false,
              icon: 'archive-outline',
              disabled: (data, params) => {
                return ['OUTQUEUE', 'INVENTORYADJUST'].indexOf(this.cms.getObjectId(params?.node?.data?.State)) > -1;
              },
              action: async (params: { node: Partial<IRowNode>, data: any }, brnConfig, btn) => {
                this.loading = true;
                return this.pushGoodsToInventoryAdjustQueue(params.data, params.data.Unit, params.data.Container, params.data.RealInventory, '').then(po => {
                  return this.apiService.putPromise(this.apiPath + '/' + params.data.Id, {}, [{Id: params.data.Id, State: 'SENT2INVENTORYADJUST'}]).then(rs => {
                    return this.refreshItems([params.data.Id]).then(rs => true);
                  });
                }).then(rs => {
                  this.loading = false;
                  return rs;
                }).catch(err => {
                  console.error(err);
                  this.loading = false;
                  return Promise.reject(err);
                });
                return true;
              }
            },
          ]),
          headerName: 'Action',
          width: 200,
          resizable: true,
        },
        {
          ...agMakeStateColDef(this.cms, WarehouseStateMap.goodsQueueStateMap, (data, params) => {
            // this.preview([data]);
            this.openChangeStateDialog(data, 'State', WarehouseStateMap.goodsQueueStateMap);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          headerName: 'DL',
          field: 'IsDeleted',
          width: '1px',
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, true, false),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

// @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
//   return 123;
// }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeCreator'] = true;
    params['includeObject'] = true;
    params['includeRelativeVouchers'] = true;
    params['includeUnit'] = true;
// params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: CommercePosOrderModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(CommercePosOrderFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: CommercePosOrderModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

// async getFormData(ids: string[]) {
//   return this.apiService.getPromise<CommercePosOrderModel[]>('/sales/commerce-pos-orders', { id: ids, includeContact: true, includeDetails: true, useBaseTimezone: true });
// }

  onGridReady(params) {
    super.onGridReady(params);
  }

  async pushGoodsToPurchaseOrderQueue(supplier: ContactModel, product: ProductModel, unit: ProductUnitModel, quantity: number, description?: string, price?: number) {
    let queuePo = await this.apiService.getPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', {eq_State: 'INQUEUE', eq_Object: supplier.id, sort_Id: 'desc', includeDetails: true}).then(rs => rs[0]);
    if (!queuePo) {
      const contact = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + supplier.id).then(rs => rs[0]);
      queuePo = await this.apiService.postPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers', {eq_State: 'INQUEUE', Object: supplier.id, sort_Id: 'desc'}, [{
        Object: this.cms.getObjectId(supplier),
        ObjectName: supplier.text,
        ObjectPhone: contact.Phone,
        ObjectEmail: contact.Email,
        ObjectAddress: contact.Address,
        ObjectIdentified: contact.IdNumber,
        State: 'INQUEUE',
        Title: `Đặt hàng ${supplier.text} ${new Date().toLocaleString()}`,
      }]).then(rs => rs[0]);

      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, {changeState: 'INQUEUE'}, [{Code: queuePo.Code}]).then(rs => rs[0]);

    }
    if (queuePo) {
      if (!queuePo.Details) {
        queuePo.Details = [];
      }

      if (queuePo.Details.findIndex(f => this.cms.getObjectId(f.Product) == this.cms.getObjectId(product.Goods) && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit)) > -1) {
        this.cms.showError('Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code);
        return Promise.reject({errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code]});
      }

      const productInfo = await this.apiService.getPromise<ProductModel[]>('/admin-product/products/' + this.cms.getObjectId(product.Goods), {includeUnitConversions: true}).then(rs => rs[0]);
      const business = [];
      if (productInfo && productInfo.UnitConversions) {
        // this.cms.showError('Không tìm thấy thông tin sản phẩm ');
        // return Promise.reject({ errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code] });
        const unitConversion = productInfo.UnitConversions.find(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit));
        if (unitConversion) {
          if (unitConversion.IsAutoAdjustInventory) {
            business.push({
              id: 'PURCHASEWAREHOUSE',
              text: 'Nhập kho & mua hàng (công nợ)',
            });
          } else {
            business.push({
              id: 'PURCHASESKIPWAREHOUSE',
              text: 'Mua hàng không qua kho (công nợ)',
            });
          }
        }
      }

      queuePo.Details.push({
        Type: 'PRODUCT',
        Business: business,
        Image: [
          ...(product.Thumbnail ? [product.Thumbnail] : []),
          ...(product.FeaturePicture ? [product.FeaturePicture] : []),
          ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
        ],
        Product: product.Goods,
        Unit: unit,
        Quantity: quantity || 0,
        Price: price,
        Description: product.Name + (description ? (' - ' + description) : ''),
      });
      queuePo.DateOfOrder = new Date().toISOString();
      queuePo = await this.apiService.putPromise<PurchaseOrderVoucherModel[]>('/purchase/order-vouchers/' + queuePo.Code, {}, [queuePo]).then(rs => rs[0]);

      this.cms.showToast('Đã thêm hàng hóa vào phiếu đặt mua hàng ' + queuePo.Code, 'Thành công');

    } else {
      this.cms.showError('Không thể đặt hàng cho ' + product.Name + ' (' + product.UnitLabel + ')');
    }
    return queuePo;
  }

  async pushGoodsToInventoryAdjustQueue(product: ProductModel, unit: ProductUnitModel, container, quantity: number, description?: string, price?: number) {
    let inventoryAdjust = await this.apiService.getPromise<WarehouseInventoryAdjustNoteModel[]>('/warehouse/inventory-adjust-notes', {eq_State: 'INQUEUE', sort_Id: 'desc', select: 'Id,Code,State', includeDetails: true}).then(rs => rs[0]);
    if (!inventoryAdjust) {
      // const contact = await this.apiService.getPromise<ContactModel[]>('/contact/contacts/' + supplier.id).then(rs => rs[0]);
      inventoryAdjust = await this.apiService.postPromise<WarehouseInventoryAdjustNoteModel[]>('/warehouse/inventory-adjust-notes', {eq_State: 'INQUEUE', sort_Id: 'desc'}, [{
        // Object: this.cms.getObjectId(supplier),
        // ObjectName: supplier.text,
        // ObjectPhone: contact.Phone,
        // SalesObjectEmail: contact.Email,
        // SalesObjectAddress: contact.Address,
        // SalesObjectIdentified: contact.IdNumber,
        State: 'INQUEUE',
        Title: `Kiểm kho từ hàng đợi ${new Date().toLocaleString()}`,
      }]).then(rs => rs[0]);

      inventoryAdjust = await this.apiService.putPromise<WarehouseInventoryAdjustNoteModel[]>('/warehouse/inventory-adjust-notes/' + inventoryAdjust.Code, {changeState: 'INQUEUE'}, [{Code: inventoryAdjust.Code}]).then(rs => rs[0]);

    }
    if (inventoryAdjust) {
      if (!inventoryAdjust.Details) {
        inventoryAdjust.Details = [];
      }

      if (inventoryAdjust.Details.findIndex(f => this.cms.getObjectId(f.Product) == this.cms.getObjectId(product.Goods) && this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit) && this.cms.getObjectId(f.Container) == this.cms.getObjectId(container)) > -1) {
        this.cms.showError('Hàng hóa đã có trong phiếu kiểm kho ' + inventoryAdjust.Code);
        return Promise.reject({errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong kiểm kho ' + inventoryAdjust.Code]});
      }

      const productInfo = await this.apiService.getPromise<ProductModel[]>('/admin-product/products/' + this.cms.getObjectId(product.Goods), {includeUnitConversions: true}).then(rs => rs[0]);
      // const business = [];
      // if (productInfo && productInfo.UnitConversions) {
      //   const unitConversion = productInfo.UnitConversions.find(f => this.cms.getObjectId(f.Unit) == this.cms.getObjectId(unit));
      //   if (unitConversion) {
      //     if (unitConversion.IsAutoAdjustInventory) {
      //       business.push({
      //         id: 'PURCHASEWAREHOUSE',
      //         text: 'Nhập kho & mua hàng (công nợ)',
      //       });
      //     } else {
      //       business.push({
      //         id: 'PURCHASESKIPWAREHOUSE',
      //         text: 'Mua hàng không qua kho (công nợ)',
      //       });
      //     }
      //   }
      // }

      inventoryAdjust.Details.push({
        Type: 'PRODUCT',
        // Business: business,
        PurchaseImage: [
          ...(product.Thumbnail ? [product.Thumbnail] : []),
          ...(product.FeaturePicture ? [product.FeaturePicture] : []),
          ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
        ],
        PurchaseProduct: product.Goods,
        PurchaseUnit: unit,
        Quantity: quantity || 0,
        Price: price,
        Description: product.Name + (description ? (' - ' + description) : ''),
      });
      inventoryAdjust.DateOfAdjusted = new Date().toISOString();
      inventoryAdjust = await this.apiService.putPromise<WarehouseInventoryAdjustNoteModel[]>('/b2b-center/purchase/orders/' + inventoryAdjust.Code, {}, [inventoryAdjust]).then(rs => rs[0]);

      this.cms.showToast('Đã thêm hàng hóa vào phiếu đặt mua hàng ' + inventoryAdjust.Code, 'Thành công');

    } else {
      this.cms.showError('Không thể đặt hàng cho ' + product.Name + ' (' + product.UnitLabel + ')');
    }
    return inventoryAdjust;
  }
}
