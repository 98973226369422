import {take, filter} from 'rxjs/operators';
import {CommonService} from '../../services/common.service';
import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {NbAuthService} from '@nebular/auth';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PageModel} from '../../models/page.model';
import {Model} from '../../models/model';

@Injectable({
  providedIn: 'root'
})
export class B2bCenterService {

  pageList$ = new BehaviorSubject<PageModel[]>([]);
  currentpage$: BehaviorSubject<string>;

  constructor(
    public cms: CommonService,
    public apiService: ApiService,
    public authService: NbAuthService,
  ) {

    let isAuthenticatedSubscription: Subscription = null;
    this.currentpage$ = new BehaviorSubject<string>(null);

    this.authService.isAuthenticated().subscribe(async isAuthenticated => {
      if (isAuthenticated) {

        // wait for first authentication success
        await this.apiService.getPromise<PageModel[]>('/b2b-center/pages', {onlyIdText: true, eq_Type: 'B2BCENTER'}).then(pageList => {
          this.pageList$.next([{id: '', text: ''}, ...pageList]);
        });

        const loginInfo = await this.cms.loginInfo$.pipe(filter(f => !!f), take(1)).toPromise();
        // store current page on change
        isAuthenticatedSubscription = this.currentpage$.subscribe(async value => {
          const loginInfo = await this.cms.loginInfo$.pipe(filter(f => !!f), take(1)).toPromise();
          if (loginInfo && loginInfo.user?.Code) {
            localStorage.setItem(loginInfo.user.Code + '.b2bcenter.page', typeof value === 'undefined' ? '' : value);
          }
        });

        // Load current apge form local store
        const currenPageCache = localStorage.getItem(loginInfo.user.Code + '.b2bcenter.page');
        this.currentpage$.next(currenPageCache);

      } else {
        if (isAuthenticatedSubscription) {
          isAuthenticatedSubscription.unsubscribe();
        }
      }
    });

  }

  async pushProductsToPlatform(productIds?: Model[], option?: { progress: (loaded: number, totla: number, item: any) => void }): Promise<any> {
    if (this.currentpage$.value) {
      const toast = this.cms.showToast('Đang đẩy thông tin sản phẩm lên sàn B2B Center, tiến trình sẽ chạy không dừng, hãy giữ nguyên màn hình để theo dõi trạng thái', 'Đang đẩy thông tin sản phẩm', {status: 'warning', duration: 0});
      return this.apiService.putProgress<Model[]>('/b2b-center/product/publics/' + this.currentpage$.value, {page: this.cms.getObjectId(this.currentpage$.value), syncAllProduct: true}, [], progressInfo => {
        if (option && option.progress) {
          option.progress(progressInfo.loaded, progressInfo.total, progressInfo['item']);
        }
      }).then(rs => {
        toast.close();
        this.cms.showToast('Đã đẩy thông tin sản phẩm lên sàn B2B Center', 'Đẩy thông tin sản phẩm thành công', {status: 'success'});
        return rs;
      }).catch(err => {
        toast.close();
        if (err.error?.errorCode == 1062) {
          // Dupplicate
          this.cms.showError('Thông tin sản phẩm đã tồn tại !');
        } else {
          this.cms.showError(err, {duration: 30000});
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject('Chưa chọn shop cần đẩy thông tin sản phẩm');
  }

  async pushProductMapsToPlatform(productIds?: Model[], option?: { progress: (loaded: number, totla: number, item: any) => void }): Promise<any> {
    if (this.currentpage$.value) {
      const toast = this.cms.showToast('Đang đẩy thông tin sản phẩm lên sàn B2B Center, tiến trình sẽ chạy không dừng, hãy giữ nguyên màn hình để theo dõi trạng thái', 'Đang đẩy thông tin sản phẩm', {status: 'warning', duration: 0});
      return this.apiService.putProgress<Model[]>('/b2b-center/product/maps/' + this.currentpage$.value, {page: this.cms.getObjectId(this.currentpage$.value), syncAllProductMap: true}, [], progressInfo => {
        if (option && option.progress) {
          option.progress(progressInfo.loaded, progressInfo.total, progressInfo['item']);
        }
      }).then(rs => {
        toast.close();
        this.cms.showToast('Đã đẩy thông tin sản phẩm lên sàn B2B Center', 'Đẩy thông tin sản phẩm thành công', {status: 'success'});
        return rs;
      }).catch(err => {
        toast.close();
        if (err.error?.errorCode == 1062) {
          // Dupplicate
          this.cms.showError('Thông tin sản phẩm đã tồn tại !');
        } else {
          this.cms.showError(err, {duration: 30000});
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject('Chưa chọn shop cần đẩy thông tin sản phẩm');
  }

  /**
   * Pull current page orders
   */
  async pullSalesOrders(orders?: string[]) {
    return this.apiService.putPromise('/b2b-center/sales/orders', {pullOrders: true, page: this.cms.getObjectId(this.currentpage$.value), orders: orders?.join(',') || null}, []);
  }

  async pullPurchaseOrders(orders?: string[]) {
    return this.apiService.putPromise('/b2b-center/purchase/orders', {pullOrders: true, page: this.cms.getObjectId(this.currentpage$.value), orders: orders?.join(',') || null}, []);
  }
}
