<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup" style="height: calc(100vh - 2rem);">
  <div formArrayName="array" style="height: 100%">
    <div *ngFor="let formItem of array.controls; let i = index" style="height: 100%">
      <div [formGroup]="formItem" style="height: 100%">
        <div class="row" style="height: 100%; justify-content: center;">
          <div class="col-sm-4" style="height: 100%; padding-top: 1rem; padding-bottom: 1rem">
            <nb-card class="small-header popup" style="height: 100%;">
              <nb-card-body style="padding-right: 2rem;">
                <textarea nbInput fullWidth class="scrollable-container" style="height: 100%">{{ input | json }}</textarea>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-sm-4" style="height: 100%; margin-left: -2rem; margin-right: -2rem; z-index: 1;">
            <nb-card class="small-header popup" style="height: 100%">
              <nb-card-header>
                <div class="nb-card-header-content">
                  <div class="nb-card-header-title">Node: Webhook</div>
                  <div class="nb-card-header-actions">
                    <button nbButton size="small" status="danger" (click)="close() && false">Close</button>
                  </div>
                </div>
              </nb-card-header>
              <nb-card-body>
                <div>
                  <div class="row">
                    <div class="col-sm-12">
                      <ngx-form-group [formGroup]="formItem" name="Path" label="{{'Path' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <input type="text" nbInput fullWidth formControlName="Path" placeholder="{{'Path' | translate | headtitlecase}}">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-12">
                      <ngx-form-group [formGroup]="formItem" name="WebhookUrl" label="{{'Webhook Url' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <input type="text" nbInput fullWidth disabled placeholder="{{'Webhook Url' | translate | headtitlecase}}" value="{{env.api.baseUrl}}/io/webhook/{{formItem.get('Path').value}}">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-12">
                      <ngx-form-group [formGroup]="formItem" name="HttpMethod" label="{{'HttpMethod' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <nb-select formControlName="HttpMethod" placeholder="Select method">
                          <nb-option value="GET">GET</nb-option>
                          <nb-option value="POST">POST</nb-option>
                          <nb-option value="PUT">PUT</nb-option>
                          <nb-option value="DELETE">DELETE</nb-option>
                        </nb-select>
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-12">
                      <ngx-form-group [formGroup]="formItem" name="AuthType" label="{{'Auth Type' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <input type="text" nbInput fullWidth formControlName="AuthType" placeholder="{{'Auth Type' | translate | headtitlecase}}">
                      </ngx-form-group>
                    </div>
                    <div class="col-sm-12">
                      <ngx-form-group [formGroup]="formItem" name="Credential" label="{{'Credential' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <input type="text" nbInput fullWidth formControlName="Credential" placeholder="{{'Credential' | translate | headtitlecase}}">
                      </ngx-form-group>
                    </div>
                  </div>
                </div>
              </nb-card-body>
              <nb-card-footer>
                <button nbButton status="success" [disabled]="!form.valid || isProcessing" hero (click)="listenHook() && false">{{ 'Listen' | translate | headtitlecase }}</button>
                <button nbButton status="danger" [disabled]="!form.valid || isProcessing" hero (click)="saveAndClose()">{{ 'Common.saveAndClose' | translate | headtitlecase }}</button>
              </nb-card-footer>
            </nb-card>
          </div>
          <div class="col-sm-4" style="height: 100%; padding-top: 1rem; padding-bottom: 1rem">
            <nb-card class="small-header popup" style="height: 100%">
              <nb-card-body style="padding-left: 2rem;">
                <textarea nbInput formControlName="Body" fullWidth class="scrollable-container" style="height: 100%">{{ output | json }}</textarea>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
