import { ColDef, Column, ColumnApi, GridApi, IDoesFilterPassParams } from "@ag-grid-community/core";

import { Component } from "@angular/core";
import { IFilterAngularComp } from "@ag-grid-community/angular";
import { CommonService } from "../../../../services/common.service";

@Component({
    template: `
    <button nbButton fullWidth ghost [outline]="false" [status]="'danger'" (click)="resetFilter() && false" [size]="'small'" nbTooltip="Đặt lại bộ lọc và sắp xếp">
        <nb-icon pack="eva" [icon]="'funnel-outline'"></nb-icon> Reset
    </button>
    `
})
export class AgCommandFilter implements IFilterAngularComp {
    // inputControl = new FormControl();
    // select2Option: Select2Option & { data?: () => any[] };
    // logic: 'AND' | 'OR' = 'AND';
    data: any[] = [];
    condition = 'filter';

    public params!: { api: GridApi, column: Column, columnApi: ColumnApi, filterParams: any, [key: string]: any };

    constructor(
        public cms: CommonService,
    ) {

    }

    resetFilter() {
        this.params.api.setFilterModel(null);
        const columnState = this.params.columnApi.getColumnState();
        this.params.columnApi['columnModel']?.columnDefs?.forEach((colDef: ColDef) => {
            const colState = columnState.find(f => f.colId == colDef.field);
            if (colState) {
                if (colDef.initialSort) {
                    if (colState) {
                        colState.sort = colDef.initialSort;
                    }
                } else {
                    colState.sort = null;
                }
            }
        });

        this.params.columnApi.applyColumnState({ state: columnState, applyOrder: true });

        return true;
    }

    agInit(params: any): void {
        this.params = params;
    }

    onFilterChanged($event: any) {
        if (!this.settingModel) {
            this.params.filterChangedCallback();
        }
    }

    getModel() {
        return null;
    }

    settingModel = false;
    setModel(model: any) {

    }

    doesFilterPass(params: IDoesFilterPassParams) {
        return true;
    }

    public isFilterActive() {
        return true;
    }

    helloFromSkillsFilter() {
        alert("Hello From The Skills Filter!");
    }

    getModelAsString() {
        return '';
    }
}
