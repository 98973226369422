import {Component, OnInit} from "@angular/core";
import {AgGridDataManagerListComponent} from "../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {ContactModel} from "../../../../models/contact.model";
import {ContactFormComponent} from "../../../contact/contact/contact-form/contact-form.component";
import {ApiService} from '../../../../services/api.service';
import {RootServices} from '../../../../services/root.services';
import {Router} from "@angular/router";
import {CommonService} from "../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {DatePipe} from "@angular/common";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef} from "@ag-grid-community/core";
import {agMakeTextColDef} from '../../../../lib/custom-element/ag-list/column-define/text.define';
import {CollaboratorService} from '../../collaborator.service';
import {AgSelect2Filter} from "../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import {AgCellButton} from "../../../../lib/custom-element/ag-list/cell/button.component";
import {PageModel} from "../../../../models/page.model";
import {DialogFormComponent} from "../../../../lib/component/dialog/dialog-form/dialog-form.component";
import {FormGroup} from "@angular/forms";
import {AppModule} from "../../../../app.module";
import {ProcessMap} from "../../../../models/process-map.model";
import {HttpErrorResponse} from "@angular/common/http";
import {AgTextCellRenderer} from "../../../../lib/custom-element/ag-list/cell/text.component";
import {CollaboratorCustomerFormComponent} from "../collaborator-customer-form/collaborator-customer-form.component";
import {agMakeImageColDef} from "../../../../lib/custom-element/ag-list/column-define/image.define";

@Component({
  selector: 'ngx-collaborator-customer-list',
  templateUrl: './collaborator-customer-list.component.html',
  styleUrls: ['./collaborator-customer-list.component.scss']
})
export class CollaboratorCustomerListComponent extends AgGridDataManagerListComponent<ContactModel, ContactFormComponent> implements OnInit {

  // Use for load settings menu for context
  feature = {
    Module: {id: 'Collaborator', text: 'CRM'},
    Feature: {id: 'Customer', text: 'Khách hàng'}
  };

  componentName: string = 'CollaboratorCustomerListComponent';
  formPath = '/collaborator/publisher/form';
  apiPath = '/collaborator/contacts';
  idKey = ['Code'];
  formDialog = CollaboratorCustomerFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorCustomerListComponent>,
    public datePipe: DatePipe,
    public collaboratorService: CollaboratorService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      this.actionButtonList = this.actionButtonList.filter(f => ['add'].indexOf(f.name) < 0);

      await this.cms.waitForLanguageLoaded();
      const processingMap: { [key: string]: ProcessMap } = AppModule.processMaps['publisher'];
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'Avatar',
          width: 100,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID Liên hệ',
          field: 'Code',
          width: 155,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Cá nhân/Tổ chức',
          field: 'ContactType',
          width: 100,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Danh xưng',
          field: 'Title',
          width: 100,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên rút gọn/Bí danh',
          field: 'ShortName',
          width: 100,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên Lưu trong liên hệ',
          field: 'Name',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'FirstName',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Họ',
          field: 'LastName',
          width: 100,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Số di động',
          field: 'Phone',
          width: 120,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Số tổng đài',
          field: 'CallcenterNumber',
          width: 120,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Email',
          field: 'Email',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Địa chỉ',
          field: 'Address',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Phường/Xã',
          field: 'Ward',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/general/locations', {includeIdText: true, eq_Type: 'WARD'}, {
                placeholder: 'Phường/Xã...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Quận/Huyện',
          field: 'District',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/general/locations', {includeIdText: true, eq_Type: 'DISTRICT'}, {
                placeholder: 'Quận/Huyện...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tỉnh/TP',
          field: 'Province',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/general/locations', {includeIdText: true, eq_Type: 'PROVINCE'}, {
                placeholder: 'Tỉnh/TP...', limit: 10, prepareResultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID App ProBox.vn',
          field: 'PublisherRefId',
          width: 200,
          valueGetter: (params) => {
            if (params.node?.data?.Publisher?.text) {
              return {
                ...params.node?.data?.Publisher,
                text: `<div>${params.node.data.Publisher.text}</div><div>ProBox.vn/${params.node.data.Publisher.refUserId}</div>`,
              };
            }
            return params?.node?.data?.Publisher;
          },
          cellRendererParams: {
            colaseButton: {
              label: 'Gán ID App ProBox.vn',
              // icon: '',
              status: 'danger',
              outline: true,
              action: (params => {
                this.cms.openDialog(DialogFormComponent, {
                  context: {
                    title: 'Gán ID App ProBox.vn',
                    width: '600px',
                    onInit: async (form, dialog) => {
                      return true;
                    },
                    controls: [
                      {
                        name: 'UserIDAppProdBox',
                        label: 'ID người dùng app ProBox',
                        placeholder: 'Điền ID của người dùng trên app ProBox...',
                        type: 'text',
                        focus: true,
                        initValue: '',
                      },
                    ],
                    actions: [
                      {
                        label: 'Trở về',
                        icon: 'back',
                        status: 'basic',
                        action: async () => {
                          return true;
                        },
                      },
                      {
                        label: 'Gán',
                        icon: 'link-2-outline',
                        status: 'success',
                        keyShortcut: 'Enter',
                        action: async (form: FormGroup) => {

                          let userIdAppProBox: string[] = form.get('UserIDAppProdBox').value.trim();
                          const pageId = this.cms.getObjectId(this.collaboratorService.currentpage$.value);

                          if (userIdAppProBox && pageId) {
                            this.apiService.putPromise(this.apiPath, {assignProBoxAppUserId: true}, [{Id: params.node?.data?.Id, Page: pageId, Publisher: userIdAppProBox}]).then(rs => {
                              this.cms.showToast('Đã gán ID người dùng trên app ProBox', 'Gán ID thành công', {status: 'success'});
                              this.refreshItems([params.node?.data?.Id]);
                            });
                          }

                          return true;
                        },
                      },
                    ],
                  },
                });
              })
            } as AgCellButton
          }
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false, []),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  executeGet(params: any, success: (resources: ContactModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ContactModel[] | HttpErrorResponse) => void): void {
    if (!params) {
      params = {};
    }
    params['includeCreator'] = true;
    params['includeIdText'] = true;
    params['includeGroups'] = true;
    params['eq_IsDeleted'] = false;
    params['eq_Groups'] = '[CUSTOMER]';
    params['includePublisherRefId'] = true;
    params['sort_Id'] = 'desc';
    return super.executeGet(params, success, error, complete);
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(ContactFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: ContactModel[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }
}
