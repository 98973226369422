import {CustomElementModule} from './../lib/custom-element/custom-element.module';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbListModule,
  NbButtonGroupModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
// import { SmartBotModule } from '../modules/smart-bot/smart-bot.module';
import {ActivityNotificationComponent} from './components/header/header/activity-notification/activity-notification.component';
import {HeaderNotificationContextDirective} from './components/header/header/header-notification-context/header-notification-context.directive';
import {HeaderNotificationContextComponent} from './components/header/header/header-notification-context/header-notification-context.component';
import {FullScreenLayoutComponent} from './layouts/full-screen/full-screen.layout';
import {HeaderSettingsContextComponent} from './components/header/header/settings-context/settings-context.component';
import {HeaderSettingsContextDirective} from './components/header/header/settings-context/settings-context.directive';
import {SmartBotModule} from "../modules/smart-bot/smart-bot.module";

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbListModule,
  SmartBotModule,
  CustomElementModule,
  NbButtonGroupModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  FullScreenLayoutComponent,

  /** Custom */
  ActivityNotificationComponent,
  HeaderNotificationContextDirective,
  HeaderNotificationContextComponent,
  HeaderSettingsContextDirective,
  HeaderSettingsContextComponent,
  /** End Custom */
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
