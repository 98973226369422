import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from "../../services/auth-guard.service";
import {IoWorkflowListComponent} from "./io-workflow/io-workflow-list/io-workflow-list.component";
import {IoWorkflowFormComponent} from "./io-workflow/io-workflow-form/io-workflow-form.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'workflow/list',
    pathMatch: 'full',
  },
  {
    path: 'workflow/list',
    canActivate: [AuthGuardService],
    component: IoWorkflowListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'workflow/form/:id',
    canActivate: [AuthGuardService],
    component: IoWorkflowFormComponent,
  },
  {
    path: 'workflow/form',
    canActivate: [AuthGuardService],
    component: IoWorkflowFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IoRoutingModule {
}
