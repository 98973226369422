// import { SalesModule } from './../../sales.module';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { WordpressOrderFormComponent } from '../order-form/order-form.component';
import { WordpressOrderPrintComponent } from '../order-print/order-print.component';
import { take, takeUntil, filter } from 'rxjs/operators';
import { AppModule } from '../../../../app.module';
import { WpOrderModel, WpSiteModel } from '../../../../models/wordpress.model';
import { WordpressService } from '../../wordpress.service';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';

@Component({
  selector: 'ngx-wordpress-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class WordpressOrderListComponent extends AgGridDataManagerListComponent<WpOrderModel, WordpressOrderFormComponent> implements OnInit {

  feature = {
    Module: {id: 'Wordpress', text: 'Web Bán hàng'},
    Feature: {id: 'Order', text: 'Đơn hàng'},
  };

  componentName: string = 'WpPosOrderListComponent';
  formPath = '/wordpress/order/form';
  apiPath = '/wordpress/orders';
  idKey = ['Code'];
  formDialog = WordpressOrderFormComponent;
  printDialog = WordpressOrderPrintComponent;

  reuseDialog = true;
  static _dialog: NbDialogRef<WordpressOrderListComponent>;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };
  loaded = false;
  siteList: WpSiteModel[];

  // _workingSite: any;
  // set workingSite(value) {
  //   if (!value) {
  //     localStorage.setItem('wordpress_workingsite', null);
  //   } else {
  //     localStorage.setItem('wordpress_workingsite', JSON.stringify({ 'id': this.cms.getObjectId(value), 'text': this.cms.getObjectText(value) }));
  //   }
  //   this._workingSite = value;
  // }
  // get workingSite() {
  //   if (!this._workingSite || !this._workingSite.id) {
  //     this._workingSite = localStorage.getItem('wordpress_workingsite');
  //     if (typeof this._workingSite === 'string') {
  //       this._workingSite = JSON.parse(this._workingSite);
  //     } else {
  //       this._workingSite = null;
  //     }
  //   }
  //   return this._workingSite;
  // }

  currnetSiteId: string = null;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public _http: HttpClient,
    public ref: NbDialogRef<WordpressOrderListComponent>,
    public wordpressService: WordpressService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    return super.init().then(async state => {
      // this.siteList = await this.apiService.getPromise<WpSiteModel[]>('/wordpress/wp-sites', { includeIdText: true }).then(rs => [{ id: 'ALL', text: 'Tất cả' }, ...rs]);
      await this.wordpressService.siteList$.pipe(takeUntil(this.destroy$), filter(f => f && f.length > 0), take(1)).toPromise().then(siteList => {
        this.siteList = siteList;
      });
      this.actionButtonList.unshift({
        type: 'button',
        name: 'unrecord',
        status: 'warning',
        label: 'Bỏ ghi',
        title: 'Bỏ ghi các phiếu đã chọn',
        size: 'medium',
        icon: 'slash-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.cms.showDialog('Đơn hàng POS', 'Bạn có chắc muốn bỏ ghi các đơn hàng đã chọn ?', [
            {
              label: 'Trở về',
              status: 'basic',
              action: () => {
              }
            },
            {
              label: 'Bỏ ghi',
              status: 'warning',
              focus: true,
              action: () => {
                this.apiService.putPromise(this.apiPath, { changeState: 'UNRECORDED' }, this.selectedIds.map(id => ({ Code: id }))).then(rs => {
                  this.cms.toastService.show('Bỏ ghi thành công !', 'Đơn hàng POS', { status: 'success' });
                  this.refresh();
                });
              }
            },
          ]);
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'writetobook',
        status: 'primary',
        label: 'Duyệt',
        title: 'Duyệt các phiếu đã chọn',
        size: 'medium',
        icon: 'checkmark-square-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.cms.showDialog('Đơn hàng POS', 'Bạn có chắc muốn bỏ ghi các đơn hàng đã chọn ?', [
            {
              label: 'Trở về',
              status: 'basic',
              action: () => {
              }
            },
            {
              label: 'Duyệt',
              status: 'primary',
              focus: true,
              action: () => {
                this.apiService.putPromise(this.apiPath, { changeState: 'APPROVED' }, this.selectedIds.map(id => ({ Code: id }))).then(rs => {
                  this.cms.toastService.show('Duyệt thành công !', 'Đơn hàng POS', { status: 'success' });
                  this.refresh();
                });
              }
            },
          ]);
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'downloadOrder',
        status: 'success',
        label: 'Tải đơn WP',
        title: 'Tải các đơn hàng trên wordpress',
        size: 'medium',
        icon: 'checkmark-square-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => {
          return !this.wordpressService.currentSite$?.value;
        },
        click: () => {
          this.apiService.putPromise('/wordpress/orders/' + this.cms.getObjectId(this.wordpressService.currentSite$?.value), { pullWpOrder: true }, [
            {
              Code: this.cms.getObjectId(this.wordpressService.currentSite$?.value),
            }
          ]).then(rs => {
            this.refresh();
          });
        }
      });

      this.actionButtonList.unshift({
        type: 'select2',
        name: 'account',
        status: 'success',
        label: 'Select site',
        icon: 'plus',
        title: 'Site',
        size: 'medium',
        select2: {
          option: {
            placeholder: 'Chọn site...',
            allowClear: false,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            data: this.siteList,
          }
        },
        asyncValue: this.wordpressService.currentSite$,
        change: async (value: any, option: any) => {
          this.wordpressService.currentSite$.next(this.cms.getObjectId(value));
        },
        disabled: () => {
          return this.loading;
        },
        click: () => {
          // this.gotoForm();
          return false;
        },
      });

      const processingMap = AppModule.processMaps['wordpressOrder'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Khách hàng',
          field: 'Object',
          pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Ngày bán hàng',
          field: 'DateOfSale',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareResultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Số tiền',
          field: 'Amount',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);
      return state;
    });

  }


  onFirstDataRendered(): void {
    super.onFirstDataRendered();
    this.wordpressService.currentSite$.pipe(takeUntil(this.destroy$), filter(f => !!f)).subscribe(siteid => {
      if (this.currnetSiteId == siteid) return;
      this.currnetSiteId = siteid;
      this.cms.takeOnce(this.componentName + ' wordpress_change_site', 500).then(async () => {
        // if (this.wordpressService.currentSite$?.value != siteid || this.refCategoryList.length == 0) {
        await this.refresh();
        // if (this.refCategoryList.length == 0) {
        // this.workingSite = value;

        // Get ref categories
        // if (siteid) {
        //   this.refCategoriesLoading = true;
        //   const toastRef = this.cms.showToast('Đang tải danh mục wordpress ' + siteid, 'Tải danh mục wordpress', { status: 'info', duration: 60000 });
        //   this.refCategoryList = await this.apiService.getPromise<any[]>('/wordpress/ref-categories', { site: siteid, limit: 'nolimit', loadByTree: true }).then(rs => {

        //     function extractTreeToList(list: any[], lv?: number): any[] {
        //       let results = [];
        //       lv = lv || 0;
        //       for (const item of list) {
        //         // item.lv = lv;
        //         item.text = item.name;
        //         item.html = (new Array(lv + 1).join('&nbsp;&nbsp;')) + item.name;
        //         // item.text = item.name;
        //         results.push(item);
        //         if (item['children']) {
        //           results = [
        //             ...results,
        //             ...extractTreeToList(item['children'], lv + 1),
        //           ];
        //         }
        //         delete item['children'];
        //       }
        //       return results;
        //     }

        //     const results = extractTreeToList(rs);
        //     return results;
        //     // return rs.map(m => {
        //     //   m.text = m.name;
        //     //   return m;
        //     // });
        //   }).catch(err => {
        //     this.refCategoriesLoading = false;
        //     toastRef.close();
        //     return Promise.reject(err);
        //   });
        //   this.refCategoriesLoading = false;
        //   toastRef.close();
        //   console.log(this.refCategoryList);
        // } else {
        //   this.refCategoryList = [];
        // }
        // }
      });
    });
  }

  editing = {};
  rows = [];

  stateDic = {
    APPROVE: { label: this.cms.translateText('Common.approved'), status: 'success', outline: false },
    IMPLEMENT: { label: this.cms.translateText('Common.implement'), status: 'warning', outline: false },
    // ACCEPTANCEREQUEST: { label: this.cms.translateText('Common.completeRequest'), status: 'primary', outline: false },
    ACCEPTANCE: { label: this.cms.translateText('Common.acceptance'), status: 'info', outline: false },
    COMPLETE: { label: this.cms.translateText('Common.completed'), status: 'success', outline: true },
    CANCEL: { label: this.cms.translateText('Common.cancel'), status: 'info', outline: true },
  };

  // loadListSetting(): any {
  //   return this.configSetting({
  //     mode: 'external',
  //     selectMode: 'multi',
  //     actions: this.isChoosedMode ? false : {
  //       position: 'right',
  //     },
  //     add: this.configAddButton(),
  //     edit: this.configEditButton(),
  //     delete: this.configDeleteButton(),
  //     pager: this.configPaging(),
  //     columns: {
  //       No: {
  //         title: 'No.',
  //         type: 'string',
  //         width: '1%',
  //         filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Code: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.code'), 'head-title'),
  //         type: 'string',
  //         width: '5%',
  //       },
  //       Object: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.Object.title'), 'head-title'),
  //         type: 'string',
  //         width: '20%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //         valuePrepareFunction: (cell: any, row: WpOrderModel) => {
  //           return row.ObjectName;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true }, {
  //                 placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
  //                   item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
  //                   return item;
  //                 }
  //               }),
  //               multiple: true,
  //               logic: 'OR',
  //               allowClear: true,
  //             },
  //           },
  //         },
  //       },
  //       Title: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.title'), 'head-title'),
  //         type: 'string',
  //         width: '15%',
  //         filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Creator: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.creator'), 'head-title'),
  //         type: 'string',
  //         width: '10%',
  //         valuePrepareFunction: (cell: string, row?: any) => {
  //           return this.cms.getObjectText(cell);
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               logic: 'OR',
  //               placeholder: 'Chọn người tạo...',
  //               allowClear: true,
  //               width: '100%',
  //               dropdownAutoWidth: true,
  //               minimumInputLength: 0,
  //               keyMap: {
  //                 id: 'id',
  //                 text: 'text',
  //               },
  //               multiple: true,
  //               ajax: {
  //                 transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
  //                   console.log(settings);
  //                   const params = settings.data;
  //                   this.apiService.getPromise('/user/users', { 'search': params['term'], includeIdText: true }).then(rs => {
  //                     success(rs);
  //                   }).catch(err => {
  //                     console.error(err);
  //                     failure();
  //                   });
  //                 },
  //                 delay: 300,
  //                 processResults: (data: any, params: any) => {
  //                   // console.info(data, params);
  //                   return {
  //                     results: data.map(item => {
  //                       return item;
  //                     }),
  //                   };
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       },
  //       Created: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.dateOfCreated'), 'head-title'),
  //         type: 'custom',
  //         width: '10%',
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableDateRangeFilterComponent,
  //         },
  //         renderComponent: SmartTableDateTimeComponent,
  //         onComponentInitFunction: (instance: SmartTableDateTimeComponent) => {
  //           // instance.format$.next('medium');
  //         },
  //       },
  //       DateOfSale: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Sales.dateOfSales'), 'head-title'),
  //         type: 'custom',
  //         width: '10%',
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableDateRangeFilterComponent,
  //         },
  //         renderComponent: SmartTableDateTimeComponent,
  //         onComponentInitFunction: (instance: SmartTableDateTimeComponent) => {
  //           // instance.format$.next('medium');
  //         },
  //       },
  //       SiteName: {
  //         title: 'Site',
  //         type: 'string',
  //         width: '15%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       RelativeVouchers: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.relationVoucher'), 'head-title'),
  //         type: 'custom',
  //         renderComponent: SmartTableRelativeVouchersComponent,
  //         width: '10%',
  //       },
  //       Amount: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.amount'), 'head-title'),
  //         type: 'currency',
  //         width: '5%',
  //         class: 'align-right',
  //         position: 'right',
  //       },
  //       // IsDebt: {
  //       //   title: this.cms.textTransform(this.cms.translate.instant('Nợ'), 'head-title'),
  //       //   type: 'boolean',
  //       //   width: '5%',
  //       // },
  //       // Copy: {
  //       //   title: 'Copy',
  //       //   type: 'custom',
  //       //   width: '5%',
  //       //   exclude: this.isChoosedMode,
  //       //   renderComponent: SmartTableButtonComponent,
  //       //   onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //       //     instance.iconPack = 'eva';
  //       //     instance.icon = 'copy';
  //       //     instance.display = true;
  //       //     instance.status = 'warning';
  //       //     instance.valueChange.subscribe(value => {
  //       //     });
  //       //     instance.click.subscribe(async (row: WpPosOrderModel) => {

  //       //       this.cms.openDialog(WordpressPosOrderFormComponent, {
  //       //         context: {
  //       //           inputMode: 'dialog',
  //       //           inputId: [row.Code],
  //       //           isDuplicate: true,
  //       //           onDialogSave: (newData: WpPosOrderModel[]) => {
  //       //             // if (onDialogSave) onDialogSave(row);
  //       //           },
  //       //           onDialogClose: () => {
  //       //             // if (onDialogClose) onDialogClose();
  //       //             this.refresh();
  //       //           },
  //       //         },
  //       //       });

  //       //     });
  //       //   },
  //       // },
  //       State: {
  //         title: this.cms.translateText('Common.state'),
  //         type: 'custom',
  //         width: '5%',
  //         // class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'checkmark-circle';
  //           instance.display = true;
  //           instance.status = 'success';
  //           instance.disabled = this.isChoosedMode;
  //           // instance.style = 'text-align: right';
  //           // instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.approved');
  //           instance.label = this.cms.translateText('Common.approved');
  //           instance.valueChange.subscribe(value => {
  //             const processMap = AppModule.processMaps['wordpressOrder'][value || ''];
  //             instance.label = this.cms.translateText(processMap?.label);
  //             instance.status = processMap?.status;
  //             instance.outline = processMap?.outline;
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: WpOrderModel) => {
  //             // this.preview([rowData]);
  //             if (rowData.State == 'PROCESSING') {
  //               this.gotoForm(rowData.Code);
  //             } else {
  //               this.preview([rowData]);
  //             }
  //           });
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               logic: 'OR',
  //               placeholder: 'Chọn trạng thái...',
  //               allowClear: true,
  //               width: '100%',
  //               dropdownAutoWidth: true,
  //               minimumInputLength: 0,
  //               keyMap: {
  //                 id: 'id',
  //                 text: 'text',
  //               },
  //               multiple: true,
  //               data: Object.keys(AppModule.processMaps['wordpressOrder']).map(stateId => ({
  //                 id: stateId,
  //                 text: this.cms.translateText(AppModule.processMaps['wordpressOrder'][stateId].label)
  //               })).filter(f => f.id != '')
  //             },
  //           },
  //         },
  //       },
  //       Permission: {
  //         title: this.cms.translateText('Common.permission'),
  //         type: 'custom',
  //         width: '5%',
  //         class: 'align-right',
  //         exclude: this.isChoosedMode,
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'shield';
  //           instance.display = true;
  //           instance.status = 'danger';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.valueChange.subscribe(value => {
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: WpOrderModel) => {

  //             this.cms.openDialog(ResourcePermissionEditComponent, {
  //               context: {
  //                 inputMode: 'dialog',
  //                 inputId: [rowData.Code],
  //                 note: 'Click vào nút + để thêm 1 phân quyền, mỗi phân quyền bao gồm người được phân quyền và các quyền mà người đó được thao tác',
  //                 resourceName: this.cms.translateText('Sales.WpPosOrder  .title', { action: '', definition: '' }) + ` ${rowData.Title || ''}`,
  //                 // resrouce: rowData,
  //                 apiPath: '/sales/orders',
  //               }
  //             });
  //           });
  //         },
  //       },
  //       Preview: {
  //         title: this.cms.translateText('Common.show'),
  //         type: 'custom',
  //         width: '5%',
  //         class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'external-link-outline';
  //           instance.display = true;
  //           instance.status = 'primary';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.valueChange.subscribe(value => {
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: WpOrderModel) => {
  //             this.preview([rowData]);
  //           });
  //         },
  //       }
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // async getFormData(ids: string[]) {
  //   return this.apiService.getPromise<WpOrderModel[]>('/sales/orders', { id: ids, includeContact: true, includeDetails: true, useBaseTimezone: true });
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams, component?: WordpressOrderListComponent) {
    params['includeCreator'] = true;
    params['includeContact'] = true;
    params['includeRelativeVouchers'] = true;

    if (this.cms.getObjectId(this.wordpressService.currentSite$?.value) != 'ALL' && this.cms.getObjectId(this.wordpressService.currentSite$?.value) != 'NONE') {
      params['eq_Site'] = this.cms.getObjectId(this.wordpressService.currentSite$?.value);
    }

    // params['sort_Id'] = 'desc';
    return params;
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeCreator'] = true;
  //     params['includeContact'] = true;
  //     params['includeRelativeVouchers'] = true;
  //     params['sort_Id'] = 'desc';
  //     if (this.cms.getObjectId(this.wordpressService.currentSite$?.value) !== 'ALL') {
  //       params['eq_Site'] = this.cms.getObjectId(this.wordpressService.currentSite$?.value);
  //     }
  //     // params['eq_Type'] = 'PAYMENT';
  //     return params;
  //   };

  //   return source;
  // }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: UserGroupModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: UserGroupModel[] | HttpErrorResponse) => void) {
  //   params['includeCategories'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  getList(callback: (list: WpOrderModel[]) => void) {
    super.getList((rs) => {
      // rs.map((product: any) => {
      //   product['Unit'] = product['Unit']['Name'];
      //   if (product['Categories']) {
      //     product['CategoriesRendered'] = product['Categories'].map(cate => cate['text']).join(', ');
      //   }
      //   return product;
      // });
      if (callback) callback(rs);
    });
  }

  // async preview(data: WpPosOrderModel[]) {
  //   this.cms.openDialog(WpPosOrderPrintComponent, {
  //     context: {
  //       showLoading: true,
  //       title: 'Xem trước',
  //       id: data.map(m => m[this.idKey]),
  //       mode: 'print',
  //       idKey: ['Code'],
  //       // approvedConfirm: true,
  //       onClose: (data: WpPosOrderModel) => {
  //         this.refresh();
  //       },
  //     },
  //   });
  //   return false;
  // }

  async preview(data: WpOrderModel[], source?: string) {
    this.cms.openDialog(WordpressOrderPrintComponent, {
      context: {
        showLoading: true,
        title: 'Xem trước',
        // data: data,
        // id: data.map(m => m[this.idKey]),
        id: data.map(item => this.makeId(item)),
        sourceOfDialog: 'list',
        mode: 'print',
        idKey: ['Code'],
        // approvedConfirm: true,
        onChange: async (data: WpOrderModel, printComponent: WordpressOrderPrintComponent) => {

          printComponent.close();
          if (data.State === 'PROCESSING') {
            // Get relative vouchers
            // const order = await this.apiService.getPromise('/collaborator/orders/' + data.Code, {includeRelativeVouchers : true});
            // if (data.RelativeVouchers && data.RelativeVouchers.length > 0) {
            // const priceReportRef = data.RelativeVouchers.find(f => f.type === 'PRICEREPORT');
            // if (priceReportRef) {
            // this.cms.openDialog(CollaboratorOrderTeleCommitFormComponent, {
            //   context: {
            //     inputId: [priceReportRef.id],
            //     inputMode: 'dialog',
            //     onDialogSave: async (data) => {
            //       console.log(data);
            //       // setTimeout(() => {
            //       this.refresh();
            //       // }, 1000);
            //     },
            //     onDialogClose: () => { },
            //   }
            // });
            this.gotoForm(data.Code);
            // }
            // }
          } else {
            this.refresh();
          }

        },
        onSaveAndClose: () => {
          this.refresh();
        },
        // onSaveAndClose: () => {
        //   this.refresh();
        // },
      },
    });
    return false;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: WpOrderModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(WordpressOrderFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: WpOrderModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

}
