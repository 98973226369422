import {Component, OnInit} from '@angular/core';
import {AgGridDataManagerListComponent} from "../../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {Model} from "../../../../../models/model";
import {RootServices} from "../../../../../services/root.services";
import {ApiService} from "../../../../../services/api.service";
import {Router} from "@angular/router";
import {CommonService} from "../../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {HttpClient} from "@angular/common/http";
import {CodeTemplateStateMap} from "../../../../code-template/code-template.state.map";
import {agMakeSelectionColDef} from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeTextColDef} from "../../../../../lib/custom-element/ag-list/column-define/text.define";
import {agMakeStateColDef} from "../../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeCommandColDef} from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {SystemApiTokenFormComponent} from "../system-api-token-form/system-api-token-form.component";

@Component({
  selector: 'ngx-system-webhook-list',
  templateUrl: './system-api-token-list.component.html',
  styleUrls: ['./system-api-token-list.component.scss']
})
export class SystemApiTokenListComponent extends AgGridDataManagerListComponent<Model, SystemApiTokenFormComponent> implements OnInit {

  componentName: string = 'SystemApiTokenListComponent';
  apiPath = '/system/api/tokens';
  formPath = '/system/api/token/form';
  idKey = ['SystemUuid'];
  formDialog = SystemApiTokenFormComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<SystemApiTokenListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      const processingMap = CodeTemplateStateMap.simpleDataManagerStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Loại',
          field: 'Type',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Mã',
          field: 'SystemUuid',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Quyền',
          field: 'Roles',
          pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 800,
        },
        // {
        //   ...agMakeStateColDef(this.cms, processingMap, (data) => {
        //     this.preview([data]);
        //   }),
        //   headerName: 'Trạng thái',
        //   field: 'State',
        //   width: 155,
        // },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            {
              name: 'showToken',
              icon: 'copy-outline',
              status: 'info',
              outline: false,
              appendTo: 'head',
              action: async (params) => {
                if (params?.node?.data?.SystemUuid) {
                  const tokenModel = await this.apiService.getPromise(this.apiPath + '/' + this.encodeId(params.node.data.SystemUuid), {getToken: true}).then(rs => rs[0]);
                  if (tokenModel) {
                    this.cms.copyTextToClipboard(tokenModel.Token);
                  }
                }
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
