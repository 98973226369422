<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <div [formGroup]="formItem">
        <nb-card class="small-header popup" [ngStyle]="{width: width, height: height}">
          <nb-card-header>
            <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('IO Workflow' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          </nb-card-header>
          <nb-card-body>
            <div>
              <div class="row">
                <div class="col-sm-4">
                  <ngx-form-group [formGroup]="formItem" name="Name" label="{{'Tên' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Tên' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-4">
                  <ngx-form-group [formGroup]="formItem" name="Description" label="{{'Mô tả' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Mô tả' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-4">
                  <ngx-form-group [formGroup]="formItem" name="SystemUuid" label="{{'ID' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                    <input type="text" nbInput fullWidth formControlName="SystemUuid" placeholder="{{'ID' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div class="buttons-row" style="text-align: right;">
              <button nbButton status="warning" (click)="addNodeFormGroup(formItem)" hero>{{ 'Thêm node' | translate | headtitlecase }}</button>
              <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{ 'Common.saveAndClose' | translate | headtitlecase }}</button>
              <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>{{ 'Common.save' | translate | headtitlecase }}</button>
            </div>
          </nb-card-footer>
        </nb-card>

        <div class="io-workflow-editor" formArrayName="Nodes">
          <div class="form-detail-item workflow-node" size="small" *ngFor="let node of getNodes(formItem).controls; let ic=index" [formGroup]="node">
            <ngx-io-workflow-webhook-node *ngIf="(node.get('Type').value | objectid) == 'WEBHOOK'" [props]="node.value" (onChange)="onNodeChange($event, node)"></ngx-io-workflow-webhook-node>
            <ngx-io-workflow-http-request-node *ngIf="(node.get('Type').value | objectid) == 'HTTPREQUEST'" [props]="node.value" (onChange)="onNodeChange($event, node)"></ngx-io-workflow-http-request-node>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
