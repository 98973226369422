import {ActionControl} from './../../../../lib/custom-element/action-control-list/action-control.interface';
import {WarehouseGoodsContainerPrintComponent} from './../warehouse-goods-container-print/warehouse-goods-container-print.component';
import {Component, Input, OnInit} from '@angular/core';
import {WarehouseGoodsContainerModel} from '../../../../models/warehouse.model';
import {WarehouseGoodsContainerFormComponent} from '../warehouse-goods-container-form/warehouse-goods-container-form.component';
import {ApiService} from '../../../../services/api.service';
import {RootServices} from '../../../../services/root.services';
import {Router} from '@angular/router';
import {CommonService} from '../../../../services/common.service';
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {AgGridDataManagerListComponent} from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import {ColDef, IGetRowsParams} from '@ag-grid-community/core';
import {agMakeCommandColDef} from '../../../../lib/custom-element/ag-list/column-define/command.define';
import {agMakeSelectionColDef} from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import {agMakeTextColDef} from '../../../../lib/custom-element/ag-list/column-define/text.define';
import {AgSelect2Filter} from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import {agMakeImageColDef} from "../../../../lib/custom-element/ag-list/column-define/image.define";
import {agMakeImagesColDef} from "../../../../lib/custom-element/ag-list/column-define/images.define";
import {DialogFormComponent} from "../../../../lib/component/dialog/dialog-form/dialog-form.component";
import {Validators} from "@angular/forms";
import {agMakeDatetimeColDef} from "../../../../lib/custom-element/ag-list/column-define/datetime.define";

@Component({
  selector: 'ngx-warehouse-goods-container-list',
  templateUrl: './warehouse-goods-container-list.component.html',
  styleUrls: ['./warehouse-goods-container-list.component.scss'],
})
export class WarehouseGoodsContainerListComponent extends AgGridDataManagerListComponent<WarehouseGoodsContainerModel, WarehouseGoodsContainerFormComponent> implements OnInit {

  feature = {
    Module: {id: 'Warehouse', text: 'Kho bãi'},
    Feature: {id: 'GoodsContainer', text: 'Vị trí hàng hóa'},
  };

  componentName: string = 'WarehouseGoodsContainerListComponent';
  formPath = '/warehouse/goods-container/form';
  apiPath = '/warehouse/goods-containers';
  idKey = ['Code'];
  formDialog = WarehouseGoodsContainerFormComponent;

  @Input() inputFilter: any;
  @Input() rowHeight = 100;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public themeService: NbThemeService,
    public ref: NbDialogRef<WarehouseGoodsContainerListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  containerTypes = {
    'AREA': 'Khu',
    'SHELF': 'Kệ',
    'CUPBOARD': 'Tủ',
    'FLOOR': 'Tầng',
    'LARGE_DRAWERS': 'Ô chứa',
    'DRAWERS': 'Ngăn',
    'BASKET': 'Rổ',
    'UNKNOW': 'Chưa biết',
  };
  containerTypeList = [
    {id: 'AREA', text: 'Khu'},
    {id: 'SHELF', text: 'Kệ'},
    {id: 'CUPBOARD', text: 'Tủ'},
    {id: 'FLOOR', text: 'Tầng'},
    {id: 'LARGE_DRAWERS', text: 'Ô chứa'},
    {id: 'DRAWERS', text: 'Ngăn'},
    {id: 'BASKET', text: 'Rổ'},
    {id: 'UNKNOW', text: 'Chưa biết'},
  ];

  stateList = [];
  stateMap = [];

  async init() {
    return super.init().then(async rs => {
      this.stateList = await this.apiService.getPromise(this.apiPath + '/states');
      this.stateMap = this.stateList.reduce((stateMap, current) => {
        stateMap[current.id] = current;
        return stateMap;
      }, {});
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node?.data?.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImagesColDef(this.cms, (image, rowData) => {
          }, (rowData) => {
            return rowData.GoodsImage?.map(m => m.LargeImage);
          }),
          headerName: 'Hình',
          field: 'GoodsImages',
          width: 150,
          minWidth: 150,
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Số nhận thức',
          field: 'FindOrder',
          width: 100,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Cha',
          field: 'Parent',
          width: 150,
          valueGetter: params => params?.data?.Shelf ? {
            id: params.data.Shelf,
            text: params.data.ShelfName,
          } : null,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', params => {
                return {
                  searchBy: 'filter_Path',
                  includeIdText: true,
                  ne_Type: 'DRAWERS',
                  sort_Path: 'asc',
                };
              }, {
                placeholder: 'Chọn kệ...', limit: 10, prepareResultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  item['text'] = item['FindOrder'] + ' - ' + item['text'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        // {
        //   ...agMakeTextColDef(this.cms),
        //   headerName: 'Kệ',
        //   field: 'Shelf',
        //   width: 150,
        //   valueGetter: params => params?.data?.Shelf ? {
        //     id: params.data.Shelf,
        //     text: params.data.ShelfName,
        //   } : null,
        //   filter: AgSelect2Filter,
        //   filterParams: {
        //     select2Option: {
        //       ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', {includeIdText: true, eq_Type: 'SHELF', sort_Name: 'asc'}, {
        //         placeholder: 'Chọn kệ...', limit: 10, prepareResultItem: (item) => {
        //           // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        //           item['text'] = item['FindOrder'] + ' - ' + item['text'];
        //           return item;
        //         }
        //       }),
        //       multiple: true,
        //       logic: 'OR',
        //       allowClear: true,
        //     }
        //   },
        // },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Đường dẫn',
          field: 'Path',
          width: 512,
          // minWidth: 250,
          // flex: 1,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          width: 250,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kho',
          field: 'Warehouse',
          width: 250,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/warehouses', {includeIdText: true}, {
                placeholder: 'Chọn kho...', limit: 10, prepareResultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  // item['text'] = item['FindOrder'] + ' - ' + item['text'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Loại',
          field: 'Type',
          width: 80,
          pinned: 'right',
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.select2OptionForTemplate,
              data: this.containerTypeList,
              multiple: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID',
          field: 'Code',
          width: 120,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Cập nhật cuối',
          field: 'LastUpdated',
          width: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Trạng thái',
          field: 'State',
          width: 100,
          valueGetter: params => params?.node?.data?.State && this.stateMap[this.cms.getObjectId(params.node.data.State)] || null,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.select2OptionForTemplate,
              data: this.stateList,
              multiple: true,
            }
          },
        },
        // {
        //   ...agMakeTextColDef(this.cms),
        //   headerName: 'Hàng hóa',
        //   field: 'Goods',
        //   width: 300,
        //   valueGetter: params => (params?.data?.Goods || []).map(m => ({ id: m.Code, text: m.Name })),
        // },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false, [
            {
              name: 'addChildren',
              title: 'Thêm vị trí con',
              status: 'success',
              icon: 'plus-square-outline',
              ghost: true,
              outline: false,
              appendTo: 'head',
              action: async (params, buttonConfig, btn) => {
                this.cms.openDialog(this.formDialog, {
                  context: {
                    // width: '720px',
                    title: 'Tạo vị trí con của ' + params.node.data.Name,
                    data: [
                      {
                        Type: this.cms.getObjectId(params.node.data.Type) == 'SHELF' ? 'FLOOR' : (this.cms.getObjectId(params.node.data.Type) == 'FLOOR' ? 'LARGE_DRAWERS' : null),
                        Warehouse: {
                          id: this.cms.getObjectId(params.node.data.Warehouse),
                          text: params.node.data.WarehouseName,
                        },
                        Parent: {
                          id: params.node.data.Code,
                          text: params.node.data.Name,
                        },
                        AccAccount: params.node.data.AccAccount,
                      }
                    ]
                  },
                });
                return true;
              }
            },
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);


      const previewBtn = this.actionButtonList.find(f => f.name == 'preview');
      previewBtn.label = 'Print QR Code';
      previewBtn.disabled = () => false;
      previewBtn.icon = 'grid-outline';
      previewBtn.click = () => {
        this.cms.openDialog(WarehouseGoodsContainerPrintComponent, {
          context: {
            id: this.selectedItems.map(item => this.makeId(item)),
            printForType: 'DRAWERS',
          }
        });
      };
      const copyBtn: ActionControl = {
        ...previewBtn,
        label: 'Copy',
        title: 'Copy',
        status: 'danger',
        icon: 'copy-outline',
        click: () => {
          this.cms.openDialog(WarehouseGoodsContainerFormComponent, {
            context: {
              showLoading: true,
              inputMode: 'dialog',
              inputId: this.selectedItems.map(item => this.makeId(item)),
              isDuplicate: true,
              onDialogSave: (newData: WarehouseGoodsContainerModel[]) => {
                // if (onDialogSave) onDialogSave(row);
                // this.onClose && this.onClose(newData[0]);
                // this.onSaveAndClose && this.onSaveAndClose(newData[0]);
              },
              onDialogClose: () => {
                // if (onDialogClose) onDialogClose();
                this.refresh();
              },
            },
          });
        }
      };
      let lastMovedWarehouse = null;
      const moveBtn: ActionControl = {
        disabled: () => this.selectedIds.length == 0,
        label: 'Di chuyển',
        title: 'Di chuyển đến vị trí khác',
        status: 'primary',
        size: 'medium',
        icon: 'diagonal-arrow-right-up-outline',
        click: () => {
          let dialogForm: NbDialogRef<DialogFormComponent>;
          dialogForm = this.cms.openDialog(DialogFormComponent, {
            context: {
              title: 'Di chuyển ' + this.selectedIds.length + ' phần tử tới vị trí khác',
              width: '512px',
              controls: [
                {
                  name: 'Warehouse',
                  type: 'select2',
                  label: 'Kho bãi',
                  focus: true,
                  initValue: lastMovedWarehouse,
                  validators: [Validators.required],
                  option: this.cms.makeSelect2AjaxOption('/warehouse/warehouses', params => {
                    return {
                      includeIdText: true,
                    };
                  }, {
                    placeholder: 'Chọn kho...',
                    limit: 10,
                  }),
                },
                {
                  name: 'Parent',
                  type: 'select2',
                  label: 'Vị trí',
                  validators: [Validators.required],
                  option: {
                    ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', params => {
                      return {
                        searchBy: 'filter_Path',
                        includeIdText: true,
                        ne_Type: 'DRAWERS',
                        sort_Path: 'asc',
                        select: 'Parent,Code,Name,Warehouse,Path,FindOrder',
                        eq_Warehouse: this.cms.getObjectId(dialogForm.componentRef.instance.formGroup.get('Warehouse').value),
                      };
                    }, {
                      placeholder: 'Chọn vị trí...',
                      limit: 10,
                    }),
                    allowClear: false,
                  },
                },
              ],
              actions: [
                {
                  label: 'Trở về',
                  status: 'basic',
                  keyShortcut: 'Escape',
                  action: async () => true,
                },
                {
                  label: 'Di chuyển',
                  status: 'danger',
                  action: async (form, dialog) => {
                    if (!form.get('Warehouse').value) {
                      this.cms.showError('Bạn chưa chọn Kho bãi', {duration: 5000});
                      return false;
                    }
                    if (!form.get('Parent').value) {
                      this.cms.showError('Bạn chưa chọn Vị trí', {duration: 5000});
                      return false;
                    }
                    lastMovedWarehouse = form.get('Warehouse').value;
                    return this.apiService.putPromise(this.apiPath, {}, this.selectedItems.map(m => ({
                      Code: m.Code,
                      Warehouse: this.cms.getObjectId(form.get('Warehouse').value),
                      Parent: this.cms.getObjectId(form.get('Parent').value),
                    }))).then(rs => {
                      this.refreshItems(this.selectedIds);
                      return true;
                    });
                  },
                },
              ],
            },
            closeOnEsc: false,
            closeOnBackdropClick: false,
          });
        }
      };

      this.actionButtonList.unshift(copyBtn);
      this.actionButtonList.unshift(moveBtn);
      return rs;
    });
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<WarehouseGoodsContainerModel, WarehouseGoodsContainerFormComponent>) {
    params['includeParent'] = true;
    params['includePath'] = true;
    params['includeWarehouse'] = true;
    // params['includeWarehouse'] = true;
    // params['includeGoods'] = true;
    params['includeIdText'] = true;
    return params;
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

}

