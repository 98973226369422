import {ProcessMap} from "../../models/process-map.model";
import {stateMap} from "../../state.map";

export class ZaloOaStateMap {


  // static SALESCOMPLETED: ProcessMap = {
  //     state: 'SALESCOMPLETED',
  //     label: 'Đã hoàn tất bán hàng',
  //     id: 'SALESCOMPLETED',
  //     text: 'Đã hoàn tất bán hàng',
  //     confirmLabel: 'Hoàn tất bán hàng',
  //     status: 'basic',
  //     color: 'gray',
  //     outline: true,
  //     confirmTitle: 'Chuyển trạng thái',
  //     confirmText: 'Bạn có muốn hoàn tất bán hàng',
  //     responseTitle: 'Chuyển trạng thái',
  //     responseText: 'Đã hoàn tất',
  // };

  static zaloOfficialAccountStateMap: { [key: string]: ProcessMap } = {
    'true': {
      state: 'ACTIVE',
      label: 'Common.active',
      status: 'success',
      outline: true,
      ghost: true,
      nextState: 'INACTIVE',
      nextStateLabel: 'Common.changeToInactive',
      confirmText: 'Common.inactiveConfirm',
      responseTitle: 'Common.inactivated',
      responseText: 'Common.inactiveSuccess',
    },
    'false': {
      state: 'INACTIVE',
      label: 'Chưa kích hoạt',
      status: 'basic',
      outline: true,
      ghost: true,
      nextState: 'ACTIVE',
      nextStateLabel: 'Common.active',
      confirmText: 'Common.activeConfirm',
      responseTitle: 'Common.activated',
      responseText: 'Common.activeSuccess',
    },
  };


}
