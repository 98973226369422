<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [ngStyle]="{width: width, height: height}">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Api Token' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="{{'Mô tả' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Mô tả' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Roles" label="{{'Quyền' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Roles" [select2Option]="select2OptionForRoles" [data]="roleList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Ip" label="{{'IP cho phép' | translate | headtitlecase}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Ip" placeholder="{{'Cách nhau dấu phẩy (,)' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer *ngIf="formItem.get('SystemUuid').value">
          <span style="font-style: italic; font-weight: bold; color: var(--text-warning-color)">Khi thay đổi Quyền hoặc IP thì token trước đó sẽ không sử dụng được nữa !</span>
        </nb-card-footer>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>{{ 'Common.addOne' | translate | headtitlecase }}</button>
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{ 'Common.saveAndClose' | translate | headtitlecase }}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>{{ 'Common.save' | translate | headtitlecase }}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
