import {Component, OnInit} from '@angular/core';
import {DataManagerListComponent, SmartTableSetting} from '../../../../lib/data-manager/data-manger-list.component';
import {WarehouseModel} from '../../../../models/warehouse.model';
import {WarehouseFormComponent} from '../warehouse-form/warehouse-form.component';
import {ApiService} from '../../../../services/api.service';
import {RootServices} from '../../../../services/root.services';
import {Router} from '@angular/router';
import {CommonService} from '../../../../services/common.service';
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AgGridDataManagerListComponent} from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import {ColDef, IGetRowsParams} from '@ag-grid-community/core';
import {agMakeCommandColDef} from '../../../../lib/custom-element/ag-list/column-define/command.define';
import {agMakeSelectionColDef} from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import {agMakeTextColDef} from '../../../../lib/custom-element/ag-list/column-define/text.define';

@Component({
  selector: 'ngx-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
})
export class WarehouseListComponent extends AgGridDataManagerListComponent<WarehouseModel, WarehouseFormComponent> implements OnInit {

  // Use for load settings menu for context
  feature = {
    Module: {id: 'Warehouse', text: 'Kho bãi'},
    Feature: {id: 'WarehouseManager', text: 'QL Kho'}
  };

  componentName: string = 'WarehouseListComponent';
  formPath = '/warehouse/warehouse/form';
  apiPath = '/warehouse/warehouses';
  idKey = ['Code'];
  formDialog = WarehouseFormComponent;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public themeService: NbThemeService,
    public ref: NbDialogRef<WarehouseListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mã',
          field: 'Code',
          width: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          width: 320,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 500,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }

  // loadListSetting(): any {
  //   return this.configSetting({
  //     mode: 'external',
  //     selectMode: 'multi',
  //     actions: {
  //       position: 'right',
  //     },
  //     // add: this.configAddButton(),
  //     // edit: this.configEditButton(),
  //     // delete: this.configDeleteButton(),
  //     // pager: this.configPaging(),
  //     columns: {
  //       Code: {
  //         title: this.cms.translateText('Common.name'),
  //         type: 'string',
  //         width: '10%',
  //       },
  //       Name: {
  //         title: this.cms.translateText('Common.name'),
  //         type: 'string',
  //         width: '40%',
  //       },
  //       Description: {
  //         title: this.cms.translateText('Common.description'),
  //         type: 'string',
  //         width: '25%',
  //       },
  //       // Branch: {
  //       //   title: this.cms.translateText('Common.branch'),
  //       //   type: 'string',
  //       //   width: '20%',
  //       // },
  //       FindOrder: {
  //         title: this.cms.translateText('Số nhận thức'),
  //         type: 'string',
  //         width: '5%',
  //       },
  //       AccAccountName: {
  //         title: this.cms.translateText('Warehouse.account'),
  //         type: 'string',
  //         width: '20%',
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<WarehouseModel, WarehouseFormComponent>) {
    params['includeBranch'] = true;
    return params;
  }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: WarehouseModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: WarehouseModel[] | HttpErrorResponse) => void) {
  //   params['includeBranch'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // getList(callback: (list: WarehouseModel[]) => void) {
  //   super.getList((rs) => {
  //     // rs.forEach(item => {
  //     //   item.Content = item.Content.substring(0, 256) + '...';
  //     // });
  //     if (callback) callback(rs.map(item => {
  //       item.Branch = this.cms.getObjectText(item.Branch);
  //       return item;
  //     }));
  //   });
  // }

  // /** Implement required */
  // openFormDialplog(ids?: string[], onDialogSave?: (newData: WarehouseModel[]) => void, onDialogClose?: () => void) {
  //   this.cms.openDialog(ProductCategoryFormComponent, {
  //     context: {
  //       inputMode: 'dialog',
  //       inputId: ids,
  //       onDialogSave: (newData: WarehouseModel[]) => {
  //         if (onDialogSave) onDialogSave(newData);
  //       },
  //       onDialogClose: () => {
  //         if (onDialogClose) onDialogClose();
  //         this.refresh();
  //       },
  //     },
  //   });
  // }

  // /** Go to form */
  // gotoForm(id?: string): false {
  //   this.openFormDialplog(id ? decodeURIComponent(id).split('&') : null);
  //   return false;
  // }

}

