import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IoRoutingModule} from './io-routing.module';
import {IoComponent} from './io.component';
import {IoWorkflowListComponent} from './io-workflow/io-workflow-list/io-workflow-list.component';
import {IoWorkflowFormComponent} from './io-workflow/io-workflow-form/io-workflow-form.component';
import {commonModules} from "../../common-modules";
import { IoWorkflowWebhookNodeComponent } from './io-workflow/nodes/webhook/io-workflow-webhook-node/io-workflow-webhook-node.component';
import { IoWorkflowWebhookNodeFormComponent } from './io-workflow/nodes/webhook/io-workflow-webhook-node-form/io-workflow-webhook-node-form.component';
import { IoWorkflowHttpRequestNodeComponent } from './io-workflow/nodes/http-request/io-workflow-http-request-node/io-workflow-http-request-node.component';
import { IoWorkflowHttpRequestNodeFormComponent } from './io-workflow/nodes/http-request/io-workflow-http-request-node-form/io-workflow-http-request-node-form.component';

@NgModule({
  declarations: [
    IoComponent,
    IoWorkflowListComponent,
    IoWorkflowFormComponent,
    IoWorkflowWebhookNodeComponent,
    IoWorkflowWebhookNodeFormComponent,
    IoWorkflowHttpRequestNodeComponent,
    IoWorkflowHttpRequestNodeFormComponent,
  ],
  imports: [
    CommonModule,
    IoRoutingModule,
    ...commonModules,
  ]
})
export class IoModule {
}
