import {ZaloOaComponent} from './zalo-oa.component';
import {ZaloOaConversationComponent} from './conversation/zalo-oa-conversation/zalo-oa-conversation.component';
import {ZaloOfficialAccountListComponent} from './official-account/zalo-official-account-list/zalo-official-account-list.component';
import {ZaloOfficialAccountFormComponent} from './official-account/zalo-official-account-form/zalo-official-account-form.component';
import {NgModule} from '@angular/core';
import {commonModules, commonProviders} from '../../common-modules';
import {ZaloOaRoutingModule} from './zalo-ao-routing.module';
import { ZaloOaTemplateListComponent } from './template/zalo-oa-template-list/zalo-oa-template-list.component';
import { ZaloOaTemplateFormComponent } from './template/zalo-oa-template-form/zalo-oa-template-form.component';
import {ZaloOaFollowerListComponent} from "./follower/zalo-oa-follower-list/zalo-oa-follower-list.component";
import {ZaloOaFollowerFormComponent} from "./follower/zalo-oa-follower-form/zalo-oa-follower-form.component";

@NgModule({
  declarations: [
    ZaloOaComponent,
    ZaloOaConversationComponent,
    ZaloOfficialAccountListComponent,
    ZaloOfficialAccountFormComponent,
    ZaloOaTemplateListComponent,
    ZaloOaTemplateFormComponent,
    ZaloOaFollowerListComponent,
    ZaloOaFollowerFormComponent,
  ],
  imports: [
    ...commonModules,
    ZaloOaRoutingModule,
  ],
  bootstrap: [ZaloOaComponent],
  providers: [
    ...commonProviders,
  ],
})
export class ZaloOaModule {
}