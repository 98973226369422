import {Component, OnInit} from '@angular/core';
import {AgGridDataManagerListComponent} from "../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {Model} from "../../../../models/model";
import {RootServices} from "../../../../services/root.services";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {CommonService} from "../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {HttpClient} from "@angular/common/http";
import {CodeTemplateStateMap} from "../../../code-template/code-template.state.map";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import {agMakeStateColDef} from "../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {HelpdeskTicketFormComponent} from "../helpdesk-ticket-form/helpdesk-ticket-form.component";
import {HelpdeskStateMap} from "../../helpdesk-state.map";

@Component({
  selector: 'ngx-helpdesk-ticket-list',
  templateUrl: './helpdesk-ticket-list.component.html',
  styleUrls: ['./helpdesk-ticket-list.component.scss']
})
export class HelpdeskTicketListComponent extends AgGridDataManagerListComponent<Model, HelpdeskTicketFormComponent> implements OnInit {

  componentName: string = 'HelpdeskTicketListComponent';
  apiPath = '/helpdesk/tickets';
  formPath = '/helpdesk/ticket/form';
  idKey = ['Code'];
  // formDialog = HelpdeskTicketFormComponent;
  // printDialog = SystemApiDocPrintComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<HelpdeskTicketListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      const processingMap = HelpdeskStateMap.ticketStateMap;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiêu đề',
          field: 'Title',
          pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 800,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
