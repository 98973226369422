import {Component, OnInit} from '@angular/core';
import {DataManagerFormComponent} from "../../../../../lib/data-manager/data-manager-form.component";
import {Model} from "../../../../../models/model";
import {RootServices} from "../../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../../services/common.service";
import {AdminProductService} from "../../../../admin-product/admin-product.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Select2Option} from "../../../../../lib/custom-element/select2/select2.component";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'ngx-system-webhook-form',
  templateUrl: './system-api-token-form.component.html',
  styleUrls: ['./system-api-token-form.component.scss']
})
export class SystemApiTokenFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'SystemApiTokenFormComponent';
  idKey = 'SystemUuid';
  apiPath = '/system/api/tokens';
  baseFormUrl = '/system/api/token/form';

  roleList = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<SystemApiTokenFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  select2OptionForRoles: Select2Option = {
    placeholder: 'Chọn...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  getRequestId(callback: (id?: string[]) => void) {
    callback(this.inputId);
  }


  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    this.cms.loginInfo$.pipe(
      takeUntil(this.destroy$),
      filter(f => !!f)
    ).subscribe(
      loginInfo => this.roleList = loginInfo.roles
        .filter(f => this.cms.getObjectId(f))
        .map(m => {
          if (!m.text) m.text = m.id;
          return m;
        }));
    return super.init();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      SystemUuid: [],
      Description: [null, Validators.required],
      Roles: [],
      Ip: [],
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  async save(): Promise<Model[]> {
    return super.save().then(rs => {
      this.adminProductService.updateGroupList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  }

}
