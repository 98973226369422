import {ColDef} from "@ag-grid-community/core";
import {CommonService} from "../../../../services/common.service";
import {AgButtonsCellRenderer} from "../cell/buttons.component";
import {AgGridDataManagerListComponent} from "../../../data-manager/ag-grid-data-manger-list.component";
import {AgCellButton} from "../cell/button.component";
import {AgCommandFilter} from "../filter/command.component.filter";

export const agMakeCommandColDef = <F, M>(
  listComponent: AgGridDataManagerListComponent<F, M>,
  cms: CommonService,
  editAction?: ((data: any) => void) | boolean,
  deleteAction?: ((data: any) => void) | boolean,
  permissionAction?: ((data: any) => void) | boolean,
  extendActions?: AgCellButton[],
  cellRendererParams?: { [key: string]: any, allowWrap?: boolean }
): ColDef => {
  const buttons = [];
  let width = 0;
  if (permissionAction) {
    buttons.push({
      name: 'permission',
      status: 'warning',
      icon: 'shield-outline',
      outline: false,
      ghost: true,
      action: async (params: any, button: any) => {
        return typeof permissionAction == 'function' ? permissionAction(params.node.data) : listComponent?.openPermissionForm(params.node.data);
      }
    });
    width += 40;
  }
  if (editAction) {
    buttons.push({
      name: 'edit',
      status: 'info',
      icon: 'edit-2-outline',
      outline: false,
      ghost: true,
      action: async (params: any, button: any) => {
        typeof editAction == 'function' ? editAction(params.node.data) : listComponent?.openForm([listComponent.makeId(params.node.data)]);
        return true;
      }
    });
    width += 40;
  }
  if (deleteAction) {
    buttons.push({
      name: 'delete',
      status: 'danger',
      icon: 'trash-2-outline',
      outline: false,
      ghost: true,
      action: async (params: any, button: any) => {
        typeof deleteAction == 'function' ? deleteAction(params.node.data) : listComponent?.deleteConfirm([listComponent.makeId(params.node.data)]);
        return true;
      }
    });
    width += 40;
  }
  if (extendActions) {
    for (const extendAction of extendActions) {
      if (typeof extendAction.ghost == 'undefined') {
        // Ghost state is default true
        extendAction.ghost = true;
      }
      if (extendAction.appendTo == 'head') {
        buttons.unshift(extendAction);
      } else {
        buttons.push(extendAction);
      }
      width += 40;
    }
  }
  const config: ColDef = {
    headerName: 'Sửa/Xóa',
    field: 'Command',
    maxWidth: width > 120 ? width : 120,
    width: width > 120 ? width : 120,
    // filter: false,
    // filter: AgCommandFilter,
    floatingFilterComponent: AgCommandFilter,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    sortable: false,
    pinned: 'right',
    type: 'rightAligned',
    cellClass: [
      'ag-cell-items-center',
      'ag-cell-justify-end',
      'ag-cell-no-padding-left',
      'ag-cell-no-padding-right',
      ...(cellRendererParams?.allowWrap && ['ag-cell-2line-wrap'] || [])],
    cellRenderer: AgButtonsCellRenderer,
    // resizable: false,
    cellStyle: {'text-overflow': 'initial', 'border': 'none'},
    cellRendererParams: {
      buttons: buttons,
      ...cellRendererParams,
    }
  };
  return config;
};
