import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService} from '../../services/auth-guard.service';
import {SystemComponent} from './system.component';
import {SystemParameterListComponent} from './parameter/system-parameter-list/system-parameter-list.component';
import {SystemParameterFormComponent} from './parameter/system-parameter-form/system-parameter-form.component';
import {SystemConfigurationBoardComponent} from '../../lib/component/configuration/system-configuration-board/system-configuration-board.component';
import {UserConfigBoardComponent} from '../../lib/component/configuration/user-config-board/user-config-board.component';
import {SystemRouteListComponent} from './route/system-route-list/system-route-list.component';
import {SystemRouteFormComponent} from './route/system-route-form/system-route-form.component';
import {SystemParamListComponent} from './param/system-param-list/system-param-list.component';
import {SystemParamFormComponent} from './param/system-param-form/system-param-form.component';
import {SystemActionListComponent} from './action/system-action-list/system-action-list.component';
import {SystemActionFormComponent} from './action/system-action-form/system-action-form.component';
import {SystemLibraryIconComponent} from './library/icon/system-library-icon/system-library-icon.component';
import {SystemApiTokenListComponent} from "./api/system-api-token/system-api-token-list/system-api-token-list.component";
import {SystemWebhookListComponent} from "./hook/system-webhook/system-webhook-list/system-webhook-list.component";
import {SystemApiDocListComponent} from "./api/system-api-docs/system-api-doc-list/system-api-doc-list.component";

export const systemRoutes: Routes = [
  {
    path: 'parameter/list',
    canActivate: [AuthGuardService],
    component: SystemParameterListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'parameter/form',
    canActivate: [AuthGuardService],
    component: SystemParameterFormComponent,
  },
  {
    path: 'parameter/form/:id',
    canActivate: [AuthGuardService],
    component: SystemParameterFormComponent,
  },
  {
    path: 'config-board',
    canActivate: [AuthGuardService],
    component: SystemConfigurationBoardComponent,
  },
  {
    path: '-config-board',
    canActivate: [AuthGuardService],
    component: UserConfigBoardComponent,
  },
  // Routes
  {
    path: 'route/rule/list',
    canActivate: [AuthGuardService],
    component: SystemRouteListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'route/rule/form',
    canActivate: [AuthGuardService],
    component: SystemRouteFormComponent,
  },
  {
    path: 'route/rule/form/:id',
    canActivate: [AuthGuardService],
    component: SystemRouteFormComponent,
  },
  // Params
  {
    path: 'route/param/list',
    canActivate: [AuthGuardService],
    component: SystemParamListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'route/param/form',
    canActivate: [AuthGuardService],
    component: SystemParamFormComponent,
  },
  {
    path: 'route/param/form/:id',
    canActivate: [AuthGuardService],
    component: SystemParamFormComponent,
  },
  // Action
  {
    path: 'route/action/list',
    canActivate: [AuthGuardService],
    component: SystemActionListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'route/action/form',
    canActivate: [AuthGuardService],
    component: SystemActionFormComponent,
  },
  {
    path: 'route/action/form/:id',
    canActivate: [AuthGuardService],
    component: SystemActionFormComponent,
  },
  // Icon
  {
    path: 'library/icon',
    canActivate: [AuthGuardService],
    component: SystemLibraryIconComponent,
  },
  {
    path: 'api',
    children: [
      {
        path: 'token',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list',
          },
          {
            path: 'list',
            canActivate: [AuthGuardService],
            component: SystemApiTokenListComponent,
            data: {
              reuse: true,
            },
          },
        ],
      },
      {
        path: 'docs',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list',
          },
          {
            path: 'list',
            canActivate: [AuthGuardService],
            component: SystemApiDocListComponent,
            data: {
              reuse: true,
            },
          },
        ],
      },
    ]
  },
  {
    path: 'hook',
    children: [
      {
        path: 'webhook',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list',
          },
          {
            path: 'list',
            canActivate: [AuthGuardService],
            component: SystemWebhookListComponent,
            data: {
              reuse: true,
            },
          },
        ],
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {
}
